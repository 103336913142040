import React, {useEffect, useState} from "react";
import {Container, Row, Spinner} from "react-bootstrap";

const Loader = () => {
    const colors = ["dark","primary","secondary","success","danger","info","warning"];
    const [index,setIndex] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            if(index>4)
                setIndex(0);
            else
                setIndex(index + 1);
        },325)

        return () => {
            return setIndex('');
        }
    },[index]);

    return(
        <Container fluid className="vh-50 d-flex">
            <Row className="justify-content-center align-self-center w-100 text-center">
                <Spinner
                    animation={"border"}
                    key={0}
                    variant = {colors[index]}
                    className="me-2"
                />
            </Row>
         </Container>
    );
}



export default Loader;
