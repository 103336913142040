import React from "react";
import {Helmet} from "react-helmet-async";
import {Container} from "react-bootstrap";
import EntityTable, {SelectColumnFilter} from "../../../../components/EntityTable";
import {getBadge} from "../../../../utils/misc";


const purchaseBillTableColumns = [
    {
        Header: "Invoice Number",
        accessor: "invoice_number",
    },
    {
        Header: "PurchaseOrder Number",
        accessor: "purchaseOrder.purchase_number",
    },
    {
        Header: "Created Date",
        accessor: "created_date",Filter: false,
        Cell: ({ cell: { value } }) => {
            let date = new Date(Number(value));
            date = date.toString().slice(0,24)
            return date;
        }

    },
    {
        Header: "Created By",
        accessor: "purchaseOrder.created_by.name",
        Filter: SelectColumnFilter,
        filter: "includes",
    },
    {
        Header: "Grand Total",
        accessor: "total_sale_after_tax",
        filter:false
    },
    {
        Header: "Status",
        accessor: "purchaseOrder.status",
        Filter: SelectColumnFilter,
        Cell: ({ cell: { value } }) => {
            return getBadge(value)
        }
    }
];

const PurchaseBillsPage = () => {

    return(<React.Fragment>
        <Helmet title="Purchase Bills"/>
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Purchase Bills</h1>
            <EntityTable
                columns={purchaseBillTableColumns}
                url={"buyhappy_purchase_invoices"}
                sortBy={[{field: "invoice_number", order: "desc"}]}
                buttons={[]}
            />
        </Container>
    </React.Fragment>);

};

export default PurchaseBillsPage;



