import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";

import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import Select from "react-select";
import axiosInstance, {ErrorMessage, GetAsInputData} from "../../../utils/axios";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {Formik} from "formik";


const paymentsSchema = Yup.object().shape({
    dealer: Yup.object().required("Trader Name is required"),
    bank_name: Yup.string().required("Bank name is required"),
    amount: Yup.string().required("Amount is required"),
    transaction_reference: Yup.string().required("Transaction reference is required"),
    inward_date: Yup.string().required("Inward Date & Time is required"),
    time: Yup.string().required("Inward Date & Time is required"),

});


const initialValues = {
    dealer: undefined,
    transaction_type:"CUSTOMER_PAYMENT",
    bank_name:undefined,
    amount:undefined,
    transaction_reference:undefined,
    inward_date:undefined,
    time:undefined
};

const PaymentsForm = () => {
    const navigate = useNavigate();
    const [customerOptions, setCustomerOptions] = useState([]);
    const [isCustomersLoading, setIsCustomersLoading] = useState(true);

    function HandleSubmit(url,data,navigateTo){
        axiosInstance.post("/api/"+url,data).then(function(response,success){
            if(response.status===200)
                navigate(navigateTo);
        })
    }

    useEffect(() => {
        const loadSelectData =  () => {
            try{
                axiosInstance.get("/api/customers?"+GetAsInputData({
                    listInfo:{
                        startRecord:0,
                        rowCount:9999,
                        sortBy: [{"field":"dealer_name","order":"asc"}],
                        searchConditions: [{"field":"type","enumName":"DealerType","type":"enum","value":"RETAILER","condition":"not_equals"}]
                    }
                })).then(function(response){
                    setCustomerOptions(response.data.customers);
                    setTimeout(() => {
                        setIsCustomersLoading(false);
                    }, 1500);
                });


            }catch(e){
                console.log(e);
            }
        }
        loadSelectData();
    },[]);

    return( <Card>
        <Card.Header>
            <Card.Title tag="h5">Add Payment</Card.Title>
        </Card.Header>
        <Card.Body>
            <Formik
                validationSchema={paymentsSchema}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500));
                    HandleSubmit("incoming_cash",GetAsInputData({incoming_cash:values}),"/payments/listings")
                }}
                initialValues={initialValues}>
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                  }) => (<Form noValidate onSubmit={handleSubmit}>
                    <Row>
                        <Col sm={5}>
                            <Form.Group  className="mb-3">
                                <Form.Label>Trader Name</Form.Label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="dealer"
                                    options={customerOptions.map((key, text) => {
                                        return {
                                            value: key.dealer_id,
                                            label: key.dealer_name,
                                            dealer_id:key.dealer_id,
                                        }}
                                    )}
                                    isLoading={isCustomersLoading}
                                    onChange={selectedOption => {
                                        let event = { target : { name:'dealer',value: selectedOption}}
                                        handleChange(event);
                                    }}
                                />
                                <ErrorMessage name={`dealer`} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Amount
                                </Form.Label>
                                <Form.Control type="number"
                                              name="amount"
                                              onChange={handleChange}
                                              isValid={touched.amount && !errors.amount}
                                              isInvalid={touched.amount && !!errors.amount}
                                              placeholder="Amount" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.amount}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Label>
                                Transaction Reference
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="transaction_reference"
                                isValid={touched.transaction_reference && !errors.transaction_reference}
                                isInvalid={touched.transaction_reference && !!errors.transaction_reference}
                                onChange={handleChange}
                                placeholder="Transaction Reference" />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.transaction_reference}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Bank name
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="bank_name"
                                    isValid={touched.bank_name && !errors.bank_name}
                                    isInvalid={touched.bank_name && !!errors.bank_name}
                                    onChange={handleChange}
                                    placeholder="Bank name" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.bank_name}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Inward Date and Time
                                </Form.Label>
                                <Form.Control name="time"
                                              placeholder="Inward Date and Time"
                                              isValid={touched.time && !errors.time}
                                              isInvalid={touched.time && !!errors.time}
                                              onChange={event => {
                                                  let anotherEvent = { target : { name:`inward_date`,value:new Date(event.currentTarget.value).getTime()   } }
                                                  handleChange(event);
                                                  handleChange(anotherEvent);
                                              }}
                                              type="datetime-local"  />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.time}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Button type="submit" variant="primary">Submit</Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>)}
            </Formik>

        </Card.Body>
    </Card>);
}


const PaymentsFormPage = () => (
    <React.Fragment>
        <Helmet title="New Payments" />
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Payments Entry</h1>

            <Row>
                <Col lg="8">
                    <PaymentsForm />
                </Col>
            </Row>
        </Container>
    </React.Fragment>
);

export default PaymentsFormPage;
