import React from "react";
import {Helmet} from "react-helmet-async";
import {Container} from "react-bootstrap";
import EntityTable from "../../../../components/EntityTable";
import {useNavigate} from "react-router-dom";


const activeDealersTableColumns = [
    {
        Header: "Name",
        accessor: "dealer_name",
    },
    {
        Header: "Region",
        accessor: "zone.region_name",
    },
    {
        Header: "GST",
        accessor: "gst"
    }
];


const activeDealerButtons = [
    {
        key:0,
        name:"New Non Biller",
        href:"/customers/active/non-billing-dealer-new"
    }
];

const NonBillerDealer = () => {

    const navigate = useNavigate();

    return(<React.Fragment>
        <Helmet title="Non Billers"/>
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Active Non Billers</h1>

            <EntityTable
                key={new Date().getTime()}
                columns={activeDealersTableColumns}
                url={"non_billing_dealers"}
                pageSize={99999}
                sortBy={[{field: "dealer_name", order: "asc"}]}
                buttons={activeDealerButtons}
                rowProps={row => ({
                    onClick: () => navigate("/customers/active/non-billers-details/"+row.original.dealer_id) ,
                    style: {
                        cursor: "pointer"
                    }
                })}
            />
        </Container>
    </React.Fragment>);

};

export default NonBillerDealer;
