import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import {Container} from "react-bootstrap";
import EntityTable, {CustomFilter} from "../../../components/EntityTable";
import {useNavigate} from "react-router-dom";


const SDBeatsPage = () => {
    const navigate = useNavigate();

    const [searchCondition,setSearchCondition] = useState();
    const [searchConditionSelectedValue,setSearchConditionSelectedValue] = useState();
    const [searchCondition1,setSearchCondition1] = useState();
    const [searchConditionSelectedValue1,setSearchConditionSelectedValue1] = useState();

    const regionSearchCondition = [{field: "country.id", type: "number", value: "96"}]

    const sdBeatsColumns = [
        {
            Header: "SD ID",
            accessor: "beat_sd_id",
        },
        {
            Header: "Name",
            accessor: "beat_name",
        },
        {
            Header: "Region",
            accessor: "beat_region.region_name",
            Filter: () => {
                return CustomFilter({
                        entityUrl:"regions",
                        setCondition:setSearchCondition,
                        entityId:"id",
                        entityValue:"region_name",
                        searchField:"beat_region.id",
                        searchType:"number",
                        entity:"regions",
                        searchCondition:regionSearchCondition,
                        selectedValue:searchConditionSelectedValue1,
                        setSelectedValue:setSearchConditionSelectedValue1
                    }
                )
            },
        },
        {
            Header: "Retailer Count",
            accessor: "retailer_count",
        },
        {
            Header: "Supermarket Count",
            accessor: "superMarketCount",
        },
        {
            Header: "MT Count",
            accessor: "mtcount",
        },
        {
            Header: "Chemist Count",
            accessor: "chemistCount",
        },
        {
            Header: "FancyCount",
            accessor: "fancyCount",
        },

        {
            Header: "CosmeticsCount",
            accessor: "cosmeticsCount",
        },
        {
            Header: "HypermarketCount",
            accessor: "hypermarketCount",
        },
        {
            Header: "KiranaCount",
            accessor: "kiranaCount",
        },
        {
            Header: "GMCount",
            accessor: "gmcount",
        },
        {
            Header: "OthersCount",
            accessor: "othersCount",
        },
        {
            Header: "SD Distributor",
            accessor: "distributor.distributorName",
            Filter: () => {
                return CustomFilter({
                        entityUrl:"sd_distributors",
                        setCondition:setSearchCondition1,
                        entityId:"distributor_id",
                        entityValue:"distributorName",
                        searchField:"distributor.distributor_id",
                        searchType:"number",
                        entity:"sd_distributors",
                        selectedValue:searchConditionSelectedValue,
                        setSelectedValue:setSearchConditionSelectedValue
                    }
                )
            },
        },
        {
            Header: "Firestore",
            accessor: "beat_firestore_id",
            Filter:false
        },
    ];

    let condition = [];
    searchCondition!=null && condition.push(searchCondition)
    searchCondition1!=null && condition.push(searchCondition1)
    if(condition.length==0)
        condition = null

    return(<React.Fragment>
        <Helmet title="Sales Diary Beats"/>
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Sales Diary Beats</h1>
            <EntityTable
                key={new Date().getTime()}
                columns={sdBeatsColumns}
                url={"sd_beats"}
                pageSize={200}
                sortBy={[{field: "beat_name", order: "asc"}]}
                buttons={[]}
                searchCondition={condition}
                rowProps={row => ({
                   onClick: () => navigate("/sd/beats-details/"+row.original.beat_id) ,
                   style: {
                       cursor: "pointer"
                   }
                })}
            />
        </Container>
    </React.Fragment>);

};

export default SDBeatsPage;
