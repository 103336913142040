import React from "react";
import {Helmet} from "react-helmet-async";
import {Container} from "react-bootstrap";
import EntityTable, {SelectColumnFilter} from "../../../../components/EntityTable";
import {useNavigate} from "react-router-dom";
import {getBadge} from "../../../../utils/misc";


const prepaidInvoiceTableColumns = [
    {
        Header: "Invoice Number",
        accessor: "invoice_number",
    },
    // {
    //     Header: "SaleOrder Number",
    //     accessor: "salesOrderShortList.sale_number",
    // },
    // {
    //     Header: "Dealer Name",
    //     accessor: "dealer.dealer_name",
    //     Filter:SelectColumnFilter
    // },
    {
        Header: "Created Date",
        accessor: "created_date",Filter: false,
        Cell: ({ cell: { value } }) => {
            let date = new Date(Number(value));
            date = date.toString().slice(0,24)
            return date;
        }

    },
    {
        Header: "Created By",
        accessor: "created_by.name",
        Filter: SelectColumnFilter,
        filter: "includes",
    },
    {
        Header: "Grand Total",
        accessor: "total_sale_after_tax",
        filter:false
    },
    {
        Header: "Status",
        accessor: "status",
        Filter: SelectColumnFilter,
        Cell: ({ cell: { value } }) => {
            return getBadge(value);
        }
    }
];

const invoiceButtons = [
    {
        key:0,
        name:"New Prepaid Invoice",
        href:"/invoices/prepaid-new"
    }
];

const PrepaidInvoicePage = () => {

    const navigate = useNavigate();

    return(<React.Fragment>
        <Helmet title="Invoices"/>
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Prepaid Invoices</h1>
            <EntityTable
                columns={prepaidInvoiceTableColumns}
                url={"buyhappy_prepaid_invoices"}
                sortBy={[{field: "id", order: "desc"},{field: "invoice_number", order: "desc"}]}
                buttons={invoiceButtons}
                rowProps={row => ({
                    onClick: () => navigate("/invoices/prepaid-details/"+row.original.id) ,
                    style: {
                        cursor: "pointer"
                    }
                })}
            />
        </Container>
    </React.Fragment>);

};

export default PrepaidInvoicePage;



