import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Button, ButtonGroup, Col, Container, Nav, Row, Tab} from "react-bootstrap";
import {useParams} from "react-router-dom";
import axiosInstance from "../../../../utils/axios";
import Loader from "../../../../components/Loader";
import ClaimsDetailsDealerPageListView from "./ClaimsDetailsDealerPageListView";
import ClaimDetailsDealerPageCardView from "./ClaimDetailsDealerPageCardView";
import ClaimsTableDealerListView from "./ClaimsTableDealerListView";
import ClaimsTableDealerSegregationView from "./ClaimsTableDealerSegregationView";

const ClaimDealerDetails = () => {
    const params = useParams();
    const [key,setKey] = useState(new Date().getTime());
    const [isLoading,setIsLoading] = useState(true);
    const [retailerData,setRetailerData] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");

    const filteredRetailers = retailerData.filter((retailer) => {
        const hasPendingClaims = retailer.claimCount.some(
            (claim) => claim.status !== "TRUE"
        );
        return (
            (retailer.retailer.dealer_name
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase()) ||
                retailer.retailer.dealer_id.toString().includes(searchQuery)) &&
            hasPendingClaims
        );
    });

    const [selectedView,setSelectedView] = useState("Listview");

    useEffect(() => {
        updateData();
    }, [key]);

    function updateData(){
        try{
            let entityURL = "/api/claims_tracker/dealer/"+params.dealerID;
            axiosInstance.get(entityURL)
                .then(function(response){
                    let responseData = response.data.claims_tracker;
                    const claimsByRetailer = {};
                    for (let claim of responseData) {
                        const retailerId = claim.retailer.dealer_id;
                        let formattedDateString = '';
                        if (claim && claim.month) {
                            const dateString = claim.month;
                            const dateComponents = dateString.split("-");

                            // Ensure that dateComponents array has two elements (year and month)
                            if (dateComponents.length === 2) {
                                const [year, month] = dateComponents;

                                // Create a date with year and month (subtract 1 from month because JavaScript counts months from 0)
                                const date = new Date(year, month - 1);

                                // Get month name and year
                                const monthName = date.toLocaleDateString("en-US", { month: "short" });
                                const yearString = date.getFullYear().toString();

                                // Format the date string
                                formattedDateString = `${yearString}-${monthName}`;
                            } else {
                                console.error('Unexpected date format. Expected format is "yyyy-mm".');
                            }
                        } else {
                            console.error('Claim or claim.month is null or undefined.');
                        }


                        if (!claimsByRetailer[retailerId]) {
                            claimsByRetailer[retailerId] = {
                                retailer: claim.retailer,
                                dealer: claim.dealer,
                                claimCount:[{
                                    count:claim.count,
                                    status:claim.approvalStatus,
                                    month:formattedDateString,
                                    totalAmount:claim.totalAmount
                                }]
                            };
                        }else{
                            let lastClaim =  claimsByRetailer[retailerId].claimCount.slice(-1)[0];
                            let difference = lastClaim.totalAmount - claim.totalAmount ;
                            let percentage = (difference / claim.totalAmount) * 100; // calculate the percentage difference
                            lastClaim.difference = Math.round(difference * 100) / 100; // round the difference to 2 decimal places
                            lastClaim.percentage = Math.round(percentage * 100) / 100; // round the percentage to 2 decimal places
                            claimsByRetailer[retailerId].claimCount.push({
                                count:claim.count,
                                status:claim.approvalStatus,
                                month:formattedDateString,
                                totalAmount:claim.totalAmount,
                                percentage:0,
                                difference:"-"
                            });
                        }
                    }
                    setRetailerData(Object.values(claimsByRetailer));
                    setIsLoading(false);
                });

        }catch(e){
            console.log(e);
        }
    }

    if(isLoading){
        return <Loader />;
    }else{
        return(

            <Container fluid className="p-0">
                <h1>Claim Overview - {retailerData[0]?.dealer?.dealer_name || 'Unknown Dealer'}</h1>

                <Row>
                    <Col lg="12">
                        <div className={"tab "}>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Nav variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">UnApproved Claims</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">All Claims</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">Claim Month Wise</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <ButtonGroup>
                                                <Button
                                                    variant={selectedView === 'Listview' ? 'primary' : 'outline-primary'}
                                                    onClick={() => {
                                                        setSelectedView('Listview');
                                                    }}
                                                >
                                                    List view
                                                </Button>
                                                <Button
                                                    variant={selectedView === 'Dashboard' ? 'primary' : 'outline-primary'}
                                                    onClick={() => {
                                                        setSelectedView('Dashboard');
                                                    }}
                                                >
                                                    Card view
                                                </Button>
                                            </ButtonGroup>
                                            {selectedView === 'Dashboard' &&  <><div className="d-flex align-items-center">
                                                <input
                                                    type="text"
                                                    className="form-control me-2"
                                                    placeholder="Search Retailer"
                                                    value={searchQuery}
                                                    onChange={(e) => setSearchQuery(e.target.value)}
                                                />
                                                <span className="text-muted">
                                                    Showing {filteredRetailers.length} of {retailerData.length} retailers
                                                </span>
                                            </div></>}

                                        </div>
                                        {selectedView==="Listview" && <ClaimsDetailsDealerPageListView key={key} setKey={setKey} dealer={retailerData[0]?.dealer}/> }
                                        {selectedView==="Dashboard" && <Row>
                                            {filteredRetailers.map((retailer) => (
                                                <ClaimDetailsDealerPageCardView key={key} retailer={retailer} />
                                            ))}
                                        </Row> }

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <Row>
                                            <ClaimsTableDealerListView key={key} dealerId={params.dealerID} />
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <Row>
                                            <ClaimsTableDealerSegregationView key={key} dealerId={params.dealerID} />
                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
};

const ClaimDetailsPage = () => (

    <React.Fragment>
        <Helmet title="Claim Details" />
        <ClaimDealerDetails />
    </React.Fragment>
);

export default ClaimDetailsPage;
