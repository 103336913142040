import React, {useContext} from "react";

import {Button, Card, Col, Form, Row} from "react-bootstrap";
import * as Yup from "yup";
import {Formik} from "formik";
import axiosInstance, {GetAsInputData,} from "../../../../utils/axios";
import NotyfContext from "../../../../contexts/NotyfContext";

const TransportForm = ({ setKey, initialValues, setEditData }) => {
  const notify = useContext(NotyfContext);

  const schema = Yup.object().shape({
    transport_name: Yup.string().required("Name is required"),
    transport_id:Yup.string().when('transport_vehicle_number', {
      is: (transport_vehicle_number) => !transport_vehicle_number || transport_vehicle_number.length === 0 ,
      then: Yup.string()
          .required('Enter either transporter ID or transport vehicle number ').nullable(),
    }).nullable().test('len', 'Must be exactly 15 characters', val => {
     if(val == undefined)
       return true;
      return val.length === 15
    }),
    transport_vehicle_number:Yup.string().when('transport_id', {
      is: (transport_id) => !transport_id || transport_id.length === 0 ,
      then: Yup.string()
          .required('Enter either transporter ID or Enter transport vehicle number').nullable(),
    }).nullable().test('len', 'Must be greater than 7 characters', val => {
      if(val == undefined)
        return true;
      return val.length > 7
    }),
  },["transport_id","transport_vehicle_number"]);

  function HandleSubmit(url, data, navigateTo) {
    axiosInstance.post("/api/" + url, data).then(function (response, success) {
      notify.success("Transport created");
      setTimeout(() => {
        setKey(new Date().getTime());
      }, 1500);
    });
  }

  function HandlePut(url, data, navigateTo) {
    axiosInstance.put("/api/" + url, data).then(function (response, success) {
      notify.success("Transport updated");
      setTimeout(() => {
        setEditData(null);
        setKey(new Date().getTime());
      }, 1500);
    });
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5">
          {initialValues.id == undefined ? "Add" : "Modify"} Transport
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Formik
          validationSchema={schema}
          onSubmit={async (values) => {
            if (values.id === undefined)
              HandleSubmit("eway_transports", GetAsInputData({ eway_transport: values }));
            else
              HandlePut(
                "eway_transports/" + values.id,
                GetAsInputData({ eway_transport: values })
              );
          }}
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row>
                <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="transport_name"
                      value={values.transport_name}
                      onChange={handleChange}
                      isValid={touched.transport_name && !errors.transport_name}
                      isInvalid={touched.transport_name && !!errors.transport_name}
                      placeholder="Name"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.transport_name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>ID</Form.Label>
                    <Form.Control
                        type="text"
                        name="transport_id"
                        value={values.transport_id}
                        onChange={handleChange}
                        isValid={touched.transport_id && !errors.transport_id}
                        isInvalid={touched.transport_id && !!errors.transport_id}
                        placeholder="Transport ID"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.transport_id}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Vehicle Number</Form.Label>
                    <Form.Control
                        type="text"
                        name="transport_vehicle_number"
                        value={values.transport_vehicle_number}
                        onChange={handleChange}
                        isValid={touched.transport_vehicle_number && !errors.transport_vehicle_number}
                        isInvalid={touched.transport_vehicle_number && !!errors.transport_vehicle_number}
                        placeholder="Transport Vehicle Number"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.transport_vehicle_number}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Button type="submit" variant="primary">
                      Submit
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default TransportForm;
