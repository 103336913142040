import React, {useContext, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Button, Container} from "react-bootstrap";
import EntityTable, {CustomFilter} from "../../../components/EntityTable";
import axiosInstance, {DownloadAttachment} from "../../../utils/axios";
import {getTimeStringFromLongNumber} from "../../../utils/misc";
import NotyfContext from "../../../contexts/NotyfContext";
import {useParams} from "react-router-dom";
import PaymentDownloadComponent from "./PaymentDownloadComponent";
import Loader from "../../../components/Loader";


const WarehouseLedgerReportTablePage = () => {

    const notify = useContext(NotyfContext)
    const params = useParams();

    const [searchCondition1,setSearchCondition1] = useState();
    const [searchConditionSelectedValue1,setSearchConditionSelectedValue1] = useState();
    const [warehouse,setWarehouse] = useState();
    const [key,setKey] = useState(1);

    const claimsTableColumns = [
        {
            Header: "Type",
            accessor: "report_type",
            Filter: false,
        },
        {
            Header: "Description",
            accessor: "description",
            Filter: false,
        },
        {
            Header: "Created Date",
            accessor: "created_date",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                return getTimeStringFromLongNumber(value)
            }

        },
        {
            Header: "Created By",
            accessor: "created_by.name",
            Filter: () => {
                return CustomFilter({
                        entityUrl:"users",
                        setCondition:setSearchCondition1,
                        entityId:"user_id",
                        entityValue:"name",
                        searchField:"created_by.user_id",
                        searchType:"number",
                        entity:"users",
                        selectedValue:searchConditionSelectedValue1,
                        setSelectedValue:setSearchConditionSelectedValue1
                    }
                )
            },
        },
        {
            Header: "Attachment",
            accessor: "attachment",
            Filter:false,
            Cell: ({ cell: { value } }) => {
               if(value==null)
                   return "N/A"
                else
                   return <Button onClick={() => {
                        DownloadAttachment("/api/downloadFile/ledger_report/" + value.id);
                    }}>Download {value.fileName}</Button>
            }
        }
    ];

    let condition = [{
        field:"report_type",
        enumName:"ReportType",
        type:"enum",
        value:"GST_LEDGER"
    },{
        field:"additional_options",
        value:"warehouse_"+params.warehouseID
    }];
    searchCondition1!=null && condition.push(searchCondition1)



    useEffect(() => {
        axiosInstance.get("/api/warehouses/"+params.warehouseID).then(r => {
            setWarehouse(r.data.warehouses);
        })
    },[params.warehouseID])

    if(warehouse==null)
        return <Loader/>

    const buttons = [
        {
            key:0,
            name:"Generate "+warehouse.warehouse_name+" statement",
            handleClick:() => {
                axiosInstance.get("/api/dispatch/"+params.warehouseID+"/generate_excel/downloadFile").then(r => {
                    notify.success("Report will be generated");
                    setKey(new Date().getTime());
                })
            }
        }
    ];

    return(<React.Fragment>
        <Helmet title="Reports"/>
        <Container fluid className="p-0">
            <PaymentDownloadComponent setKey={setKey} url={"/api/dispatch_custom/"+params.warehouseID+"/generate_excel/downloadFile"}/>
            <br/>
            <h1 className="h3 mb-3">{warehouse.warehouse_name} Reports</h1>
            <EntityTable
                key={key}
                columns={claimsTableColumns}
                url={"reports"}
                sortBy={[{field: "id", order: "desc"}]}
                buttons={buttons}
                searchCondition={condition}
            />
        </Container>
    </React.Fragment>);

};

export default WarehouseLedgerReportTablePage;
