import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import {Col, Container, Row} from "react-bootstrap";

import * as Icon from "react-feather";
import axiosInstance from "../../../../utils/axios";
import {ProductForm} from "./ProductForm";
import EntityTable from "../../../../components/EntityTable";


const productButtons = [
    {
        key:0,
        name:"New Product",
        href:"/purchases/products-new"
    },
];

const ProductsPage = () => {


    const productColumns = [
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "Code",
            accessor: "code",
        },
        {
            Header: "Expiry year",
            accessor: "expiry_year",
        },
        {
            Header: "Actions",
            Cell: ({ row }) => {
                return <Icon.Edit2 onClick={() =>{
                    axiosInstance.get("/api/products/"+row.original.id).then(function(response){
                        if(response.data.product.forSale)
                            response.data.product.yessale=true
                        else
                            response.data.product.nosale=true
                        if(response.data.product.forBuy)
                            response.data.product.yesbuy=true
                        else
                            response.data.product.nobuy=true
                        response.data.product.type={
                            id:response.data.product.type,
                            text:response.data.product.type
                        }
                        response.data.product.availability={
                            id:response.data.product.availability,
                            text:response.data.product.availability
                        }
                        response.data.product.unit_measure={
                            id:response.data.product.unit_measure,
                            text:response.data.product.unit_measure
                        }

                        setEditData(response.data.product);
                    });
                }}/>
            }
        }
    ];

    const [editData,setEditData] = useState('');

    if(editData===''){
        return (<React.Fragment>
            <Helmet title="Products"/>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Products</h1>
                <EntityTable
                    columns={productColumns}
                    url={"products"}
                    sortBy={[{field: "name", order: "asc"}]}
                    buttons={productButtons}
                />
            </Container>
        </React.Fragment>);
    }else {

        return(
            <React.Fragment>
                <Helmet title="New Product" />
                <Container fluid className="p-0">
                    <h1 className="h3 mb-3">Product</h1>
                    <Row>
                        <Col lg="12">
                            <ProductForm initialValues={editData} setEditData={setEditData} successUrl={"/purchases/products"} />
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }

};

export default ProductsPage;



