import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";

import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import axiosInstance, {ErrorMessage, GetAsInputData} from "../../../../utils/axios";
import * as Yup from "yup";
import {FieldArray, Formik} from "formik";
import {useNavigate} from "react-router-dom";
import {roles} from "../../../../utils/roles";
import Select from "react-select";

const userSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    username: Yup.string().required("Username is required").min(4, 'Must be at least 4 letters').max(55, 'Must be less than 55 letters'),
});

const UserForm = ({initialValues,successUrl,setEditData}) => {
    const navigate = useNavigate();
    const [departmentOptions,setDepartmentOptions] = useState([]);
    const [isDepartmentLoading,setIsDepartmentLoading] = useState(true);
    const [designationsOptions,setDesignationsOptions] = useState([]);
    const [isDesignationLoading,setIsDesignationLoading] = useState(true);
    const [warehouseOptions,setWarehouseOptions] = useState([]);
    const [isWarehouseLoading,setIsWarehouseLoading] = useState(true);

    function HandlePost(url,data,navigateTo){
        axiosInstance.post("/api/"+url,data).then(function(response,success){
            if(response.status===200)
                navigate(navigateTo);
        })
    }
    function HandlePut(url,data,navigateTo){
        axiosInstance.put("/api/"+url,data).then(function(response,success){
            setEditData('');
        })

    }

    useEffect(() => {
        axiosInstance.get("/api/departments?"+GetAsInputData({
            listInfo:{
                startRecord:0,
                rowCount:9999,
                sortBy: [{"field":"department_name","order":"asc"}],
            }
        })).then(function(response){
            setDepartmentOptions(response.data.departments);
            setIsDepartmentLoading(false);
        });

        axiosInstance.get("/api/designations?"+GetAsInputData({
            listInfo:{
                startRecord:0,
                rowCount:9999
            }
        })).then(function(response){
            setDesignationsOptions(response.data.designations);
            setIsDesignationLoading(false);
        });

        axiosInstance.get("/api/warehouses?"+GetAsInputData({
            listInfo:{
                startRecord:0,
                rowCount:9999
            }
        })).then(function(response){
            setWarehouseOptions(response.data.warehouses);
            setIsWarehouseLoading(false);
        });

    },[]);

    return( <Card>
        <Card.Header>
            <Card.Title tag="h5">{initialValues.user_id===undefined?"Add":"Modify"} User </Card.Title>
        </Card.Header>
        <Card.Body>
            <Formik
                validationSchema={userSchema}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500));
                    values.roles = [];
                    Object.keys(values).map((key,index) => {
                        if(key.startsWith("ROLE") && values[key]===true){
                            values.roles.push({role:{
                                    role_id:key
                                }});
                        }
                    });
                    if(values.user_id===undefined)
                        HandlePost("users",GetAsInputData({user:values}),successUrl);
                    else
                        HandlePut("users/"+values.user_id,GetAsInputData({user:values}),successUrl);
                }}
                initialValues={initialValues}>
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row>
                            <Col sm={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={values.name}
                                        placeholder="Name"
                                        isValid={touched.name && !errors.name}
                                        isInvalid={touched.name && !!errors.name}
                                        onChange={handleChange} />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Form.Group  className="mb-3">
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="username"
                                        value={values.username}
                                        placeholder="Username"
                                        isValid={touched.username && !errors.username}
                                        isInvalid={touched.username && !!errors.username}
                                        onChange={handleChange} />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.username}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            {  !values.user_id  && <Col sm={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Password
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="password"
                                        value={values.password}
                                        placeholder="Password"
                                        isValid={touched.password && !errors.password}
                                        isInvalid={touched.password && !!errors.password}
                                        onChange={handleChange}/>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.password}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            }
                        </Row>

                        <Row>
                            <Col sm={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Employee ID
                                    </Form.Label>
                                    <Form.Control type="text" name="emp_id"
                                        value={values.emp_id}
                                        placeholder="Emploee ID"
                                        isValid={touched.emp_id && !errors.emp_id}
                                        isInvalid={touched.emp_id && !!errors.emp_id}
                                        onChange={handleChange}/>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.emp_id}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Designation
                                    </Form.Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        value={values.designation}
                                        name="designation"
                                        getOptionLabel={(option)=>option.name}
                                        getOptionValue={(option)=>option.id}
                                        options={designationsOptions}
                                        isLoading={isDesignationLoading}
                                        onChange={selectedOption => {
                                            let event = { target : { name:`designation`,value: selectedOption } };
                                            handleChange(event);
                                            let anotherEvent = { target : { name:`grade`,value: selectedOption.grade } };
                                            handleChange(anotherEvent);
                                        }}
                                    />
                                    <ErrorMessage name={`designation`} />
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Department
                                    </Form.Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        value={values.department}
                                        name="department"
                                        getOptionLabel={(option)=>option.department_name}
                                        getOptionValue={(option)=>option.department_id}
                                        options={departmentOptions}
                                        isLoading={isDepartmentLoading}
                                        onChange={selectedOption => {
                                            let event = { target : { name:`department`,value: selectedOption } };
                                            handleChange(event);
                                        }}
                                    />
                                    <ErrorMessage name={`department`} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Card.Header>
                            <Card.Title tag="h5">Add Roles</Card.Title>
                        </Card.Header>
                        <Row>
                            <Col md={12}>
                                <Card.Title tag="h5">User Permission</Card.Title>
                                <Form.Group className="mb-3">
                                    <RoleCheckBox roleId={roles.ROLE_VIEW_USERS} values={values} label={"View"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_CREATE_MODIFY_USERS} values={values} label={"Create & Modify"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_DISABLE_USERS} values={values} label={"Disable"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_RESET_USERS_PASSWORD} values={values} label={"Reset Password"} onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={3}>
                                <Card.Title tag="h5">Purchase Order Permission</Card.Title>
                                <Form.Group className="mb-3">
                                    <RoleCheckBox roleId={roles.ROLE_VIEW_PURCHASE_ORDER} values={values} label={"View"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_CREATE_MODIFY_PURCHASE_ORDER} values={values} label={"Create & Modify"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_APPROVE_PURCHASE_ORDER} values={values} label={"Approve"} onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Card.Title tag="h5">Receipt Permission</Card.Title>
                                <Form.Group className="mb-3">
                                    <RoleCheckBox roleId={roles.ROLE_VIEW_GOODS_RECEIPT} values={values} label={"View"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_CREATE_MODIFY_GOODS_RECEIPT} values={values} label={"Create & Modify"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_APPROVE_GOODS_RECEIPT} values={values} label={"Approve"} onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={3}>
                                <Card.Title tag="h5">Dealer Permission</Card.Title>
                                <Form.Group className="mb-3">
                                    <RoleCheckBox roleId={roles.ROLE_VIEW_GENERAL_TRADERS} values={values} label={"View"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_CREATE_MODIFY_GENERAL_TRADERS} values={values} label={"Create & Modify"} onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Card.Title tag="h5">Distributor Permission</Card.Title>
                                <Form.Group className="mb-3">
                                    <RoleCheckBox roleId={roles.ROLE_VIEW_DEALERS} values={values} label={"View"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_CREATE_MODIFY_DEALERS} values={values} label={"Create & Modify"} onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Card.Title tag="h5">Retailer Permission</Card.Title>
                                <Form.Group className="mb-3">
                                    <RoleCheckBox roleId={roles.ROLE_VIEW_RETAILERS} values={values} label={"View"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_CREATE_MODIFY_RETAILERS} values={values} label={"Create & Modify"} onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Card.Title tag="h5">Other Permission</Card.Title>
                                <Form.Group className="mb-3">
                                    <RoleCheckBox roleId={roles.ROLE_ENABLE_DISABLE_DEALERS_GENERAL_TRADER} values={values} label={"Custom Modification Dealers"} onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={3}>
                                <Card.Title tag="h5">Sales Order Permission</Card.Title>
                                <Form.Group className="mb-3">
                                    <RoleCheckBox roleId={roles.ROLE_VIEW_ALL_SALE_ORDERS_DOMESTIC} values={values} label={"View"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_CREATE_MODIFY_ALL_SALE_ORDERS_DOMESTIC} values={values} label={"Create & Modify"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_APPROVE_SALE_ORDERS} values={values} label={"Approve"} onChange={handleChange}/>
                                    <br/>
                                    <br/>
                                    <Card.Title tag="h5">SO Site Based Permission</Card.Title>
                                    <RoleCheckBox roleId={roles.ROLE_VIEW_SITE_BASED_SALE_ORDERS_DOMESTIC} values={values} label={"View"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_CREATE_MODIFY_SITE_BASED_SALE_ORDERS_DOMESTIC} values={values} label={"Create & Modify"} onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Card.Title tag="h5">Sales Invoice Permission</Card.Title>
                                <Form.Group className="mb-3">
                                    <RoleCheckBox roleId={roles.ROLE_VIEW_ALL_INVOICE_DOMESTIC} values={values} label={"View"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_CREATE_MODIFY_ALL_INVOICE_DOMESTIC} values={values} label={"Create & Modify"} onChange={handleChange}/>
                                    <br/>
                                    <br/>
                                    <Card.Title tag="h5">INV Site Based Permission</Card.Title>
                                    <RoleCheckBox roleId={roles.ROLE_VIEW_SITE_BASED_INVOICE_DOMESTIC} values={values} label={"View"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_CREATE_MODIFY_SITE_BASED_INVOICE_DOMESTIC} values={values} label={"Create & Modify"} onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Card.Title tag="h5">Prepaid Invoice Permission</Card.Title>
                                <Form.Group className="mb-3">
                                    <RoleCheckBox roleId={roles.ROLE_VIEW_ALL_INVOICE_PREPAID} values={values} label={"View"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_CREATE_MODIFY_ALL_INVOICE_PREPAID} values={values} label={"Create & Modify"} onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Card.Title tag="h5">LUT Invoice Permission</Card.Title>
                                <Form.Group className="mb-3">
                                    <RoleCheckBox roleId={roles.ROLE_VIEW_INVOICE_LUT} values={values} label={"View"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_CREATE_MODIFY_INVOICE_LUT} values={values} label={"Create & Modify"} onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Card.Title tag="h5">Claims Permission</Card.Title>
                                    <RoleCheckBox roleId={roles.ROLE_VIEW_CLAIM} values={values} label={"View"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_CREATE_MODIFY_CLAIM} values={values} label={"Create & Modify"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_APPROVE_CLAIM} values={values} label={"Approve"} onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Card.Title tag="h5">Payment Permission</Card.Title>
                                <Form.Group className="mb-3">
                                    <RoleCheckBox roleId={roles.ROLE_VIEW_PAYMENTS} values={values} label={"View"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_CREATE_MODIFY_PAYMENTS_ENTRIES} values={values} label={"Create & Modify"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_APPROVE_PAYMENTS_ENTRIES} values={values} label={"Approve"} onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Card.Title tag="h5">Admin Config Permission</Card.Title>
                                <Form.Group className="mb-3">
                                    <RoleCheckBox roleId={roles.ROLE_VIEW_ADMIN_CONFIG} values={values} label={"View"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_CREATE_MODIFY_ADMIN_CONFIG} values={values} label={"Create & Modify"} onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Card.Title tag="h5">SD Permission</Card.Title>
                                <Form.Group className="mb-3">
                                    <RoleCheckBox roleId={roles.ROLE_VIEW_SD_DATA} values={values} label={"View"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_CREATE_MODIFY_SD_DATA} values={values} label={"Create & Modify"} onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={3}>
                                <Card.Title tag={"h5"}>Legder Permission</Card.Title>
                                <Form.Group className="mb-3">
                                    <RoleCheckBox roleId={roles.ROLE_DOWNLOAD_LEDGERS} values={values} label={"Download"} onChange={handleChange}/>
                                    <RoleCheckBox roleId={roles.ROLE_VIEW_CREATE_REPORTS} values={values} label={"View"} onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Card.Header/>
                            <Card.Title tag="h5">User Sites</Card.Title>
                            <Card.Header/>
                            <FieldArray
                                name="sites"
                                render={arrayHelpers => (
                                    <div>
                                        {
                                            values.sites && values.sites.length > 0 ? (
                                                values.sites.map((warehouse, index) => (
                                                    <Row key={index}>
                                                        <Col md={4}>
                                                            <Form.Label htmlFor="warehouse">Warehouse</Form.Label>
                                                            <Form.Group className="mb-3">
                                                                <Select
                                                                    key={`products.${index}`}
                                                                    className="react-select-container"
                                                                    classNamePrefix="react-select"
                                                                    name={`products[${index}].warehouse`}
                                                                    value={warehouse.warehouse}

                                                                    getOptionLabel={(option)=>option.warehouse_name}
                                                                    getOptionValue={(option)=>option.warehouse_id}
                                                                    options={warehouseOptions}
                                                                    isLoading={isWarehouseLoading}
                                                                    onChange={selectedOption => {
                                                                        let anotherEvent = { target : { name:`sites[${index}].warehouse`,value: selectedOption}};
                                                                        handleChange(anotherEvent);
                                                                    }}
                                                                />
                                                                <ErrorMessage name={`sites[${index}].warehouse`} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={4}>
                                                            <Button onClick={() => arrayHelpers.push({
                                                                warehouse: undefined,
                                                            })} variant="secondary">Add More</Button>
                                                            <Button onClick={() => {
                                                                arrayHelpers.remove(index);
                                                            }} variant="secondary">Remove</Button>
                                                        </Col>
                                                    </Row>))):(
                                                arrayHelpers.push({
                                                    warehouse: undefined,
                                                })
                                            )}</div>
                                )}
                            />
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Button type="submit" variant="primary">Submit</Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>)}
            </Formik>
        </Card.Body>
    </Card>);
}

const UserFormPage = () => {

    const initialValues = {
        name: "",
        username: "",
        password: "",
        emp_id: "",
        roles:[],
        sites:[],
    };

    return (<React.Fragment>
        <Helmet title="New User" />
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">New User</h1>

            <Row>
                <Col lg="12">
                    <UserForm initialValues={initialValues} successUrl="/users/erp" />
                </Col>
            </Row>
        </Container>
    </React.Fragment>);
}

export {UserForm}

const RoleCheckBox = ({roleId,values,label,onChange}) => {
    return (
            <Form.Check id={roleId} value={values[roleId]} checked={values[roleId]} inline label={label} type="checkbox" onChange={onChange}/>
    );
}

export default UserFormPage;

