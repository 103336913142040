import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import {Col, Container, Row} from "react-bootstrap";

import EntityTable from "../../../components/EntityTable";
import {ProductForm} from "./products/ProductForm";


const productButtons = [
    {
        key:0,
        name:"New Supplier",
        href:"/purchases/suppliers-new"
    },
];

const productColumns = [
    {
        Header: "Supplier Name",
        accessor: "company_name",
    },
    {
        Header: "GST",
        accessor: "gst",
    },
    {
        Header: "Zone",
        accessor: "zone.name",
    }
];

const SupplierPage = () => {

    const [editData,setEditData] = useState('');

    if(editData===''){
        return(<React.Fragment>
            <Helmet title="Suppliers"/>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Product Suppliers</h1>
                <EntityTable
                    columns={productColumns}
                    url={"suppliers"}
                    sortBy={[{field: "company_name", order: "asc"}]}
                    buttons={productButtons}
                />
            </Container>
        </React.Fragment>);
    }else {

        return(
            <React.Fragment>
                <Helmet title="New Product" />
                <Container fluid className="p-0">
                    <h1 className="h3 mb-3">Product</h1>
                    <Row>
                        <Col lg="12">
                            <ProductForm initialValues={editData} setEditData={setEditData} successUrl={"/purchases/products"} />
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }

};

export default SupplierPage;



