import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";

import {Alert, Button, Card, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import Select from "react-select";
import axiosInstance, {ErrorMessage, GetAsInputData} from "../../../../utils/axios";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {FieldArray, Formik} from "formik";

const purchaseOrderSchema = Yup.object().shape({
    supplier: Yup.object().required("Suppler is required"),
    warehouse: Yup.object().required("Warehouse is required"),
    pi_number:Yup.string().required("Pi number is required"),
    pi_date_field:Yup.date().required("Pi date is required"),
    transport:Yup.string().required("Mode of Transport is required"),
    products: Yup.array()
        .of(
            Yup.object().shape({
                product: Yup.object().required("Product is required").nullable(),
                quantity: Yup.number().required("Quantity is required").min(1,"Quantity should be greater than 0"),
                price: Yup.number().required("Price is required"),
                description: Yup.string().when(["product", "price"], {
                    is: (product, price) => {
                        if(product!==undefined && price!==undefined)
                            return product.seller_price!==price;
                    },
                    then: Yup.string().required("Reason needed for price change")
                })
            })
        )
});



const PurchaseOrderForm = ({initialValues,setPurchaseData,successUrl}) => {
    const navigate = useNavigate();
    const [productOptions, setProductOptions] = useState([]);
    const [isProductsLoading, setIsProductLoading] = useState(true);
    const [sellerOptions, setSellerOptions] = useState([]);
    const [isSellersLoading, setIsSellersLoading] = useState(true);
    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [isWarehouseLoading, setIsWarehouseLoading] = useState(true);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function HandlePost(url,data,navigateTo){
        axiosInstance.post("/api/"+url,data).then(function(response,success){
            if(response.status===200)
                navigate(navigateTo);
        })
    }

    function HandlePut(url,data,navigateTo){
        axiosInstance.put("/api/"+url,data).then(function(response,success){
            setPurchaseData(response.data.purchase);
            handleShow();
            setTimeout(handleClose,2500);
            if(response.status===200)
                navigate(navigateTo);
        })

    }

    function loadSupplierProducts(supplierID){
        axiosInstance.get("/api/product_suppliers?"+GetAsInputData({
            listInfo:{
                startRecord:0,
                rowCount:9999,
                sortBy: [{"field":"product.name","order":"asc"}],
                searchConditions:[{"field":"supplier.id","value":""+supplierID,"type":"number"}]
            }
        })).then(function(response){
            let supplierProducts = [];
            response.data.product.map((supplierProduct,index) => {
                supplierProduct.product.seller_price = supplierProduct.price;
                supplierProducts.push(supplierProduct.product);
            });
            setProductOptions(supplierProducts);
            setTimeout(() => {
                setIsProductLoading(false);
            }, 1500);
        });
    }

    useEffect(() => {
        const loadSelectData =  () => {
            try{
                axiosInstance.get("/api/suppliers?"+GetAsInputData({
                    listInfo:{
                        startRecord:0,
                        rowCount:9999,
                        sortBy: [{"field":"company_name","order":"asc"}]
                    }
                })).then(function(response){
                    setSellerOptions(response.data.suppliers);
                    setTimeout(() => {
                        setIsSellersLoading(false);
                    }, 1500);
                });

                axiosInstance
                    .get("/api/warehouses?"+GetAsInputData({
                        listInfo:{
                            startRecord:0,
                            rowCount:9999,
                            sortBy: [{"field":"warehouse_name","order":"asc"}],
                        }
                    })).then(function(response){
                        setWarehouseOptions(response.data.warehouses);
                        setIsWarehouseLoading(false);
                    });

            }catch(e){
                console.log(e);
            }
        }
        loadSelectData();
        if(initialValues.po_number!==undefined)
            loadSupplierProducts(initialValues.supplier.id);
    },[]);

    return( <Card>
                <Alert show={show} onHide={show} variant="primary" key="0" dismissible>
                    <div className="alert-message">
                        <strong>Purchase Order</strong> updated successfully!
                    </div>
                </Alert>
        <Card.Header>
            <Card.Title tag="h5">{initialValues.po_number===undefined?"Add":"Modify"} PurchaseOrders</Card.Title>
        </Card.Header>
        <Card.Body>
            <Formik
                validationSchema={purchaseOrderSchema}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500));
                    if(values.po_number===undefined)
                        HandlePost("purchases",GetAsInputData({purchase:values}),successUrl);
                    else
                        HandlePut("purchases/"+values.po_number,GetAsInputData({purchase:values}),successUrl);
                }}
                initialValues={initialValues}>
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                  }) => (<Form noValidate onSubmit={handleSubmit}>
                    <Row>
                        <Col sm={5}>
                            <Form.Group  className="mb-3">
                                <Form.Label>Supplier Name</Form.Label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="supplier"
                                    value={values.supplier}
                                    getOptionLabel={(option)=>option.company_name}
                                    getOptionValue={(option)=>option.id}
                                    options={sellerOptions}
                                    isLoading={isSellersLoading}
                                    onChange={selectedOption => {
                                        let event = { target : { name:'supplier',value: selectedOption}}
                                        handleChange(event);
                                        setIsProductLoading(true);
                                        setProductOptions([]);
                                        loadSupplierProducts(selectedOption.id);
                                    }}
                                />
                                <ErrorMessage name={`supplier`} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Pi Number
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="pi_number"
                                    value={values.pi_number}
                                    isValid={touched.pi_number && !errors.pi_number}
                                    isInvalid={touched.pi_number && !!errors.pi_number}
                                    onChange={handleChange}
                                    placeholder="Pi Number" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.pi_number}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Label>
                                Pi Date
                            </Form.Label>
                            <Form.Control
                                type="date"
                                name="pi_date_field"
                                value={values.pi_date_field}
                                isValid={touched.pi_date_field && !errors.pi_date_field}
                                isInvalid={touched.pi_date_field && !!errors.pi_date_field}
                                onChange={event =>{
                                    handleChange(event);
                                    let anotherEvent = { target : { name:`pi_created_date`,value: new Date(event.currentTarget.value).getTime()}};
                                    handleChange(anotherEvent);
                                }}
                                placeholder="Pi Date" />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.pi_date_field}
                            </Form.Control.Feedback>
                        </Col>
                        <Col sm={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                   Mode of transport
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="transport"
                                    value={values.transport}
                                    isValid={touched.transport && !errors.transport}
                                    isInvalid={touched.transport && !!errors.transport}
                                    onChange={handleChange}
                                    placeholder="Mode of Transport" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.transport}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Label>
                                Warehouse
                            </Form.Label>
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                value={values.warehouse}
                                getOptionLabel={(option)=>option.warehouse_name}
                                getOptionValue={(option)=>option.warehouse_id}
                                options={warehouseOptions}
                                isLoading={isWarehouseLoading}
                                onChange={selectedOption => {
                                    let event = { target : { name:`warehouse`,value: selectedOption } };
                                    handleChange(event);
                                }}
                            />
                            <ErrorMessage name={`warehouse`} />
                        </Col>
                    </Row>
                    <Card.Header/>
                    <Card.Title tag="h5">Purchase Products</Card.Title>
                    <Card.Header/>
                    <FieldArray
                        name="products"
                        render={arrayHelpers => (
                            <div>
                                {
                                    values.products && values.products.length > 0 ? (
                                        values.products.map((product, index) => (
                                            <Row key={index}>
                                                <Col md={5}>
                                                    <Form.Label htmlFor="product">Product</Form.Label>
                                                    <Form.Group className="mb-3">
                                                        <Select
                                                            key={`products.${index}`}
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            name={`products[${index}].product`}
                                                            value={product.product}
                                                            getOptionLabel={(option)=>option.name}
                                                            getOptionValue={(option)=>option.id}
                                                            isDisabled={isProductsLoading}
                                                            options={productOptions}
                                                            isLoading={isProductsLoading}
                                                            onChange={selectedOption => {
                                                                let event = { target : { name:`products[${index}].product`,value: {
                                                                            id: selectedOption.id,
                                                                            name: selectedOption.name,
                                                                            seller_price:selectedOption.seller_price
                                                                        } } };
                                                                handleChange(event);
                                                                let seller_price = selectedOption.seller_price;
                                                                let anotherEvent = { target : { name:`products[${index}].price`,value: seller_price}};
                                                                handleChange(anotherEvent);
                                                            }}
                                                        />
                                                        <ErrorMessage name={`products[${index}].product`} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={2}>
                                                    <Form.Label htmlFor="quantity">Quantity</Form.Label>
                                                    <InputGroup>
                                                        <FormControl
                                                            key={`products.${index}`}
                                                            placeholder="Quantity"
                                                            type="number"
                                                            name={`products[${index}].quantity`}
                                                            value={product.quantity}
                                                            aria-label="Quantity"
                                                            onChange={handleChange}
                                                            disabled={!product.product} />
                                                    </InputGroup>
                                                    <ErrorMessage name={`products[${index}].quantity`} />
                                                </Col>
                                                <Col md={2}>
                                                    <Form.Label htmlFor="price">Price</Form.Label>
                                                    <InputGroup>
                                                        <FormControl
                                                            key={`products.${index}`}
                                                            placeholder="Price"
                                                            type="number"
                                                            name={`products[${index}].price`}
                                                            value={product.price}
                                                            aria-label="Quantity"
                                                            onChange={event => {
                                                                handleChange(event);
                                                                let anotherEvent = { target : { name:`products[${index}].description_disabled`,value: Number(event.currentTarget.value)===product.product.seller_price}};
                                                                handleChange(anotherEvent);
                                                                if(Number(event.currentTarget.value)===product.product.seller_price){
                                                                    anotherEvent = { target : { name:`products[${index}].description`,value: ""}};
                                                                    handleChange(anotherEvent);
                                                                }
                                                            }}
                                                            disabled={!product.product} />
                                                    </InputGroup>
                                                    <ErrorMessage name={`products[${index}].price`} />
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Label htmlFor="description">Reason for change</Form.Label>
                                                    <InputGroup>
                                                        <FormControl
                                                            key={`products.${index}`}
                                                            placeholder="Reason for change"
                                                            type="text"
                                                            name={`products[${index}].description`}
                                                            value={product.description}
                                                            onChange={handleChange}
                                                            aria-label="Reason for change"
                                                            disabled={product.product===undefined?product.description_disabled:Number(product.price)===Number(product.product.seller_price)}/>
                                                        <Button onClick={() => arrayHelpers.push({
                                                            product: undefined,
                                                            price:0,
                                                            quantity:0,
                                                            description:"",
                                                            description_disabled:true
                                                        })} variant="secondary">Add More</Button>
                                                        <Button onClick={() => {
                                                            arrayHelpers.remove(index);
                                                        }} variant="secondary">Remove</Button>

                                                    </InputGroup>
                                                    <ErrorMessage name={`products[${index}].description`} />
                                                </Col>
                                            </Row>))):(
                                        arrayHelpers.push({
                                            product:undefined,
                                            price:undefined,
                                            description:"",
                                            quantity:undefined,
                                            description_disabled:true
                                        })
                                    )}</div>
                        )}
                    />
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Button type="submit" variant="primary">Submit</Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>)}
            </Formik>

        </Card.Body>
    </Card>);
}

const PurchaseOrderFormPage = () => {

    const initialValues = {
        supplier: undefined,
        warehouse: {"warehouse_id":1,"warehouse_name":"Chennai"},
        pi_date:0,
        pi_number:undefined,
        pi_date_field:undefined,
        transport:undefined,
        products:[{
            product:undefined,
            price:undefined,
            description:"",
            quantity:undefined,
            description_disabled:true
        }]
    };

    return(
        <React.Fragment>
            <Helmet title="New Purchase Order" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Purchase Order</h1>

                <Row>
                    <Col lg="12">
                        <PurchaseOrderForm initialValues={initialValues} successUrl={"/purchases/orders"} />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}

export {PurchaseOrderForm}
export default PurchaseOrderFormPage;
