import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import {Badge, ButtonGroup, Container, Dropdown, DropdownButton} from "react-bootstrap";
import EntityTable, {CustomFilter, SelectColumnFilter} from "../../../components/EntityTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../utils/axios";


const PaymentsPage = () => {

    const [searchCondition,setSearchCondition] = useState();
    const [searchConditionSelectedValue,setSearchConditionSelectedValue] = useState();
    const [searchCondition1,setSearchCondition1] = useState();
    const [searchConditionSelectedValue1,setSearchConditionSelectedValue1] = useState();

    const paymentsTableColumns = [
        {
            Header: "Created By",
            accessor: "created_by.name",
            Filter: () => {
                return CustomFilter({
                        entityUrl:"users",
                        setCondition:setSearchCondition1,
                        entityId:"user_id",
                        entityValue:"name",
                        searchField:"created_by.user_id",
                        searchType:"number",
                        entity:"users",
                        selectedValue:searchConditionSelectedValue1,
                        setSelectedValue:setSearchConditionSelectedValue1
                    }
                )
            },
        },
        {
            Header: "Created Date",
            accessor: "created_date",Filter: false,
            Cell: ({ cell: { value } }) => {
                let date = new Date(Number(value));
                date = date.toString().slice(0,24)
                return date;
            }

        },
        {
            Header: "Status",
            accessor: "is_approved",
            Cell: ({ row }) => {
                const [is_approved,setIsApproved] = useState(row.values.is_approved);
                if(is_approved==="TRUE"){
                    return(<Badge bg="success" >
                        <FontAwesomeIcon icon={faCheck} /> Approved
                    </Badge>);
                }else if(is_approved==="FALSE"){
                    return(<Badge bg="danger" >
                        <FontAwesomeIcon icon={faTimes} /> Rejected
                    </Badge>);
                }
                return(<DropdownButton
                    as={ButtonGroup}
                    key={"primary"}
                    id={`dropdown-variants-primary`}
                    variant={"primary"}
                    title={"Choose"}
                    className="me-2"
                    onSelect={event => {
                        axiosInstance.get("/api/incoming_cash/" +row.original.id + "/"+event).then(function(response){
                            setIsApproved(response.data.incoming_cash.is_approved);
                        });
                    }}
                >
                    <Dropdown.Item eventKey="approve">Approve</Dropdown.Item>
                    <Dropdown.Item eventKey="reject">Reject</Dropdown.Item>
                </DropdownButton>);
            },
            Filter: SelectColumnFilter,
        },
        {
            Header:"Claim Month",
            accessor: "dealerMonthlyTransaction",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                if(value == null)
                    return ""
                return value.year+" - "+value.month
            },
        },
        {
            Header: "Account Holder",
            accessor: "dealer.dealer_name",
            Filter: () => {
                return CustomFilter({
                        entityUrl:"customers",
                        setCondition:setSearchCondition,
                        entityId:"dealer_id",
                        entityValue:"dealer_name",
                        searchField:"dealer.dealer_id",
                        searchType:"number",
                        entity:"customers",
                        selectedValue:searchConditionSelectedValue,
                        setSelectedValue:setSearchConditionSelectedValue
                    }
                )
            },
        },
        {
            Header: "Receiver Account",
            accessor: "dealerReceiver.dealer_name",
            Filter: false,
        },
        {
            Header: "Cash",
            accessor: "amount",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                return value.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR"
                });
            },
        },
        {
            Header: "Type",
            accessor: "transaction_type",
            Cell: ({ cell: { value } }) => {
                if(value==="CUSTOMER_PAYMENT")
                    return "PAYMENT";
                else return  value;
            },
            Filter: SelectColumnFilter,
        },
        {
            Header: "Reference",
            accessor: "transaction_reference"
        },

        {
            Header: "Inward Date",
            accessor: "inward_date",Filter: false,
            Cell: ({ cell: { value } }) => {
                let date = new Date(Number(value));
                date = date.toString().slice(0,24)
                return date;
            }

        },
        {
            Header: "A/R By",
            accessor: "approved_by.name",
            Filter: false,
        },

        {
            Header: "A/R Date",
            accessor: "approved_date",Filter: false,
            Cell: ({ cell: { value } }) => {
                let date = new Date(Number(value));
                date = date.toString().slice(0,24)
                return date;
            }
        },
        {
            Header: "Bank",
            accessor: "bank_name",
            Filter: false,
        },


    ];

    const paymentsButtons = [
        {
            key:0,
            name:"Payment Entry",
            href:"/payments/listing-new"
        },
        {
            key:1,
            name:"Credit Entry",
            href:"/payments/credit-new"
        },
        {
            key:2,
            name:"Debit Entry",
            href:"/payments/debit-new"
        },
        {
            key:3,
            name:"Claim Transfer Entry",
            variant:"secondary",
            href:"/payments/claim-transfer-new"
        },
        {
            key:4,
            name:"Claim Debit Entry",
            variant:"secondary",
            href:"/payments/claim-transfer-debit"
        },
        {
            key:5,
            name:"Claim Credit Entry",
            variant:"secondary",
            href:"/payments/claim-transfer-credit"
        }
    ];
    let condition = [];
    searchCondition!=null && condition.push(searchCondition)
    searchCondition1!=null && condition.push(searchCondition1)
    if(condition.length==0)
        condition = null
    return(<React.Fragment>
        <Helmet title="Payments"/>
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Payments</h1>

            <EntityTable
                columns={paymentsTableColumns}
                url={"incoming_cash"}
                searchCondition={condition}
                sortBy={[{field: "created_date", order: "desc"}]}
                buttons={paymentsButtons}
            />
        </Container>
    </React.Fragment>);

};

export default PaymentsPage;



