import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import {Container} from "react-bootstrap";
import EntityTable, {CustomFilter} from "../../../components/EntityTable";


const SDRetailersPage = () => {
    const [searchCondition,setSearchCondition] = useState();
    const [searchConditionSelectedValue1,setSearchConditionSelectedValue1] = useState();

    const regionSearchCondition = [{field: "country.id", type: "number", value: "96"}]

    const SDRetailersColumns = [
        {
            Header: "Beat",
            accessor: "beat.beat_name",
        },
        {
            Header: "SD ID",
            accessor: "retailer_sd_id",
        },
        {
            Header: "Name",
            accessor: "retailer_name",
        },
        {
            Header: "Address",
            accessor: "retailer_address",
        },
        {
            Header: "Phone",
            accessor: "retailer_phone",
        },
        {
            Header: "Mobile",
            accessor: "retailer_mobile",
        },
        {
            Header: "Email",
            accessor: "retailer_email",
        },
        {
            Header: "Pin Code",
            accessor: "retailer_pincode",
        },
        {
            Header: "Type",
            accessor: "retailer_type",
        },
        {
            Header: "Region",
            accessor: "retailer_region",
            Filter: () => {
                return CustomFilter({
                        entityUrl:"regions",
                        setCondition:setSearchCondition,
                        entityId:"id",
                        entityValue:"region_name",
                        searchField:"retailer_region.id",
                        searchType:"number",
                        entity:"regions",
                        searchCondition:regionSearchCondition,
                        selectedValue:searchConditionSelectedValue1,
                        setSelectedValue:setSearchConditionSelectedValue1
                    }
                )
            },
        },
        {
            Header: "Latitude",
            accessor: "retailer_loc_lat",
        },
        {
            Header: "Longitude",
            accessor: "retailer_loc_long",
        },
        {
            Header: "GST",
            accessor: "retailer_gst",
        },
        {
            Header: "Firestore",
            accessor: "retailer_firestore_id",
            Filter:false
        },
    ];

    let condition = [];
    searchCondition!=null && condition.push(searchCondition)
    if(condition.length==0)
        condition = null

    return(<React.Fragment>
        <Helmet title="Sales Diary Retailers"/>
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Sales Diary Retailers</h1>
            <EntityTable
                key={new Date().getTime()}
                columns={SDRetailersColumns}
                url={"sd_retailers"}
                pageSize={1000}
                sortBy={[{field: "retailer_name", order: "asc"}]}
                buttons={[]}
                searchCondition={condition}
                // rowProps={row => ({
                //    onClick: () => navigate("/sd/retailers/details/"+row.original.dealer_id) ,
                //    style: {
                //        cursor: "pointer"
                //    }
                // })}
            />
        </Container>
    </React.Fragment>);

};

export default SDRetailersPage;
