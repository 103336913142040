import React, {useEffect, useState} from "react";

import {Alert, Button, Card, Col, Form, Row} from "react-bootstrap";

import axiosInstance, {ErrorMessage, GetAsInputData} from "../../../../utils/axios";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {Formik} from "formik";
import Select from "react-select";

const dealerSchema = Yup.object().shape({
    dealer: Yup.object().required("Trader Name is required"),
    retailer: Yup.object().required("Trader Name is required"),
});

const ShippingAddressForm = ({initialValues,successUrl,setDealerRetailerData}) => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [isCustomersLoading, setIsCustomersLoading] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function HandlePost(url,data,navigateTo){
        axiosInstance.post("/api/"+url,data).then(function(response,success){
            if(response.status===200){
                handleShow();
                setTimeout(handleClose,2500)


                setTimeout(() => {
                    let entityURL = "/api/dealer_retailer";

                    const input_data = {
                        listInfo:{
                            startRecord:0,
                            rowCount:9999,
                            sortBy:[{"field":"dealer.dealer_name","order":"asc"}],
                            searchConditions:[{"field":"dealer.dealer_id","value":""+initialValues.dealer.dealer_id,"type":"number"}]
                        }
                    };
                    let encodedData = GetAsInputData(input_data);
                    axiosInstance.get(entityURL+"?"+encodedData)
                        .then(function(response){
                            navigate(navigateTo);
                            setTimeout(() => {
                                setDealerRetailerData(response.data.dealer_retailer);
                            }, 500);
                        })
                },1000);

            }
        })
    }

    useEffect(() => {
        const loadSelectData =  () => {

            try{
                axiosInstance.get("/api/customers?"+GetAsInputData({
                    listInfo:{
                        startRecord:0,
                        rowCount:9999,
                        sortBy: [{"field":"dealer_name","order":"asc"}],
                        searchConditions:[{field:"type",value:"RETAILER",type:"enum",enumName:"DealerType"}]
                    }
                })).then(function(response){
                    setCustomerOptions(response.data.customers);
                    setTimeout(() => {
                        setIsCustomersLoading(false);
                    }, 1500);
                });


            }catch(e){
                console.log(e);
            }
        }
        loadSelectData();
    },[]);

    return( <Card>
        <Alert show={show} onHide={show} variant="primary" key="0" dismissible>
            <div className="alert-message">
                <strong>Shipping Address</strong> added successfully!
            </div>
        </Alert>
        <Card.Header>
            <Card.Title tag="h5">Add shipping address</Card.Title>
        </Card.Header>
        <Card.Body>
            <Formik
                validationSchema={dealerSchema}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500));
                    HandlePost("dealer_retailer",GetAsInputData({dealer_retailer:values}),successUrl);
                }}
                initialValues={initialValues}>
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row>
                            <Col sm={5}>
                                <Form.Group  className="mb-3">
                                    <Form.Label>Trader Name</Form.Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        name="retailer"
                                        value={values.retailer}
                                        getOptionLabel={(option)=>option.dealer_name}
                                        getOptionValue={(option)=>option.dealer_id}
                                        options={customerOptions}
                                        isLoading={isCustomersLoading}
                                        onChange={selectedOption => {
                                            let event = { target : {
                                                    name:'retailer',
                                                    value: {
                                                        dealer_id:selectedOption.dealer_id,
                                                        dealer_name:selectedOption.dealer_name
                                                    }
                                                }}
                                            handleChange(event);
                                        }}
                                    />
                                    <ErrorMessage name={`dealer`} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Button type="submit" variant="primary">Submit</Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>)}
            </Formik>
        </Card.Body>
    </Card>);
}

export default ShippingAddressForm;
