import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";

import {Alert, Button, Card, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";

import Select from "react-select";
import axiosInstance, {ErrorMessage, GetAsInputData} from "../../../../utils/axios";
import * as Yup from "yup";
import {FieldArray, Formik} from "formik";
import {useNavigate} from "react-router-dom";

const dealerSchema = Yup.object().shape({
    dealer_name: Yup.string().required("Trader Name is required"),
    gst: Yup.string().required("GST is required").min(15, 'Must be exactly 15 digits').max(15, 'Must be exactly 15 digits'),
    contact_name: Yup.string().required("Contact Name is required"),
    address: Yup.string().required("Address is required"),
    sub_address: Yup.string().required("Pin code is required"),
    phone_number: Yup.string().required("Phone number is required"),
    land_line: Yup.string().required("Landline number is required"),
    email:Yup.string().email(),
    zone: Yup.object().required("Region is required"),
    products: Yup.array()
        .of(
            Yup.object().shape({
                product: Yup.object().required("Product is required").nullable(),
                basic_rate: Yup.number().required("Basic Rate is required"),
                margin_rate: Yup.number().required("Product Margin is required"),
            })
        )

});

const GeneralTraderForm = ({initialValues,successUrl,setDealerData}) => {
    const navigate = useNavigate();
    const [regionOptions, setRegionOptions] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [isRegionLoading, setIsRegionLoading] = useState(true);
    const [isProductsLoading, setIsProductLoading] = useState(true);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function HandlePost(url,data,navigateTo){
        axiosInstance.post("/api/"+url,data).then(function(response,success){
            if(response.status===200)
                navigate(navigateTo);
        })
    }
    function HandlePut(url,data,navigateTo){
        axiosInstance.put("/api/"+url,data).then(function(response,success){
            setDealerData(response.data.dealer);
            handleShow();
            setTimeout(handleClose,2500);
            if(response.status===200)
                navigate(navigateTo);
        })

    }

    useEffect(() => {
        const loadSelectData =  () => {

            try{
                axiosInstance.get("/api/regions?"+GetAsInputData({
                    listInfo:{
                        startRecord:0,
                        rowCount:9999,
                        sortBy: [{"field":"region_name","order":"asc"}],
                        searchConditions:[{"field":"country.id","value":"96","type":"number"}]
                    }
                })).then(function(response){
                    setRegionOptions(response.data.regions);
                    setTimeout(() => {
                        setIsRegionLoading(false);
                    }, 1500);
                });

                axiosInstance.get("/api/products?"+GetAsInputData({
                    listInfo:{
                        startRecord:0,
                        rowCount:9999,
                        sortBy: [{"field":"name","order":"asc"}],
                        searchConditions:[{"field":"forSale","value":"true","type":"boolean"}]
                    }
                })).then(function(response){
                    setProductOptions(response.data.products);
                    setTimeout(() => {
                        setIsProductLoading(false);
                    }, 1500);
                });

            }catch(e){
                console.log(e);
            }
        }
        loadSelectData();
    },[]);

    return( <Card>
                <Alert show={show} onHide={show} variant="primary" key="0" dismissible>
                    <div className="alert-message">
                        <strong>General Trader</strong> updated successfully!
                    </div>
                </Alert>
               <Card.Header>
                   <Card.Title tag="h5">{initialValues.dealer_id===undefined?"Add":"Modify"} General Trader</Card.Title>
               </Card.Header>
               <Card.Body>
                   <Formik
                       validationSchema={dealerSchema}
                       onSubmit={async (values) => {
                           await new Promise((r) => setTimeout(r, 500));
                           if(values.dealer_id===undefined)
                               HandlePost("dealers",GetAsInputData({dealer:values}),successUrl);
                           else
                               HandlePut("dealers/"+values.dealer_id,GetAsInputData({dealer:values}),successUrl);
                       }}
                       initialValues={initialValues}>
                       {({
                             handleSubmit,
                             handleChange,
                             handleBlur,
                             values,
                             touched,
                             isValid,
                             errors,
                         }) => (
                           <Form noValidate onSubmit={handleSubmit}>
                           <Row>
                               <Col sm={6}>
                                   <Form.Group className="mb-3">
                                       <Form.Label>Trader Name</Form.Label>
                                       <Form.Control
                                           type="text"
                                           name="dealer_name"
                                           value={values.dealer_name}
                                           placeholder="Trader Name"
                                           isValid={touched.dealer_name && !errors.dealer_name}
                                           isInvalid={touched.dealer_name && !!errors.dealer_name}
                                           onChange={handleChange} />
                                       <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                       <Form.Control.Feedback type="invalid">
                                           {errors.dealer_name}
                                       </Form.Control.Feedback>
                                   </Form.Group>
                               </Col>
                               <Col sm={6}>
                                   <Form.Group  className="mb-3">
                                       <Form.Label>GST</Form.Label>
                                       <Form.Control
                                           type="text"
                                           name="gst"
                                           value={values.gst}
                                           placeholder="GST"
                                           isValid={touched.gst && !errors.gst}
                                           isInvalid={touched.gst && !!errors.gst}
                                           onChange={(event) => {
                                               const gstNumber = event.currentTarget.value;
                                               if(gstNumber.length!==15){
                                                   handleChange(event);
                                               }else{
                                                   handleChange(event);
                                                   axiosInstance.get("https://appyflow.in/api/verifyGST?gstNo="+gstNumber+"&key_secret=w1pv9sAv18OwYa73oB8Qatj95EH3")
                                                       .then((response) => {
                                                           response = response.data;
                                                           values.dealer_name = response.taxpayerInfo.tradeNam===""?response.taxpayerInfo.lgnm:response.taxpayerInfo.tradeNam;
                                                           values.sub_address = response.taxpayerInfo.pradr.addr.pncd;
                                                           values.address = "";
                                                           if(response.taxpayerInfo.pradr.addr.bnm!=="")
                                                               values.address+=response.taxpayerInfo.pradr.addr.bnm+", ";
                                                           if(response.taxpayerInfo.pradr.addr.bno!=="")
                                                               values.address+=response.taxpayerInfo.pradr.addr.bno+", ";

                                                           if(response.taxpayerInfo.pradr.addr.flno!=="")
                                                               values.address+=response.taxpayerInfo.pradr.addr.flno+", ";

                                                           if(response.taxpayerInfo.pradr.addr.lg!=="")
                                                               values.address+=response.taxpayerInfo.pradr.addr.lg+", ";

                                                           if(response.taxpayerInfo.pradr.addr.lt!=="")
                                                               values.address+=response.taxpayerInfo.pradr.addr.lt+", ";

                                                           if(response.taxpayerInfo.pradr.addr.loc!=="")
                                                               values.address+=response.taxpayerInfo.pradr.addr.loc+", ";

                                                           if(response.taxpayerInfo.pradr.addr.st!=="")
                                                               values.address+=response.taxpayerInfo.pradr.addr.st+", ";

                                                           if(response.taxpayerInfo.pradr.addr.dst!=="")
                                                               values.address+=response.taxpayerInfo.pradr.addr.dst+", ";

                                                           if(response.taxpayerInfo.pradr.addr.city!==""){
                                                               values.address+=response.taxpayerInfo.pradr.addr.city+", ";
                                                               values.zone = response.taxpayerInfo.pradr.addr.city;
                                                           }

                                                           if(response.taxpayerInfo.pradr.addr.stcd!==""){
                                                               values.address+=response.taxpayerInfo.pradr.addr.stcd;
                                                               values.zone = response.taxpayerInfo.pradr.addr.stcd;
                                                           }
                                                           values.address = values.address.replaceAll(",,",",");
                                                           values.gst = response.taxpayerInfo.gstin;
                                                           values.distributorPan = response.taxpayerInfo.panNo;

                                                           let anotherEvent = { target : { name:`dealer_name`,value:values.dealer_name  } };
                                                           handleChange(anotherEvent);
                                                           anotherEvent = { target : { name:`gst`,value:values.gst  } };
                                                           handleChange(anotherEvent);
                                                           anotherEvent = { target : { name:`address`,value:values.address  } };
                                                           handleChange(anotherEvent);
                                                           anotherEvent = { target : { name:`sub_address`,value:values.sub_address  } };
                                                           handleChange(anotherEvent);
                                                           let zone = regionOptions.filter((e) => {if(e.region_name === values.zone)return e;})
                                                           if(zone.length!=0){
                                                               anotherEvent = { target : { name:`zone`,value:zone[0]  } };
                                                               handleChange(anotherEvent);
                                                           }


                                                       }).catch((error) => {
                                                       console.log(error);
                                                   })
                                               }

                                           }} />
                                       <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                       <Form.Control.Feedback type="invalid">
                                           {errors.gst}
                                       </Form.Control.Feedback>
                                   </Form.Group>
                               </Col>
                           </Row>
                           <Row>
                               <Col sm={4}>
                                   <Form.Group className="mb-3">
                                       <Form.Label>
                                           Contact Name
                                       </Form.Label>
                                       <Form.Control
                                           type="text"
                                           name="contact_name"
                                           value={values.contact_name}
                                           placeholder="Contact Name"
                                           isValid={touched.contact_name && !errors.contact_name}
                                           isInvalid={touched.contact_name && !!errors.contact_name}
                                           onChange={handleChange} />
                                       <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                       <Form.Control.Feedback type="invalid">
                                           {errors.contact_name}
                                       </Form.Control.Feedback>
                                   </Form.Group>
                               </Col>
                               <Col sm={6}>
                                   <Form.Label>
                                       Address
                                   </Form.Label>
                                   <Form.Control type="text" name="address"
                                                 value={values.address}
                                                 placeholder="Address"
                                                 isValid={touched.address && !errors.address}
                                                 isInvalid={touched.address &&!!errors.address}
                                                 onChange={handleChange} />
                                   <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                   <Form.Control.Feedback type="invalid">
                                       {errors.address}
                                   </Form.Control.Feedback>
                               </Col>
                               <Col sm={2}>
                                   <Form.Label>
                                       Pincode
                                   </Form.Label>
                                   <Form.Control type="text" name="sub_address"
                                              value={values.sub_address}
                                              placeholder="Pincode"
                                              isValid={touched.sub_address && !errors.sub_address}
                                              isInvalid={touched.sub_address && !!errors.sub_address}
                                              onChange={handleChange}/>
                                   <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                   <Form.Control.Feedback type="invalid">
                                       {errors.sub_address}
                                   </Form.Control.Feedback>
                               </Col>
                           </Row>

                           <Row>
                               <Col sm={3}>
                                   <Form.Group className="mb-3">
                                       <Form.Label>
                                           Phone Number
                                       </Form.Label>
                                       <Form.Control type="text" name="phone_number"
                                                  value={values.phone_number}
                                                  placeholder="Phone Number"
                                                  isValid={touched.phone_number && !errors.phone_number}
                                                  isInvalid={touched.phone_number && !!errors.phone_number}
                                                  onChange={handleChange}/>
                                       <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                       <Form.Control.Feedback type="invalid">
                                           {errors.phone_number}
                                       </Form.Control.Feedback>
                                   </Form.Group>
                               </Col>
                               <Col sm={3}>
                                   <Form.Label>
                                       LandLine Number
                                   </Form.Label>
                                   <Form.Control  type="text" name="land_line"
                                              value={values.land_line}
                                              placeholder="Landline Number"
                                              isValid={touched.land_line && !errors.land_line}
                                              isInvalid={touched.land_line && !!errors.land_line}
                                              onChange={handleChange}/>
                                   <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                   <Form.Control.Feedback type="invalid">
                                       {errors.land_line}
                                   </Form.Control.Feedback>
                               </Col>
                               <Col sm={3}>
                                   <Form.Label>
                                       Email
                                   </Form.Label>
                                   <Form.Control type="email" name="email"
                                                 value={values.email}
                                                 placeholder="Email"
                                                 isValid={touched.email && !errors.email}
                                                 isInvalid={touched.email && !!errors.email}
                                                 onChange={handleChange}/>
                                   <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                   <Form.Control.Feedback type="invalid">
                                       {errors.email}
                                   </Form.Control.Feedback>
                               </Col>
                               <Col sm={3}>
                                   <Form.Label>
                                       Region
                                   </Form.Label>
                                   <Select
                                       className="react-select-container"
                                       classNamePrefix="react-select"
                                       name="zone"
                                       value={values.zone}
                                       getOptionLabel={(option)=>option.region_name}
                                       getOptionValue={(option)=>option.id}
                                       options={regionOptions}
                                       isLoading={isRegionLoading}
                                       onChange={selectedOption => {
                                           let event = { target : { name:'zone',value: selectedOption}}
                                           handleChange(event)
                                       }}
                                   />
                                   <ErrorMessage name={`zone`} />
                               </Col>
                           </Row>
                           <Card.Header>
                               <Card.Title tag="h5">Add Products</Card.Title>
                           </Card.Header>
                               <FieldArray
                                   name="products"
                                   render={arrayHelpers => (
                                       <div>
                                           {values.products && values.products.length > 0 ? (
                                               values.products.map((product, index) => (
                                                   <Row name={`products.${index}`} key={index}>
                                                       <Col md={2}>
                                                           <Form.Label htmlFor="mrp">MRP</Form.Label>
                                                           <InputGroup>
                                                               <Button onClick={() => arrayHelpers.remove(index)} variant="secondary">Remove</Button>
                                                               <Form.Control
                                                                   key={`products.${index}`}
                                                                   placeholder="MRP"
                                                                   type="text"
                                                                   value={product.product===undefined?0:product.product.price}
                                                                   id="mrp"
                                                                   disabled/>

                                                           </InputGroup>
                                                       </Col>
                                                       <Col md={5}>
                                                           <Form.Label htmlFor="product">Product</Form.Label>
                                                           <Form.Group className="mb-3">
                                                               <Select
                                                                   key={`products.${index}`}
                                                                   className="react-select-container"
                                                                   classNamePrefix="react-select"
                                                                   name={`products[${index}].product`}
                                                                   value={product.product}
                                                                   getOptionLabel={(option)=>option.name}
                                                                   getOptionValue={(option)=>option.id}
                                                                   options={productOptions}
                                                                   isLoading={isProductsLoading}
                                                                   onChange={selectedOption => {
                                                                       let event = { target : { name:`products[${index}].product`,value:selectedOption  } };
                                                                       handleChange(event);
                                                                   }}
                                                               />
                                                               <ErrorMessage name={`products[${index}].product`} />
                                                           </Form.Group>
                                                       </Col>
                                                       <Col md={2}>
                                                           <Form.Group className="mb-3">
                                                               <Form.Label htmlFor="basic_rate">Basic</Form.Label>
                                                               <Form.Control  placeholder="Basic" type="number" min={0} max={product.product===undefined?0:product.product.price} disabled={!product.product} name={`products[${index}].basic_rate`} value={product.basic_rate} id="Basic" onChange={ event =>{
                                                                   handleChange(event);
                                                                   let value = product.product.price - event.currentTarget.value;
                                                                   value /= product.product.price;
                                                                   value *= 100;
                                                                   let anotherEvent = { target : { name:`products[${index}].margin_rate`,value:value   } }
                                                                   handleChange(anotherEvent)
                                                               }} />
                                                               <ErrorMessage name={`products[${index}].basic_rate`} />
                                                           </Form.Group>
                                                       </Col>
                                                       <Col md={2}>
                                                           <Form.Label htmlFor="margin_rate">Profit</Form.Label>
                                                           <InputGroup>
                                                               <FormControl key={`products.${index}`} placeholder="Profit" type="number" name={`products[${index}].margin_rate`} value={product.margin_rate} aria-label="Profit" onChange={handleChange} disabled />
                                                               <Button onClick={() => arrayHelpers.push({
                                                                   product: undefined,
                                                                   basic_rate:0,
                                                                   margin_rate:0
                                                               })} variant="secondary">Add More</Button>
                                                           </InputGroup>
                                                       </Col>
                                                   </Row>
                                               ))
                                           ) : (
                                               arrayHelpers.push({
                                                   product: undefined,
                                                   basic_rate:0,
                                                   margin_rate:0
                                               })
                                           )}
                                       </div>
                                   )}
                               />
                           <Row>
                               <Col md={12}>
                                   <Form.Group className="mb-3">
                                       <Button type="submit" variant="primary">Submit</Button>
                                   </Form.Group>
                               </Col>
                           </Row>
                       </Form>)}
                   </Formik>
               </Card.Body>
           </Card>);
}

const GeneralTraderFormPage = () => {

    const initialValues = {
        dealer_name: "",
        gst: "",
        address: "",
        sub_address: "",
        land_line: "",
        phone_number: "",
        email: "",
        contact_name:"",
        zone:undefined,
        products:[{
            product: undefined,
            basic_rate:undefined,
            margin_rate:undefined
        }]
    };

    return (<React.Fragment>
        <Helmet title="New General Trader" />
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">General Trader</h1>

            <Row>
                <Col lg="12">
                    <GeneralTraderForm initialValues={initialValues} successUrl="/customers/active/dealers" />
                </Col>
            </Row>
        </Container>
    </React.Fragment>);
}

export {GeneralTraderForm}

export default GeneralTraderFormPage;
