import React from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import axiosInstance, { ErrorMessage, GetAsInputData } from "../../../utils/axios";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { ModelConstants } from "../../../utils/constants";

const typeOptions = [
    { id: "DISPLAY_CLAIM", text: "Display Claim" },
    { id: "DB_INCENTIVE", text: "DB Incentive" },
    { id: "LISTING_FEES", text: "Listing Fees" },
    { id: "TRADE_SCHEME", text: "Trade Scheme" },
    { id: "PRODUCT_RETURN", text: "Product Returns" },
];

const contractSchema = Yup.object().shape({
    dealer: Yup.object().required("Trader Name is required"),
    type: Yup.object().required("Contract Type is required"),
    allowed: Yup.number().required("Amount allowed is required"),
});

const ContractForm = ({ initialValues }) => {
    const navigate = useNavigate();

    const handleSubmit = (url, data, navigateTo) => {
        axiosInstance.post("/api/" + url, data).then((response) => {
            if (response.status === 201) {
                navigate(navigateTo);
            }
        });
    };

    const loadCustomerOptions = async (inputValue) => {
        try {
            const response = await axiosInstance.get("/api/customers?" + GetAsInputData({
                listInfo: {
                    startRecord: 0,
                    rowCount: 10,
                    sortBy: [{ field: "dealer_name", order: "asc" }],
                    searchConditions: [
                        { field: "dealer_name", value: inputValue, condition: "like" },
                        { field: "type", enumName: "DealerType", type: "enum", value: "RETAILER", condition: "equals" },
                    ],
                },
            }));
            return response.data.customers.map((key) => ({
                value: key.dealer_id,
                label: key.dealer_name,
                dealer_id: key.dealer_id,
            }));
        } catch (e) {
            console.log(e);
            return [];
        }
    };

    return (
        <Card>
            <Card.Header>
                <Card.Title tag="h5">Add Contract</Card.Title>
            </Card.Header>
            <Card.Body>
                <Formik
                    validationSchema={contractSchema}
                    onSubmit={async (values) => {
                        await new Promise((r) => setTimeout(r, 500));
                        values.createdDate = new Date().getTime();
                        handleSubmit("contracts", GetAsInputData({ contract: values }), "/claims/contracts");
                    }}
                    initialValues={initialValues}
                >
                    {({ handleSubmit, handleChange, values, touched, errors }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row>
                                <Col sm={5}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Retailer Name</Form.Label>
                                        <AsyncSelect
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            name={ModelConstants.Contract.DEALER}
                                            cacheOptions
                                            defaultOptions
                                            loadOptions={loadCustomerOptions}
                                            onChange={(selectedOption) => {
                                                const event = { target: { name: ModelConstants.Contract.DEALER, value: selectedOption } };
                                                handleChange(event);
                                            }}
                                        />
                                        <ErrorMessage name={ModelConstants.Contract.DEALER} />
                                    </Form.Group>
                                </Col>
                                <Col sm={5}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Type</Form.Label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            name="type"
                                            value={values.type}
                                            getOptionLabel={(option) => option.text}
                                            getOptionValue={(option) => option.id}
                                            options={typeOptions}
                                            onChange={(selectedOption) => {
                                                const event = { target: { name: "type", value: selectedOption } };
                                                handleChange(event);
                                                const contractTypeEvent = {
                                                    target: { name: ModelConstants.Contract.CONTRACT_TYPE, value: selectedOption.id },
                                                };
                                                handleChange(contractTypeEvent);
                                            }}
                                        />
                                        <ErrorMessage name="type" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={ModelConstants.Contract.ALLOWED}
                                        isValid={touched.allowed && !errors.allowed}
                                        isInvalid={touched.allowed && !!errors.allowed}
                                        onChange={handleChange}
                                        placeholder="Approved Amount"
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">{errors.allowed}</Form.Control.Feedback>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col md={12}>
                                    <Form.Group className="mb-3">
                                        <Button type="submit" variant="primary">
                                            Submit
                                        </Button>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Card.Body>
        </Card>
    );
};

const ContractsFormPage = () => {
    const initialValues = {
        dealer: undefined,
        type: undefined,
        allowed: undefined,
    };

    return (
        <React.Fragment>
            <Helmet title="New Contracts" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Contract Entry</h1>
                <Row>
                    <Col lg="8">
                        <ContractForm initialValues={initialValues} />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default ContractsFormPage;