import React, {useEffect, useMemo, useState} from 'react';
import {useFilters, usePagination, useTable} from "react-table";
import {Button, Card, Col, Dropdown, DropdownButton, Form, InputGroup, Pagination, Row, Table} from "react-bootstrap";
import axiosInstance, {GetAsInputData} from "../utils/axios";
import get from 'lodash.get';
import {Link} from "react-router-dom";
import Loader from "../components/Loader";
import Select from "react-select";
import {Search} from "react-feather";

const CustomEnumFilter = ({
                              options,
                              setCondition,
                              searchField,
                              selectedValue,
                              setSelectedValue,
                              enumKey,
                          }) => {
    return (
        <Select
            className="react-select-container"
            classNamePrefix="react-select"
            name={"enum" + new Date().getTime()}
            value={selectedValue}
            isClearable={true}
            options={options}
            onChange={(selectedOption) => {
                if (selectedOption == null || selectedOption == undefined) {
                    setSelectedValue(undefined);
                    setCondition(undefined);
                } else {
                    setSelectedValue(selectedOption);
                    setCondition({
                        field: searchField,
                        enumName: enumKey,
                        type: "enum",
                        value: "" + selectedOption.value,
                    });
                }
            }}
        />
    );
};

function CustomTextFilter({
                              setCondition,
                              searchField,
                              selectedValue,
                              setSelectedValue,
                          }) {
    const [text, setText] = useState(selectedValue || "");

    function setData() {
        if (text != null && text != undefined) {
            setSelectedValue(text);
            let condition = { field: searchField, condition: "like", value: text };
            setCondition(condition);
        } else {
            setSelectedValue(null);
            setCondition(null);
        }
    }

    return (
        <InputGroup>
            <Form.Control
                value={text || ""}
                onChange={(e) => {
                    setText(e.target.value || undefined);
                }}
                onKeyPress={(event) => {
                    if (event.key === "Enter") {
                        setData();
                    }
                }}
                placeholder={`Search records...`}
                className="mt-2"
            />
            <Button onClick={setData} className="mt-2" variant="">
                <Search className="feather" />
            </Button>
        </InputGroup>
    );
}

const CustomFilter = ({entityUrl,setCondition,entityId,entityValue,searchField,searchType,entity,searchCondition,selectedValue,setSelectedValue}) => {

    const [options,setOptions] = useState([])

    useEffect(() => {
        axiosInstance.get("/api/"+entityUrl+"?"+GetAsInputData({
            listInfo:{
                startRecord:0,
                rowCount:9999,
                searchConditions:searchCondition,
                sortBy: [{"field":entityValue,"order":"asc"}]
            }
        })).then(function(response){
            setOptions(response.data[entity])
        })
    },[])
    return(
        <Select
            className="react-select-container"
            classNamePrefix="react-select"
            name={entity}
            value={selectedValue}
            isClearable={true}
            getOptionLabel={(option)=>option[entityValue]}
            getOptionValue={(option)=>option[entityId]}
            options={options}
            onChange={selectedOption => {
                if(selectedOption==null || selectedOption == undefined){
                    setSelectedValue(undefined)
                    setCondition(undefined)
                }else{
                    setSelectedValue(selectedOption)
                    setCondition({field: searchField, type: searchType, value: ""+selectedOption[entityId]})
                }

            }}

        />
    )

}

function SelectColumnFilter({column: { filterValue, setFilter, preFilteredRows, id }}) {
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()].sort();
    }, [id, preFilteredRows]);
    return (
        <Form.Select
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </Form.Select>
    );
}

function DefaultColumnFilter({column: { filterValue, preFilteredRows, setFilter },}) {
    const count = preFilteredRows.length;

    return (
        <Form.Control
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Search ${count} records...`}
            className="mt-2"
        />
    );
}

const RowSpanCell = ({
                         value,
                         rowIndex,
                         accessor,
                         nestedAccessor = null,
                         data,
                         cell,
                     }) => {
    let rowspan = 1;
    let nestedRowspan = 1;

    for (let i = rowIndex + 1; i < data.length; i++) {
        if (get(data[i], accessor) === get(data[rowIndex], accessor, '')) {
            rowspan++;
        } else {
            break;
        }
    }

    if (nestedAccessor) {
        let lastNestedAccessorValue = get(data[rowIndex], nestedAccessor, '');
        let nestedGroupEndIndex = rowIndex + rowspan;
        for (let i = rowIndex + 1; i < nestedGroupEndIndex; i++) {
            if (get(data[i], nestedAccessor) === lastNestedAccessorValue) {
                nestedRowspan++;
            } else {
                break;
            }
        }
    }

    let isFirstRow = true;

    if (rowIndex > 0) {
        const currentAccessorValue = get(data[rowIndex], accessor, '');
        const prevAccessorValue = get(data[rowIndex - 1], accessor, '');

        if (currentAccessorValue === prevAccessorValue) {
            if (nestedAccessor) {
                const currentNestedAccessorValue = get(
                    data[rowIndex],
                    nestedAccessor,
                    '',
                );
                const prevNestedAccessorValue = get(
                    data[rowIndex - 1],
                    nestedAccessor,
                    '',
                );

                if (currentNestedAccessorValue === prevNestedAccessorValue) {
                    isFirstRow = false;
                }
            } else {
                isFirstRow = false;
            }
        }
    }

    if (isFirstRow) {
        if (nestedAccessor) {
            return (
                <td style={{textAlign:"center"}} {...cell.getCellProps()} rowSpan={nestedRowspan}>
                    {value}
                </td>
            );
        } else {
            return (
                <td style={{textAlign:"center"}} {...cell.getCellProps()} rowSpan={rowspan}>
                    {value}
                </td>
            );
        }
    } else {
        return null;
    }
};


const EntityTable = ({
    preFetchedData,
    columns,
    fields,
    url,
    sortBy,
    buttons,
    searchCondition,
    pageSize,
    dataCallBackFn,
    rowProps = () => ({}),
    tableProps = () => ({})
}) => {

    const [localOptions,setLocalOptions] = useState({
        data:preFetchedData?preFetchedData:[],
        pageCount:0,
        startRecord:0,
        canPreviousPage:false,
        canNextPage:false,
        pageIndex:0,
        pageSize:pageSize?pageSize:150,
        sortBy:sortBy,
        url:url,
        searchCondition:searchCondition,
        noData:false
    });

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const [visibleColumns, setVisibleColumns] = useState(columns.map((col) => col.accessor));

    const handleColumnVisibility = (columnAccessor) => {
        setVisibleColumns((prevVisibleColumns) => {
            if (prevVisibleColumns.includes(columnAccessor)) {
                return prevVisibleColumns.filter((col) => col !== columnAccessor);
            } else {
                return [...prevVisibleColumns, columnAccessor];
            }
        });
    };

    const visibleColumnsMemo = useMemo(() => columns.filter((col) => visibleColumns.includes(col.accessor)), [
        columns,
        visibleColumns,
    ]);


    let data = localOptions.data

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page
    } =
        useTable(
            {
                columns: visibleColumnsMemo,
                data,
                manualPagination: true,
                defaultColumn,
                filterTypes,
            },
            useFilters,
            usePagination
        );

    useEffect(() => {
        const load =  () => {

            const input_data = {
                listInfo:{
                    startRecord:localOptions.startRecord,
                    rowCount:localOptions.pageSize,
                    sortBy:sortBy,
                    searchConditions:searchCondition
                }
            };


            try{

                let entityURL = "/api/"+url;

                let encodedData = GetAsInputData(input_data);

                axiosInstance.get(entityURL+"?"+encodedData)
                    .then(function(response){
                        if(dataCallBackFn!==undefined && response.data[url].length!==0)
                            response.data[url] = dataCallBackFn(response.data[url]);
                        setLocalOptions({
                            pageCount: Math.ceil(response.data.listInfo.totalCount/localOptions.pageSize),
                            canNextPage: response.data.listInfo.hasMoreRows,
                            canPreviousPage: response.data.listInfo.startRecord!==0,
                            data:response.data[url],
                            pageIndex: localOptions.pageIndex,
                            startRecord:response.data.listInfo.startRecord,
                            pageSize: response.data.listInfo.rowCount,
                            url:url,
                            sortBy:sortBy,
                            searchCondition:response.data.listInfo.searchConditions,
                            noData:!response.data.listInfo.totalCount
                        });
                    })

            }catch(e){
                console.log(e);
            }
        }
        if(preFetchedData===undefined)
            load();
    },[localOptions.pageSize,localOptions.startRecord,searchCondition]);

    if(localOptions.data.length===0 && !localOptions.noData){
        return( <Loader/>);
    }else{
        return (
            <Card>
                <Card.Header>
                    {buttons.map((button) => {
                        button.variant  = button.variant===undefined?'primary':button.variant
                        if(button.href!==undefined)
                            return (
                                <Link key={button.href} to={button.href}>
                                    <Button variant={button.variant} className=" me-1 mb-1">{button.name}</Button>
                                </Link>
                            );
                        else
                            return (
                                <Button variant={button.variant} onClick={button.handleClick} className=" me-1 mb-1">{button.name}</Button>
                            );
                    })}
                    <DropdownButton id="column-chooser" title="Choose Columns" className="me-1 mb-1">
                        {columns.map((column) => (
                            <Dropdown.Item
                                key={column.accessor}
                                onClick={() => handleColumnVisibility(column.accessor)}
                            >
                                <Form.Check
                                    type="checkbox"
                                    label={column.Header}
                                    checked={visibleColumns.includes(column.accessor)}
                                    onChange={() => {}} // to suppress uncontrolled to controlled warning
                                />
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                </Card.Header>

                {
                    localOptions.noData &&  (
                        <Card.Body>
                            <Table responsive hover striped bordered {...getTableProps()}>
                                <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th {...column.getHeaderProps()}>
                                                {column.render("Header")}
                                                <div>
                                                    {column.canFilter ? column.render("Filter") : null}
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                                </thead>
                            </Table>
                            <h1 style={{
                                margin:"50px",
                                border:'0px'
                            }}> No Data </h1>
                        </Card.Body>
                    )

                }
                {
                    !localOptions.noData &&
                    <Card.Body>
                        <Table responsive hover striped bordered {...getTableProps(tableProps())}>
                            <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>
                                            {column.render("Header")}
                                            <div>
                                                {column.canFilter ? column.render("Filter") : null}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps(rowProps(row))}>
                                        {row.cells.map((cell) => {
                                            if(cell.column.dontRenderTag){
                                                return <>{cell.render("Cell")}</>
                                            }else{
                                                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                            }
                                        })}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                        { preFetchedData===undefined && <Row>
                            <Col md="6">
                            <span className="mx-2">
                              Page{" "}
                                <strong>
                                {localOptions.pageIndex + 1} of {localOptions.pageCount}
                              </strong>
                            </span>
                                <span className="ms-3 me-2">Show:</span>
                                <Form.Select
                                    className="d-inline-block w-auto"
                                    value={localOptions.pageSize}
                                    onChange={(e) => {
                                        setLocalOptions({
                                            pageCount: localOptions.pageCount,
                                            canNextPage: localOptions.canNextPage,
                                            canPreviousPage: localOptions.canPreviousPage,
                                            data:[],
                                            pageIndex: localOptions.pageIndex,
                                            startRecord:localOptions.startRecord,
                                            pageSize: Number(e.target.value),
                                            url:localOptions.url,
                                            sortBy:localOptions.sortBy
                                        });
                                    }}
                                >
                                    {[150, 300, 450, 1000,2000,3000].map((pageSize) => (
                                        <option key={pageSize} value={pageSize}>
                                            {pageSize}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col md="6">
                                <Pagination className="float-end">
                                    <Pagination.First
                                        onClick={() => {
                                            setLocalOptions({
                                                pageCount: localOptions.pageCount,
                                                canNextPage: localOptions.canNextPage,
                                                canPreviousPage: localOptions.canPreviousPage,
                                                data:[],
                                                pageIndex: 0,
                                                startRecord:0,
                                                pageSize: localOptions.pageSize,
                                                url:localOptions.url,
                                                sortBy:localOptions.sortBy
                                            });
                                        }
                                        }
                                        disabled={!localOptions.canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={() => {
                                            if(localOptions.startRecord-localOptions.pageSize < 0)
                                                setLocalOptions({
                                                    pageCount: localOptions.pageCount,
                                                    canNextPage: localOptions.canNextPage,
                                                    canPreviousPage: localOptions.canPreviousPage,
                                                    data:[],
                                                    pageIndex: 0,
                                                    startRecord:0,
                                                    pageSize: localOptions.pageSize,
                                                    url:localOptions.url,
                                                    sortBy:localOptions.sortBy
                                                });
                                            else
                                                setLocalOptions({
                                                    pageCount: localOptions.pageCount,
                                                    canNextPage: localOptions.canNextPage,
                                                    canPreviousPage: localOptions.canPreviousPage,
                                                    data:[],
                                                    pageIndex: localOptions.pageIndex - 1,
                                                    startRecord:localOptions.startRecord - localOptions.pageSize,
                                                    pageSize: localOptions.pageSize,
                                                    url:localOptions.url,
                                                    sortBy:localOptions.sortBy
                                                });
                                        }}
                                        disabled={!localOptions.canPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() => {
                                            setLocalOptions({
                                                pageCount: localOptions.pageCount,
                                                canNextPage: localOptions.canNextPage,
                                                canPreviousPage: localOptions.canPreviousPage,
                                                data:[],
                                                pageIndex: localOptions.pageIndex + 1,
                                                startRecord:localOptions.startRecord + localOptions.pageSize,
                                                pageSize: localOptions.pageSize,
                                                url:localOptions.url,
                                                sortBy:localOptions.sortBy
                                            });
                                        }}
                                        disabled={!localOptions.canNextPage}
                                    />
                                </Pagination>
                            </Col>
                        </Row>}
                    </Card.Body>
                }

            </Card>
        );
    }

};

export default EntityTable;
export {
    CustomFilter,
    SelectColumnFilter,
    DefaultColumnFilter,
    CustomEnumFilter,
    CustomTextFilter,
    RowSpanCell
}; //NumberRangeColumnFilter
