import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import {Container} from "react-bootstrap";
import EntityTable, {CustomEnumFilter, CustomFilter, CustomTextFilter} from "../../../components/EntityTable";


const ClaimLedgerPage = () => {


    const [searchCondition,setSearchCondition] = useState();
    const [searchConditionSelectedValue,setSearchConditionSelectedValue] = useState();
    const [searchCondition1,setSearchCondition1] = useState();
    const [searchConditionSelectedValue1,setSearchConditionSelectedValue1] = useState();
    const [searchCondition2,setSearchCondition2] = useState();
    const [searchConditionSelectedValue2,setSearchConditionSelectedValue2] = useState();
    const [searchCondition3,setSearchCondition3] = useState();
    const [searchConditionSelectedValue3,setSearchConditionSelectedValue3] = useState();
    const [searchCondition4,setSearchCondition4] = useState();
    const [searchConditionSelectedValue4,setSearchConditionSelectedValue4] = useState();

    const paymentsTableColumns = [

        {
            Header: "Account Holder",
            accessor: "dealer.dealer_name",
            Filter: () => {
                return CustomFilter({
                        entityUrl:"customers",
                        setCondition:setSearchCondition,
                        entityId:"dealer_id",
                        entityValue:"dealer_name",
                        searchField:"dealer.dealer_id",
                        searchType:"number",
                        entity:"customers",
                        searchCondition:[{
                            field:"type",
                            type:"number",
                            value:4+"",
                            condition:"not_equals"
                        }],
                        selectedValue:searchConditionSelectedValue,
                        setSelectedValue:setSearchConditionSelectedValue
                    }
                )
            },
        },
        {
            Header: "Retailer",
            accessor: "retailer.dealer_name",
            Filter: () => {
                return CustomFilter({
                        entityUrl:"customers",
                        setCondition:setSearchCondition2,
                        entityId:"dealer_id",
                        entityValue:"dealer_name",
                        searchField:"dealer.dealer_id",
                        searchType:"number",
                        entity:"customers",
                        selectedValue:searchConditionSelectedValue2,
                        setSelectedValue:setSearchConditionSelectedValue2,
                        searchCondition:[{
                            field:"type",
                            type:"number",
                            value:4+"",
                        }]
                    }
                )
            },
        },
        {
            Header: "Type",
            accessor: "transaction_type",
            Filter: (
                <CustomEnumFilter
                    options={[
                        { value: "CREDIT", label: "Claim Approved" },
                        { value: "DEBIT", label: "Invoice / Bill" },
                        { value: "CLAIM_TRANSFER", label: "Claim Transfer" },
                        { value: "CLAIM_CREDIT", label: "Claim Credit" },
                        { value: "CLAIM_DEBIT", label: "Claim Debit" },
                    ]}
                    searchField={"transaction_type"}
                    setCondition={setSearchCondition3}
                    selectedValue={searchConditionSelectedValue3}
                    enumKey={"TransactionEnums"}
                    setSelectedValue={setSearchConditionSelectedValue3}
                />
            ),
        },
        {
            Header: "Reference",
            accessor: "transaction_reference",
            Filter: (<CustomTextFilter
                searchField={"transaction_reference"}
                setCondition={setSearchCondition4}
                selectedValue={searchConditionSelectedValue4}
                setSelectedValue={setSearchConditionSelectedValue4}
            />),
        },
        {
            Header: "Debit",
            accessor: "debit",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                return  value===null?0:value.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR"
                });
            },
        },
        {
            Header: "Credit",
            accessor: "credit",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                return  value===null?0:value.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR"
                });
            },
        },
        {
            Header: "Previous balance",
            accessor: "previous_balance",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                return  value===null?0:value.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR"
                });
            },
        },
        {
            Header: "Closing balance",
            accessor: "closing_balance",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                return  value===null?0:value.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR"
                });
            },
        },
        {
            Header: "Created Date",
            accessor: "created_date",Filter: false,
            Cell: ({ cell: { value } }) => {
                let date = new Date(Number(value));
                date = date.toString().slice(0,21)
                return date;
            }

        },
        {
            Header: "Created By",
            accessor: "created_by.name",
            Filter: () => {
                return CustomFilter({
                        entityUrl:"users",
                        setCondition:setSearchCondition1,
                        entityId:"user_id",
                        entityValue:"name",
                        searchField:"created_by.user_id",
                        searchType:"number",
                        entity:"users",
                        selectedValue:searchConditionSelectedValue1,
                        setSelectedValue:setSearchConditionSelectedValue1
                    }
                )
            },
        },

    ];
    let condition = [];
    searchCondition!=null && condition.push(searchCondition)
    searchCondition1!=null && condition.push(searchCondition1)
    searchCondition2!=null && condition.push(searchCondition2)
    searchCondition3!=null && condition.push(searchCondition3)
    searchCondition4!=null && condition.push(searchCondition4)
    if(condition.length==0)
        condition = null
    return(<React.Fragment>
        <Helmet title="Claims"/>
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Claim ledger</h1>
            <EntityTable
                columns={paymentsTableColumns}
                url={"claim_ledgers"}
                sortBy={[{field: "created_date", order: "desc"}]}
                searchCondition={condition}
                buttons={[]}
            />
        </Container>
    </React.Fragment>);

};

export default ClaimLedgerPage;



