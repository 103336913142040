import React from "react";
import {Helmet} from "react-helmet-async";
import {Container} from "react-bootstrap";
import EntityTable from "../../../components/EntityTable";


const salesConfigTableColumns = [
    {
        Header: "Name",
        accessor: "product.name",
    },
    {
        Header: "MT Distributor Pricing",
        accessor: "mt_distributor_price",
        Filter:false
    },
    {
        Header: "GT Distributor Pricing",
        Filter:false,
        accessor: "gt_distributor_price"

    },
    {
        Header: "SS Pricing",
        Filter:false,
        accessor: "super_stockist_price"
    },
    {
        Header: "Price without offer",
        Filter:false,
        accessor: "without_offer_price"
    },
    {
        Header: "35% Difference",
        Filter:false,
        accessor: "thirty_five_percent"
    },
    {
        Header: "30% Difference",
        Filter:false,
        accessor: "thirty_percent"
    },
    {
        Header: "27% Difference",
        Filter:false,
        accessor: "twenty_seven_percent"
    },
    {
        Header: "25% Difference",
        Filter:false,
        accessor: "twenty_five_percent"
    },
    {
        Header: "23% Difference",
        Filter:false,
        accessor: "twenty_three_percent"
    },
    {
        Header: "22% Difference",
        Filter:false,
        accessor: "twenty_two_percent"
    },
    {
        Header: "20% Difference",
        Filter:false,
        accessor: "twenty_percent"
    }
];


const SalesPricing = () => {

    return(<React.Fragment>
        <Helmet title="Sales Pricing"/>
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Sales Pricing</h1>

            <EntityTable key={new Date().getTime()}
                           columns={salesConfigTableColumns}
                           url={"dis_products"}
                           pageSize={9999}
                           sortBy={[{field: "product.name", order: "asc"}]}
                           buttons={[]}
            />
        </Container>
    </React.Fragment>);

};

export default SalesPricing;
