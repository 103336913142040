import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Button, Col, Container, Nav, Row, Tab, Table} from "react-bootstrap";
import {useParams} from "react-router-dom";
import axiosInstance, {GetAsInputData, PostApproval} from "../../../utils/axios";
import Loader from "../../../components/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamation} from "@fortawesome/free-solid-svg-icons";
import {DomesticSaleOrderForm} from "./SalesOrderDomesticForm";
import {LUTSaleOrderForm} from "./SalesOrderLUTForm";
import {getBadge} from "../../../utils/misc";
import HistoryTable from "../history/HistoryTable";

const SaleOrderDetails = () => {
    const params = useParams();
    const [saleOrderData,setSaleOrderData] = useState();
    const [saleOrderInvoices,setSaleOrderInvoices] = useState([]);

    useEffect(() => {
        const load =  () => {
            try{
                let entityURL = "/api/sales_order/"+params.saleOrderID;
                axiosInstance.get(entityURL)
                    .then(function(response){
                        response.data.sale_order.products.map((saleProduct,index) =>{
                            saleProduct.boxes = saleProduct.quantity / saleProduct.product.box_count ;
                            return saleProduct;
                        });
                        var date = new Date(Number(response.data.sale_order.po_date));
                        var day = ("0" + date.getDate()).slice(-2);
                        var month = ("0" + (date.getMonth() + 1)).slice(-2);
                        response.data.sale_order.po_date_field = date.getFullYear()+"-"+(month)+"-"+(day);
                        response.data.sale_order.dealer.balance = response.data.balance;
                        setTimeout(() => {
                            setSaleOrderData(response.data.sale_order);
                        }, 500);
                    })
            }catch(e){
                console.log(e);
            }

            try{
                let entityURL = "/api/sales_delivery";

                const input_data = {
                    listInfo:{
                        startRecord:0,
                        rowCount:9999,
                        sortBy:[{"field":"invoice.id","order":"desc"}],
                        searchConditions:[{"field":"order.so_number","value":""+params.saleOrderID,"type":"longnumber"}]
                    }
                };


                let encodedData = GetAsInputData(input_data);

                axiosInstance.get(entityURL+"?"+encodedData)
                    .then(function(response){
                        setTimeout(() => {
                            setSaleOrderInvoices(response.data.sales_delivery);
                        }, 500);
                    })
            }catch(e){
                console.log(e);
            }
        }

        load();
    },[]);

    function updateData(){
        try{
            let entityURL = "/api/sales_order/"+params.saleOrderID;
            axiosInstance.get(entityURL)
                .then(function(response){
                    response.data.sale_order.products.map((saleProduct,index) =>{
                        saleProduct.boxes = saleProduct.quantity / saleProduct.product.box_count ;
                        return saleProduct;
                    });
                    response.data.sale_order.dealer.balance = response.data.balance;
                    setTimeout(() => {
                        setSaleOrderData(response.data.sale_order);
                    }, 500);
                })
        }catch(e){
            console.log(e);
        }
    }

    if(saleOrderData ===undefined){
        return <Loader />;
    }else{
        return(

            <Container fluid className="p-0">
                <h1 className="h3 mb-3">{saleOrderData.dealer.dealer_name} - {saleOrderData.sale_number}</h1>
                <Row>
                    <Col lg="12">
                        <div className={"tab " }>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Nav variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Details</Nav.Link>
                                    </Nav.Item>
                                    {
                                        saleOrderData.status==="CREATED"?
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second">Approval</Nav.Link>
                                                </Nav.Item>:""
                                    }
                                    {
                                        saleOrderData.status!=="CREATED" && saleOrderData.status!=="REJECTED" && saleOrderData.status!=="LESS_MONEY_REJECTION"?
                                            <Nav.Item>
                                                <Nav.Link eventKey="third">Invoices</Nav.Link>
                                            </Nav.Item>:""
                                    }

                                    {
                                        saleOrderData.status==="CREATED" || saleOrderData.status==="REJECTED" || saleOrderData.status==="LESS_MONEY_REJECTION"?<Nav.Item>
                                            <Nav.Link eventKey="fourth">Modify Sale Order</Nav.Link>
                                        </Nav.Item>:""
                                    }<Nav.Item>
                                    <Nav.Link eventKey="history">History</Nav.Link>
                                </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey={"history"}>
                                        <Row>
                                            <Col lg={6}>
                                                <HistoryTable
                                                    url={
                                                        "/api/audit/sale_order/" + saleOrderData.so_number
                                                    }
                                                />
                                            </Col>
                                            {saleOrderData.products.map((product, i) => {
                                                return (
                                                    <Col lg={6}>
                                                        <HistoryTable
                                                            url={"/api/audit/sale_order/product/" + product.id}
                                                            name={product.product.name}
                                                        />
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="first">
                                        <h4 className="tab-title">Sale Details</h4>
                                        <Col lg="6">
                                            <Table size="sm" className="my-2">
                                                <tbody>
                                                <tr>
                                                    <th>Type</th>
                                                    <td>{saleOrderData.sale_type}</td>
                                                </tr>
                                                <tr>
                                                    <th>Purchase Number</th>
                                                    <td>{saleOrderData.po_number}</td>
                                                </tr>
                                                <tr>
                                                    <th>Purchase Date</th>
                                                    <td>{new Date(Number(saleOrderData.po_date)).toString().slice(3,23)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Created On</th>
                                                    <td>{new Date(Number(saleOrderData.created_date)).toString().slice(3,23)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Sale</th>
                                                    <td>{saleOrderData.total_before_tax.toLocaleString("en-IN", {
                                                        style: "currency",
                                                        currency: "INR"
                                                    })}</td>
                                                </tr>
                                                <tr>
                                                    <th>Tax</th>
                                                    <td>{saleOrderData.total_tax.toLocaleString("en-IN", {
                                                        style: "currency",
                                                        currency: "INR"
                                                    })}</td>
                                                </tr>
                                                <tr>
                                                    <th>Total</th>
                                                    <td>{saleOrderData.total_after_tax.toLocaleString("en-IN", {
                                                        style: "currency",
                                                        currency: "INR"
                                                    })}</td>
                                                </tr>
                                                <tr>
                                                    <th>Status</th>
                                                    <td>
                                                        {getBadge(saleOrderData.status)}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <br/>
                                        <h4 className="tab-title">Sale Products </h4>
                                        <Col lg="6">
                                            <div className="table-responsive">
                                                {
                                                    <Table size="sm" className="my-2">
                                                        <thead>
                                                        <tr>
                                                            <th>Product Name</th>
                                                            <th>Quantity</th>
                                                            <th>Pending</th>
                                                            <th>Price</th>
                                                            <th>Amount</th>
                                                            <th>GST</th>
                                                            <th>Total</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        { saleOrderData.products.map((product, i) => {
                                                            return (
                                                                <tr>
                                                                    <td>{product.product.name}</td>
                                                                    <td>{product.quantity}</td>
                                                                    <td>{product.pending_quantity}</td>
                                                                    <td>{product.price}</td>
                                                                    <td>{product.product_total_before_tax}</td>
                                                                    <td>{product.product_total_tax}</td>
                                                                    <td>{product.product_total_after_tax}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>
                                                    </Table>
                                                }
                                            </div>
                                        </Col>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Invoice Number</th>
                                                    <th>Dealer Name</th>
                                                    <th>Created Date</th>
                                                    <th>Created By</th>
                                                    <th>Total</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {saleOrderInvoices.length>0?saleOrderInvoices.map( (row, i) => {
                                                return(
                                                    <tr>
                                                        <td>{row.invoice.invoice_number}</td>
                                                        <td>{row.invoice.dealer.dealer_name}</td>
                                                        <td>{new Date(Number(row.invoice.created_date)).toString().slice(0,24)}</td>
                                                        <td>{row.invoice.created_by.name}</td>
                                                        <td>{row.invoice.total_sale_after_tax}</td>
                                                        <td>{getBadge(saleOrderData.status)}</td>
                                                    </tr>
                                                );
                                            }):("No invoices available")}

                                            </tbody>
                                        </Table>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fourth">
                                        {
                                            saleOrderData.sale_type==="DOMESTIC"?<DomesticSaleOrderForm initialValues={saleOrderData} setSaleData={setSaleOrderData} successUrl={"/sales/orders-details/"+saleOrderData.so_number} />:<LUTSaleOrderForm initialValues={saleOrderData} setSaleData={setSaleOrderData} successUrl={"/sales/orders-details/"+saleOrderData.so_number} />
                                        }
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <Col lg={2}>
                                            <Table size="sm" className="my-2">
                                                <tbody>
                                                    <tr>
                                                        <th>Ledger Balance</th>
                                                        <td>{saleOrderData.dealer.balance===undefined?"N/A":saleOrderData.dealer.balance}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Sale Order Total</th>
                                                        <td>{saleOrderData.total_after_tax}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Status</th>
                                                        <td>{getBadge(saleOrderData.status)}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <br/>
                                        { saleOrderData.status==="CREATED" && saleOrderData.total_after_tax<=saleOrderData.dealer.balance && <Button variant={`primary`} className=" me-1 mb-1" onClick={ () =>{PostApproval("sales_order",saleOrderData.so_number,"is_approved","APPROVED",updateData);}}>Approve</Button> }
                                        { saleOrderData.status==="CREATED" && saleOrderData.total_after_tax>saleOrderData.dealer.balance && <Button variant={`warning`} className=" me-1 mb-1"><FontAwesomeIcon icon={faExclamation} /> Less Money</Button> }
                                        { saleOrderData.status==="CREATED" && <Button variant={`danger`} className=" me-1 mb-1" onClick={ () =>{PostApproval("sales_order",saleOrderData.so_number,"is_approved","REJECTED",updateData);}}> Reject </Button> }
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }


};

const SaleOrderDetailsPage = () => (

    <React.Fragment>
        <Helmet title="Sales Order Details" />
        <SaleOrderDetails />
    </React.Fragment>
);

export default SaleOrderDetailsPage;
