import React from "react";
import {Helmet} from "react-helmet-async";
import {Container} from "react-bootstrap";
import EntityTable, {SelectColumnFilter} from "../../../../components/EntityTable";
import {useNavigate} from "react-router-dom";
import {getBadge} from "../../../../utils/misc";


const purchasesTableColumns = [
    {
        Header: "Order ID",
        accessor: "purchase_number",
    },
    {
        Header:"Supplier",
        accessor: "supplier.company_name",
        Filter: SelectColumnFilter,
    },
    {
        Header: "Created Date",
        accessor: "created_date",Filter: false,
        Cell: ({ cell: { value } }) => {
            let date = new Date(Number(value));
            date = date.toString().slice(0,24)
            return date;
        }

    },
    {
        Header: "Created By",
        accessor: "created_by.name",
        Filter: SelectColumnFilter,
        filter: "includes",
    },
    {
        Header: "Proforma Invoice Number",
        accessor: "pi_number",
    },
    {
        Header: "Status",
        accessor: "status",
        Filter: SelectColumnFilter,
        Cell: ({ cell: { value } }) => {
            return getBadge(value);
        }
    }
];

const purchasesButtons = [
    {
        key:0,
        name:"New Order",
        href:"/purchases/orders-new"
    },
    // {
    //     key:1,
    //     name:"Automated Order",
    //     href:"sales/domestic-orders"
    // },
    // {
    //     key:2,
    //     name:"Vimac Order",
    //     href:"sales/domestic-orders"
    // }
];

const PurchasePage = () => {
    const navigate = useNavigate();
    return(<React.Fragment>
        <Helmet title="Purchase Orders"/>
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Purchase Orders</h1>

            <EntityTable
                columns={purchasesTableColumns}
                fields={["po_number","approval"]}
                url={"purchases"}
                sortBy={[{field: "po_number", order: "desc"}]}
                buttons={purchasesButtons}
                rowProps={row => ({
                    onClick: () => navigate("/purchases/orders-details/"+row.original.po_number) ,
                    style: {
                        cursor: "pointer"
                    }
                })}
            />
        </Container>
    </React.Fragment>);

};

export default PurchasePage;



