import React from "react";
import {Helmet} from "react-helmet-async";
import {Container} from "react-bootstrap";
import EntityTable, {SelectColumnFilter} from "../../../components/EntityTable";

const paymentsTableColumns = [
    {
        Header: "Created By",
        accessor: "created_by.name",
        Filter: SelectColumnFilter,
        filter: "includes",
    },
    {
        Header: "Created Date",
        accessor: "created_date",Filter: false,
        Cell: ({ cell: { value } }) => {
            let date = new Date(Number(value));
            date = date.toString().slice(0,21)
            return date;
        }

    },
    {
        Header: "Product name",
        accessor: "product.name",
        Filter: SelectColumnFilter,
        filter: "includes",
    },
    {
        Header: "Batch",
        accessor: "batch_number",
        Filter: false
    },
    {
        Header: "Received",
        accessor: "quantity_received",
        Filter: false
    },
    {
        Header: "Total",
        accessor: "total_stock",
        Filter: false
    },
    {
        Header: "Issued",
        accessor: "quantity_issued",
        Filter: false
    },
    {
        Header: "Closing",
        accessor: "closing_balance",
        Filter: false
    },
    {
        Header: "Remarks",
        accessor: "remarks",
        Filter: false
    },
];

const ChennaiLedgerPage = () => {

    return(<React.Fragment>
        <Helmet title="Payments"/>
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Chennai ledger</h1>

            <EntityTable
                columns={paymentsTableColumns}
                url={"ledgers_chennai"}
                sortBy={[{field: "id", order: "desc"}]}
                buttons={[]}
            />
        </Container>
    </React.Fragment>);

};

export default ChennaiLedgerPage;



