import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import {Container} from "react-bootstrap";
import EntityTable, {CustomFilter} from "./../../../components/EntityTable";


const TransactionsPage = () => {

    const [searchCondition,setSearchCondition] = useState();

    const transactionsTableColumns = [
        {
            Header: "UTR Number",
            accessor: "transactionID",
        },
        {
            Header: "Dealer Code",
            accessor: "dealerCode",
        },
        {
            Header: "Account Code",
            accessor: "dealer.code",
        },
        {
            Header: "Dealer Name",
            accessor: "dealer.dealer_name",
            Filter: () => {
                return CustomFilter("customers",setSearchCondition,"dealer_id","dealer_name","dealer.dealer_id","number","customers")
            },
        },
        {
            Header: "Amount",
            accessor: "transactionAmount",
            Cell: ({ cell: { value } }) => {
                return value.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR"
                });
            },
            Filter:false
        },
        {
            Header: "Inward Time",
            accessor: "transactionTime",
            Filter:false
        },
        {
            Header: "Created Time",
            accessor: "createdDate",
            Cell: ({ cell: { value } }) => {
                let date = new Date(Number(value));
                date = date.toString().slice(0,24)
                return date;
            },
            Filter:false
        },
    ];
    let condition = [];
    searchCondition!=null && condition.push(searchCondition)
    if(condition.length==0)
        condition = null

    return(<React.Fragment>
        <Helmet title="Transactions"/>
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Transactions</h1>

            <EntityTable
                columns={transactionsTableColumns}
                url={"transactions"}
                searchCondition={condition}
                sortBy={[{field: "id", order: "desc"}]}
                buttons={[]}
            />
        </Container>
    </React.Fragment>);

};

export default TransactionsPage;



