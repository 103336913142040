import React, {useContext, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Button, Container} from "react-bootstrap";
import useAuth from "../../../../hooks/useAuth";
import NotyfContext from "../../../../contexts/NotyfContext";
import axiosInstance from "../../../../utils/axios";
import EntityTable from "../../../../components/EntityTable";
import {roles} from "../../../../utils/roles";


const InactiveDealers = () => {


    const columns = [
        {
            Header: "Name",
            accessor: "dealer_name",
        },
        {
            Header: "Account Number",
            accessor: "code",
        },
        {
            Header: "GST",
            accessor: "gst"
        }
    ];



    const condition = [
        {field: "type", type: "number", value: "0"}
    ];

    const {user} = useAuth();
    const notify = useContext(NotyfContext);

    if(user.roles.indexOf(roles.ROLE_ENABLE_DISABLE_DEALERS_GENERAL_TRADER)!=-1 && columns.length!=4){
        columns.push({
            Header: "Enable",
            accessor: "dealer_id",Filter:false,
            Cell: ({ cell: { value } }) => {
                const [dealerID,setDealerId] = useState(value)
                if(dealerID==null){
                    return ""
                }else{
                    return <Button onClick={() => {
                        axiosInstance.get("/api/dealers/"+dealerID+"/approve").then(function(response){
                            notify.success("Dealer enabled")
                            setDealerId(null);
                        });
                    }} style={{width:'100%'}} variant={'outline-primary'}>Enable</Button>
                }

            }
        });
    }

    return(<React.Fragment>
        <Helmet title="General Traders"/>
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Inactive General Traders</h1>

            <EntityTable
                columns={columns}
                url={"customers_inactive"}
                pageSize={99999}
                sortBy={[{field: "dealer_name", order: "asc"}]}
                buttons={[]}
                searchCondition={condition}
            />
        </Container>
    </React.Fragment>);

};

export default InactiveDealers;
