import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";

import {Button, Card, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import Select from "react-select";
import axiosInstance, {ErrorMessage, GetAsInputData} from "../../../../utils/axios";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {FieldArray, Formik} from "formik";

const purchaseOrderSchema = Yup.object().shape({
    purchaseOrder: Yup.object().required("PurchaseOrder is required"),
    invoice_number:Yup.string().required("Pi number is required"),
    pi_date_field:Yup.date().required("Pi date is required"),
    products: Yup.array()
        .of(
            Yup.object().shape({
                product: Yup.object().required("Product is required").nullable(),
                received_quantity: Yup.number().required("Quantity is required").min(1,"Quantity should be greater than 0")
            })
        )
});


const initialValues = {
    purchaseOrder: undefined,
    invoice_number:undefined,
    invoice_date:0,
    pi_date_field:undefined,
    transport:undefined,
    products:[{
        product:undefined,
        received_quantity:undefined
    }]
};

const ReceiptForm = () => {
    const navigate = useNavigate();
    const [productOptions, setProductOptions] = useState([]);
    const [isProductsLoading, setIsProductLoading] = useState(true);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [isCustomersLoading, setIsCustomersLoading] = useState(true);

    function HandleSubmit(url,data,navigateTo){
        axiosInstance.post("/api/"+url,data).then(function(response,success){
            if(response.status===200)
                navigate(navigateTo);
        })
    }

    function loadDealerProducts(purchaseOrderID){
        axiosInstance.get("/api/purchase_products?"+GetAsInputData({
            listInfo:{
                startRecord:0,
                rowCount:9999,
                sortBy: [{"field":"product.name","order":"asc"}],
                searchConditions:[{"field":"purchaseOrder.po_number","value":""+purchaseOrderID,"type":"number"},{"field":"pending_quantity","value":"0","condition":"not_equals","type":"number"}]
            }
        })).then(function(response){
            setProductOptions(response.data.product);
            setTimeout(() => {
                setIsProductLoading(false);
            }, 1500);
        });
    }

    useEffect(() => {
        const loadSelectData =  () => {

            try{
                axiosInstance.get("/api/purchases?"+GetAsInputData({
                    listInfo:{
                        startRecord:0,
                        rowCount:9999,
                        sortBy: [{"field":"purchase_number","order":"asc"}],
                        searchConditions:[{"field":"status","value":"ACKNOWLEDGED","type":"enum","enumName":"PurchaseStatus"}]
                    }
                })).then(function(response){
                    setCustomerOptions(response.data.purchases);
                    setTimeout(() => {
                        setIsCustomersLoading(false);
                    }, 1500);
                });


            }catch(e){
                console.log(e);
            }
        }
        loadSelectData();
    },[]);

    return( <Card>
        <Card.Header>
            <Card.Title tag="h5">Add Receipts</Card.Title>
        </Card.Header>
        <Card.Body>
            <Formik
                validationSchema={purchaseOrderSchema}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500));
                    HandleSubmit("goods_receipts",GetAsInputData({goods_receipt:values}),"/purchases/receipts")
                }}
                initialValues={initialValues}>
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                  }) => (<Form noValidate onSubmit={handleSubmit}>
                    <Row>
                        <Col sm={5}>
                            <Form.Group  className="mb-3">
                                <Form.Label>Purchase Order</Form.Label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="purchaseOrder"
                                    options={customerOptions.map((key, text) => {
                                        return {
                                            value: key.po_number,
                                            label: key.purchase_number,
                                            po_number:key.po_number,
                                        }}
                                    )}
                                    isLoading={isCustomersLoading}
                                    onChange={selectedOption => {
                                        let event = { target : { name:'purchaseOrder',value: selectedOption}}
                                        handleChange(event);
                                        setIsProductLoading(true);
                                        setProductOptions([]);
                                        loadDealerProducts(selectedOption.value);
                                    }}
                                />
                                <ErrorMessage name={`purchaseOrder`} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Supplier Invoice Number
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="invoice_number"
                                    isValid={touched.invoice_number && !errors.invoice_number}
                                    isInvalid={touched.invoice_number && !!errors.invoice_number}
                                    onChange={handleChange}
                                    placeholder="Supplier Invoice Number" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.invoice_number}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Label>
                                Supplier Invoice Date
                            </Form.Label>
                            <Form.Control
                                type="date"
                                name="pi_date_field"
                                isValid={touched.pi_date_field && !errors.pi_date_field}
                                isInvalid={touched.pi_date_field && !!errors.pi_date_field}
                                onChange={event =>{
                                    handleChange(event);
                                    let anotherEvent = { target : { name:`invoice_date`,value: new Date(event.currentTarget.value).getTime()}};
                                    handleChange(anotherEvent);
                                }}
                                placeholder="Supplier Invoice Date" />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.pi_date_field}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Card.Header/>
                    <Card.Title tag="h5">Receipt Products</Card.Title>
                    <Card.Header/>
                    <FieldArray
                        name="products"
                        render={arrayHelpers => (
                            <div>
                                {
                                    values.products && values.products.length > 0 ? (
                                        values.products.map((product, index) => (
                                            <Row key={index}>
                                                <Col md={4}>
                                                    <Form.Label htmlFor="product">Product</Form.Label>
                                                    <Form.Group className="mb-3">
                                                        <Select
                                                            key={`products.${index}`}
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            name={`products[${index}].product`}
                                                            value={product.product}
                                                            isDisabled={isProductsLoading}
                                                            options={productOptions.map((key, text) => {
                                                                return {
                                                                    value: key.product.id,
                                                                    label: key.product.name,
                                                                    optionData:key
                                                                }}
                                                            )}
                                                            isLoading={isProductsLoading}
                                                            onChange={selectedOption => {
                                                                let event = { target : { name:`products[${index}].product`,value: {
                                                                            value: selectedOption.value,
                                                                            label: selectedOption.label,
                                                                            id: selectedOption.value,
                                                                            name: selectedOption.label,
                                                                            price:selectedOption.optionData.price
                                                                        } } };
                                                                handleChange(event);
                                                                let price = selectedOption.optionData.price;
                                                                let anotherEvent = { target : { name:`products[${index}].price`,value: price}};
                                                                handleChange(anotherEvent);
                                                            }}
                                                        />
                                                        <ErrorMessage name={`products[${index}].product`} />
                                                    </Form.Group>
                                                </Col>

                                                <Col md={4}>
                                                    <Form.Label htmlFor="received_quantity">Quantity</Form.Label>
                                                    <InputGroup>
                                                        <FormControl
                                                            key={`products.${index}`}
                                                            placeholder="Quantity"
                                                            type="number"
                                                            name={`products[${index}].received_quantity`}
                                                            value={product.received_quantity}
                                                            aria-label="Quantity"
                                                            onChange={handleChange}
                                                            disabled={!product.product} />
                                                        <Button onClick={() => arrayHelpers.push({
                                                            product: undefined,
                                                            "received_quantity":0
                                                        })} variant="secondary">Add More</Button>
                                                        <Button onClick={() => {
                                                            arrayHelpers.remove(index);
                                                        }} variant="secondary">Remove</Button>

                                                    </InputGroup>
                                                    <ErrorMessage name={`products[${index}].received_quantity`} />
                                                </Col>
                                            </Row>))):(
                                        arrayHelpers.push({
                                            product:undefined,
                                            quantity:undefined
                                        })
                                    )}</div>
                        )}
                    />
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Button type="submit" variant="primary">Submit</Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>)}
            </Formik>

        </Card.Body>
    </Card>);
}

const GoodsReceiptFormPage = () => (
    <React.Fragment>
        <Helmet title="New Receipt" />
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Goods Receipt</h1>

            <Row>
                <Col lg="8">
                    <ReceiptForm />
                </Col>
            </Row>
        </Container>
    </React.Fragment>
);

export default GoodsReceiptFormPage;
