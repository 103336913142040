import {
    BookOpen,
    CheckSquare,
    Clipboard,
    File,
    FileText,
    Settings,
    ShoppingBag,
    ShoppingCart,
    Sliders,
    Users,
} from "react-feather";
import {roles} from "../../utils/roles";

const salesSection = [
  {
    href: "/customers",
    icon:Sliders,
    title:"Config",
    children: [
      {
        href: "/customers/active",
        title: "Active",
        children: [
          {
            href: "/customers/active/dealers",
            title: "General Traders",
            roles:[roles.ROLE_VIEW_GENERAL_TRADERS]
          },
          {
            href: "/customers/active/gts",
            title: "GT Distributor",
            roles:[roles.ROLE_VIEW_DEALERS]
          },
          {
            href: "/customers/active/mts",
            title: "MT Distributor",
            roles:[roles.ROLE_VIEW_DEALERS]
          },
          {
            href: "/customers/active/ss",
            title: "Super Stockist",
            roles:[roles.ROLE_VIEW_DEALERS]
          },
          {
            href: "/customers/active/retailers",
            title: "Retailer",
            roles:[roles.ROLE_VIEW_RETAILERS]
          },
          {
            href: "/customers/active/non-billers",
            title: "Non Billers",
            roles:[roles.ROLE_VIEW_DEALERS]
          }
        ]
      },
      {
        href: "/customers/inactive",
        title: "Inactive",
        children: [
          {
            href: "/customers/inactive/dealers",
            title: "General Traders",
            roles:[roles.ROLE_VIEW_GENERAL_TRADERS]
          },
          {
            href: "/customers/inactive/gts",
            title: "GT Distributor",
            roles:[roles.ROLE_VIEW_DEALERS]
          },
          {
            href: "/customers/inactive/mts",
            title: "MT Distributor",
            roles:[roles.ROLE_VIEW_DEALERS]
          },
          {
            href: "/customers/inactive/ss",
            title: "Super Stockist",
            roles:[roles.ROLE_VIEW_DEALERS]
          }
        ]
      },
      {
        href: "/customers/pricing",
        title: "Sales Config",
        roles:[roles.ROLE_VIEW_DEALERS]
      }
    ],
  },
  {
    href: "/sales/",
    title:"Sales",
    icon:ShoppingCart,
    children: [
      {
        href: "/orders",
        title: "Sale Order",
        icon:ShoppingCart,
        roles:[roles.ROLE_VIEW_ALL_SALE_ORDERS_DOMESTIC],
        children: [
          {
            href: "/sales/orders",
            title: "Domestic",
            roles:[roles.ROLE_VIEW_ALL_SALE_ORDERS_DOMESTIC]
          },
        ]
      },
      {
        href: "/invoices",
        title:"Invoice",
        icon:Clipboard,
        children: [
          {
            href: "/invoices/warehouse",
            title: "Warehouse",
            roles:[roles.ROLE_VIEW_ALL_INVOICE_DOMESTIC]
          },
          {
            href: "/invoices/prepaid",
            title: "Prepaid",
            roles:[roles.ROLE_VIEW_ALL_INVOICE_PREPAID]
          },
          // {
          //   href: "/dashboard/default",
          //   title: "E-Comm",
          // },
          // {
          //   href: "/dashboard/default",
          //   title: "Proforma",
          // }
          {
            href: "/invoices/lc",
            title: "LC ",
            roles:[roles.ROLE_VIEW_CREATE_MODIFY_LC]
          },
          {
            href: "/invoices/manual_mdi",
            title: "V - M.D.I",
            roles:[roles.ROLE_CREATE_MODIFY_ALL_INVOICE_DOMESTIC]
          },
          {
            href: "/invoices/manual_adi",
            title: "V - A.D.I",
            roles:[roles.ROLE_CREATE_MODIFY_ALL_INVOICE_DOMESTIC]
          },
        ],
      },
      // {
      //   href: "/sales/orders/export",
      //   title: "Export",
      //   roles:[roles.ROLE_VIEW_ALL_SALE_ORDERS_DOMESTIC]
      // },
      // {
      //   href: "/sales/orders/lut",
      //   title: "LUT",
      //   roles:[roles.ROLE_VIEW_ALL_SALE_ORDERS_DOMESTIC]
      // }
    ],
  },

  {
    href: "/payments",
    title: "Payments",
    icon: Clipboard,
    children: [
      {
        title:"Payments",
        href: "/payments/listings",
        roles:[roles.ROLE_VIEW_PAYMENTS]
      },
      {
        title:"Transactions",
        href: "/transactions",
        roles:[roles.ROLE_VIEW_PAYMENTS]
      }
    ]
  }

];

const purchasesSection = [{
  href: "/purchases", title: "Purchase Order", icon: ShoppingBag, children: [
    {
      title:"Orders",
      icon:ShoppingBag,
      href: "/purchases/orders",
      roles:[roles.ROLE_VIEW_PURCHASE_ORDER]
    },
    {
      title:"Receipts",
      icon:FileText,
      href: "/purchases/receipts",
      roles:[roles.ROLE_VIEW_GOODS_RECEIPT]
    },
    {
      title:"Bills",
      icon:BookOpen,
      href: "/purchases/bills",
      // roles:["ROLE_VIEW_BUY_HAPPY_PURCHASE_INVOICE"]
    },
    {
      title:"Products",
      href: "/purchases/products",
      roles:[roles.ROLE_VIEW_ADMIN_CONFIG]
    },
    {
      title:"Vendors",
      href: "/purchases/vendors",
      roles:[roles.ROLE_VIEW_ADMIN_CONFIG]
    }
  ]
},


];

const reportSection = [
  {
    href: "/reports/report",
    icon: FileText,
    title: "Reports",
    children: [
      {
        href: "/reports/money_ledger",
        title: "Money Ledger",
        roles:[roles.ROLE_VIEW_CREATE_REPORTS],
      },
      {
        href: "/reports/prepaid_ledger",
        title: "Prepaid Ledger",
        roles:[roles.ROLE_VIEW_CREATE_REPORTS],
      },
      {
        href: "/reports/warehouse",
        title: "Warehouse",
        roles:[roles.ROLE_VIEW_ALL_INVOICE_DOMESTIC]
      },
    ]
  },
];

const claimsSection = [
  {
    href: "/claims",
    icon: File,
    title: "Claims",
    children: [
      {
        href: "/claims/claim",
        title: "Claims",
        roles:[roles.ROLE_VIEW_CLAIM],
      },
      {
        href: "/claims/contracts",
        title: "Contracts",
        roles:[roles.ROLE_VIEW_DEALERS]
      }
    ]
  }
];

const ledgerSection = [
  {
    href: "/ledgers",
    icon: CheckSquare,
    title: "Ledgers",
    children:[
      {
        href: "/ledgers",
        title: "Ledgers",
        children:[{
          href: "/ledgers/payments",
          title: "Payments",
        },{
          href: "/ledgers/claims",
          title: "Claims",
        }],
        roles:[roles.ROLE_VIEW_PAYMENTS]
      },
      // {
      //   href: "/ledgers/prepaid",
      //   title: "Prepaid Stock",
      //   roles:["ROLE_VIEW_LEDGERS"]
      // },
      // {
      //   href: "/ledgers/chennai",
      //   title: "Chennai Stock",
      //   roles:["ROLE_VIEW_LEDGERS"]
      // },
      // {
      //   href: "/ledgers/pune",
      //   title: "Pune Stock",
      //   roles:["ROLE_VIEW_LEDGERS"]
      // },
    ]
  }
];

const userSection = [
  {
    href: "/users",
    icon: Users,
    title: "Users",
    children:[
      {
        href: "/users/erp",
        title: "ERP Users",
        roles:[roles.ROLE_VIEW_USERS]
      },
      {
        href: "/users/sales",
        title: "Sales Users",
        roles:[roles.ROLE_VIEW_USERS]
      }
    ]
  }
];

const settingsSection = [
  {
    href: "/settings",
    icon:Settings,
    title: "Settings",
    children:[
      {
        href: "/settings/department",
        title: "Department",
        roles:[roles.ROLE_VIEW_ADMIN_CONFIG]
      },
      {
        href: "/settings/designation",
        title: "Designation",
        roles:[roles.ROLE_VIEW_ADMIN_CONFIG]
      },
      {
        href: "/settings/warehouse",
        title: "Warehouse",
        roles:[roles.ROLE_VIEW_ADMIN_CONFIG]
      },
      {
        href: "/settings/transport",
        title: "Transport",
        roles:[roles.ROLE_VIEW_ADMIN_CONFIG]
      },
      {
        href: "/settings/preferences",
        title: "Preferences",
        roles:[roles.ROLE_VIEW_ADMIN_CONFIG]
      },
    ]
  }
];

const sdCleaningSection = [
  {
    href: "/sd",
    icon:Sliders,
    title: "Sales Data",
    children:[
      {
        href: "/sd/distributors",
        title: "SD Distributors",
        roles:[roles.ROLE_VIEW_SD_DATA]
      },
      {
        href: "/sd/beats",
        title: "SD Beats",
        roles:[roles.ROLE_VIEW_SD_DATA]
      },
      {
        href: "/sd/retailers",
        title: "SD Retailers",
        roles:[roles.ROLE_VIEW_SD_DATA]
      }
    ]
  }
];

const navItems = [
  {
    title: "Sales",
    pages: salesSection,
  },
  {
    title:"Ledgers",
    pages:ledgerSection
  },
  {
    title: "Purchases",
    pages: purchasesSection,
  },
  {
    title:"Report",
    pages:reportSection
  },
  {
    title: "Claim",
    pages: claimsSection,
  },
  {
    title:"Users",
    pages:userSection
  },
  {
    title:"Settings",
    pages:settingsSection
  },
  {
    title:"SD Data Cleaning",
    pages:sdCleaningSection
  }
];

export default navItems;
