import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import {Container, Row} from "react-bootstrap";
import EntityTable, {CustomFilter} from "../../../components/EntityTable";


const PaymentLedgerPage = () => {

    const [searchCondition,setSearchCondition] = useState();
    const [searchConditionSelectedValue,setSearchConditionSelectedValue] = useState();
    const [searchCondition1,setSearchCondition1] = useState();
    const [searchConditionSelectedValue1,setSearchConditionSelectedValue1] = useState();

    const paymentsTableColumns = [

        {
            Header: "Account Holder",
            accessor: "dealer.dealer_name",
            Filter: () => {
                return CustomFilter({
                        entityUrl:"customers",
                        setCondition:setSearchCondition,
                        entityId:"dealer_id",
                        entityValue:"dealer_name",
                        searchField:"dealer.dealer_id",
                        searchType:"number",
                        entity:"customers",
                        selectedValue:searchConditionSelectedValue,
                        setSelectedValue:setSearchConditionSelectedValue
                    }
                )
            },
        },
        {
            Header: "Type",
            accessor: "transaction_type",
            Filter: false,
        },
        {
            Header: "Reference",
            accessor: "transaction_reference",
            Filter: false,
        },
        {
            Header: "Debit",
            accessor: "debit",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                return  value===null?0:value.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR"
                });
            },
        },
        {
            Header: "Credit",
            accessor: "credit",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                return  value===null?0:value.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR"
                });
            },
        },
        {
            Header: "Previous balance",
            accessor: "previous_balance",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                return  value===null?0:value.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR"
                });
            },
        },
        {
            Header: "Closing balance",
            accessor: "closing_balance",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                return  value===null?0:value.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR"
                });
            },
        },
        {
            Header: "Created Date",
            accessor: "created_date",Filter: false,
            Cell: ({ cell: { value } }) => {
                let date = new Date(Number(value));
                date = date.toString().slice(0,21)
                return date;
            }

        },
        {
            Header: "Created By",
            accessor: "created_by.name",
            Filter: () => {
                return CustomFilter({
                        entityUrl:"users",
                        setCondition:setSearchCondition1,
                        entityId:"user_id",
                        entityValue:"name",
                        searchField:"created_by.user_id",
                        searchType:"number",
                        entity:"users",
                        selectedValue:searchConditionSelectedValue1,
                        setSelectedValue:setSearchConditionSelectedValue1
                    }
                )
            },
        },

    ];
    let condition = [];
    searchCondition!=null && condition.push(searchCondition)
    searchCondition1!=null && condition.push(searchCondition1)
    if(condition.length==0)
        condition = null
    return(<React.Fragment>
        <Helmet title="Payments"/>
        <Container fluid className="p-0">
            <Row>
                <h1 className="h3 mb-3">Payment ledger</h1>

                <EntityTable
                    columns={paymentsTableColumns}
                    url={"money_ledgers"}
                    sortBy={[{field: "created_date", order: "desc"}]}
                    searchCondition={condition}
                    buttons={[]}
                />
            </Row>

        </Container>
    </React.Fragment>);

};

export default PaymentLedgerPage;



