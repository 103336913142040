export const ModelConstants = {
  LedgerShortList: {
    ID: "id",
    PRODUCT: "product",
    SUPPLIER: "supplier",
    CREATED_DATE: "created_date",
    CREATED_BY: "created_by",
    BATCH_NUMBER: "batch_number",
    BATCH_SIZE: "batch_size",
    QUANTITY_RECEIVED: "quantity_received",
    TOTAL_STOCK: "total_stock",
    QUANTITY_ISSUED: "quantity_issued",
    CLOSING_BALANCE: "closing_balance",
    REMARKS: "remarks",
    DEALER: "dealer",
    WAREHOUSE: "warehouse",
  },
  MoneyLedger: {
    ID: "id",
    DEALER: "dealer",
    TRANSACTION_TYPE: "transaction_type",
    CREATED_BY: "created_by",
    CREATED_DATE: "created_date",
    TRANSACTION_REFERENCE: "transaction_reference",
    DEBIT: "debit",
    CREDIT: "credit",
    IS_DEBIT: "is_debit",
    PREVIOUS_BALANCE: "previous_balance",
    CLOSING_BALANCE: "closing_balance",
  },
  ClaimLedger: {
    ID: "id",
    DEALER: "dealer",
    TRANSACTION_TYPE: "transaction_type",
    CREATED_BY: "created_by",
    CREATED_DATE: "created_date",
    TRANSACTION_REFERENCE: "transaction_reference",
    DEBIT: "debit",
    CREDIT: "credit",
    IS_DEBIT: "is_debit",
    PREVIOUS_BALANCE: "previous_balance",
    CLOSING_BALANCE: "closing_balance",
    RETAILER: "retailer",
  },
  Ledger: {
    ID: "id",
    PRODUCT: "product",
    SUPPLIER: "supplier",
    CREATED_DATE: "created_date",
    CREATED_BY: "created_by",
    BATCH_NUMBER: "batch_number",
    QUANTITY_RECEIVED: "quantity_received",
    TOTAL_STOCK: "total_stock",
    QUANTITY_ISSUED: "quantity_issued",
    CLOSING_BALANCE: "closing_balance",
    REMARKS: "remarks",
    DEALER: "dealer",
    WAREHOUSE: "warehouse",
  },
  ODLedger: {
    ID: "id",
    PRODUCT: "product",
    SUPPLIER: "supplier",
    CREATED_DATE: "created_date",
    CREATED_BY: "created_by",
    BATCH_NUMBER: "batch_number",
    QUANTITY_RECEIVED: "quantity_received",
    TOTAL_STOCK: "total_stock",
    QUANTITY_ISSUED: "quantity_issued",
    CLOSING_BALANCE: "closing_balance",
    REMARKS: "remarks",
    DEALER: "dealer",
  },
  PrepaidLedger: {
    ID: "id",
    PRODUCT: "product",
    SUPPLIER: "supplier",
    CREATED_DATE: "created_date",
    CREATED_BY: "created_by",
    BATCH_NUMBER: "batch_number",
    QUANTITY_RECEIVED: "quantity_received",
    TOTAL_STOCK: "total_stock",
    QUANTITY_ISSUED: "quantity_issued",
    CLOSING_BALANCE: "closing_balance",
    REMARKS: "remarks",
    DEALER: "dealer",
  },
  MailInfoSaver: {
    ID: "id",
    SUBJECT: "subject",
    HEADER: "header",
    MODULE: "module",
    ENTITY_ID: "entity_id",
  },
  UploadFileResponse: {
    ID: "id",
    MODULE: "module",
    FILE_NAME: "fileName",
    FILE_TYPE: "fileType",
    SIZE: "size",
  },
  FileStorageProperties: {
    UPLOAD_DIR: "uploadDir",
  },
  Transactions: {
    ID: "id",
    TRANSACTION_ID: "transactionID",
    DEALER_CODE: "dealerCode",
    TRANSACTION_TIME: "transactionTime",
    TRANSACTION_AMOUNT: "transactionAmount",
    DEALER: "dealer",
    CREATED_DATE: "createdDate",
  },
  Notification: {
    ID: "id",
    USER: "user",
    NOTIFICATION: "notification",
  },
  History: {
    ID: "id",
    ENTITY_ID: "entity_id",
    MODULE: "module",
    MODULE_ATTRIBUTE: "module_attribute",
    DESCRIPTION: "description",
    CREATED_DATE: "created_date",
    CREATED_BY: "created_by",
  },
  GTManagement: {
    GT_ID: "gt_id",
    DEALER: "dealer",
    CURRENT_BALANCE: "current_balance",
  },
  IncomingCash: {
    ID: "id",
    CREATED_DATE: "created_date",
    CREATED_BY: "created_by",
    APPROVED_DATE: "approved_date",
    APPROVED_BY: "approved_by",
    DEALER: "dealer",
    TRANSACTION_TYPE: "transaction_type",
    TRANSACTION_REFERENCE: "transaction_reference",
    AMOUNT: "amount",
    BANK_NAME: "bank_name",
    INWARD_DATE: "inward_date",
    IS_APPROVED: "is_approved",
    DEALER_RECEIVER: "dealerReceiver",
    DEALER_MONTHLY_TRANSACTION: "dealerMonthlyTransaction",
  },
  InvoiceShortList: {
    ID: "id",
    INVOICE_NUMBER: "invoice_number",
    INVOICE_TYPE: "invoice_type",
    CREATED_DATE: "created_date",
    INVOICE_DATE: "invoice_date",
    MODE_OF_TRANSPORT: "mode_of_transport",
    PURCHASE_ORDER: "purchaseOrder",
    DEALER: "dealer",
    SUPPLIER: "supplier",
    TOTAL_QUANTITY: "total_quantity",
    TOTAL_SALE: "total_sale",
    TOTAL_TAX: "total_tax",
    TOTAL_SALE_AFTER_TAX: "total_sale_after_tax",
  },
  InvoiceProducts: {
    INVOICE_PRODUCT_ID: "invoice_product_id",
    INVOICE: "invoice",
    PRODUCT: "product",
    RECEIPT: "receipt",
    MANUFACTURING_DATE: "manufacturing_date",
    EXPIRY_DATE: "expiry_date",
    CODE: "code",
    PACKING_PARTICULARS: "packing_particulars",
    QUANTITY: "quantity",
    PRODUCT_TOTAL_SALE: "product_total_sale",
    PRODUCT_TOTAL_TAX: "product_total_tax",
    PRODUCT_TOTAL_SALE_AFTER_TAX: "product_total_sale_after_tax",
    PRODUCT_PRICE: "product_price",
    BATCH_NUMBER_CUSTOM: "batch_number_custom",
  },
  Invoice: {
    ID: "id",
    INVOICE_NUMBER: "invoice_number",
    INVOICE_TYPE: "invoice_type",
    CREATED_DATE: "created_date",
    INVOICE_DATE: "invoice_date",
    MODE_OF_TRANSPORT: "mode_of_transport",
    PURCHASE_ORDER: "purchaseOrder",
    SALES_ORDER: "salesOrder",
    DEALER: "dealer",
    SUPPLIER: "supplier",
    TOTAL_QUANTITY: "total_quantity",
    TOTAL_SALE: "total_sale",
    TOTAL_TAX: "total_tax",
    TOTAL_SALE_AFTER_TAX: "total_sale_after_tax",
    PRODUCTS: "products",
    CREATED_BY: "created_by",
    TOTAL_BOX_COUNT: "total_box_count",
    PORT_OF_LOADING: "port_of_loading",
    PORT_OF_UNLOADING: "port_of_unloading",
    CURRENCY_CHANGE: "currency_change",
    FINAL_DESTINATION: "final_destination",
    BILLED_TO_PREPAID: "billed_to_prepaid",
    TRANSPORT_CHARGE: "transport_charge",
    IS_IGST: "is_igst",
    STATUS: "status",
    DEALER_ADDRESSES: "dealerAddresses",
    TCS_TAX: "tcs_tax",
    IRN: "IRN",
    QRCODE: "QRCode",
    EWBNO: "ewbno",
    WAREHOUSE: "warehouse",
    CLAIM: "claim",
  },
  EWayTransport: {
    ID: "id",
    TRANSPORT_NAME: "transport_name",
    TRANSPORT_ID: "transport_id",
    TRANSPORT_VEHICLE_NUMBER: "transport_vehicle_number",
  },
  PurchaseInvoiceShortList: {
    ID: "id",
    INVOICE_NUMBER: "invoice_number",
    INVOICE_TYPE: "invoice_type",
    CREATED_DATE: "created_date",
    PURCHASE_ORDER: "purchaseOrder",
    DEALER: "dealer",
    SUPPLIER: "supplier",
    TOTAL_QUANTITY: "total_quantity",
    TOTAL_SALE: "total_sale",
    TOTAL_TAX: "total_tax",
    TOTAL_SALE_AFTER_TAX: "total_sale_after_tax",
  },
  SalesInvoiceShortList: {
    ID: "id",
    INVOICE_NUMBER: "invoice_number",
    INVOICE_TYPE: "invoice_type",
    CREATED_DATE: "created_date",
    SALES_ORDER_SHORT_LIST: "salesOrderShortList",
    DEALER: "dealer",
    TOTAL_QUANTITY: "total_quantity",
    TOTAL_SALE: "total_sale",
    TOTAL_TAX: "total_tax",
    TOTAL_SALE_AFTER_TAX: "total_sale_after_tax",
    CREATED_BY: "created_by",
    STATUS: "status",
    CLAIM: "claim",
  },
  PrepaidInvoiceShortList: {
    ID: "id",
    INVOICE_NUMBER: "invoice_number",
    INVOICE_TYPE: "invoice_type",
    CREATED_DATE: "created_date",
    TOTAL_QUANTITY: "total_quantity",
    TOTAL_SALE: "total_sale",
    TOTAL_TAX: "total_tax",
    TOTAL_SALE_AFTER_TAX: "total_sale_after_tax",
    CREATED_BY: "created_by",
    STATUS: "status",
  },
  ODInvoiceShortList: {
    ID: "id",
    INVOICE_NUMBER: "invoice_number",
    INVOICE_TYPE: "invoice_type",
    CREATED_DATE: "created_date",
    TOTAL_QUANTITY: "total_quantity",
    TOTAL_SALE: "total_sale",
    TOTAL_TAX: "total_tax",
    TOTAL_SALE_AFTER_TAX: "total_sale_after_tax",
    CREATED_BY: "created_by",
    STATUS: "status",
  },
  ProformaSalesInvoiceShortList: {
    ID: "id",
    INVOICE_NUMBER: "invoice_number",
    INVOICE_TYPE: "invoice_type",
    CREATED_DATE: "created_date",
    SALES_ORDER_SHORT_LIST: "salesOrderShortList",
    DEALER: "dealer",
    TOTAL_QUANTITY: "total_quantity",
    TOTAL_SALE: "total_sale",
    TOTAL_TAX: "total_tax",
    TOTAL_SALE_AFTER_TAX: "total_sale_after_tax",
    CURRENCY_CHANGE: "currency_change",
    CREATED_BY: "created_by",
  },
  GoodsReceipt: {
    GRN_ID: "grn_id",
    PURCHASE_ORDER: "purchaseOrder",
    STATUS: "status",
    CREATED_BY: "created_by",
    CREATED_DATE: "created_date",
    IS_INVOICE_RAISED: "is_invoice_raised",
    INVOICE_NUMBER: "invoice_number",
    INVOICE_DATE: "invoice_date",
    INVOICE_PROOF: "invoice_proof",
    PRODUCTS: "products",
  },
  GoodsReceiptShortList: {
    ID: "id",
    STATUS: "status",
    CREATED_BY: "created_by",
    CREATED_DATE: "created_date",
  },
  GoodsReceiptGetList: {
    ID: "id",
    PURCHASE_ORDER: "purchaseOrder",
    STATUS: "status",
    CREATED_BY: "created_by",
    INVOICE_NUMBER: "invoice_number",
    CREATED_DATE: "created_date",
    INVOICE_PROOF: "invoice_proof",
  },
  GoodsReceiptProducts: {
    ID: "id",
    GOODS_RECEIPT: "goodsReceipt",
    PRODUCT: "product",
    RECEIVED_QUANTITY: "received_quantity",
  },
  Role: {
    ROLE_ID: "role_id",
    ROLE: "role",
  },
  City: {
    ID: "id",
    CITIES_NAME: "cities_name",
    COUNTRY: "country",
    REGION: "region",
  },
  UserSites: {
    ID: "id",
    WAREHOUSE: "warehouse",
    USER: "user",
  },
  DealerGetList: {
    USER_ID: "user_id",
    NAME: "name",
    EMP_ID: "emp_id",
    USERNAME: "username",
    EMAIL: "email",
    ENABLED: "enabled",
    DESIGNATION: "designation",
    DEPARTMENT: "department",
    THEME: "theme",
  },
  CurrentUser: {
    USER_ID: "user_id",
  },
  User: {
    USER_ID: "user_id",
    NAME: "name",
    EMP_ID: "emp_id",
    USERNAME: "username",
    PASSWORD: "password",
    EMAIL: "email",
    OFFICIAL_NUMBER: "official_number",
    DESIGNATION: "designation",
    DEPARTMENT: "department",
    ENABLED: "enabled",
    SITES: "sites",
    ROLES: "roles",
  },
  UserGetList: {
    USER_ID: "user_id",
    NAME: "name",
    EMP_ID: "emp_id",
    USERNAME: "username",
    EMAIL: "email",
    ENABLED: "enabled",
    DESIGNATION: "designation",
    DEPARTMENT: "department",
    THEME: "theme",
  },
  UserGetUsername: {
    USER_ID: "user_id",
    NAME: "name",
    USERNAME: "username",
  },
  UserShortList: {
    USER_ID: "user_id",
    NAME: "name",
    USER_NAME: "user_name",
  },
  UserVSRole: {
    ID: "id",
    USER: "user",
    ROLE: "role",
  },
  DepartmentShortList: {
    DEPARTMENT_ID: "department_id",
    DEPARTMENT_NAME: "department_name",
  },
  Department: {
    DEPARTMENT_ID: "department_id",
    DEPARTMENT_NAME: "department_name",
    DEPARTMENT_DESCRIPTION: "department_description",
    DELETED: "deleted",
  },
  ProductShortList: {
    ID: "id",
    NAME: "name",
    PRODUCT_GST: "product_gst",
    UNIT_MEASURE: "unit_measure",
    PRICE: "price",
    BOX_COUNT: "box_count",
    FOR_SALE: "forSale",
    CODE: "code",
    EXPIRY_YEAR: "expiry_year",
  },
  ProductSupplierFetch: {
    PSID: "psid",
    SUPPLIER: "supplier",
    PRICE: "price",
    PRODUCT: "product",
  },
  ProductSupplier: {
    PSID: "psid",
    SUPPLIER: "supplier",
    PRICE: "price",
    PRODUCT: "product",
  },
  ProductSuperShortList: {
    ID: "id",
    NAME: "name",
    CODE: "code",
    FOR_SALE: "forSale",
    FOR_BUY: "forBuy",
  },
  Product: {
    ID: "id",
    NAME: "name",
    DESCRIPTION: "description",
    UNIT_MEASURE: "unit_measure",
    PRODUCT_SUPPLIERS: "productSuppliers",
    DELETED: "deleted",
    CODE: "code",
    PRODUCT_GST: "product_gst",
    TYPE: "type",
    AVAILABILITY: "availability",
    SELLING_PRICE: "selling_price",
    BOX_COUNT: "box_count",
    FOR_SALE: "forSale",
    FOR_BUY: "forBuy",
    EXPIRY_YEAR: "expiry_year",
    SKU_TYPE: "skuType",
  },
  Country: {
    ID: "id",
    COUNTRY_NAME: "country_name",
    COUNTRY_CODE: "country_code",
  },
  Region: {
    ID: "id",
    REGION_NAME: "region_name",
    REGION_CODE: "region_code",
    COUNTRY: "country",
    VALUE: "value",
    LABEL: "label",
    DEALER_CODE: "dealerCode",
  },
  Zone: {
    ID: "id",
    NAME: "name",
    IS_IGST: "is_igst",
    ZONE_COUNTRY: "zone_country",
    ZONE_REGION: "zone_region",
    DELETED: "deleted",
  },
  SupplierShortList: {
    ID: "id",
    COMPANY_NAME: "company_name",
  },
  SupplierProduct: {
    PSID: "psid",
    SUPPLIER: "supplier",
    PRICE: "price",
    PRODUCT: "product",
  },
  Supplier: {
    ID: "id",
    COMPANY_NAME: "company_name",
    ADDRESS1: "address1",
    ADDRESS2: "address2",
    NAME: "name",
    NUMBER: "number",
    GST: "gst",
    ZONE: "zone",
    DELETED: "deleted",
    EMAIL: "email",
    LAND_LINE: "land_line",
    CODE: "code",
    SUPPLIER_PRODUCTS: "supplierProducts",
  },
  Designation: {
    ID: "id",
    NAME: "name",
  },
  Warehouse: {
    WAREHOUSE_ID: "warehouse_id",
    WAREHOUSE_NAME: "warehouse_name",
    WAREHOUSE_ADDRESS: "warehouse_address",
    PINCODE: "pincode",
    PHONE_NUMBER: "phone_number",
    GST_NUMBER: "gst_number",
    WAREHOUSE_EMAIL: "warehouse_email",
    WAREHOUSE_IEC: "warehouse_iec",
    REGION: "region",
    INVOICE_STRING: "invoiceString",
  },
  SalesOrder: {
    SO_NUMBER: "so_number",
    SALE_NUMBER: "sale_number",
    STATUS: "status",
    CREATED_DATE: "created_date",
    CREATED_BY: "created_by",
    TOTAL_BEFORE_TAX: "total_before_tax",
    TOTAL_TAX: "total_tax",
    TOTAL_AFTER_TAX: "total_after_tax",
    DEALER: "dealer",
    IS_APPROVED: "is_approved",
    SALE_TYPE: "sale_type",
    PO_NUMBER: "po_number",
    TERMS: "terms",
    BANK_DETAILS: "bank_details",
    PO_DATE: "po_date",
    TOTAL_TCS: "total_tcs",
    WAREHOUSE: "warehouse",
    CLAIM: "claim",
    PRODUCTS: "products",
  },
  SalesOrderDeliveryTrack: {
    ID: "id",
    ORDER: "order",
    INVOICE: "invoice",
  },
  SalesProduct: {
    ID: "id",
    ORDER: "order",
    PRODUCT: "product",
    PRICE: "price",
    QUANTITY: "quantity",
    PENDING_QUANTITY: "pending_quantity",
    PRODUCT_TOTAL_BEFORE_TAX: "product_total_before_tax",
    PRODUCT_TOTAL_TAX: "product_total_tax",
    PRODUCT_TOTAL_AFTER_TAX: "product_total_after_tax",
  },
  SalesOrderShortList: {
    SO_NUMBER: "so_number",
    SALE_NUMBER: "sale_number",
    STATUS: "status",
    CREATED_DATE: "created_date",
    CREATED_BY: "created_by",
    SALE_TYPE: "sale_type",
    WAREHOUSE: "warehouse",
  },
  SalesProductShortList: {
    ID: "id",
    ORDER: "order",
    PRODUCT: "product",
    PENDING_QUANTITY: "pending_quantity",
    PRICE: "price",
  },
  SalesOrderGetList: {
    SO_NUMBER: "so_number",
    SALE_NUMBER: "sale_number",
    STATUS: "status",
    CREATED_DATE: "created_date",
    CREATED_BY: "created_by",
    TOTAL_BEFORE_TAX: "total_before_tax",
    TOTAL_TAX: "total_tax",
    TOTAL_AFTER_TAX: "total_after_tax",
    DEALER: "dealer",
    SALE_TYPE: "sale_type",
    PO_NUMBER: "po_number",
    IS_APPROVED: "is_approved",
    WAREHOUSE: "warehouse",
    CLAIM: "claim",
  },
  PurchaseOrder: {
    PO_NUMBER: "po_number",
    CREATED_DATE: "created_date",
    PI_NUMBER: "pi_number",
    PI_CREATED_DATE: "pi_created_date",
    TRANSPORT: "transport",
    TOTAL: "total",
    CREATED_BY: "created_by",
    SUPPLIER: "supplier",
    PRODUCTS: "products",
    STATUS: "status",
    PURCHASE_NUMBER: "purchase_number",
    APPROVAL: "approval",
    PURCHASE_TERMS: "purchase_terms",
    SPECIAL_INSTRUCTIONS: "special_instructions",
    WAREHOUSE: "warehouse",
  },
  PurchaseOrderReport: {
    PO_NUMBER: "po_number",
    PURCHASE_NUMBER: "purchase_number",
    APPROVAL: "approval",
    CREATED_DATE: "created_date",
  },
  PurchaseProduct: {
    ID: "id",
    PRODUCT: "product",
    QUANTITY: "quantity",
    TOTAL_AMOUNT: "total_amount",
    PENDING_QUANTITY: "pending_quantity",
    TOTAL_BEFORE_GST: "total_before_gst",
    PURCHASE_ORDER: "purchaseOrder",
    PRICE: "price",
    DESCRIPTION: "description",
  },
  PurchaseOrderShortList: {
    PO_NUMBER: "po_number",
    CREATED_DATE: "created_date",
    TOTAL: "total",
    CREATED_BY: "created_by",
    STATUS: "status",
    PURCHASE_NUMBER: "purchase_number",
    APPROVAL: "approval",
  },
  PurchaseProductReport: {
    ID: "id",
    PRODUCT: "product",
    PENDING_QUANTITY: "pending_quantity",
    PURCHASE_ORDER: "purchaseOrder",
  },
  PurchaseProductGetList: {
    ID: "id",
    PRODUCT: "product",
    QUANTITY: "quantity",
    TOTAL_AMOUNT: "total_amount",
    PENDING_QUANTITY: "pending_quantity",
    PURCHASE_ORDER: "purchaseOrder",
  },
  PurchaseReceipt: {
    ID: "id",
    PURCHASE_ORDER: "purchase_order",
    RECEIPT: "receipt",
  },
  PurchaseOrderGetList: {
    PO_NUMBER: "po_number",
    CREATED_DATE: "created_date",
    PI_NUMBER: "pi_number",
    PI_CREATED_DATE: "pi_created_date",
    TRANSPORT: "transport",
    TOTAL: "total",
    CREATED_BY: "created_by",
    SUPPLIER: "supplier",
    STATUS: "status",
    PURCHASE_NUMBER: "purchase_number",
    APPROVAL: "approval",
    WAREHOUSE: "warehouse",
  },
  PurchaseOrderSuperShortList: {
    PO_NUMBER: "po_number",
    STATUS: "status",
    PURCHASE_NUMBER: "purchase_number",
  },
  SDDistributor: {
    DISTRIBUTOR_ID: "distributor_id",
    DISTRIBUTOR_SD_ID: "distributor_sd_id",
    DISTRIBUTOR_NAME: "distributorName",
    DISTRIBUTOR_ADDRESS: "distributor_address",
    DISTRIBUTOR_PHONE: "distributor_phone",
    DISTRIBUTOR_MOBILE: "distributor_mobile",
    DISTRIBUTOR_EMAIL: "distributor_email",
    DISTRIBUTOR_PINCODE: "distributor_pincode",
    DISTRIBUTOR_REGION: "distributor_region",
    DISTRIBUTOR_LOC_LAT: "distributor_loc_lat",
    DISTRIBUTOR_LOC_LONG: "distributor_loc_long",
    DISTRIBUTOR_GST: "distributor_gst",
    DISTRIBUTOR_FIRESTORE_ID: "distributor_firestore_id",
    DISTRIBUTOR_CITY: "distributor_city",
  },
  SDBeat: {
    BEAT_ID: "beat_id",
    BEAT_NAME: "beat_name",
    BEAT_REGION: "beat_region",
    RETAILER_COUNT: "retailer_count",
    SUPER_MARKET_COUNT: "superMarketCount",
    MTCOUNT: "MTCount",
    CHEMIST_COUNT: "chemistCount",
    FANCY_COUNT: "FancyCount",
    COSMETICS_COUNT: "CosmeticsCount",
    HYPERMARKET_COUNT: "HypermarketCount",
    OTHERS_COUNT: "OthersCount",
    KIRANA_COUNT: "KiranaCount",
    GMCOUNT: "GMCount",
    DISTRIBUTOR: "distributor",
    BEAT_FIRESTORE_ID: "beat_firestore_id",
    BEAT_CITY: "beat_city",
    BEAT_SD_ID: "beat_sd_id",
  },
  SDRetailer: {
    RETAILER_ID: "retailer_id",
    RETAILER_SD_ID: "retailer_sd_id",
    RETAILER_NAME: "retailer_name",
    RETAILER_REGION: "retailer_region",
    RETAILER_CITY: "retailer_city",
    RETAILER_ADDRESS: "retailer_address",
    RETAILER_PHONE: "retailer_phone",
    RETAILER_MOBILE: "retailer_mobile",
    RETAILER_EMAIL: "retailer_email",
    RETAILER_PINCODE: "retailer_pincode",
    RETAILER_TYPE: "retailer_type",
    RETAILER_LOC_LAT: "retailer_loc_lat",
    RETAILER_LOC_LONG: "retailer_loc_long",
    RETAILER_GST: "retailer_gst",
    RETAILER_MONTHLY_POTENTIAL: "retailer_monthly_potential",
    BEAT: "beat",
    RETAILER_FIRESTORE_ID: "retailer_firestore_id",
    IMAGE: "image",
    IMAGE_PATH: "imagePath",
  },
  StockManagementGetList: {
    ID: "id",
    PRODUCT: "product",
    AVAILABLE: "available",
    SOLD: "sold",
  },
  StockManagement: {
    ID: "id",
    PRODUCT: "product",
    AVAILABLE: "available",
    SOLD: "sold",
    DEALER: "dealer",
  },
  Report: {
    ID: "id",
    MODULE: "module",
    REPORT_TYPE: "report_type",
    CREATED_DATE: "created_date",
    CREATED_BY: "created_by",
    DESCRIPTION: "description",
    ATTACHMENT: "attachment",
    ADDITIONAL_OPTIONS: "additional_options",
  },
  DealerBankReport: {
    DEALER_ID: "dealer_id",
    CREATED_DATE: "created_date",
    CLOSING_BALANCE: "closing_balance",
    DEALER_NAME: "dealer_name",
  },
  ReportStack: {
    ID: "id",
    MODULE: "module",
    REPORT_TYPE: "report_type",
    CREATED_DATE: "created_date",
    CREATED_BY: "created_by",
    DESCRIPTION: "description",
    ATTACHMENT: "attachment",
  },
  ProductReport: {
    PRODUCT: "product",
    CREATED_DATE: "created_date",
    CLOSING_BALANCE: "closing_balance",
    PRODUCT_NAME: "product_name",
  },
  Dealer: {
    DEALER_ID: "dealer_id",
    DEALER_NAME: "dealer_name",
    CODE: "code",
    ADDRESS: "address",
    SUB_ADDRESS: "sub_address",
    PHONE_NUMBER: "phone_number",
    CONTACT_NAME: "contact_name",
    GST: "gst",
    ZONE: "zone",
    STATUS: "status",
    PRODUCTS: "products",
    LAND_LINE: "land_line",
    EMAIL: "email",
    TYPE: "type",
    AADHAR_NO: "aadhar_no",
    FAX_NO: "fax_no",
    PAN_CARD: "pan_card",
    CREATED_BY: "created_by",
    CREATED_DATE: "created_date",
    FOOD: "food",
    PHARMA: "pharma",
    FMCG: "fmcg",
    RETAILER_PERCENTAGE: "retailer_percentage",
    RETAILER_TYPE: "retailer_type",
  },
  DealerProduct: {
    DPID: "dpid",
    DEALER: "dealer",
    PRODUCT: "product",
    BASIC_RATE: "basic_rate",
    MARGIN_RATE: "margin_rate",
  },
  DealerShortList: {
    DEALER_ID: "dealer_id",
    DEALER_NAME: "dealer_name",
    CODE: "code",
    ADDRESS: "address",
    SUB_ADDRESS: "sub_address",
    PHONE_NUMBER: "phone_number",
    CONTACT_NAME: "contact_name",
    GST: "gst",
    STATUS: "status",
    LAND_LINE: "land_line",
    EMAIL: "email",
    TYPE: "type",
    ZONE: "zone",
    RETAILER_PERCENTAGE: "retailer_percentage",
    RETAILER_TYPE: "retailer_type",
  },
  DealerRetailerShortList: {
    DEALER_RETAILER_ID: "dealer_retailer_id",
    DEALER: "dealer",
    RETAILER: "retailer",
  },
  DealerAddressShortList: {
    DEALER_ID: "dealer_id",
    DEALER_NAME: "dealer_name",
  },
  DealerCode: {
    DEALER_ID: "dealer_id",
    DEALER_NAME: "dealer_name",
    CODE: "code",
  },
  DealerAddresses: {
    DEALER_SHIPPING_ID: "dealer_shipping_id",
    DEALER: "dealer",
    DEALER_ADDRESS: "dealer_address",
    DEALER_ADDRESS_GST: "dealer_address_gst",
    DEALER_CONTACT_NAME: "dealer_contact_name",
    DEALER_CONTACT_NUMBER: "dealer_contact_number",
  },
  DISSSProduct: {
    ID: "id",
    PRODUCT: "product",
    BASIC_RATE: "basic_rate",
    MT_DISTRIBUTOR_PRICE: "mt_distributor_price",
    GT_DISTRIBUTOR_PRICE: "gt_distributor_price",
    SUPER_STOCKIST_PRICE: "super_stockist_price",
    WITHOUT_OFFER_PRICE: "without_offer_price",
    THIRTY_FIVE_PERCENT: "thirty_five_percent",
    THIRTY_PERCENT: "thirty_percent",
    TWENTY_SEVEN_PERCENT: "twenty_seven_percent",
    TWENTY_FIVE_PERCENT: "twenty_five_percent",
    TWENTY_TWO_PERCENT: "twenty_two_percent",
    TWENTY_PERCENT: "twenty_percent",
    TWENTY_THREE_PERCENT: "twenty_three_percent",
  },
  ClaimsTracker: {
    CLAIM_ID: "claim_id",
    DEALER: "dealer",
    RETAILER: "retailer",
    CLAIM_TYPE: "claimType",
    CREATED_BY: "createdBy",
    CREATED_DATE: "createdDate",
    APPROVAL_STATUS: "approvalStatus",
    APPROVAL_BY: "approvalBy",
    INVOICE_NUMBER: "invoiceNumber",
    INVOICE_DATE: "invoiceDate",
    TOTAL_AMOUNT_PAYABLE: "totalAmountPayable",
    TOTAL_AMOUNT_CLAIM: "totalAmountClaim",
    TOTAL_AMOUNT_PAID: "totalAmountPaid",
    CLAIMS: "claims",
  },
  ClaimsTrackerContent: {
    CLAIMS_TRACKER_CONTENT_ID: "claims_tracker_content_id",
    CLAIMS_TRACKER: "claimsTracker",
    PRODUCT: "product",
    PRODUCT_COUNT: "productCount",
    PRODUCT_PRICE: "productPrice",
    PRODUCT_TOTAL: "productTotal",
    AMOUNT_PAYABLE: "amountPayable",
    AMOUNT_PAID: "amountPaid",
    CREDIT_NOTE_NUMBER: "creditNoteNumber",
    REASON: "reason",
  },
  Contract: {
    CONTRACT_ID: "contractId",
    DEALER: "dealer",
    CONTRACT_TYPE: "contractType",
    CREATED_BY: "createdBy",
    CREATED_DATE: "createdDate",
    APPROVAL_STATUS: "approvalStatus",
    ALLOWED: "allowed",
    USED: "used",
  },
  ClaimsTrackerGetList: {
    CLAIM_ID: "claim_id",
    CURRENT_DATE: "currentDate",
    DEALER: "dealer",
    CLAIM_TYPE: "claimType",
    CREATED_BY: "createdBy",
    CREATED_DATE: "createdDate",
    APPROVAL_STATUS: "approvalStatus",
    APPROVAL_BY: "approvalBy",
  },
  ClaimsTrackerContentGetList: {
    CLAIMS_TRACKER_CONTENT_ID: "claims_tracker_content_id",
    CLAIMS_TRACKER: "claimsTracker",
    PRODUCT: "product",
    PRODUCT_COUNT: "productCount",
    PRODUCT_PRICE: "productPrice",
    PRODUCT_TOTAL: "productTotal",
    AMOUNT_PAYABLE: "amountPayable",
    AMOUNT_PAID: "amountPaid",
    CREDIT_NOTE_NUMBER: "creditNoteNumber",
  },
  DealerMonthlyTransaction: {
    ID: "id",
    DEALER_ID: "dealerId",
    YEAR: "year",
    MONTH: "month",
    TOTAL_CREDIT: "totalCredit",
    TOTAL_DEBIT: "totalDebit",
  },
};