import React from "react";
import {Helmet} from "react-helmet-async";

import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import axiosInstance, {GetAsInputData} from "../../../../utils/axios";
import * as Yup from "yup";
import {Formik} from "formik";
import {useNavigate} from "react-router-dom";

const userSchema = Yup.object().shape({
    displayName: Yup.string().required("Name is required"),
    phoneNumber: Yup.string().required("PhoneNumber is required\n Include +91").min(13, 'Must be exactly 13 digits').max(13, 'Must be exactly 13 digits'),
});

const FirebaseUserForm = ({initialValues,successUrl,setEditData}) => {
    const navigate = useNavigate();

    function HandlePost(url,data,navigateTo){
        axiosInstance.post("/api/"+url,data).then(function(response,success){
            if(response.status===200)
                navigate(navigateTo);
        }).catch(function(response,error){
            if(!!response.response_status && response.response_status.status==="BAD_REQUEST")
                alert("Bad Request");
            alert(response.message);
        });
    }
    function HandlePut(url,data,navigateTo){
        axiosInstance.put("/api/"+url,data).then(function(response,success){
            setEditData('');
        }).catch(function(response,error){
            if(!!response.response_status && response.response_status.status==="BAD_REQUEST")
                alert("Bad Request");
            alert(response.message);
        });

    }

    return( <Card>
        <Card.Header>
            <Card.Title tag="h5">{initialValues.user_id===undefined?"Add":"Modify"} Firebase User </Card.Title>
        </Card.Header>
        <Card.Body>
            <Formik
                validationSchema={userSchema}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500));
                    if(values.uid===undefined)
                        HandlePost("firebaseUsers",GetAsInputData({firebaseUser:values}),successUrl);
                    else
                        HandlePut("firebaseUsers/"+values.uid,GetAsInputData({firebaseUser:values}),successUrl);
                }}
                initialValues={initialValues}>
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row>
                            <Col sm={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="displayName"
                                        value={values.displayName}
                                        placeholder="Name"
                                        isValid={touched.displayName && !errors.displayName}
                                        isInvalid={touched.displayName && !!errors.displayName}
                                        onChange={handleChange} />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.displayName}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Group  className="mb-3">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="phoneNumber"
                                        value={values.phoneNumber}
                                        placeholder="Phone Number"
                                        isValid={touched.phoneNumber && !errors.phoneNumber}
                                        isInvalid={touched.phoneNumber && !!errors.phoneNumber}
                                        onChange={handleChange} />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.phoneNumber}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Card.Header>
                            <Card.Title tag="h5">Add Roles</Card.Title>
                        </Card.Header>
                        <Row>
                            <Card.Title tag="h5">User Permission</Card.Title>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Check name={"customClaims.VIEW_USERS"} value={values.customClaims.VIEW_USERS} checked={values.customClaims.VIEW_USERS} inline label="View" type="checkbox" onChange={handleChange}/>
                                    <Form.Check name={"customClaims.VIEW_MAPPED_USERS"} value={values.customClaims.VIEW_MAPPED_USERS} checked={values.customClaims.VIEW_MAPPED_USERS} inline label="View" type="checkbox" onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Card.Title tag="h5">Team Permission</Card.Title>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Check name={"customClaims.VIEW_TEAMS"} value={values.customClaims.VIEW_TEAMS} checked={values.customClaims.VIEW_TEAMS} inline label="View" type="checkbox" onChange={handleChange}/>
                                    <Form.Check name={"customClaims.CREATE_TEAMS"} value={values.customClaims.CREATE_TEAMS} checked={values.customClaims.CREATE_TEAMS} inline label="Create" type="checkbox" onChange={handleChange}/>
                                    <Form.Check name={"customClaims.MODIFY_TEAMS"} value={values.customClaims.MODIFY_TEAMS} checked={values.customClaims.MODIFY_TEAMS} inline label="Modify" type="checkbox" onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Card.Title tag="h5">Beats Permission</Card.Title>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Check name={"customClaims.VIEW_BEATS"} value={values.customClaims.VIEW_BEATS} checked={values.customClaims.VIEW_BEATS} inline label="View" type="checkbox" onChange={handleChange}/>
                                    <Form.Check name={"customClaims.CREATE_BEATS"} value={values.customClaims.CREATE_BEATS} checked={values.customClaims.CREATE_BEATS} inline label="Create" type="checkbox" onChange={handleChange}/>
                                    <Form.Check name={"customClaims.MODIFY_BEATS"} value={values.customClaims.MODIFY_BEATS} checked={values.customClaims.MODIFY_BEATS} inline label="Modify" type="checkbox" onChange={handleChange}/>
                                    <Form.Check name={"customClaims.VIEW_OWN_BEATS"} value={values.customClaims.VIEW_OWN_BEATS} checked={values.customClaims.VIEW_OWN_BEATS} inline label="View Mapped" type="checkbox" onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Card.Title tag="h5">Distributor Permission</Card.Title>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Check name={"customClaims.VIEW_DISTRIBUTORS"} value={values.customClaims.VIEW_DISTRIBUTORS} checked={values.customClaims.VIEW_DISTRIBUTORS} inline label="View" type="checkbox" onChange={handleChange}/>
                                    <Form.Check name={"customClaims.CREATE_DISTRIBUTORS"} value={values.customClaims.CREATE_DISTRIBUTORS} checked={values.customClaims.CREATE_DISTRIBUTORS} inline label="Create" type="checkbox" onChange={handleChange}/>
                                    <Form.Check name={"customClaims.MODIFY_DISTRIBUTORS"} value={values.customClaims.MODIFY_DISTRIBUTORS} checked={values.customClaims.MODIFY_DISTRIBUTORS} inline label="Modify" type="checkbox" onChange={handleChange}/>
                                    <Form.Check name={"customClaims.VIEW_OWN_DISTRIBUTORS"} value={values.customClaims.VIEW_OWN_DISTRIBUTORS} checked={values.customClaims.VIEW_OWN_DISTRIBUTORS} inline label="View Mapped" type="checkbox" onChange={handleChange}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Check name={"customClaims.ADD_MODIFY_DISTRIBUTORS_USERS"} value={values.customClaims.ADD_MODIFY_DISTRIBUTORS_USERS} checked={values.customClaims.ADD_MODIFY_DISTRIBUTORS_USERS} inline label="Add Modify Mapped Users" type="checkbox" onChange={handleChange}/>
                                    <Form.Check name={"customClaims.ADD_MODIFY_DISTRIBUTORS_USERS"} value={values.customClaims.ADD_MODIFY_DISTRIBUTORS_BEATS} checked={values.customClaims.ADD_MODIFY_DISTRIBUTORS_BEATS} inline label="Add Modify Mapped Beats" type="checkbox" onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Card.Title tag="h5">Retailers Permission</Card.Title>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Check name={"customClaims.VIEW_RETAILERS"} value={values.customClaims.VIEW_RETAILERS} checked={values.customClaims.VIEW_RETAILERS} inline label="View" type="checkbox" onChange={handleChange}/>
                                    <Form.Check name={"customClaims.CREATE_RETAILERS"} value={values.customClaims.CREATE_RETAILERS} checked={values.customClaims.CREATE_RETAILERS} inline label="Create" type="checkbox" onChange={handleChange}/>
                                    <Form.Check name={"customClaims.MODIFY_RETAILERS"} value={values.customClaims.MODIFY_RETAILERS} checked={values.customClaims.MODIFY_RETAILERS} inline label="Modify" type="checkbox" onChange={handleChange}/>
                                    <Form.Check name={"customClaims.VIEW_BEATS_RETAILERS"} value={values.customClaims.VIEW_BEATS_RETAILERS} checked={values.customClaims.VIEW_BEATS_RETAILERS} inline label="View Mapped Beats Retailers" type="checkbox" onChange={handleChange}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Button type="submit" variant="primary">Submit</Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>)}
            </Formik>
        </Card.Body>
    </Card>);
}

const FirebaseUserFormPage = () => {

    const initialValues = {
        displayName: "",
        phoneNumber: "+91",
        customClaims:{}
    };

    return (<React.Fragment>
        <Helmet title="New Firebase User" />
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">New Firebase User</h1>

            <Row>
                <Col lg="4">
                    <FirebaseUserForm initialValues={initialValues} successUrl="/users/sales" />
                </Col>
            </Row>
        </Container>
    </React.Fragment>);
}

export {FirebaseUserForm}

export default FirebaseUserFormPage;
