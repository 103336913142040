import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Alert, Button, Col, Container, Nav, Row, Tab, Table} from "react-bootstrap";
import {useParams} from "react-router-dom";
import axiosInstance from "../../../../utils/axios";
import Loader from "../../../../components/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamation} from "@fortawesome/free-solid-svg-icons";
import {getBadge} from "../../../../utils/misc";

const InvoiceDetails = () => {
    const params = useParams();
    const [invoiceData,setInvoiceData] = useState();
    const [invoiceApproved,setinvoiceApproved] = useState(false);
    const [invoiceRejected,setinvoiceRejected] = useState(false);

    useEffect(() => {
        const load =  () => {
            try{
                let entityURL = "/api/buyhappy_prepaid_invoices/"+params.invoiceID;
                axiosInstance.get(entityURL)
                    .then(function(response){
                        setTimeout(() => {
                            setInvoiceData(response.data.invoice);
                        }, 1500);
                    })
            }catch(e){
                console.log(e);
            }
        }

        load();
    },[params.invoiceID]);

    function updateData(){
        try{
            let entityURL = "/api/buyhappy_prepaid_invoices/"+params.invoiceID;
            axiosInstance.get(entityURL)
                .then(function(response){
                    setTimeout(() => {
                        setInvoiceData(response.data.invoice);
                        setinvoiceApproved(false);
                        setinvoiceRejected(false);
                    }, 1500);
                })
        }catch(e){
            console.log(e);
        }
    }

    function approveInvoice(){
        axiosInstance("/api/buyhappy_prepaid_invoices/"+invoiceData.id+"/approve").then((response,success) => {
            setinvoiceApproved(true);
            updateData();
        });
    }

    function rejectInvoice(){
        axiosInstance("/api/buyhappy_prepaid_invoices/"+invoiceData.id+"/reject").then((response,success) => {
            setinvoiceRejected(true);
            updateData();
        });
    }


    if(invoiceData ===undefined){
        return <Loader />;
    }else{
        return(

            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Prepaid Invoice - {invoiceData.invoice_number}</h1>
                <Row>
                    <Col lg="12">
                        <div className={"tab " }>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Nav variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Details</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Alert show={invoiceApproved} onHide={invoiceApproved} variant="success" key="2" dismissible>
                                        <div className="alert-message">
                                            <strong>Invoice</strong> approved successfully!
                                        </div>
                                    </Alert>
                                    <Alert show={invoiceRejected} onHide={invoiceRejected} variant="danger" key="3" dismissible>
                                        <div className="alert-message">
                                            <strong>Invoice</strong> rejected!
                                        </div>
                                    </Alert>
                                    <Tab.Pane eventKey="first">
                                        <h4 className="tab-title">Invoice Details</h4>
                                        <Col lg="6">
                                            <div className="table-responsive">
                                                <Table size="sm" className="my-2">
                                                    <tbody>
                                                    <tr key={0}>
                                                        <th>Created On</th>
                                                        <td>{new Date(Number(invoiceData.created_date)).toString().slice(3,23)}</td>
                                                    </tr>
                                                    <tr key={1}>
                                                        <th>Quantity</th>
                                                        <td>{invoiceData.total_quantity}</td>
                                                    </tr>
                                                    <tr key={2}>
                                                        <th>Mode of transport</th>
                                                        <td>{invoiceData.mode_of_transport}</td>
                                                    </tr>
                                                    <tr key={3}>
                                                        <th>Created On</th>
                                                        <td>{new Date(Number(invoiceData.created_date)).toString().slice(3,23)}</td>
                                                    </tr>
                                                    <tr key={4}>
                                                        <th>Sale</th>
                                                        <td>{invoiceData.total_sale.toLocaleString("en-IN", {
                                                            style: "currency",
                                                            currency: "INR"
                                                        })}</td>
                                                    </tr>
                                                    <tr key={5}>
                                                        <th>Tax</th>
                                                        <td>{invoiceData.total_tax.toLocaleString("en-IN", {
                                                            style: "currency",
                                                            currency: "INR"
                                                        })}</td>
                                                    </tr>
                                                    <tr key={6}>
                                                        <th>Total</th>
                                                        <td>{invoiceData.total_sale_after_tax.toLocaleString("en-IN", {
                                                            style: "currency",
                                                            currency: "INR"
                                                        })}</td>
                                                    </tr>
                                                    <tr key={7}>
                                                        <th>Status</th>
                                                        <td>
                                                            {getBadge(invoiceData.status)}
                                                        </td>
                                                    </tr>
                                                    {
                                                        invoiceData.irn!==null &&
                                                        <tr key={8}>
                                                            <th>IRN Number</th>
                                                            <td>{invoiceData.irn}</td>
                                                        </tr>
                                                    }
                                                    {
                                                        invoiceData.ewbno!==null &&
                                                        <tr key={9}>
                                                            <th>E-way Bill Number</th>
                                                            <td>{invoiceData.ewbno}</td>
                                                        </tr>
                                                    }
                                                    </tbody>
                                                </Table>
                                            </div>
                                            {  invoiceData.status === "DRAFT" && <Button variant={`success`} className=" me-1 mb-1" onClick={approveInvoice}>Approve</Button>}
                                            {  invoiceData.status !== "CANCELLED" && <Button variant={`danger`} className=" me-1 mb-1" onClick={rejectInvoice}>Reject</Button> }
                                            {  invoiceData.status === "PUBLISHED" &&  <Button variant={`info`} className=" me-1 mb-1" onClick={() => {
                                                window.open("/buyhappy_prepaid_invoices/" + invoiceData.id+"/downloadFile","_blank")
                                            }} ><FontAwesomeIcon  icon={faExclamation} /> Download Invoice </Button> }
                                        </Col>
                                        <br/>
                                        <h4 className="tab-title">Invoice Products </h4>
                                        <Col lg="10">
                                            <div className="table-responsive">
                                                {
                                                    <Table size="sm" className="my-2">
                                                        <thead>
                                                        <tr key={10}>
                                                            <th>Product Name</th>
                                                            <th>Quantity</th>
                                                            <th>Batch No</th>
                                                            <th>MFG Date</th>
                                                            <th>EXP Date</th>
                                                            <th>Price</th>
                                                            <th>Amount</th>
                                                            <th>GST</th>
                                                            <th>Total</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        { invoiceData.products.map((product, i) => {
                                                            return (
                                                                <tr key={i+"11"}>
                                                                    <td>{product.product.name}</td>
                                                                    <td>{product.quantity}</td>
                                                                    <td>{product.batch_number_custom}</td>
                                                                    <td>{new Date(Number(product.manufacturing_date)).toString().slice(3,16)}</td>
                                                                    <td>{new Date(Number(product.expiry_date)).toString().slice(3,16)}</td>
                                                                    <td>{product.product_price}</td>
                                                                    <td>{product.product_total_sale}</td>
                                                                    <td>{product.product_total_tax}</td>
                                                                    <td>{product.product_total_sale_after_tax}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>
                                                    </Table>
                                                }
                                            </div>
                                        </Col>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }


};

const InvoicePrepaidDetailsPage = () => (

    <React.Fragment>
        <Helmet title="Prepaid Invoice Details" />
        <InvoiceDetails />
    </React.Fragment>
);

export default InvoicePrepaidDetailsPage;
