import React, {useContext, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";

import {Button, Card, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import Select from "react-select";
import axiosInstance, {ErrorMessage, GetAsInputData, PostApproval} from "../../../utils/axios";
import * as Yup from "yup";
import {FieldArray, Formik} from "formik";
import NotifyContext from "../../../contexts/NotyfContext"
import {useNavigate} from "react-router-dom";

const automaticSchema = Yup.object().shape({
    supplier: Yup.object().required("Supplier is required"),
    warehouse: Yup.object().required("Warehouse is required"),
    pi_number:Yup.string().required("Proforma invoice number is required"),
    pi_date_field:Yup.date().required("Proforma invoice date is required"),
    supplier_invoice_number:Yup.string().required("Supplier Invoice number is required"),
    supplier_invoice_date_field:Yup.date().required("Proforma invoice date is required"),
    dealer: Yup.object().required("Trader Name is required"),
    invoice_date: Yup.string().required("Invoice Date is required"),
    dealerAddresses:Yup.object().nullable(),
    mode_of_transport: Yup.string().required("Mode of Transport is required"),
    products: Yup.array()
        .of(
            Yup.object().shape({
                product: Yup.object().required("Product is required"),
                boxes: Yup.number().required("Box count is required"),
                batch_number_custom: Yup.string().required("Batch Number is required")
            })
        )
});

const AutomaticInvoiceForm = ({initialValues,disableEdit}) => {

    const navigate = useNavigate();
    const notify = useContext(NotifyContext)
    const [productOptions, setProductOptions] = useState([]);
    const [isProductsLoading, setIsProductLoading] = useState(true);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [isCustomersLoading, setIsCustomersLoading] = useState(true);
    const [dealerAddressOptions, setDealerAddressOptions] = useState([]);
    const [isDealerAddressLoading, setIsDealerAddressLoading] = useState(true);

    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [isWarehousesLoading, setIsWarehousesLoading] = useState(true);

    function CreateSaleInvoice(response,values){
        axiosInstance.post("/api/buyhappy_sales_invoices",GetAsInputData({buyhappy_sales_invoices:values}))
        .then(function(response,success){
            notify.success("Sale Invoice created Successfully");
            navigate("/invoices/details/"+response.data.buyhappy_sales_invoices.id);
        }).catch(function(response,error){
            notify.error("Error creating invoice");
        });
    }

    function ApproveSaleOrder(response,values){
        PostApproval("sales_order",response.data.sales_order.so_number,"is_approved","APPROVED",(response) => {
            notify.success("Sale Order Approved Successfully");
            values.salesOrder = {so_number:response.sale_order.so_number};
            CreateSaleInvoice(response,values)
        });
    }

    function CreateSalesOrder(response,values,purchaseValues){
        purchaseValues.sale_type="DOMESTIC"
        values.sale_type="DOMESTIC"
        axiosInstance.post("/api/sales_order",GetAsInputData({sale_order:purchaseValues})).then(function(response,success){
            notify.success("Sale Order Created Successfully");
            ApproveSaleOrder(response,values);
        }).catch(function(response,error){
            notify.error("Error creating Sale Order")
        });
    }

    function ApproveGRN(response,values,purchaseValues){
        axiosInstance.put("/api/goods_receipts/approve_stock/"+response.data.goods_receipts.grn_id).then((response,success) => {
            notify.success("Goods Receipt Note Approved Successfully");
            CreateSalesOrder(response,values,purchaseValues)
        }).catch(function(response,error){
            notify.error("Error Approving Goods Receipt Note")
        });
    }

    function CreateGRN(response,values,purchaseValues){

        values.purchaseOrder={po_number:response.data.purchases.po_number}
        values.invoice_number=values.supplier_invoice_number
        values.invoice_date=values.supplier_invoice_date

        axiosInstance.post("/api/goods_receipts",GetAsInputData({goods_receipt:values})).then(function(response,success){
            notify.success("Goods Receipt Note Created Successfully");
            ApproveGRN(response,values,purchaseValues)
        }).catch(function(response,error){
            notify.error("Error Creating Goods Receipt Note")
        });
    }

    function HandlePOApproval(response,values,purchaseValues){
        PostApproval("purchases",response.data.purchases.po_number,"approval","APPROVED",() => {
            notify.success("Purchase Order Approved");
            CreateGRN(response,values,purchaseValues)
        });
    }

    function HandleSubmit(values){
        let bill = returnSaleValues(values.products,"sale_tax");
        axiosInstance.get("/api/customers/"+values.dealer.dealer_id+"/"+values.claim)
        .then(response => {
            if(response.data < bill ){
                notify.error("Ledger Amount "+response.data+" less than required amount "+bill.toFixed(2));
            }else{
                let purchaseValues = {...values};
                let hashmapProducts = {};
                purchaseValues.products.forEach(function(purchaseProduct) {
                    if(hashmapProducts[purchaseProduct.product.name] == undefined){
                        hashmapProducts[purchaseProduct.product.name] = {
                            product:purchaseProduct.product,
                            count:purchaseProduct.quantity
                        };
                    }else{
                        hashmapProducts[purchaseProduct.product.name].count+=purchaseProduct.quantity;
                    }
                })
                purchaseValues.products = [];
                Object.keys(hashmapProducts).forEach(function(key){
                    purchaseValues.products.push({
                        product:hashmapProducts[key].product,
                        quantity:hashmapProducts[key].count,
                        received_quantity:hashmapProducts[key].count
                    })
                });
                axiosInstance.post("/api/purchases",GetAsInputData({purchase:purchaseValues})).then(function(response,success){
                    notify.success("Purchase Order Created Successfully")
                    HandlePOApproval(response,values,purchaseValues)
                }).catch(function(response,error){
                    notify.error("Error Creating Purchase Order");
                });
            }
        });


    }

    function returnSaleValues(values,type){
        let sale = 0;
        let tax = 0;
        for(let i=0;i<values.length;i++){
            if(values[i].product!==undefined && values[i].quantity!==undefined){
                sale += (values[i].product.basic_rate===undefined?values[i].price:values[i].product.basic_rate) * values[i].quantity;
                tax += (values[i].product.basic_rate===undefined?values[i].price:values[i].product.basic_rate) * values[i].quantity * (values[i].product.product_gst);
            }
        }
        if(type==="sale")
            return sale;
        if(type==="tax")
            return tax/100;
        if(type==="sale_tax")
            return (sale+(tax/100));
    }

    function loadDealerAddress(dealerID){
        axiosInstance.get("/api/dealer_address?"+GetAsInputData({
            listInfo:{
                startRecord:0,
                rowCount:9999,
                sortBy: [{"field":"dealer_address","order":"asc"}],
                searchConditions:[{"field":"dealer.dealer_id","value":""+dealerID,"type":"longnumber"}]
            }
        })).then(function(response){
            setDealerAddressOptions(response.data.dealer_address);
            setTimeout(() => {
                setIsDealerAddressLoading(false);
            }, 1500);
        });
    }

    function loadDealerProducts(dealerID){
        axiosInstance.get("/api/dealer_products?"+GetAsInputData({
            listInfo:{
                startRecord:0,
                rowCount:9999,
                sortBy: [{"field":"product.name","order":"asc"}],
                searchConditions:[{"field":"dealer.dealer_id","value":""+dealerID,"type":"number"}]
            }
        })).then(function(response){
            let dealerProducts = [];
            response.data.dealer_products.map((dealerProduct,index) => {
                dealerProduct.product.basic_rate = dealerProduct.basic_rate;
                dealerProducts.push(dealerProduct.product);
            });
            setProductOptions(dealerProducts);
            setTimeout(() => {
                setIsProductLoading(false);
            }, 1500);
        });
    }

    useEffect(() => {

        axiosInstance.get("/api/customers?"+GetAsInputData({
            listInfo:{
                startRecord:0,
                rowCount:9999,
                sortBy: [{"field":"dealer_name","order":"asc"}],
                searchConditions: [{"field":"type","enumName":"DealerType","type":"enum","value":"RETAILER","condition":"not_equals"},
                    {"field":"type","enumName":"DealerType","type":"enum","value":"NON_BILLING","condition":"not_equals"}
                ]
            }
        })).then(function(response){
            setCustomerOptions(response.data.customers);
            setTimeout(() => {
                setIsCustomersLoading(false);
            }, 1500);
        });

        axiosInstance.get("/api/warehouses?"+GetAsInputData({
            listInfo:{
                startRecord:0,
                rowCount:9999,
                sortBy: [{"field":"warehouse_name","order":"asc"}],
            }
        })).then(function(response){
            setWarehouseOptions(response.data.warehouses);
            setTimeout(() => {
                setIsWarehousesLoading(false);
            }, 1500);
        });

        if(initialValues.dealer!=undefined){
            loadDealerAddress(initialValues.dealer.dealer_id)
            loadDealerProducts(initialValues.dealer.dealer_id)
        }

    },[]);


    return(
        <Card>
            <Card.Body>
                <Formik
                    validationSchema={automaticSchema}
                    onSubmit={async (values) => {

                        HandleSubmit(values);

                    }}
                    initialValues={initialValues}>
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                      }) => (<Form noValidate onSubmit={handleSubmit}>
                        <Card.Header>
                            <Card.Title tag="h5">Add Purchase Order</Card.Title>
                        </Card.Header>
                        <Row>
                            <Col sm={5}>
                                <Form.Group  className="mb-3">
                                    <Form.Label>Supplier Name</Form.Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        name="supplier"
                                        isDisabled={true}
                                        value={values.supplier}
                                        getOptionLabel={(option)=>option.company_name}
                                        getOptionValue={(option)=>option.id}
                                    />
                                    <ErrorMessage name={`supplier`} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Pi Number
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="pi_number"
                                        disabled={disableEdit}
                                        value={values.pi_number}
                                        isValid={touched.pi_number && !errors.pi_number}
                                        isInvalid={touched.pi_number && !!errors.pi_number}
                                        onChange={handleChange}
                                        placeholder="Pi Number" />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.pi_number}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Label>
                                    Pi Date
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    name="pi_date_field"
                                    disabled={disableEdit}
                                    value={values.pi_date_field}
                                    isValid={touched.pi_date_field && !errors.pi_date_field}
                                    isInvalid={touched.pi_date_field && !!errors.pi_date_field}
                                    onChange={event =>{
                                        handleChange(event);
                                        let anotherEvent = { target : { name:`pi_created_date`,value: new Date(event.currentTarget.value).getTime()}};
                                        handleChange(anotherEvent);
                                    }}
                                    placeholder="Pi Date" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.pi_date_field}
                                </Form.Control.Feedback>
                            </Col>
                            <Col lg={4}>
                                <Form.Label>
                                    Warehouse
                                </Form.Label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    value={values.warehouse}
                                    getOptionLabel={(option)=>option.warehouse_name}
                                    getOptionValue={(option)=>option.warehouse_id}
                                    options={warehouseOptions}
                                    isLoading={false}
                                    onChange={selectedOption => {
                                        let event = { target : { name:`warehouse`,value: selectedOption } };
                                        handleChange(event);
                                        axiosInstance.get("/api/buyhappy_sales_invoices/getCount/"+selectedOption.warehouse_id+"/"+values.claim).then(r => {
                                            let anotherEvent = { target : { name:'possible_invoice_number',value: r.data.invoice_number}};
                                            handleChange(anotherEvent);
                                        })
                                    }}

                                />
                                <ErrorMessage name={`warehouse`} />
                            </Col>
                        </Row>
                        <Card.Header>
                            <Card.Title tag="h5">Add Goods Receipt Note</Card.Title>
                        </Card.Header>
                        <Row>
                            <Col sm={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Supplier Invoice Number
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={values.supplier_invoice_number}
                                        disabled={disableEdit}
                                        name="supplier_invoice_number"
                                        isValid={touched.supplier_invoice_number && !errors.supplier_invoice_number}
                                        isInvalid={touched.supplier_invoice_number && !!errors.supplier_invoice_number}
                                        onChange={handleChange}
                                        placeholder="Supplier Invoice Number" />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.supplier_invoice_number}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Label>
                                    Supplier Invoice Date
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    name="supplier_invoice_date_field"
                                    disabled={disableEdit}
                                    value={values.supplier_invoice_date_field}
                                    isValid={touched.supplier_invoice_date_field && !errors.supplier_invoice_date_field}
                                    isInvalid={touched.supplier_invoice_date_field && !!errors.supplier_invoice_date_field}
                                    onChange={event =>{
                                        handleChange(event);
                                        let anotherEvent = { target : { name:`supplier_invoice_date`,value: new Date(event.currentTarget.value).getTime()}};
                                        handleChange(anotherEvent);
                                    }}
                                    placeholder="Supplier Invoice Date" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.supplier_invoice_date_field}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Card.Header>
                            <Card.Title tag="h5">Add Sale Order</Card.Title>
                        </Card.Header>
                        <Row>
                            <Col sm={4}>
                                <Form.Group  className="mb-3">
                                    <Form.Label>Trader Name</Form.Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        name="dealer"
                                        value={values.dealer}
                                        isDisabled={disableEdit}
                                        getOptionLabel={(option)=>option.dealer_name}
                                        getOptionValue={(option)=>option.dealer_id}
                                        options={customerOptions}
                                        isLoading={isCustomersLoading}
                                        onChange={selectedOption => {
                                            let event = { target : {
                                                    name:'dealer',
                                                    value: {
                                                        dealer_id:selectedOption.dealer_id,
                                                        dealer_name:selectedOption.dealer_name
                                                    }
                                                }}
                                            handleChange(event);
                                            setIsProductLoading(true);
                                            setProductOptions([]);
                                            loadDealerProducts(selectedOption.dealer_id);
                                            loadDealerAddress(selectedOption.dealer_id);
                                        }}
                                    />
                                    <ErrorMessage name={`dealer`} />
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Label>
                                    Mode Of Transport
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="mode_of_transport"
                                    isValid={touched.mode_of_transport && !errors.mode_of_transport}
                                    isInvalid={touched.mode_of_transport && !!errors.mode_of_transport}
                                    onChange={event => {
                                        handleChange(event)
                                        let anotherEvent = { target : { name:'transport',value: event.target.value}}
                                        handleChange(anotherEvent);
                                    }}
                                    placeholder="Mode Of Transport" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.mode_of_transport}
                                </Form.Control.Feedback>
                            </Col>
                            <Col>
                                <Form.Label>
                                    Is Claim Order
                                </Form.Label>
                                <br/>
                                <Button className={"me-3"} onClick={() => {
                                    let event = { target : {
                                            name:'claim',
                                            value: true
                                        }}
                                    handleChange(event);
                                    axiosInstance.get("/api/buyhappy_sales_invoices/getCount/"+values.warehouse.warehouse_id+"/true").then(r => {
                                        let anotherEvent = { target : { name:'possible_invoice_number',value: r.data.invoice_number}};
                                        handleChange(anotherEvent);
                                    })
                                }} variant={values.claim?"success":"secondary"} >Yes</Button>
                                <Button className={"me-3"} onClick={() => {
                                    let event = { target : {
                                            name:'claim',
                                            value: false
                                        }}
                                    handleChange(event);
                                    axiosInstance.get("/api/buyhappy_sales_invoices/getCount/"+values.warehouse.warehouse_id+"/false").then(r => {
                                        let anotherEvent = { target : { name:'possible_invoice_number',value: r.data.invoice_number}};
                                        handleChange(anotherEvent);
                                    })
                                }} variant={!values.claim?"danger":"secondary"} >No</Button>
                            </Col>
                        </Row>
                        <Card.Header>
                            <Card.Title tag="h5">Add Invoice</Card.Title>
                        </Card.Header>
                        <Row>
                            <Col sm={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Invoice Date
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="invoice_date"
                                        isValid={touched.invoice_date && !errors.invoice_date}
                                        isInvalid={touched.invoice_date && !!errors.invoice_date}
                                        onChange={event => {
                                            let anotherEvent = { target : { name:'invoice_date',value: new Date(event.currentTarget.value).getTime()}}
                                            handleChange(anotherEvent);
                                        }}
                                        placeholder="Invoice Date" />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.invoice_date}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Label>
                                    Invoice type
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={values.invoice_type}
                                    name="invoice_type"
                                    disabled={true}
                                    placeholder="Invoice Type" />
                            </Col>
                            <Col lg={3}>
                                <Form.Label>
                                    Possible Invoice Number
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="possible_invoice_number"
                                    value={values.possible_invoice_number}
                                    disabled={true}
                                    placeholder="Invoice Number" />
                            </Col>
                            <Col sm={3}>
                                <Form.Label>
                                    Shipped to
                                </Form.Label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    value={values.dealerAddresses}
                                    name="dealerAddresses"
                                    options={dealerAddressOptions.map((key, text) => {
                                        return {
                                            value: key.dealer_shipping_id,
                                            label: key.dealer_address,
                                            dealer_shipping_id:key.dealer_shipping_id
                                        }}
                                    )}
                                    isLoading={isDealerAddressLoading}
                                    isDisabled={isDealerAddressLoading}
                                    onChange={selectedOption => {
                                        let event = { target : { name:'dealerAddresses',value: selectedOption}}
                                        handleChange(event);
                                    }}
                                />
                                <ErrorMessage name={`dealerAddresses`} />
                            </Col>
                        </Row>
                        <Card.Header>
                            <Card.Title tag="h5">Add Products</Card.Title>
                        </Card.Header>
                        <FieldArray
                            name="products"
                            render={arrayHelpers => (
                                <div>
                                    {
                                        values.products && values.products.length > 0 ? (
                                            values.products.map((saleProduct, index) => (
                                                <Row key={index}>
                                                    <Col md={4}>
                                                        <Form.Label htmlFor="product">Product</Form.Label>
                                                        <Form.Group className="mb-3">
                                                            <Select
                                                                key={`products.${index}`}
                                                                className="react-select-container"
                                                                classNamePrefix="react-select"
                                                                name={`products[${index}].product`}
                                                                value={saleProduct.product}
                                                                getOptionLabel={(option)=>option.name}
                                                                getOptionValue={(option)=>option.id}
                                                                options={productOptions}
                                                                isDisabled={isProductsLoading}
                                                                isLoading={isProductsLoading}
                                                                onChange={selectedOption => {

                                                                    if(saleProduct.boxes!==undefined){
                                                                        let quantity = saleProduct.boxes * selectedOption.box_count;
                                                                        saleProduct.quantity = quantity;
                                                                    }
                                                                    let event = { target : { name:`products[${index}].product`,value: selectedOption } };
                                                                    handleChange(event);
                                                                }}
                                                            />
                                                            <ErrorMessage name={`products[${index}].product`} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={2}>
                                                        <Form.Label htmlFor="boxes">Boxes</Form.Label>
                                                        <InputGroup>
                                                            <FormControl
                                                                key={`products.${index}`}
                                                                placeholder="Boxes"
                                                                type="number"
                                                                name={`products[${index}].boxes`}
                                                                value={saleProduct.boxes }
                                                                aria-label="Boxes"
                                                                onChange={event => {
                                                                    let quantity = saleProduct.product.box_count * Number(event.target.value);
                                                                    saleProduct.quantity = quantity;
                                                                    saleProduct.received_quantity = quantity;
                                                                    handleChange(event);
                                                                }}
                                                                disabled={!saleProduct.product} />
                                                        </InputGroup>
                                                        <ErrorMessage name={`products[${index}].boxes`} />
                                                    </Col>
                                                    <Col md={2}>
                                                        <Form.Label htmlFor="batch_number_custom">Batch Number</Form.Label>
                                                        <InputGroup>
                                                            <FormControl
                                                                key={`products.${index}`}
                                                                placeholder="Batch Number"
                                                                type="text"
                                                                name={`products[${index}].batch_number_custom`}
                                                                value={saleProduct.batch_number_custom}
                                                                aria-label="Batch Number"
                                                                onChange={handleChange}
                                                                disabled={!saleProduct.product} />
                                                        </InputGroup>
                                                        <ErrorMessage name={`products[${index}].batch_number_custom`} />
                                                    </Col>
                                                    <Col md={4}>
                                                        <Form.Label htmlFor="quantity">Quantity</Form.Label>
                                                        <InputGroup>
                                                            <FormControl
                                                                key={`products.${index}`}
                                                                placeholder="Quantity"
                                                                type="number"
                                                                name={`products[${index}].quantity`}
                                                                value={saleProduct.quantity}
                                                                aria-label="Quantity"
                                                                disabled />
                                                            <Button onClick={() => arrayHelpers.push({
                                                                product: undefined,
                                                                boxes:0,
                                                                quantity:0,
                                                                batch_number_custom:'',
                                                            })} variant="secondary">Add More</Button>
                                                            <Button onClick={() => {
                                                                arrayHelpers.remove(index);
                                                            }} variant="secondary">Remove</Button>

                                                        </InputGroup>
                                                    </Col>
                                                </Row>))):(
                                            arrayHelpers.push({
                                                product: undefined,
                                                boxes:0,
                                                batch_number_custom:'',
                                                quantity:0
                                            })
                                        )}</div>
                            )}
                        />
                        <Row>
                            <Col sm={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Sale
                                    </Form.Label>
                                    <Form.Control type="text" value={ returnSaleValues(values.products,"sale")  } placeholder="Sale" disabled/>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Label>
                                    Tax
                                </Form.Label>
                                <Form.Control type="text"value={ returnSaleValues(values.products,"tax")  } placeholder="Tax" disabled/>
                            </Col>
                            <Col sm={4}>
                                <Form.Label>
                                    Total
                                </Form.Label>
                                <Form.Control type="text" value={ returnSaleValues(values.products,"sale_tax")  } placeholder="Sales + Tax" disabled/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Button type="submit" variant="primary">Submit</Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>)}
                </Formik>

            </Card.Body>
        </Card>
    );
}

const AutomaticInvoiceFormPage = () => {

    const [possibleInvoice_number,setPossibleInvoiceNumber] = useState("Fetching...");

    useEffect(() => {
        axiosInstance.get("/api/buyhappy_sales_invoices/getCount/1/false").then(r => {
            setPossibleInvoiceNumber(r.data.invoice_number)
        })
    },[])

    const initialValues = {
        supplier: {"id": 68, "company_name": "VIMAC"},
        warehouse: {"warehouse_id": 1, "warehouse_name": "Chennai"},
        invoice_type: "BUY_HAPPY_SALES_DEALERS_INVOICE",
        pi_date: 0,
        pi_number: undefined,
        pi_date_field: undefined,
        supplier_invoice_number: '',
        supplier_invoice_date_field: '',
        dealer: undefined,
        invoice_date: '',
        possible_invoice_number:possibleInvoice_number,
        dealerAddresses: undefined,
        mode_of_transport: '',
        claim: false,
        products: [{
            product: undefined, boxes: 0, batch_number_custom: '', quantity: 0
        }]
    };

    return (<React.Fragment>
        <Helmet title="New Manual Dealer Invoicing"/>
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Manual Invoice</h1>
            <Row>
                <Col lg="12">
                    <AutomaticInvoiceForm key={new Date().getTime()} initialValues={initialValues} disableEdit={false}/>
                </Col>
            </Row>
        </Container>
    </React.Fragment>);

};

export {AutomaticInvoiceForm}
export default AutomaticInvoiceFormPage;
