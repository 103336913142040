import React, {useState} from "react";
import {Badge, ButtonGroup, Dropdown, DropdownButton} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faExclamation, faTimes} from "@fortawesome/free-solid-svg-icons";
import EntityTable, {CustomFilter, SelectColumnFilter} from "../../../../components/EntityTable";
import axiosInstance from "../../../../utils/axios";
import {roles} from "../../../../utils/roles";
import useAuth from "../../../../hooks/useAuth";


const ClaimsTableDealerSegregationView = ({dealerId}) => {
    const claimsTableColumns = [
        {
            Header: "Time Period",
            accessor: "month",
            Filter: false,
            Cell: ({ cell: { value },row }) => {
                const date = new Date(0); // Initialize a new Date object
                date.setMonth(value - 1); // Set the month (subtract 1 because months are 0-based in JavaScript)

                return row.original.year+"-"+date.toLocaleString('default', { month: 'long' });
            },
        },
        {
            Header: "Total Credit",
            accessor: "totalCredit",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                if(value==null)
                    return Number(0).toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR"
                    });
                return value.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR"
                });
            },
        },
        {
            Header: "Total Debit",
                accessor: "totalDebit",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                if(value==null)
                    return Number(0).toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR"
                    });
                return value.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR"
                });
            },
        }
    ];

    let searchCondition = [{
        field: "dealerId",
        value: dealerId,
    }];

    return(<React.Fragment>
        <EntityTable
            key={new Date().getTime()}
            columns={claimsTableColumns}
            url={"dealer_monthly_transactions"}
            sortBy={[{field: "year", order: "desc"},{field: "month", order: "desc"}]}
            buttons={[]}
            searchCondition={searchCondition}
        />
    </React.Fragment>);

};

export default ClaimsTableDealerSegregationView;
