import React, {useContext, useState} from "react";
import {Button, Card, Col, Form, InputGroup, Modal, Row,} from "react-bootstrap";
import {Formik} from "formik";
import * as Yup from "yup";
import NotyfContext from "../../../../contexts/NotyfContext";
import axiosInstance from "../../../../utils/axios";

const schema = Yup.object().shape({
  current_password: Yup.string().required("Current Password is required"),
  new_password: Yup.string().required("New Password is required"),
  re_type_new_password: Yup.string()
    .required("Retype password")
    .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
});

export const UserChangePasswordForm = ({ userId }) => {
  const [toggle, setToggle] = useState(true);
  const notify = useContext(NotyfContext);

  function HandlePut(url, data) {
    axiosInstance.put(url, data).then(function (response, success) {
      setToggle(!toggle);
      notify.success("Password changed successfully");
    });
  }

  return (
    <React.Fragment>
      <Modal show={toggle} onHide={() => setToggle(!toggle)}>
        <Modal.Header closeButton>Change Password</Modal.Header>
        <Modal.Body className="">
          <Card>
            <Card.Body>
              <Formik
                validationSchema={schema}
                onSubmit={async (values) => {
                  const url = "/api/users/changePassword";
                  if (values.new_password === values.re_type_new_password) {
                    HandlePut(
                      url,
                      "password=" +
                        values.new_password +
                        "&currentPassword=" +
                        values.current_password
                    );
                  }
                }}
                initialValues={{
                  current_password_show: false,
                  new_password_show: false,
                  re_type_new_password_show: false,
                  current_password: undefined,
                  new_password: undefined,
                  re_type_new_password: undefined,
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Row>
                      <Col sm={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Current Password</Form.Label>
                          <InputGroup>
                            <Form.Control
                              type={
                                values.current_password_show
                                  ? "text"
                                  : "password"
                              }
                              name="current_password"
                              value={values.current_password}
                              placeholder="Current Password"
                              isValid={
                                touched.current_password &&
                                !errors.current_password
                              }
                              isInvalid={
                                touched.current_password &&
                                !!errors.current_password
                              }
                              onChange={handleChange}
                            />
                            <Button
                              onClick={() => {
                                let event = {
                                  target: {
                                    name: "current_password_show",
                                    value: !values.current_password_show,
                                  },
                                };
                                handleChange(event);
                                setTimeout(() => {
                                  let event = {
                                    target: {
                                      name: "current_password_show",
                                      value: false,
                                    },
                                  };
                                  handleChange(event);
                                }, 3000);
                              }}
                            >
                              Show Password
                            </Button>
                            <Form.Control.Feedback>
                              Looks good!
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              {errors.current_password}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Form.Label>New Password</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type={
                              values.new_password_show ? "text" : "password"
                            }
                            name="new_password"
                            value={values.new_password}
                            placeholder="New Password"
                            isValid={
                              touched.new_password && !errors.new_password
                            }
                            isInvalid={
                              touched.new_password && !!errors.new_password
                            }
                            onChange={handleChange}
                          />
                          <Button
                            onClick={() => {
                              let event = {
                                target: {
                                  name: "new_password_show",
                                  value: !values.new_password_show,
                                },
                              };
                              handleChange(event);
                              setTimeout(() => {
                                let event = {
                                  target: {
                                    name: "new_password_show",
                                    value: false,
                                  },
                                };
                                handleChange(event);
                              }, 3000);
                            }}
                          >
                            Show Password
                          </Button>
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            {errors.new_password}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Form.Group className={"mt-3"}>
                          <Form.Label>Retype Password</Form.Label>
                          <InputGroup>
                            <Form.Control
                              type={
                                values.re_type_new_password_show
                                  ? "text"
                                  : "password"
                              }
                              name="re_type_new_password"
                              value={values.re_type_new_password}
                              placeholder="Retype Password"
                              isValid={
                                touched.re_type_new_password &&
                                !errors.re_type_new_password
                              }
                              isInvalid={
                                touched.re_type_new_password &&
                                !!errors.re_type_new_password
                              }
                              onChange={handleChange}
                            />
                            <Button
                              onClick={() => {
                                let event = {
                                  target: {
                                    name: "re_type_new_password_show",
                                    value: !values.re_type_new_password_show,
                                  },
                                };
                                handleChange(event);
                                setTimeout(() => {
                                  let event = {
                                    target: {
                                      name: "re_type_new_password_show",
                                      value: false,
                                    },
                                  };
                                  handleChange(event);
                                }, 3000);
                              }}
                            >
                              Show Password
                            </Button>
                            <Form.Control.Feedback>
                              Looks good!
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                              {errors.re_type_new_password}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col md={12}>
                        <Form.Group className="mb-3">
                          <Button type="submit" variant="primary">
                            Submit
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
