import React from "react";

import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth
import Page500 from "./pages/auth/Page500";
import Page404 from "./pages/auth/Page404";
import SignInPage from "./pages/auth/SignIn";
import SalesPage from "./pages/modules/saleorders/SalesOrder";
import SaleOrderDetailsPage from "./pages/modules/saleorders/SaleOrderDetails";
import SaleOrderFormPage from "./pages/modules/saleorders/SalesOrderDomesticForm";
import ExportSaleOrderFormPage from "./pages/modules/saleorders/SalesOrderExportForm";
import SaleOrderLUTFormPage from "./pages/modules/saleorders/SalesOrderLUTForm";
import AutomaticInvoiceFormPage from "./pages/modules/invoices/AutomaticInvoiceForm";
import VIMACAutomaticInvoiceFormPage from "./pages/modules/invoices/VimacAutomaticInvoiceForm";
import LCInvoiceFormPage from "./pages/modules/invoices/LCInvoiceForm";
import PrepaidInvoicePage from "./pages/modules/invoices/prepaid/PrepaidInvoices";
import InvoicePrepaidDetailsPage from "./pages/modules/invoices/prepaid/InvoicePrepaidDetails";
import PrepaidInvoiceFormPage from "./pages/modules/invoices/prepaid/PrepaidInvoiceForm";
import PaymentsFormPage from "./pages/modules/payments/PaymentsEntryForm";
import DebitsFormPage, {ClaimDebitsFormPage} from "./pages/modules/payments/DebitEntryForm";
import TransactionsPage from "./pages/modules/payments/Transactions";
import PaymentLedgerPage from "./pages/modules/ledgers/PaymentLedger";
import PrepaidLedgerPage from "./pages/modules/ledgers/PrepaidLedger";
import ChennaiLedgerPage from "./pages/modules/ledgers/ChennaiLedger";
import PuneLedgerPage from "./pages/modules/ledgers/PuneLedger";
import PurchaseOrderTable from "./pages/modules/purchases/order/PurchaseOrderTable";
import PurchaseOrderFormPage from "./pages/modules/purchases/order/PurchaseOrderForm";
import PurchaseOrderDetailsPage from "./pages/modules/purchases/order/PurchaseOrderDetails";
import GoodsReceipts from "./pages/modules/purchases/receipts/GoodsReceiptTable";
import GoodsReceiptFormPage from "./pages/modules/purchases/receipts/GoodsReceiptForm";
import ProductsPage from "./pages/modules/purchases/products/ProductsTable";
import ReceiptDetailsPage from "./pages/modules/purchases/receipts/ReceiptDetails";
import ProductFormPage from "./pages/modules/purchases/products/ProductForm";
import SupplierPage from "./pages/modules/purchases/VendorsTable";
import ActiveDealers from "./pages/modules/customers/generaltraders/ActiveDealers";
import GeneralTraderFormPage from "./pages/modules/customers/generaltraders/GeneralTraderForm";
import GeneralTraderDetailsPage from "./pages/modules/customers/generaltraders/GeneralTraderDetails";
import ActiveGT from "./pages/modules/customers/distributor/ActiveGT";
import GTDistributorFormPage from "./pages/modules/customers/distributor/GTDistributorForm";
import GtDistributorDetailsPage from "./pages/modules/customers/distributor/GtDistributorDetails";
import ActiveMT from "./pages/modules/customers/distributor/ActiveMT";
import MTDistributorFormPage from "./pages/modules/customers/distributor/MTDistributorForm";
import MtDistributorDetailsPage from "./pages/modules/customers/distributor/MtDistributorDetails";
import ActiveSS from "./pages/modules/customers/superstockist/ActiveSS";
import SuperStockistFormPage from "./pages/modules/customers/superstockist/SSDistributorForm";
import SuperStockistDetailsPage from "./pages/modules/customers/superstockist/SuperstockistDetails";
import ActiveRetailer from "./pages/modules/customers/retailer/ActiveRetailers";
import RetailerFormPage from "./pages/modules/customers/retailer/RetailerForm";
import RetailerDetailsPage from "./pages/modules/customers/retailer/RetailerDetails";
import InactiveDealers from "./pages/modules/customers/generaltraders/InactiveDealers";
import FirebaseUserFormPage from "./pages/modules/settings/users/FirebaseUserForm";
import UserFormPage from "./pages/modules/settings/users/UserForm";
import SDRetailersPage from "./pages/modules/salesdiary/SDRetailers";
import SDBeatsPage from "./pages/modules/salesdiary/SDBeats";
import SDDistributorsPage from "./pages/modules/salesdiary/SDDistributors";
import ClaimFormPage from "./pages/modules/claims/ClaimForm";
import SalesPricing from "./pages/modules/customers/SalesConfig";
import InactiveSS from "./pages/modules/customers/superstockist/InactiveSS";
import InactiveGt from "./pages/modules/customers/distributor/InactiveGT";
import InactiveMt from "./pages/modules/customers/distributor/InactiveMT";
import CreditsFormPage, {ClaimCreditsFormPage} from "./pages/modules/payments/CreditsEntryForm";
import SalesUsersPage from "./pages/modules/settings/users/SaleUsers";
import ERPUsersPage from "./pages/modules/settings/users/ERPUsers";
import SDBeatDetailsPage from "./pages/modules/salesdiary/SDBeatDetails";
import SDDistributorDetailsPage from "./pages/modules/salesdiary/SDDistributorDetails";
import ClaimsTablePage from "./pages/modules/claims/ClaimsTable";
import PurchaseBillsPage from "./pages/modules/purchases/order/PurchaseBillsTable";
import PaymentsPage from "./pages/modules/payments/Payments";
import DepartmentTable from "./pages/modules/settings/config/DepartmentTable";
import DesignationTable from "./pages/modules/settings/config/DesignationTable";
import WarehouseTable from "./pages/modules/settings/config/WarehouseTable";
import ClaimLedgerPage from "./pages/modules/ledgers/ClaimLedger";
import TransportTable from "./pages/modules/settings/config/TransportTable";
import InvoicePage from "./pages/modules/invoices/domestic/InvoicePage";
import InvoiceForm from "./pages/modules/invoices/domestic/InvoiceForm";
import InvoiceDetailsPage from "./pages/modules/invoices/InvoiceDetails";
import ChennaiLUTInvoiceFormPage from "./pages/modules/invoices/lut/ChennaiLUTInvoiceForm";
import NonBillerDealer from "./pages/modules/customers/nonbillingdealer/NonBillers";
import NonBillersFormPage from "./pages/modules/customers/nonbillingdealer/NonBillersForm";
import NonBillerDetailsPage from "./pages/modules/customers/nonbillingdealer/NonBillersDetails";
import ClaimTransferFormPage from "./pages/modules/payments/ClaimTransferEntryForn";
import MoneyLedgerReportTablePage from "./pages/modules/report/MoneyLedgerReportTable";
import WarehouseLedgerReportTablePage from "./pages/modules/report/WarehouseLedgerReportTable";
import PrepaidLedgerReportTablePage from "./pages/modules/report/PrepaidLedgerReportTable";
import SupplierFormPage from "./pages/modules/purchases/SupplierForm";
import ClaimDetailsDealerPage from "./pages/modules/claims/details/ClaimDetailsDealerPage";
import ClaimDetailsPage from "./pages/modules/claims/ClaimDetailsPage";
import ContractsTablePage from "./pages/modules/claims/ContractTable";
import ContractEntryForm from "./pages/modules/claims/ContractEntryForm";
import ContractsFormPage from "./pages/modules/claims/ContractEntryForm";
import ClaimOtherFormPage from "./pages/modules/claims/ClaimOtherForm";


// Protected routes

const routes = [
  {
    path:"/settings",
    element: <DashboardLayout />,
    children: [
      {
        path: "department",
        element: <DepartmentTable />,
      },
      {
        path: "designation",
        element: <DesignationTable />,
      },
      {
        path: "warehouse",
        element: <WarehouseTable />,
      },
      {
        path:"transport",
        element:<TransportTable/>
      }
    ]
  },
  {
    path: "/sales",
    element: <DashboardLayout />,
    children: [
      {
        path: "orders",
        element: <SalesPage />,
      },
      {
        path:"orders-details/:saleOrderID",
        element:<SaleOrderDetailsPage/>
      },
      {
        path: "domestic-orders",
        element: <SaleOrderFormPage />,
      },
      {
        path: "export-orders",
        element: <ExportSaleOrderFormPage />,
      },
      {
        path: "lut-orders",
        element: <SaleOrderLUTFormPage />,
      }
    ]
  },

  {
    path: "/invoices",
    element: <DashboardLayout />,
    children: [
      {
        path:"manual_mdi",
        element:< ><AutomaticInvoiceFormPage/></>
      },
      {
        path:"manual_adi",
        element:< ><VIMACAutomaticInvoiceFormPage/></>
      },
      {
        path:"lc",
        element:< ><LCInvoiceFormPage/></>
      },

      {
        path:"details/:invoiceID",
        element:<InvoiceDetailsPage/>
      },
      {
        path: "warehouse/:warehouseID",
        element: <InvoicePage />,
      },
      {
        path: "new",
        element: <InvoiceForm />,
      },
      {
        path: "chennai-new-lut",
        element: <ChennaiLUTInvoiceFormPage />,
      },
      {
        path: "prepaid",
        element: <PrepaidInvoicePage />,
      },
      {
        path: "prepaid-details/:invoiceID",
        element: <InvoicePrepaidDetailsPage />,
      },
      {
        path:"prepaid-new",
        element:<PrepaidInvoiceFormPage/>
      }
    ]
  },
  {
    path:"/payments",
    element:<DashboardLayout/>,
    children: [
      {
        path:"claim-transfer-new",
        element: <ClaimTransferFormPage/>
      },
      {
        path:"claim-transfer-credit",
        element: <ClaimCreditsFormPage/>
      },
      {
        path:"claim-transfer-debit",
        element:<ClaimDebitsFormPage/>
      },
      {
        path: "listings",
        element: <PaymentsPage />,
      },
      {
        path: "listing-new",
        element: <PaymentsFormPage />,
      },
      {
        path: "debit-new",
        element: <DebitsFormPage />,
      },
      {
        path: "credit-new",
        element: <CreditsFormPage />,
      },
    ]
  },
  {
    path:"/transactions",
    element:<DashboardLayout><TransactionsPage/></DashboardLayout>
  },
  {
    path:"/ledgers",
    element:<DashboardLayout/>,
    children: [
      {
        path: "payments",
        element: <PaymentLedgerPage />,
      },
      {
        path: "claims",
        element: <ClaimLedgerPage />,
      },
      {
        path: "prepaid",
        element: <PrepaidLedgerPage />,
      },
      {
        path: "chennai",
        element: <ChennaiLedgerPage />,
      },
      {
        path: "pune",
        element: <PuneLedgerPage />,
      }
    ]
  },
  {
    path:"/purchases",
    element:<DashboardLayout/>,
    children:[
      {
        path:"orders",
        element: <PurchaseOrderTable/>
      },
      {
        path:"orders-new",
        element: <PurchaseOrderFormPage/>
      },
      {
        path:"orders-details/:purchaseOrderID",
        element: <PurchaseOrderDetailsPage/>
      },
      {
        path:"receipts",
        element: <GoodsReceipts/>
      },
      {
        path:"receipts-new",
        element: <GoodsReceiptFormPage/>
      },
      {
        path:"receipts-details/:receiptID",
        element: <ReceiptDetailsPage/>
      },
      {
        path:"bills",
        element: <PurchaseBillsPage/>
      },
      {
        path:"products",
        element: <ProductsPage/>
      },
      {
        path:"products-new",
        element: <ProductFormPage/>
      },
      {
        path:"vendors",
        element:<SupplierPage/>
      },{
        path:"suppliers-new",
        element: <SupplierFormPage/>
      },
    ]
  },
  {
    path: "/customers",
    element: <DashboardLayout />,
    children: [
      {
        path: "active",
        children: [


          {
            path:"dealers",
            element: <ActiveDealers />
          },
          {
            path:"dealers-new",
            element: <GeneralTraderFormPage />,
          },
          {
            path: "dealers-details/:dealerID",
            element: <GeneralTraderDetailsPage />,
          },


          {
            path: "gts",
            element: <ActiveGT />,
          },
          {
            path: "gts-new",
            element: <GTDistributorFormPage />,
          },
          {
            path: "gts-details/:dealerID",
            element: <GtDistributorDetailsPage />,
          },

          {
            path: "mts",
            element: <ActiveMT />,
          },
          {
            path: "mts-new",
            element: <MTDistributorFormPage />,
          },
          {
            path: "mts-details/:dealerID",
            element: <MtDistributorDetailsPage />,
          },


          {
            path: "ss",
            element: <ActiveSS />,
          },
          {
            path: "ss-new",
            element: <SuperStockistFormPage />,
          },
          {
            path:"ss-details/:dealerID",
            element:<SuperStockistDetailsPage/>
          },
          {
            path: "non-billers",
            element: <NonBillerDealer />,
          },
          {
            path:"non-billing-dealer-new",
            element: <NonBillersFormPage />,

          },
          {
            path:"non-billers-details/:dealerID",
            element: <NonBillerDetailsPage/>
          },
          {
            path: "retailers",
            element: <ActiveRetailer />,
          },
          {
            path: "retailers-new",
            element: <RetailerFormPage />,
          },
          {
            path:"retailers-details/:retailerID",
            element:<RetailerDetailsPage/>
          },
        ]

      },
      {
        path: "inactive",
        children: [
          {
            path:"dealers",
            element: <InactiveDealers />,
          },
          {
            path: "gts",
            element: <InactiveGt />,
          },
          {
            path: "mts",
            element: <InactiveMt />,
          },
          {
            path: "ss",
            element: <InactiveSS />,
          }
        ]

      },
      {
        path: "pricing",
        element: <SalesPricing />,
      },

    ]
  },
  {
    path: "/reports",
    element: <DashboardLayout/>,
    children: [
      {
        path: "money_ledger",
        element: <MoneyLedgerReportTablePage/>
      },
      {
        path: "prepaid_ledger",
        element: <PrepaidLedgerReportTablePage/>
      },
      {
        path:"warehouse/:warehouseID",
        element:<WarehouseLedgerReportTablePage/>
      }
    ]
  },
  {
    path:"/claims",
    element: <DashboardLayout/>,
    children:[
      {
        path:"contracts",
        element:<ContractsTablePage/>
      },
      {
        path:"contracts-new",
        element:<ContractsFormPage/>
      },
        {
          path:"claim",
          element:<ClaimsTablePage/>
        },
        {
          path:"claim-new",
          element:<ClaimFormPage/>
        },
      {
        path:"claim-new-other",
        element:<ClaimOtherFormPage/>
      },
        {
          path:"claim-details/:dealerID",
          element:<ClaimDetailsDealerPage/>
        },
      {
        path:"claim-details/:dealerID/:claimID",
        element:<ClaimDetailsPage/>
      }
    ]
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignInPage />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "/sd",
    element: <DashboardLayout />,
    children: [
      {
        path: "distributors",
        element: <SDDistributorsPage />,
      },
      {
        path:"distributors-details/:distributorID",
        element:<SDDistributorDetailsPage/>
      },
      {
        path: "beats",
        element: <SDBeatsPage />,
      },
      {
        path: "beats-details/:beatId",
        element: <SDBeatDetailsPage />,
      },
      {
        path: "retailers",
        element: <SDRetailersPage />,
      }
    ],
  },
  {
    path:"/users",
    element:<DashboardLayout/>,
    children:[
      {
        path:"erp",
        element:<ERPUsersPage/>
      },
      {
        path:"erp-new",
        element:<UserFormPage/>
      },
      {
        path:"sales",
        element: <SalesUsersPage/>
      },
      {
        path:"sales-new",
        element: <FirebaseUserFormPage/>
      }
    ]
  },
  {
    path: "",
    element: <AuthGuard><DashboardLayout /></AuthGuard>
  },
  // {
  //   path: "*",
  //   element: <AuthLayout />,
  //   children: [
  //     {
  //       path: "*",
  //       element: <Page404 />,
  //     },
  //   ],
  // },
];

export default routes;
