import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faExclamation, faTimes,} from "@fortawesome/free-solid-svg-icons";
import {Badge} from "react-bootstrap";

export function getBadge(badgeName) {
    switch (badgeName) {
        case "CREATED":
            return (
                <Badge bg="primary">
                    <FontAwesomeIcon icon={faExclamation} /> Created
                </Badge>
            );
        case "PRE_CLOSED":
            return (
                <Badge bg="info">
                    <FontAwesomeIcon icon={faCheck} /> Pre Closed
                </Badge>
            );

        case "RE_APPROVAL":
        case "AWAITING_APPROVAL":
        case "WAITING_FOR_APPROVAL":
            return (
                <Badge bg="secondary">
                    <FontAwesomeIcon icon={faExclamation} /> Awaiting Approval
                </Badge>
            );

        case "REJECTED":
            return (
                <Badge bg="danger">
                    <FontAwesomeIcon icon={faTimes} /> Rejected
                </Badge>
            );

        case "APPROVED":
            return (
                <Badge bg="success">
                    <FontAwesomeIcon icon={faCheck} /> Approved
                </Badge>
            );

        case "ACKNOWLEDGED":
            return (
                <Badge bg="warning">
                    <FontAwesomeIcon icon={faCheck} /> Acknowledged
                </Badge>
            );

        case "CLOSED":
        case "STOCK_ISSUED":
            return (
                <Badge bg="success">
                    <FontAwesomeIcon icon={faCheck} /> Closed
                </Badge>
            );

        case "DRAFT":
            return (
                <Badge bg="secondary">
                    <FontAwesomeIcon icon={faExclamation} /> Draft
                </Badge>
            );

        case "PUBLISHED":
            return (
                <Badge bg="success">
                    <FontAwesomeIcon icon={faCheck} /> Published
                </Badge>
            );

        case "CANCELLED":
            return (
                <Badge bg="danger">
                    <FontAwesomeIcon icon={faTimes} /> Cancelled
                </Badge>
            );

        case "LESS_MONEY_REJECTION":{
            return (
                <Badge bg="danger">
                    <FontAwesomeIcon icon={faTimes} /> Insufficient Balance
                </Badge>
            );
        }

        case true:{
            return (
                <Badge bg="success">
                    <FontAwesomeIcon icon={faCheck} /> True
                </Badge>
            );
        }

        case false:{
            return (
                <Badge bg="danger">
                    <FontAwesomeIcon icon={faTimes} /> False
                </Badge>
            );
        }


        default:
            return (
                <Badge bg="success">
                    <FontAwesomeIcon icon={faCheck} /> {badgeName}
                </Badge>
            );
    }
}


export function getFormDateStringFromLongNumber(dateTime) {
    let date = new Date(Number(dateTime));
    let day = ("0" + date.getDate()).slice(-2);
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let formattedDate = date.getFullYear() + "-" + month + "-" + day;
    return formattedDate;
}

export function getMonthStringFromLongNumber(dateTime) {
    let date = new Date(Number(dateTime));
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let formattedDate = month + "/" + date.getFullYear();
    return formattedDate;
}

export function getDateStringFromLongNumber(dateTime) {
    let date = new Date(Number(dateTime));
    let day = ("0" + date.getDate()).slice(-2);
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let formattedDate = day + "/" + month + "/" + date.getFullYear();
    return formattedDate;
}

export function getTimeStringFromLongNumber(dateTime) {
    let date = new Date(Number(dateTime));
    let hour = ("0" + date.getHours()).slice(-2);
    let minutes = ("0" + (date.getMinutes() + 1)).slice(-2);
    var ampm = hour >= 12 ? "pm" : "am";
    hour = hour % 12;
    hour = hour ? hour : 12;
    let formattedDate =
        getDateStringFromLongNumber(dateTime) +
        " " +
        hour +
        ":" +
        minutes +
        " " +
        ampm;
    return formattedDate;
}
