import React, {useEffect, useState} from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import useSidebar from "../../hooks/useSidebar";
import SidebarNav from "./SidebarNav";
import axiosInstance, {GetAsInputData} from "../../utils/axios";
import {roles} from "../../utils/roles";

const Sidebar = ({ items, showFooter = true }) => {
    const { isOpen } = useSidebar();

    const [wareHouseOptions, setWarehouseOptions] = useState([]);
    const [reportWareHouseOptions, setReportWareHouseOptions] = useState([]);

    useEffect(() => {
        axiosInstance
            .get("/api/warehouses?"+GetAsInputData({
                listInfo:{
                    startRecord:0,
                    rowCount:9999,
                    sortBy:[ {"field":"warehouse_name","order":"asc"}],
                }
            })).then(function(response){
            let batchLoadedItems = [];
            let reportLoadedItems = [];
            let index = 0;
            response.data.warehouses.forEach((warehouse) => {
                batchLoadedItems.push({
                    href: "/invoices/warehouse/"+warehouse.warehouse_id,
                    title: warehouse.warehouse_name,
                    roles:[roles.ROLE_VIEW_ALL_INVOICE_DOMESTIC],
                    key: "/invoices/warehouse/"+warehouse.warehouse_id,
                });
                reportLoadedItems.push({
                    href: "/reports/warehouse/"+warehouse.warehouse_id,
                    title: warehouse.warehouse_name,
                    roles:[roles.ROLE_VIEW_CREATE_REPORTS],
                    key: "/reports/warehouse/"+warehouse.warehouse_id,
                });
                index++;
            });
            setReportWareHouseOptions(reportLoadedItems);
            setWarehouseOptions(batchLoadedItems)
        });
    }, []);

    items[0].pages[1].children[1].children[0].children = wareHouseOptions ;
    items[3].pages[0].children[2].children = reportWareHouseOptions ;

      return (
        <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
          <div className="sidebar-content">
            <PerfectScrollbar>
                <img width="100%" className={"my-3"} src="https://buyhappy.in/images/buyhappy_logo.png" />

              <SidebarNav items={items} />
              {/*{!!showFooter && <SidebarFooter />}*/}
            </PerfectScrollbar>
          </div>
        </nav>
      );
};

export default Sidebar;
