import React, {useState} from "react";
import {Button, Col, Form, InputGroup, Row, Table} from "react-bootstrap";
import Select from "react-select";
import axiosInstance from "../../../utils/axios";

export const CustomerModificationPage = ({dealerData,fetchUpdateDealerData}) => {

    const options = [
        { value: "GENERAL_TRADER", label: "General Trader" },
        { value: "GT_DISTRIBUTOR", label: "GT Distributor" },
        { value: "MT_DISTRIBUTOR", label: "MT Distributor" },
        { value: "SS_DISTRIBUTOR", label: "Superstockist" },
    ];

    const dealerTypeSelectValue = options.filter((option,index) => {
        if(option.value==dealerData.type)
            return option
    });

    const [selectValue,setSelectValue] = useState(dealerTypeSelectValue[0]);
    const [inoutValue,setInputValue] = useState(dealerData.code);

    return (
        <Row>
            <h4 className="tab-title">Dealer Custom Modification  </h4>
            <Col lg="6">
                <Table size="sm" className="my-2">
                    <tbody>
                        <tr>
                            <th>Code</th>
                            <td>
                                <Form.Group className="mb-3">
                                    <InputGroup>
                                        <Button onClick={() => {
                                            axiosInstance.get("/api/customers/"+dealerData.dealer_id+"/dealerCode/"+inoutValue).then(r => {
                                                fetchUpdateDealerData();
                                            })
                                        }} variant="secondary">Update Dealer Code</Button>
                                        <Form.Control
                                            type="text"
                                            name="dealerCode"
                                            value={inoutValue}
                                            placeholder="Dealer Code"
                                            onChange={(e) => {
                                                setInputValue(e.target.value)
                                            }} />
                                    </InputGroup>
                                </Form.Group>
                            </td>
                        </tr>
                        <tr>
                            <th>Dealer Type</th>
                            <td>
                                <InputGroup>
                                <Button onClick={() => {
                                    axiosInstance.get("/api/customers/"+dealerData.dealer_id+"/dealerType/"+selectValue.value).then(r => {
                                        fetchUpdateDealerData();
                                    })
                                }} variant="secondary">Update Dealer Type</Button>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="dealerType"
                                    value={selectValue}
                                    options={options}
                                    isLoading={false}
                                    onChange={selectedOption => {
                                        setSelectValue(selectedOption);
                                    }}
                                />
                                </InputGroup>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
}
