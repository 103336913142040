import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Alert,
    Badge,
    ButtonGroup,
    Col,
    Container,
    Dropdown,
    DropdownButton,
    Nav,
    Row,
    Tab,
    Table
} from "react-bootstrap";
import {useParams} from "react-router-dom";
import axiosInstance from "../../../../utils/axios";
import Loader from "../../../../components/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";

const ReceiptDetails = () => {
    const params = useParams();
    const [receiptData,setReceiptData] = useState();
    const [receiptApproved,setReceiptApproved] = useState(false);
    const [receiptRejected,setReceiptRejected] = useState(false);

    useEffect(() => {
        const load =  () => {
            try{
                let entityURL = "/api/goods_receipts/"+params.receiptID;
                axiosInstance.get(entityURL)
                    .then(function(response){
                        setTimeout(() => {
                            setReceiptData(response.data.goods_receipt);
                        }, 1500);
                    })
            }catch(e){
                console.log(e);
            }
        }

        load();
    },[params.receiptID]);

    function updateData(){
        try{
            let entityURL = "/api/goods_receipts/"+params.receiptID;
            axiosInstance.get(entityURL)
                .then(function(response){
                    setTimeout(() => {
                        setReceiptData(response.data.goods_receipt);
                        setReceiptApproved(false);
                        setReceiptRejected(false);
                    }, 1500);
                })
        }catch(e){
            console.log(e);
        }
    }

    function approveReceipt(eventKey){
        axiosInstance.put("/api/"+eventKey+"/approve_stock/"+receiptData.grn_id).then((response,success) => {
            setReceiptApproved(true);
            updateData();
        });
    }

    if(receiptData ===undefined){
        return <Loader />;
    }else{
        return(

            <Container fluid className="p-0">
                <h1 className="h3 mb-3">{receiptData.purchaseOrder.supplier.company_name} - {receiptData.purchaseOrder.purchase_number} - {receiptData.grn_id}</h1>
                <Row>
                    <Col lg="12">
                        <div className={"tab " }>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Nav variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Details</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Alert show={receiptApproved} onHide={receiptApproved} variant="success" key="2" dismissible>
                                        <div className="alert-message">
                                            <strong>Receipt</strong> approved successfully!
                                        </div>
                                    </Alert>
                                    <Alert show={receiptRejected} onHide={receiptRejected} variant="danger" key="3" dismissible>
                                        <div className="alert-message">
                                            <strong>Receipt</strong> rejected!
                                        </div>
                                    </Alert>
                                    <Tab.Pane eventKey="first">
                                        <h4 className="tab-title">Receipt Details</h4>
                                        <Col lg="6">
                                            <Table size="sm" className="my-2">
                                                <tbody>
                                                <tr key={0}>
                                                    <th>Created on</th>
                                                    <td>{new Date(Number(receiptData.created_date)).toString().slice(3,23)}</td>
                                                </tr>
                                                <tr key={1}>
                                                    <th>Created by</th>
                                                    <td>{receiptData.created_by.name}</td>
                                                </tr>
                                                <tr key={2}>
                                                    <th>Supplier Invoice Number</th>
                                                    <td>{receiptData.invoice_number}</td>
                                                </tr>
                                                <tr key={3}>
                                                    <th>Supplier Invoice Date</th>
                                                    <td>{new Date(Number(receiptData.invoice_date)).toString().slice(3,23)}</td>
                                                </tr>
                                                <tr key={4}>
                                                    <th>Supplier Invoice</th>
                                                    <td>{receiptData.invoice_proof && receiptData.invoice_proof.fileName}</td>
                                                </tr>
                                                <tr key={5}>
                                                    <th>Status</th>
                                                    <td>
                                                        {
                                                            receiptData.status==="STOCK_APPROVED"?
                                                                <Badge bg="success"><FontAwesomeIcon icon={faCheck} /> Approved</Badge>:
                                                                receiptData.status==="REJECTED"?
                                                                    <Badge bg="danger" ><FontAwesomeIcon icon={faTimes} /> Cancelled</Badge>:<Badge>{receiptData.status}</Badge>
                                                        }
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                            { receiptData.status === "CREATED" &&
                                            // <Button variant={`success`} className=" me-1 mb-1" onClick={approveReceipt}>Approve</Button>
                                                <DropdownButton
                                                as={ButtonGroup}
                                                key={"primary"}
                                                id={`dropdown-variants-primary`}
                                                variant={"primary"}
                                                title={"Choose approval type"}
                                                className="me-2"
                                                onSelect={approveReceipt}
                                                >
                                                    <Dropdown.Item eventKey="goods_receipts">Approve for distributor</Dropdown.Item>
                                                    <Dropdown.Item eventKey="prepaid_goods_receipts">Approve for prepaid</Dropdown.Item>
                                                </DropdownButton>
                                            }
                                        </Col>
                                        <br/>
                                        <h4 className="tab-title">Receipt Products </h4>
                                        <Col lg="3">
                                            {
                                                <Table size="sm" className="my-2">
                                                    <thead>
                                                    <tr key={10}>
                                                        <th>Product Name</th>
                                                        <th>Quantity</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    { receiptData.products.map((product, i) => {
                                                        return (
                                                            <tr key={i+"11"}>
                                                                <td>{product.product.name}</td>
                                                                <td>{product.received_quantity}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                    </tbody>
                                                </Table>
                                            }
                                        </Col>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }


};

const ReceiptDetailsPage = () => (

    <React.Fragment>
        <Helmet title="Receipt Details" />
        <ReceiptDetails />
    </React.Fragment>
);

export default ReceiptDetailsPage;
