export const roles = {

        /**
         General Traders Permission Done
         */
        ROLE_VIEW_GENERAL_TRADERS:"ROLE_VIEW_GENERAL_TRADERS",
        ROLE_CREATE_MODIFY_GENERAL_TRADERS:"ROLE_CREATE_MODIFY_GENERAL_TRADERS",

        /**
         GT MT SS Permission Done
         */


        ROLE_VIEW_DEALERS:"ROLE_VIEW_DEALERS",
        ROLE_CREATE_MODIFY_DEALERS:"ROLE_CREATE_MODIFY_DEALERS",

        /**
         DEALER MISC Done
         */

        ROLE_VIEW_RETAILERS:"ROLE_VIEW_RETAILERS",
        ROLE_CREATE_MODIFY_RETAILERS:"ROLE_CREATE_MODIFY_RETAILERS",

        /**
         DEALER MISC Done
         */

        ROLE_ENABLE_DISABLE_DEALERS_GENERAL_TRADER:"ROLE_ENABLE_DISABLE_DEALERS_GENERAL_TRADER",

        /**
         SO PERMISSIONS Partial Done
         */

        ROLE_VIEW_ALL_SALE_ORDERS_DOMESTIC:"ROLE_VIEW_ALL_SALE_ORDERS_DOMESTIC",
        ROLE_CREATE_MODIFY_ALL_SALE_ORDERS_DOMESTIC:"ROLE_CREATE_MODIFY_ALL_SALE_ORDERS_DOMESTIC",

        ROLE_VIEW_SITE_BASED_SALE_ORDERS_DOMESTIC:"ROLE_VIEW_SITE_BASED_SALE_ORDERS_DOMESTIC",
        ROLE_CREATE_MODIFY_SITE_BASED_SALE_ORDERS_DOMESTIC:"ROLE_CREATE_MODIFY_SITE_BASED_SALE_ORDERS_DOMESTIC",

        ROLE_VIEW_SALE_ORDERS_EXPORT:"ROLE_VIEW_SALE_ORDERS_EXPORT",
        ROLE_CREATE_MODIFY_SALE_ORDERS_EXPORT:"ROLE_CREATE_MODIFY_SALE_ORDERS_EXPORT",

        ROLE_VIEW_SALE_ORDERS_LUT:"ROLE_VIEW_SALE_ORDERS_LUT",
        ROLE_CREATE_MODIFY_SALE_ORDERS_LUT:"ROLE_CREATE_MODIFY_SALE_ORDERS_LUT",

        /**
         SO MISC Done
         */

        ROLE_APPROVE_SALE_ORDERS:"ROLE_APPROVE_SALE_ORDERS",

        /**
         Invoice Permission Partial Done
         */

        ROLE_VIEW_ALL_INVOICE_DOMESTIC:"ROLE_VIEW_ALL_INVOICE_DOMESTIC",
        ROLE_CREATE_MODIFY_ALL_INVOICE_DOMESTIC:"ROLE_CREATE_MODIFY_ALL_INVOICE_DOMESTIC",

        ROLE_VIEW_SITE_BASED_INVOICE_DOMESTIC:"ROLE_VIEW_SITE_BASED_INVOICE_DOMESTIC",
        ROLE_CREATE_MODIFY_SITE_BASED_INVOICE_DOMESTIC:"ROLE_CREATE_MODIFY_SITE_BASED_INVOICE_DOMESTIC",

        ROLE_VIEW_INVOICE_EXPORT:"ROLE_VIEW_INVOICE_EXPORT",
        ROLE_CREATE_MODIFY_INVOICE_EXPORT:"ROLE_CREATE_MODIFY_INVOICE_EXPORT",

        ROLE_VIEW_INVOICE_LUT:"ROLE_VIEW_INVOICE_LUT",
        ROLE_CREATE_MODIFY_INVOICE_LUT:"ROLE_CREATE_MODIFY_INVOICE_LUT",


        ROLE_VIEW_ALL_INVOICE_PREPAID:"ROLE_VIEW_ALL_INVOICE_PREPAID",
        ROLE_CREATE_MODIFY_ALL_INVOICE_PREPAID:"ROLE_CREATE_MODIFY_ALL_INVOICE_PREPAID",

        ROLE_VIEW_INVOICE_PROFORMA:"ROLE_VIEW_INVOICE_PROFORMA",
        ROLE_CREATE_MODIFY_INVOICE_PROFORMA:"ROLE_CREATE_MODIFY_INVOICE_PROFORMA",

        ROLE_VIEW_INVOICE_E_COMM:"ROLE_VIEW_INVOICE_E_COMM",
        ROLE_CREATE_MODIFY_INVOICE_E_COMM:"ROLE_CREATE_MODIFY_INVOICE_E_COMM",


        ROLE_VIEW_CREATE_MODIFY_LC:"ROLE_VIEW_CREATE_MODIFY_LC",

        /**
         PAYMENTS PERMISSIONS Done
         */

        ROLE_VIEW_PAYMENTS:"ROLE_VIEW_PAYMENTS",
        ROLE_CREATE_MODIFY_PAYMENTS_ENTRIES:"ROLE_CREATE_MODIFY_PAYMENTS_ENTRIES",
        ROLE_APPROVE_PAYMENTS_ENTRIES:"ROLE_APPROVE_PAYMENTS_ENTRIES",


        /**
         PO PERMISSIONS Done
         */

        ROLE_VIEW_PURCHASE_ORDER:"ROLE_VIEW_PURCHASE_ORDER",
        ROLE_CREATE_MODIFY_PURCHASE_ORDER:"ROLE_CREATE_MODIFY_PURCHASE_ORDER",

        ROLE_APPROVE_PURCHASE_ORDER:"ROLE_APPROVE_PURCHASE_ORDER",


        /**
         ADMIN CONFIG Done
         */

        ROLE_VIEW_ADMIN_CONFIG:"ROLE_VIEW_ADMIN_CONFIG",
        ROLE_CREATE_MODIFY_ADMIN_CONFIG:"ROLE_CREATE_MODIFY_ADMIN_CONFIG",


        /**
         CLAIM CONFIG Done
         */

        ROLE_VIEW_CLAIM:"ROLE_VIEW_CLAIM",
        ROLE_CREATE_MODIFY_CLAIM:"ROLE_CREATE_MODIFY_CLAIM",
        ROLE_APPROVE_CLAIM:"ROLE_APPROVE_CLAIM",

        /**
         USER CONFIG Done
         */

        ROLE_VIEW_USERS:"ROLE_VIEW_USERS",
        ROLE_CREATE_MODIFY_USERS:"ROLE_CREATE_MODIFY_USERS",
        ROLE_RESET_USERS_PASSWORD:"ROLE_RESET_USERS_PASSWORD",
        ROLE_DISABLE_USERS:"ROLE_DISABLE_USERS",


        /**
         SD CONFIG Done
         */

        ROLE_VIEW_SD_DATA:"ROLE_VIEW_SD_DATA",
        ROLE_CREATE_MODIFY_SD_DATA:"ROLE_CREATE_MODIFY_SD_DATA",


        /**
         GRN PERMISSIONS Done
         */

        ROLE_VIEW_GOODS_RECEIPT:"ROLE_VIEW_GOODS_RECEIPT",
        ROLE_CREATE_MODIFY_GOODS_RECEIPT:"ROLE_CREATE_MODIFY_GOODS_RECEIPT",

        ROLE_APPROVE_GOODS_RECEIPT:"ROLE_APPROVE_GOODS_RECEIPT",
        ROLE_DOWNLOAD_LEDGERS:"ROLE_DOWNLOAD_LEDGERS",
        ROLE_VIEW_CREATE_REPORTS:"ROLE_VIEW_CREATE_REPORTS"

};
