import React, {useContext} from "react";

import {Button, Card, Col, Form, Row} from "react-bootstrap";
import * as Yup from "yup";
import {Formik} from "formik";
import axiosInstance, {GetAsInputData} from "../../../../utils/axios";
import NotyfContext from "../../../../contexts/NotyfContext";

const DepartmentForm = ({ setKey, initialValues, setEditData }) => {
  const notify = useContext(NotyfContext);
  const schema = Yup.object().shape({
    department_name: Yup.string().required("Name is required"),
    department_description: Yup.string().required("Description is required"),
  });

  function HandleSubmit(url, data, navigateTo) {
    axiosInstance.post("/api/" + url, data).then(function (response, success) {
      notify.success("Department created");
      setTimeout(() => {
        setKey(new Date().getTime());
      }, 1500);
    });
  }

  function HandlePut(url, data, navigateTo) {
    axiosInstance.put("/api/" + url, data).then(function (response, success) {
      notify.success("Department updated");
      setTimeout(() => {
        setEditData(null);
        setKey(new Date().getTime());
      }, 1500);
    });
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5">
          {initialValues.department_id == undefined ? "Add" : "Modify"}{" "}
          Department
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Formik
          validationSchema={schema}
          onSubmit={async (values) => {
            if (values.department_id === undefined)
              HandleSubmit(
                "departments",
                GetAsInputData({ department: values })
              );
            else
              HandlePut(
                "departments/" + values.department_id,
                GetAsInputData({ department: values })
              );
          }}
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row>
                <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="department_name"
                      value={values.department_name}
                      onChange={handleChange}
                      isValid={
                        touched.department_name && !errors.department_name
                      }
                      isInvalid={
                        touched.department_name && !!errors.department_name
                      }
                      placeholder="Name"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.department_name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      name="department_description"
                      value={values.department_description}
                      onChange={handleChange}
                      isValid={
                        touched.department_description &&
                        !errors.department_description
                      }
                      isInvalid={
                        touched.department_description &&
                        !!errors.department_description
                      }
                      placeholder="Description"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.department_description}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Button type="submit" variant="primary">
                      Submit
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default DepartmentForm;
