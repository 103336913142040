import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";

import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import Select from "react-select";
import axiosInstance, {ErrorMessage, GetAsInputData} from "../../../utils/axios";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {Formik} from "formik";

const ClaimTransferForm = () => {
    const schema = Yup.object().shape({
        dealer: Yup.object().required("Trader Name is required"),
        amount: Yup.string().required("Amount is required"),
        transaction_reference: Yup.string().required("Transaction reference is required"),
        dealerReceiver:Yup.object().required(" Receiver Trader Name is required ")
    });


    const initialValues = {
        dealer: undefined,
        dealerReceiver:undefined,
        transaction_type:"CLAIM_TRANSFER",
        amount:undefined,
        bank_name:"CLAIM_TRANSFER",
        transaction_reference:undefined
    };

    const navigate = useNavigate();
    const [customerOptions, setCustomerOptions] = useState([]);
    const [isCustomersLoading, setIsCustomersLoading] = useState(true);
    const [monthlyOptions, setMonthlyOptions] = useState([]);
    const [isMonthlyOptionsLoading, setIsMonthlyOptionsLoading] = useState(true);

    function HandleSubmit(url,data,navigateTo){
        axiosInstance.post("/api/"+url,data).then(function(response,success){
            if(response.status===200)
                navigate(navigateTo);
        })
    }

    useEffect(() => {
        const loadSelectData =  () => {
            try{
                axiosInstance.get("/api/customers?"+GetAsInputData({
                    listInfo:{
                        startRecord:0,
                        rowCount:9999,
                        sortBy: [{"field":"dealer_name","order":"asc"}],
                        searchConditions: [{"field":"type","enumName":"DealerType","type":"enum","value":"RETAILER","condition":"not_equals"}]
                    }
                })).then(function(response){
                    setCustomerOptions(response.data.customers);
                    setTimeout(() => {
                        setIsCustomersLoading(false);
                    }, 1500);
                });


            }catch(e){
                console.log(e);
            }
        }
        loadSelectData();
    },[]);

    const loadMonthlyDealerData =  (dealerId) => {
        try{
            axiosInstance.get("/api/dealer_monthly_transactions?"+GetAsInputData({
                listInfo:{
                    startRecord:0,
                    rowCount:9999,
                    sortBy: [{"field":"month","order":"desc"},{"field":"year","order":"desc"}],
                    searchConditions: [{field: "dealerId", value: ""+dealerId}]
                }
            })).then(function(response){
                let filteredTransactions = response.data.dealer_monthly_transactions.filter(transaction => transaction.totalCredit - transaction.totalDebit > 0);
                setMonthlyOptions(filteredTransactions)
                setIsMonthlyOptionsLoading(false);
            });
        }catch(e){
            console.log(e);
        }
    }

    return( <Card>
        <Card.Header>
            <Card.Title tag="h5">Add Payment</Card.Title>
        </Card.Header>
        <Card.Body>
            <Formik
                validationSchema={schema}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500));
                    values.inward_date = new Date().getTime();
                    HandleSubmit("incoming_cash",GetAsInputData({incoming_cash:values}),"/payments/listings")
                }}
                initialValues={initialValues}>
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                  }) => (<Form noValidate onSubmit={handleSubmit}>
                    <Row>
                        <Col sm={4}>
                            <Form.Group  className="mb-3">
                                <Form.Label>From Trader</Form.Label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="dealer"
                                    options={customerOptions.map((key, text) => {
                                        return {
                                            value: key.dealer_id,
                                            label: key.dealer_name,
                                            dealer_id:key.dealer_id,
                                        }}
                                    )}
                                    isLoading={isCustomersLoading}
                                    onChange={selectedOption => {
                                        let event = { target : { name:'dealer',value: selectedOption}}
                                        handleChange(event);
                                        loadMonthlyDealerData(selectedOption.dealer_id);

                                    }}
                                />
                                <ErrorMessage name={`dealer`} />
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Group  className="mb-3">
                                <Form.Label>To Trader</Form.Label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="dealerReceiver"
                                    options={customerOptions.map((key, text) => {
                                        return {
                                            value: key.dealer_id,
                                            label: key.dealer_name,
                                            dealer_id:key.dealer_id,
                                        }}
                                    )}
                                    isLoading={isCustomersLoading}
                                    onChange={selectedOption => {
                                        let event = { target : { name:'dealerReceiver',value: selectedOption}}
                                        handleChange(event);
                                    }}
                                />
                                <ErrorMessage name={`dealerReceiver`} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <Form.Group  className="mb-3">
                                <Form.Label>To Trader</Form.Label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="dealerMonthlyTransaction"
                                    options={monthlyOptions.map((key, text) => {
                                        return {
                                            value: key.id,
                                            label: key.year +" - "+key.month+" - "+Math.round(key.totalCredit),
                                            totalCredit:key.totalCredit,
                                            totalDebit:key.totalDebit,
                                            id:key.id
                                        }}
                                    )}
                                    isLoading={isMonthlyOptionsLoading}
                                    onChange={selectedOption => {
                                        let event = { target : { name:'amountAvailable',value: selectedOption.totalCredit - selectedOption.totalDebit}}
                                        handleChange(event);
                                        let anotherEvent = { target : { name:'dealerMonthlyTransaction',value: selectedOption}}
                                        handleChange(anotherEvent);
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Amount Available
                                </Form.Label>
                                <Form.Control type="number"
                                              name="amountAvailable"
                                              placeholder="Amount Available"
                                              value={Math.round(values.amountAvailable)}
                                                disabled/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Amount
                                </Form.Label>
                                <Form.Control type="number"
                                              name="amount"
                                              onChange={handleChange}
                                              isValid={touched.amount && !errors.amount}
                                              isInvalid={touched.amount && !!errors.amount}
                                              placeholder="Amount" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.amount}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Label>
                                Credit Note Number
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="transaction_reference"
                                isValid={touched.transaction_reference && !errors.transaction_reference}
                                isInvalid={touched.transaction_reference && !!errors.transaction_reference}
                                onChange={handleChange}
                                placeholder="Credit Note Number" />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.transaction_reference}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Button type="submit" variant="primary">Submit</Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>)}
            </Formik>

        </Card.Body>
    </Card>);
}


const ClaimTransferFormPage = () => (
    <React.Fragment>
        <Helmet title="Claim Transfer Entry" />
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Claim Transfer Entry</h1>

            <Row>
                <Col lg="8">
                    <ClaimTransferForm />
                </Col>
            </Row>
        </Container>
    </React.Fragment>
);

export default ClaimTransferFormPage;
