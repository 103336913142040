import React, {useContext, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";

import {Button, Card, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import Select from "react-select";
import axiosInstance, {ErrorMessage, GetAsInputData, PostApproval} from "../../../utils/axios";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {FieldArray, Formik} from "formik";
import NotifyContext from "../../../contexts/NotyfContext";

const schema = Yup.object().shape({
    dealer: Yup.object().required("Dealer is required"),
    invoice_date: Yup.string().required("Invoice Date is required"),
    mode_of_transport: Yup.string().required("Mode of Transport is required"),
    products: Yup.array()
        .of(
            Yup.object().shape({
                product: Yup.object().required("Product is required").nullable(),
                quantity: Yup.number().required("Box count is required"),
                batch_number_custom:Yup.string().required("Batch number is required"),
                product_price:Yup.string().required("Price is required")
            })
        )

});


const initialValues = {
    supplier: undefined,
    dealer:{"dealer_id":634,"dealer_name":"VIMAC"},
    invoice_date:undefined,
    mode_of_transport:undefined,
    invoice_type:"BUY_HAPPY_SALES_DEALERS_INVOICE",
    warehouse: {"warehouse_id":1,"warehouse_name":"Chennai"},
    sale:0,
    tax:0,
    sale_tax:0,
    products:[{
        product:undefined,
        quantity:0,
        code:0,
        batch_number_custom:undefined,
        product_price:0
    }]
};

const LCInvoiceForm = () => {
    const navigate = useNavigate();
    const notify = useContext(NotifyContext)
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [isSupplierLoading, setIsSupplierLoading] = useState(true);
    const [productOptions, setProductOptions] = useState([]);
    const [isProductsLoading, setIsProductsLoading] = useState(true);

    function returnSaleValues(values,type){
        let sale = 0;
        let tax = 0;
        for(let i=0;i<values.length;i++){
            if(values[i].product!==undefined){
                sale += values[i].product_price * values[i].quantity;
                tax += values[i].product_price * values[i].quantity * 18;
            }
        }
        if(type==="sale")
            return sale;
        if(type==="tax")
            return tax/100;
        if(type==="sale_tax")
            return (sale+(tax/100));
    }

    function CreateSaleInvoice(response,values){
        axiosInstance.post("/api/buyhappy_sales_invoices",GetAsInputData({buyhappy_sales_invoices:values}))
            .then(function(response,success){
                notify.success("Sale Invoice created Successfully");
                navigate("/invoices/details/"+response.data.buyhappy_sales_invoices.id);
            }).catch(function(response,error){
            notify.error("Error creating invoice");
        });
    }

    function ApproveSaleOrder(response,values){
        PostApproval("sales_order",response.data.sales_order.so_number,"is_approved","APPROVED",(response) => {
            notify.success("Sale Order Approved Successfully");
            values.salesOrder = {so_number:response.sale_order.so_number};
            CreateSaleInvoice(response,values)
        });
    }

    function CreateSalesOrder(values,purchaseValues){
        purchaseValues.sale_type="DOMESTIC"
        values.sale_type="DOMESTIC"
        axiosInstance.post("/api/sales_order",GetAsInputData({sale_order:purchaseValues})).then(function(response,success){
            notify.success("Sale Order Created Successfully");
            ApproveSaleOrder(response,values);
        }).catch(function(response,error){
            notify.error("Error creating Sale Order")
        });
    }

    function HandleSubmit(values){
        let purchaseValues = {...values};
        let hashmapProducts = {};
        purchaseValues.products.forEach(function(purchaseProduct) {
            if(hashmapProducts[purchaseProduct.product.name] == undefined){
                hashmapProducts[purchaseProduct.product.name] = {
                    product:purchaseProduct.product,
                    count:purchaseProduct.quantity,
                };
            }else{
                hashmapProducts[purchaseProduct.product.name].count+=purchaseProduct.quantity;
            }
        })
        purchaseValues.products = [];
        Object.keys(hashmapProducts).forEach(function(key){
            purchaseValues.products.push({
                product:hashmapProducts[key].product,
                quantity:hashmapProducts[key].count,
                received_quantity:hashmapProducts[key].count
            })
        });
        CreateSalesOrder(values,purchaseValues);
    }

    function loadSupplierProducts(supplierID){
        axiosInstance.get("/api/product_suppliers?"+GetAsInputData({
            listInfo:{
                startRecord:0,
                rowCount:9999,
                sortBy: [{"field":"product.name","order":"asc"}],
                searchConditions:[{"field":"supplier.id","value":""+supplierID,"type":"number"}]
            }
        })).then(function(response){
            let supplierProducts = [];
            response.data.product.map((supplierProduct,index) => {
                supplierProduct.product.seller_price = supplierProduct.price;
                supplierProducts.push(supplierProduct.product);
            });
            setProductOptions(supplierProducts);
            setTimeout(() => {
                setIsProductsLoading(false);
            }, 1500);
        });
    }

    useEffect(() => {
        const loadSelectData =  () => {

            try{
                axiosInstance.get("/api/suppliers?"+GetAsInputData({
                    listInfo:{
                        startRecord:0,
                        rowCount:9999,
                        sortBy: [{"field":"company_name","order":"asc"}],
                    }
                })).then(function(response){
                    setSupplierOptions(response.data.suppliers);
                    setTimeout(() => {
                        setIsSupplierLoading(false);
                    }, 1500);
                });


            }catch(e){
                console.log(e);
            }
        }
        loadSelectData();
    },[]);

    return( <Card>
        <Card.Header>
            <Card.Title tag="h5">Add Invoice</Card.Title>
        </Card.Header>
        <Card.Body>
            <Formik
                validationSchema={schema}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500));
                    HandleSubmit(values)
                }}
                initialValues={initialValues}>
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                  }) => (<Form noValidate onSubmit={handleSubmit}>
                    <Row>
                        <Col sm={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>Supplier</Form.Label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="supplier"
                                    value={values.supplier}
                                    getOptionLabel={(option)=>option.company_name}
                                    getOptionValue={(option)=>option.id}
                                    options={supplierOptions}
                                    isLoading={isSupplierLoading}
                                    onChange={selectedOption => {
                                        let event = { target : {
                                                name:'supplier',
                                                value: {
                                                    company_name:selectedOption.company_name,
                                                    id:selectedOption.id
                                                }
                                            }}
                                        handleChange(event);
                                        loadSupplierProducts(selectedOption.id)
                                    }}
                                />
                                <ErrorMessage name={`supplier`} />
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Dealer
                                </Form.Label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="dealer"
                                    isDisabled={true}
                                    value={values.dealer}
                                    getOptionLabel={(option)=>option.dealer_name}
                                    getOptionValue={(option)=>option.dealer_id}
                                />
                                <ErrorMessage name={`supplier`} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Invoice Date
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    name="invoice_date"
                                    isValid={touched.invoice_date && !errors.invoice_date}
                                    isInvalid={touched.invoice_date && !!errors.invoice_date}
                                    onChange={event => {
                                        let anotherEvent = { target : { name:'invoice_date',value: new Date(event.currentTarget.value).getTime()}}
                                        handleChange(anotherEvent);
                                    }}
                                    placeholder="Invoice Date" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.invoice_date}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Label>
                                Mode Of Transport
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="mode_of_transport"
                                isValid={touched.mode_of_transport && !errors.mode_of_transport}
                                isInvalid={touched.mode_of_transport && !!errors.mode_of_transport}
                                onChange={handleChange}
                                placeholder="Mode Of Transport" />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.mode_of_transport}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Card.Header/>
                    <Card.Title tag="h5">Invoice Products</Card.Title>
                    <Card.Header/>
                    <FieldArray
                        name="products"
                        render={arrayHelpers => (
                            <div>
                                {
                                    values.products && values.products.length > 0 ? (
                                        values.products.map((product, index) => (
                                            <Row key={index}>
                                                <Col md={4}>
                                                    <Form.Label htmlFor="product">Product</Form.Label>
                                                    <Form.Group className="mb-3">
                                                        <Select
                                                            key={`products.${index}`}
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            name={`products[${index}].product`}
                                                            value={product.product}
                                                            getOptionLabel={(option)=>option.name}
                                                            getOptionValue={(option)=>option.id}
                                                            isDisabled={isProductsLoading}
                                                            options={productOptions}
                                                            isLoading={isProductsLoading}
                                                            onChange={selectedOption => {
                                                                let event = { target : { name:`products[${index}].product`,value: {
                                                                            id: selectedOption.id,
                                                                            name: selectedOption.name,
                                                                            seller_price:selectedOption.seller_price
                                                                        } } };
                                                                handleChange(event);
                                                                let seller_price = selectedOption.seller_price;
                                                                let anotherEvent = { target : { name:`products[${index}].product_price`,value: seller_price}};
                                                                handleChange(anotherEvent);
                                                            }}
                                                        />
                                                        <ErrorMessage name={`products[${index}].product`} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={2}>
                                                    <Form.Label htmlFor="HSN Code">Code</Form.Label>
                                                    <InputGroup>
                                                        <FormControl
                                                            key={`products.${index}`}
                                                            placeholder="Code"
                                                            type="text"
                                                            name={`products[${index}].code`}
                                                            value={product.code}
                                                            aria-label="Code"
                                                            onChange={handleChange}
                                                            disabled={!product.product} />
                                                    </InputGroup>
                                                    <ErrorMessage name={`products[${index}].code`} />
                                                </Col>
                                                <Col md={2}>
                                                    <Form.Label htmlFor="batch_number_custom">Batch Number</Form.Label>
                                                    <InputGroup>
                                                        <FormControl
                                                            key={`products.${index}`}
                                                            placeholder="Batch Number"
                                                            type="text"
                                                            name={`products[${index}].batch_number_custom`}
                                                            value={product.batch_number_custom}
                                                            aria-label="Batch Number"
                                                            onChange={handleChange}
                                                            disabled={!product.product} />
                                                    </InputGroup>
                                                    <ErrorMessage name={`products[${index}].batch_number_custom`} />
                                                </Col>
                                                <Col md={2}>
                                                    <Form.Label htmlFor="price">Price</Form.Label>
                                                    <InputGroup>
                                                        <FormControl
                                                            key={`products.${index}`}
                                                            placeholder="Price"
                                                            type="number"
                                                            name={`products[${index}].product_price`}
                                                            value={product.product_price}
                                                            onChange={handleChange}
                                                            disabled={!product.product} />
                                                    </InputGroup>
                                                    <ErrorMessage name={`products[${index}].product_price`} />
                                                </Col>
                                                <Col md={2}>
                                                    <Form.Label htmlFor="quantity">Quantity</Form.Label>
                                                    <InputGroup>
                                                        <FormControl
                                                            key={`products.${index}`}
                                                            placeholder="Quantity"
                                                            type="number"
                                                            name={`products[${index}].quantity`}
                                                            value={product.quantity}
                                                            aria-label="Quantity"
                                                            onChange={handleChange}
                                                        />
                                                        <Button onClick={() => arrayHelpers.push({
                                                            product:undefined,
                                                            quantity:0,
                                                            code:0,
                                                            batch_number_custom:undefined,
                                                            product_price:0
                                                        })} variant="secondary">Add More</Button>
                                                        <Button onClick={() => {
                                                            arrayHelpers.remove(index);
                                                        }} variant="secondary">Remove</Button>

                                                    </InputGroup>
                                                </Col>
                                            </Row>))):(
                                        arrayHelpers.push({
                                            product:undefined,
                                            quantity:0,
                                            code:0,
                                            batch_number_custom:undefined,
                                            product_price:0
                                        })
                                    )}</div>
                        )}
                    />
                    <Row>
                        <Col sm={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Sale
                                </Form.Label>
                                <Form.Control type="text" value={ returnSaleValues(values.products,"sale")  } placeholder="Sale" disabled/>
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Label>
                                Tax
                            </Form.Label>
                            <Form.Control type="text"value={ returnSaleValues(values.products,"tax")  } placeholder="Tax" disabled/>
                        </Col>
                        <Col sm={4}>
                            <Form.Label>
                                Total
                            </Form.Label>
                            <Form.Control type="text" value={ returnSaleValues(values.products,"sale_tax")  } placeholder="Sales + Tax" disabled/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Button type="submit" variant="primary">Submit</Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>)}
            </Formik>

        </Card.Body>
    </Card>);
}

const LCInvoiceFormPage = () => (
    <React.Fragment>
        <Helmet title="New LC Invoice" />
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">LC Invoice</h1>

            <Row>
                <Col lg="12">
                    <LCInvoiceForm />
                </Col>
            </Row>
        </Container>
    </React.Fragment>
);

export default LCInvoiceFormPage;
