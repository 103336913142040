import React from "react";
import {Helmet} from "react-helmet-async";
import {Badge, Button, Container} from "react-bootstrap";
import EntityTable, {SelectColumnFilter} from "../../../../components/EntityTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";


const goodsReceiptTableColumns = [
    {
        Header: "GRN ID",
        accessor: "id",
    },
    {
        Header: "Purchase Number",
        accessor: "purchaseOrder.purchase_number",
    },
    {
        Header: "Created By",
        accessor: "created_by.name",
    },
    {
        Header: "Status",
        accessor: "status",
        Filter: SelectColumnFilter,
        Cell: ({ cell: { value } }) => {
            if(value==="STOCK_APPROVED"){
                return(<Badge bg="success" >
                    <FontAwesomeIcon icon={faCheck} /> Approved
                </Badge>);
            }
            return(<Button>{value}</Button>);
        }
    }
];


const receiptButtons = [
    {
        key:0,
        name:"New Receipt",
        href:"/purchases/receipts-new"
    }
];

const GoodsReceipts = () => {

    const navigate = useNavigate();

    return(<React.Fragment>
        <Helmet title="Goods Receipt"/>
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Goods Receipt</h1>
            <EntityTable
                columns={goodsReceiptTableColumns}
                buttons={receiptButtons}
                url={"goods_receipts"}
                sortBy={[{field: "id", order: "desc"}]}
                rowProps={row => ({
                    onClick: () => navigate("/purchases/receipts-details/"+row.original.id) ,
                    style: {
                        cursor: "pointer"
                    }
                })}
            />
        </Container>
    </React.Fragment>);

};

export default GoodsReceipts;



