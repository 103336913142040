import React, {useEffect, useState} from "react";

import {Button, Card, Col, Form, Row} from "react-bootstrap";
import Select from "react-select";
import axiosInstance, {ErrorMessage, GetAsInputData} from "../../../utils/axios";
import * as Yup from "yup";
import {Formik} from "formik";

const ewayBillSchema = Yup.object().shape({
    Irn: Yup.string().required("IRN is required"),
    TransName:Yup.string().required("Transport is required"),
});

const EwayBillForm = ({initialValues,submitCallBack}) => {
    const [productOptions, setProductOptions] = useState([]);
    const [isProductsLoading, setIsProductLoading] = useState(true);


    function HandleSubmit(url,data){
        axiosInstance.post("/api/"+url,data).then(function(response,success){
            if(response.status===200)
                submitCallBack(response.data);
        })
    }

    useEffect(() => {
        const loadSelectData =  () => {
            try{
                axiosInstance.get("/api/eway_transports?"+GetAsInputData({
                    listInfo:{
                        startRecord:0,
                        rowCount:9999,
                        sortBy: [{"field":"transport_name","order":"asc"}]
                    }
                })).then(function(response){
                    setProductOptions(response.data.eway_transports);
                    setTimeout(() => {
                        setIsProductLoading(false);
                    }, 1500);
                });
            }catch(e){
                console.log(e);
            }
        }
        loadSelectData();
    },[]);

    return( <Card>
        <Card.Header>
            <Card.Title tag="h5">Generate EwayBill</Card.Title>
        </Card.Header>
        <Card.Body>
            <Formik
                validationSchema={ewayBillSchema}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500));
                    HandleSubmit("ewaybill",GetAsInputData({ewaybill:values}))
                }}
                initialValues={initialValues}>
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                  }) => (<Form noValidate onSubmit={handleSubmit}>
                    <Row>
                        <Col sm={5}>
                            <Form.Group  className="mb-3">
                                <Form.Label>Choose Transport</Form.Label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="eway_transports"
                                    getOptionLabel={(option)=>option.transport_name}
                                    getOptionValue={(option)=>option.id}
                                    options={productOptions}
                                    isLoading={isProductsLoading}
                                    onChange={selectedOption => {
                                        let event = { target : { name:'eway_transports',value: selectedOption}}
                                        values.TransName = selectedOption.transport_name;
                                        if(selectedOption.transport_vehicle_number === null){
                                            values.TransId = selectedOption.transport_id;
                                        }else{
                                            values.TransMode = "1";
                                            values.VehType = "R";
                                            values.VehNo = selectedOption.transport_vehicle_number;
                                        }
                                        handleChange(event);
                                    }}
                                />
                                <ErrorMessage name={`eway_transports`} />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Button type="submit" variant="primary">Submit</Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>)}
            </Formik>

        </Card.Body>
    </Card>);
}

export default EwayBillForm;
