import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";

import {Alert, Button, Card, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import Select from "react-select";
import axiosInstance, {ErrorMessage, GetAsInputData} from "../../../../utils/axios";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {FieldArray, Formik} from "formik";

const productUnitType = [
    {"id":"KGS","text":"KGS"},
    {"id":"Units","text":"Units"},
    {"id":"Meters","text":"Meters"},
    {"id":"Inches","text":"Inches"},
    {"id":"Feet","text":"Feet"},
    {"id":"CMs","text":"CMs"},
    {"id":"Reams","text":"Reams"},
    {"id":"Grams","text":"Grams"},
    {"id":"Gross","text":"Gross"},
    {"id":"ML","text":"ML"},
    {"id":"Litre","text":"Litre"},
    {"id":"Sheets","text":"Sheets"},
    {"id":"Numbers","text":"Numbers"},
    {"id":"Bottles","text":"Bottles"},
    {"id":"Box","text":"Box"},
    {"id":"Rolls","text":"Rolls"},
    {"id":"Packet","text":"Packet"},
    {"id":"Pair","text":"Pair"}
];
const productAvailability = [
    {"id":"DOMESTIC","text":"Domestic"},
    {"id":"IMPORT","text":"Import"},
    {"id":"SPECIALITY","text":"Speciality"}
]
const productStructure = [
    {"id":"POWDER","text":"Powder"},
    {"id":"LIQUID","text":"Liquid"},
    {"id":"FLAKES","text":"Flakes"},
    {"id":"OIL","text":"Oil"},
    {"id":"NOODLES","text":"Noodles"},
    {"id":"CRYSTAL","text":"Crystal"},
    {"id":"CARTONS","text":"Cartons"},
    {"id":"LAMINATE_ROLLS","text":"Laminate Roll"},
    {"id":"BOXES","text":"Boxes"},
    {"id":"STATIONERY_ITEMS","text":"Stationery Items"},
    {"id":"HOUSE_KEEPING_ITEMS","text":"HouseKeeping Items"}
];

const productSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
    unit_measure:Yup.object().required("Unit measure is required"),
    code:Yup.string().required("Code is required"),
    product_gst:Yup.number().required("GST% is required"),
    type:Yup.object().required("Type is required"),
    availability:Yup.object().required("Availability is required"),
    selling_price:Yup.number().required("MRP is required"),
    box_count:Yup.number().required("Box Count is required"),
    forSale:Yup.boolean().required("Is sale?"),
    forBuy:Yup.boolean().required("Is buy?"),
    productSuppliers: Yup.array()
        .of(
            Yup.object().shape({
                supplier: Yup.object().required("Supplier is required"),
                price: Yup.number().required("Price is required"),
            })
        )
});


const ProductForm = ({initialValues,setEditData,successUrl}) => {
    const navigate = useNavigate();
    const [sellerOptions, setSellerOptions] = useState([]);
    const [isSellersLoading, setIsSellersLoading] = useState(true);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function HandlePost(url,data,navigateTo){


        axiosInstance.post("/api/"+url,data).then(function(response,success){
            if(response.status===200)
                navigate(navigateTo);
        })
    }

    function HandlePut(url,data,navigateTo){

        axiosInstance.put("/api/"+url,data).then(function(response,success){
            setEditData('');
            handleShow();
            setTimeout(handleClose,2500);
            if(response.status===200)
                navigate(navigateTo);
        })

    }

    useEffect(() => {
        const loadSelectData =  () => {
            try{
                axiosInstance.get("/api/suppliers?"+GetAsInputData({
                    listInfo:{
                        startRecord:0,
                        rowCount:9999,
                        sortBy: [{"field":"company_name","order":"asc"}]
                    }
                })).then(function(response){
                    setSellerOptions(response.data.suppliers);
                    setTimeout(() => {
                        setIsSellersLoading(false);
                    }, 1500);
                });
            }catch(e){
                console.log(e);
            }
        }
        loadSelectData();
    },[]);

    return( <Card>
        <Alert show={show} onHide={show} variant="primary" key="0" dismissible>
            <div className="alert-message">
                <strong>Purchase Order</strong> updated successfully!
            </div>
        </Alert>
        <Card.Header>
            <Card.Title tag="h5">{initialValues.id===undefined?"Add":"Modify"} Product</Card.Title>
        </Card.Header>
        <Card.Body>
            <Formik
                validationSchema={productSchema}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500));
                    values.type=values.type.id;
                    values.availability=values.availability.id;
                    values.unit_measure=values.unit_measure.id;
                    if(values.id===undefined)
                        HandlePost("products",GetAsInputData({product:values}),successUrl);
                    else
                        HandlePut("products/"+values.id,GetAsInputData({product:values}),successUrl);
                }}
                initialValues={initialValues}>
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                  }) => (<Form noValidate onSubmit={handleSubmit}>
                    <Row>
                        <Col sm={3}>
                            <Form.Group  className="mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={values.name}
                                    isValid={touched.name && !errors.name}
                                    isInvalid={touched.name && !!errors.name}
                                    onChange={handleChange}
                                    placeholder="Name" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Group  className="mb-3">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="description"
                                    value={values.description}
                                    isValid={touched.description && !errors.description}
                                    isInvalid={touched.description && !!errors.description}
                                    onChange={handleChange}
                                    placeholder="Description" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.description}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    HSN Code
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    name="code"
                                    value={values.code}
                                    isValid={touched.code && !errors.code}
                                    isInvalid={touched.code && !!errors.code}
                                    onChange={handleChange}
                                    placeholder="HSN Code" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.code}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Label>
                                GST %
                            </Form.Label>
                            <Form.Control
                                type="number"
                                name="product_gst"
                                value={values.product_gst}
                                isValid={touched.product_gst && !errors.product_gst}
                                isInvalid={touched.product_gst && !!errors.product_gst}
                                onChange={handleChange}
                                placeholder="GST %" />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.product_gst}
                            </Form.Control.Feedback>
                        </Col>
                        <Col sm={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    MRP
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="selling_price"
                                    value={values.selling_price}
                                    isValid={touched.selling_price && !errors.selling_price}
                                    isInvalid={touched.selling_price && !!errors.selling_price}
                                    onChange={handleChange}
                                    placeholder="MRP" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.selling_price}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Pieces per box
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="box_count"
                                    value={values.box_count}
                                    isValid={touched.box_count && !errors.box_count}
                                    isInvalid={touched.box_count && !!errors.box_count}
                                    onChange={handleChange}
                                    placeholder="Pieces per box" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.box_count}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Unit
                                </Form.Label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="supplier"
                                    value={values.unit_measure}
                                    getOptionLabel={(option)=>option.text}
                                    getOptionValue={(option)=>option.id}
                                    options={productUnitType}
                                    onChange={selectedOption => {
                                        let event = { target : { name:'unit_measure',value: selectedOption}}
                                        handleChange(event);
                                    }}
                                />
                                <ErrorMessage name={`unit_measure`} />
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Availability
                                </Form.Label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="availability"
                                    value={values.availability}
                                    getOptionLabel={(option)=>option.text}
                                    getOptionValue={(option)=>option.id}
                                    options={productAvailability}
                                    onChange={selectedOption => {
                                        let event = { target : { name:'availability',value: selectedOption}}
                                        handleChange(event);
                                    }}
                                />
                                <ErrorMessage name={`availability`} />
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Structure
                                </Form.Label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="type"
                                    value={values.type}
                                    getOptionLabel={(option)=>option.text}
                                    getOptionValue={(option)=>option.id}
                                    options={productStructure}
                                    onChange={selectedOption => {
                                        let event = { target : { name:'type',value: selectedOption}}
                                        handleChange(event);
                                    }}
                                />
                                <ErrorMessage name={`type`} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={1}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Is Buy
                                </Form.Label>
                                <div className="custom-controls-stacked">
                                    <Form.Check
                                        inline
                                        type="radio"
                                        id="forBuy"
                                        name="yesbuy"
                                        value={values.yesbuy}
                                        checked={values.yesbuy}
                                        label="Yes"
                                        onChange={event => {
                                            event = { target : { name:'yesbuy',value: true}}
                                            let anotherEvent = { target : { name:'forBuy',value: true}}
                                            let anotherEvent1 = { target : { name:'nobuy',value: false}}
                                            handleChange(event);
                                            handleChange(anotherEvent);
                                            handleChange(anotherEvent1);
                                        }}
                                        className="mb-2"
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        id="forBuy"
                                        name="nobuy"
                                        value={values.nobuy}
                                        checked={values.nobuy}
                                        label="No"
                                        onChange={event => {
                                            event = { target : { name:'nobuy',value: true}}
                                            let anotherEvent = { target : { name:'forBuy',value: false}}
                                            let anotherEvent1 = { target : { name:'yesbuy',value: false}}
                                            handleChange(event);
                                            handleChange(anotherEvent);
                                            handleChange(anotherEvent1);
                                        }}
                                        className="mb-2"
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                        <Col sm={1}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Is Sale
                                </Form.Label>
                                <div className="custom-controls-stacked">
                                    <Form.Check
                                        inline
                                        type="radio"
                                        id="forSale"
                                        name="yessale"
                                        value={values.yessale}
                                        checked={values.yessale}
                                        label="Yes"
                                        onChange={event => {
                                            event = { target : { name:'yessale',value: true}}
                                            let anotherEvent = { target : { name:'forSale',value: true}}
                                            let anotherEvent1 = { target : { name:'nosale',value: false}}
                                            handleChange(event);
                                            handleChange(anotherEvent);
                                            handleChange(anotherEvent1);
                                        }}
                                        className="mb-2"
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        id="forSale"
                                        name="nosale"
                                        value={values.nosale}
                                        checked={values.nosale}
                                        label="No"
                                        onChange={event => {
                                            event = { target : { name:'nosale',value: true}}
                                            let anotherEvent = { target : { name:'forSale',value: false}}
                                            let anotherEvent1 = { target : { name:'yessale',value: false}}
                                            handleChange(event);
                                            handleChange(anotherEvent);
                                            handleChange(anotherEvent1);
                                        }}
                                        className="mb-2"
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Expiry Year
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    name="expiry_year"
                                    value={values.expiry_year}
                                    isValid={touched.expiry_year && !errors.expiry_year}
                                    isInvalid={touched.expiry_year && !!errors.expiry_year}
                                    onChange={handleChange}
                                    placeholder="Expiry Year" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.expiry_year}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Card.Header><Card.Title tag="h5">Product Suppliers</Card.Title></Card.Header>
                    <FieldArray
                        name="productSuppliers"
                        render={arrayHelpers => (
                            <div>
                                {
                                    values.productSuppliers && values.productSuppliers.length > 0 ? (
                                        values.productSuppliers.map((productSupplier, index) => (
                                            <Row key={index}>
                                                <Col md={3}>
                                                    <Form.Label htmlFor="supplier">Supplier</Form.Label>
                                                    <Form.Group className="mb-3">
                                                        <Select
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            value={productSupplier.supplier}
                                                            name={`productSuppliers[${index}].supplier`}
                                                            getOptionLabel={(option)=>option.company_name}
                                                            getOptionValue={(option)=>option.id}
                                                            options={sellerOptions}
                                                            isLoading={isSellersLoading}
                                                            onChange={selectedOption => {
                                                                let event = { target : { name:`productSuppliers[${index}].supplier`,value: selectedOption}}
                                                                handleChange(event);
                                                            }}
                                                        />
                                                        <ErrorMessage name={`productSuppliers[${index}].supplier`} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Label htmlFor="description">Price</Form.Label>
                                                    <InputGroup>
                                                        <FormControl
                                                            key={`productSuppliers.${index}`}
                                                            placeholder="Price"
                                                            type="text"
                                                            value={productSupplier.price}
                                                            name={`productSuppliers[${index}].price`}
                                                            onChange={handleChange}
                                                            />
                                                        <Button onClick={() => arrayHelpers.push({
                                                            supplier: '',
                                                            price:0,
                                                        })} variant="secondary">Add More</Button>
                                                        <Button onClick={() => {
                                                            arrayHelpers.remove(index);
                                                        }} variant="secondary">Remove</Button>

                                                    </InputGroup>
                                                    <ErrorMessage name={`productSuppliers[${index}].Price`} />
                                                </Col>
                                            </Row>))):(
                                        arrayHelpers.push({
                                            supplier:'',
                                            price:'',
                                        })
                                    )}</div>
                        )}
                    />
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Button type="submit" variant="primary">Submit</Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>)}
            </Formik>

        </Card.Body>
    </Card>);
}

const ProductFormPage = () => {

    const initialValues = {
        name: '',
        description: '',
        code:'',
        product_gst:'',
        selling_price:'',
        box_count:'',
        forSale:'',
        forBuy:'',
        type:'',
        availability:'',
        unit_measure:'',
        expiry_year:'',
        products:[{
            supplier:'',
            price:'',
        }]
    };

    return(
        <React.Fragment>
            <Helmet title="New Product" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Product</h1>

                <Row>
                    <Col lg="12">
                        <ProductForm initialValues={initialValues} successUrl={"/purchases/products"} />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}

export {ProductForm}
export default ProductFormPage;
