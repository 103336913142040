import React from "react";
// import {useTranslation} from "react-i18next";
import {Button, Form, InputGroup, Nav, Navbar} from "react-bootstrap";

import {Search} from "react-feather";

import useSidebar from "../../hooks/useSidebar";
import NavbarUser from "./NavbarUser";

// import avatar1 from "../../assets/img/avatars/avatar.jpg";
// import avatar3 from "../../assets/img/avatars/avatar-3.jpg";
// import avatar4 from "../../assets/img/avatars/avatar-4.jpg";
// import avatar5 from "../../assets/img/avatars/avatar-5.jpg";
//
// const notifications = [
//   {
//     type: "important",
//     title: "Update completed",
//     description: "Restart server 12 to complete the update.",
//     time: "2h ago",
//   },
//   {
//     type: "default",
//     title: "Lorem ipsum",
//     description: "Aliquam ex eros, imperdiet vulputate hendrerit et.",
//     time: "6h ago",
//   },
//   {
//     type: "login",
//     title: "Login from 192.186.1.1",
//     description: "",
//     time: "6h ago",
//   },
//   {
//     type: "request",
//     title: "New connection",
//     description: "Anna accepted your request.",
//     time: "12h ago",
//   },
// ];
//
// const messages = [
//   {
//     name: "Ashley Briggs",
//     avatar: avatar5,
//     description: "Nam pretium turpis et arcu. Duis arcu tortor.",
//     time: "15m ago",
//   },
//   {
//     name: "Chris Wood",
//     avatar: avatar1,
//     description: "Curabitur ligula sapien euismod vitae.",
//     time: "2h ago",
//   },
//   {
//     name: "Stacie Hall",
//     avatar: avatar4,
//     description: "Pellentesque auctor neque nec urna.",
//     time: "4h ago",
//   },
//   {
//     name: "Bertha Martin",
//     avatar: avatar3,
//     description: "Aenean tellus metus, bibendum sed, posuere ac, mattis non.",
//     time: "5h ago",
//   },
// ];

const NavbarComponent = () => {
  // const { t } = useTranslation();
  const { isOpen, setIsOpen } = useSidebar();

  return (
    <Navbar variant="light" expand className="navbar-bg">
      <span
        className="sidebar-toggle d-flex"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <i className="hamburger align-self-center" />
      </span>

      <Form inline="true" className="d-none d-sm-inline-block">
        <InputGroup className="input-group-navbar">
          <Form.Control placeholder={"Search"} aria-label="Search" disabled/>
          <Button variant="">
            <Search className="feather" disabled/>
          </Button>
        </InputGroup>
      </Form>

      <Navbar.Collapse>
        <Nav className="navbar-align">
          {/*<NavbarDropdown*/}
          {/*  header="New Messages"*/}
          {/*  footer="Show all messages"*/}
          {/*  icon={MessageCircle}*/}
          {/*  count={messages.length}*/}
          {/*  showBadge*/}
          {/*>*/}
          {/*  {messages.map((item, key) => {*/}
          {/*    return (*/}
          {/*      <NavbarDropdownItem*/}
          {/*        key={key}*/}
          {/*        icon={*/}
          {/*          <img*/}
          {/*            className="avatar img-fluid rounded-circle"*/}
          {/*            src={item.avatar}*/}
          {/*            alt={item.name}*/}
          {/*          />*/}
          {/*        }*/}
          {/*        title={item.name}*/}
          {/*        description={item.description}*/}
          {/*        time={item.time}*/}
          {/*        spacing*/}
          {/*      />*/}
          {/*    );*/}
          {/*  })}*/}
          {/*</NavbarDropdown>*/}

          {/*<NavbarDropdown*/}
          {/*  header="New Notifications"*/}
          {/*  footer="Show all notifications"*/}
          {/*  icon={BellOff}*/}
          {/*  count={notifications.length}*/}
          {/*>*/}
          {/*  {notifications.map((item, key) => {*/}
          {/*    let icon = <Bell size={18} className="text-warning" />;*/}

          {/*    if (item.type === "important") {*/}
          {/*      icon = <AlertCircle size={18} className="text-danger" />;*/}
          {/*    }*/}

          {/*    if (item.type === "login") {*/}
          {/*      icon = <Home size={18} className="text-primary" />;*/}
          {/*    }*/}

          {/*    if (item.type === "request") {*/}
          {/*      icon = <UserPlus size={18} className="text-success" />;*/}
          {/*    }*/}

          {/*    return (*/}
          {/*      <NavbarDropdownItem*/}
          {/*        key={key}*/}
          {/*        icon={icon}*/}
          {/*        title={item.title}*/}
          {/*        description={item.description}*/}
          {/*        time={item.time}*/}
          {/*      />*/}
          {/*    );*/}
          {/*  })}*/}
          {/*</NavbarDropdown>*/}

          {/*{<NavbarLanguages />}*/}
          <NavbarUser />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
