import React, {useContext, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Badge, Button, Col, Container, Nav, Row, Tab, Table} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../../utils/axios";
import Loader from "../../../components/Loader";
import {ExternalLink} from "react-feather";
import NotyfContext from "../../../contexts/NotyfContext"
import EntityTable, {CustomFilter} from "../../../components/EntityTable";


const SDBeatDetails = () => {
    const params = useParams();
    const notyf = useContext(NotyfContext);
    const [beatData,setBeatData] = useState();
    const navigate = useNavigate();
    const [searchCondition,setSearchCondition] = useState();
    const [defaultSearchCondition,setDefaultSearchCondition] = useState([]);
    const [searchConditionSelectedValue1,setSearchConditionSelectedValue1] = useState();

    const regionSearchCondition = [{field: "country.id", type: "number", value: "96"}]

    const SDRetailersColumns = [
        {
            Header: "Beat",
            accessor: "beat.beat_name",
        },
        {
            Header: "SD ID",
            accessor: "retailer_sd_id",
        },
        {
            Header: "Name",
            accessor: "retailer_name",
        },
        {
            Header: "Address",
            accessor: "retailer_address",
        },
        {
            Header: "Phone",
            accessor: "retailer_phone",
        },
        {
            Header: "Mobile",
            accessor: "retailer_mobile",
        },
        {
            Header: "Email",
            accessor: "retailer_email",
        },
        {
            Header: "Pin Code",
            accessor: "retailer_pincode",
        },
        {
            Header: "Type",
            accessor: "retailer_type",
        },
        {
            Header: "Region",
            accessor: "retailer_region.region_name",
            Filter: () => {
                return CustomFilter({
                        entityUrl:"regions",
                        setCondition:setSearchCondition,
                        entityId:"id",
                        entityValue:"region_name",
                        searchField:"retailer_region.id",
                        searchType:"number",
                        entity:"regions",
                        searchCondition:regionSearchCondition,
                        selectedValue:searchConditionSelectedValue1,
                        setSelectedValue:setSearchConditionSelectedValue1
                    }
                )
            },
        },
        {
            Header: "Latitude",
            accessor: "retailer_loc_lat",
        },
        {
            Header: "Longitude",
            accessor: "retailer_loc_long",
        },
        {
            Header: "GST",
            accessor: "retailer_gst",
        },
        {
            Header: "Firestore",
            accessor: "retailer_firestore_id",
            Filter:false,
            Cell: ({ row }) => {
                const [retailerFirestoreID,setRetailerFirestoreID] = useState(row.original.retailer_firestore_id);
                if(retailerFirestoreID==null){
                    if(beatData.beat_firestore_id==null){
                        return(<>Sync Beat first</>)
                    }
                    return(<Button name={'retailerSync'} onClick={() => {
                        axiosInstance.get("/api/sd_retailers/"+row.original.retailer_id+"/sync").then(function(response){
                            setRetailerFirestoreID(response.data.sd_retailers.retailer_firestore_id)
                            notyf.open({
                                type:"success",
                                message: "Synced "+response.data.sd_retailers.retailer_name+" with ID "+response.data.sd_retailers.retailer_firestore_id,
                                duration:"5000",
                                ripple:true,
                                dismissible:false,
                                position: {
                                    x: "center",
                                    y: "top",
                                },
                            })

                        })
                    }}> Sync to server </Button>)
                }
                return(
                    <>{retailerFirestoreID}</>
                );
            },
        },
    ];

    let condition = defaultSearchCondition;
    searchCondition!=null && condition.push(searchCondition)
    if(condition.length==0)
        condition = null

    function beatSync(){
        axiosInstance.get("/api/sd_beats/"+beatData.beat_id+"/sync")
            .then(function(response){

                setTimeout(() => {
                    setBeatData(response.data.sd_beats);

                }, 1500);
            })
    }

    function syncRetailer(button){
        if(button!=undefined){
            button.focus()
            button.click();
        }

    }

    useEffect(() => {
        const load =  () => {
            try{
                let entityURL = "/api/sd_beats/"+params.beatId;
                axiosInstance.get(entityURL)
                    .then(function(response){

                        setTimeout(() => {
                            setBeatData(response.data.sd_beats);
                            setDefaultSearchCondition([{field: "beat.beat_id", type: "number", value: ""+response.data.sd_beats.beat_id}])
                        }, 1500);
                    })
            }catch(e){
                console.log(e);
            }
        }

        load();
    },[]);

    if(beatData === undefined){
        return <Loader />;
    }else{
        return(

            <Container fluid className="p-0">
                <h1 className="h3 mb-3">{beatData.beat_name}</h1>
                <Row>
                    <Col lg="12">
                        <div className={"tab " }>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Nav variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Details</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Retailers</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <h4 className="tab-title">Contact Info</h4>
                                            <Col lg="6">
                                                <Table size="sm" className="my-2">
                                                    <tbody>
                                                    <tr>
                                                        <th>Name</th>
                                                        <td>{beatData.beat_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Region</th>
                                                        {beatData.beat_region!=null && <td>{beatData.beat_region.region_name}</td>}
                                                        {beatData.beat_region==null && <td></td>}
                                                    </tr>
                                                    <tr>
                                                        <th>Status</th>
                                                        <td>
                                                            {beatData.beat_firestore_id!=null && <Badge bg="success">Active</Badge>}
                                                            {beatData.beat_firestore_id==null && <Badge bg="warning">Not yet synced with server</Badge>}
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>
                                            <br/>
                                            <h4 className="tab-title">Counts</h4>
                                            <Col lg="2">
                                            <Table size="sm" className="my-2">
                                                <tbody>

                                                    <tr>
                                                        <th>Chemist </th>
                                                        <td>{beatData.chemistCount}</td>
                                                    </tr>

                                                    <tr>
                                                        <th>Cosmetic </th>
                                                        <td>{beatData.cosmeticsCount}</td>
                                                    </tr>

                                                    <tr>
                                                        <th>Fancy </th>
                                                        <td>{beatData.fancyCount}</td>
                                                    </tr>

                                                    <tr>
                                                        <th>GM </th>
                                                        <td>{beatData.gmcount}</td>
                                                    </tr>

                                                    <tr>
                                                        <th>Hypermarket </th>
                                                        <td>{beatData.hypermarketCount}</td>
                                                    </tr>

                                                    <tr>
                                                        <th>Kirana </th>
                                                        <td>{beatData.kiranaCount}</td>
                                                    </tr>

                                                    <tr>
                                                        <th>MT </th>
                                                        <td>{beatData.mtcount}</td>
                                                    </tr>

                                                    <tr>
                                                        <th>Others </th>
                                                        <td>{beatData.othersCount}</td>
                                                    </tr>

                                                    <tr>
                                                        <th>Supermarket </th>
                                                        <td>{beatData.superMarketCount}</td>
                                                    </tr>
                                                    <br/>
                                                    <tr><th/><td/></tr>
                                                    <tr>
                                                        <th>Total</th>
                                                        <td>{beatData.retailer_count}</td>
                                                    </tr>

                                                </tbody>
                                            </Table>
                                            </Col>
                                            <br/>

                                        {beatData.distributor!=null && <><h4 className="tab-title">Update</h4><Col>
                                            {beatData.beat_firestore_id==null  && beatData.distributor?.distributor_firestore_id!=null  && <Button  className=" me-1 mb-1" onClick={beatSync} variant={'info'}>Sync to server</Button>}

                                            {beatData.beat_firestore_id==null && beatData.distributor.distributor_firestore_id==null && <Button  className=" me-1 mb-1" onClick={() => {
                                                navigate("/sd/distributors-details/"+beatData.distributor.distributor_id)
                                            }} variant={'warning'}>Sync Distributor first - Goto Distributor <ExternalLink  size={14} className="align-middle" /></Button>}


                                            {beatData.beat_firestore_id!=null && <><Button  className=" me-1 mb-1" variant={'info'}>Update data to server</Button></>}

                                            <Button  className=" me-1 mb-1" variant={'info'} onClick={() => {
                                                axiosInstance.get("/api/sd_beats/"+beatData.beat_id+"/sync/count").then(function(response){
                                                    setBeatData(response.data.sd_beats);
                                                });
                                            }}>Sync count</Button>

                                            {beatData.beat_region==null && <Button  className=" me-1 mb-1" onClick={() => {
                                                axiosInstance.get("/api/sd_beats/"+beatData.beat_id+"/sync/region")
                                            }}> Sync distributor region </Button>}

                                        </Col></>}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <Button onClick={() => {
                                            var buttonList = document.getElementsByName('retailerSync');
                                            let i=0;
                                            let length = buttonList.length;
                                            let intervalID;
                                            if(length!=0){
                                                intervalID = setInterval(() => {
                                                    syncRetailer(buttonList[i]);
                                                    if(buttonList.length==1)
                                                        clearInterval(intervalID);
                                                }, 2000);

                                            }
                                        }}>Sync All Retailers</Button>
                                        <EntityTable
                                            key={new Date().getTime()}
                                            columns={SDRetailersColumns}
                                            url={"sd_retailers"}
                                            pageSize={150}
                                            sortBy={[{field: "retailer_name", order: "asc"}]}
                                            buttons={[]}
                                            searchCondition={condition}
                                        />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </Col>
                </Row>
            </Container>
            );
    }


};

const SDBeatDetailsPage = () => (

    <React.Fragment>
        <Helmet title="SD Beat Details" />
        <SDBeatDetails />
    </React.Fragment>
);

export default SDBeatDetailsPage;
