import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";

import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import * as Yup from "yup";
import axiosInstance, {ErrorMessage, GetAsInputData} from "../../../utils/axios";
import Select from "react-select";
import {Formik} from "formik";
import {AutomaticInvoiceForm} from "./AutomaticInvoiceForm";


const automaticSchema = Yup.object().shape({
    dealer: Yup.object().required("Dealer is required"),
    invoice_number: Yup.string().required("Invoice Number is required").min(13,"Minimum length is 13").max(13,"Max length is 13")
});
const VIMACAutomaticInvoiceForm = ({initialValues,setData}) => {

    const [customerOptions, setCustomerOptions] = useState([]);
    const [isCustomersLoading, setIsCustomersLoading] = useState(true);

    useEffect(() => {

        axiosInstance.get("/api/customers?"+GetAsInputData({
            listInfo:{
                startRecord:0,
                rowCount:9999,
                sortBy: [{"field":"dealer_name","order":"asc"}],
                searchConditions: [
                    {"field":"type","enumName":"DealerType","type":"enum","value":"RETAILER","condition":"not_equals"},
                    {"field":"type","enumName":"DealerType","type":"enum","value":"NON_BILLING","condition":"not_equals"}
                ]
            }
        })).then(function(response){
            setCustomerOptions(response.data.customers);
            setTimeout(() => {
                setIsCustomersLoading(false);
            }, 1500);
        });

    },[]);

    function returnProductSplit(response) {
        const templateProducts = [];
        const dealerProducts = response.data.dealer_products;

        const createOfferProduct = (id, name, box_count) => ({
            id,
            name,
            box_count,
            basic_rate: 0,
            margin_rate: 0,
            product_gst: 0
        });

        response.data.invoice.forEach(product => {
            const dealerProduct = dealerProducts.find(
                dealerProd => dealerProd.product.name === product.name
            );

            const baseProduct = {
                batch_number_custom: product.batch,
                boxes: product.boxes,
                product: {
                    ...{ basic_rate: dealerProduct.basic_rate },
                    ...dealerProduct.product
                },
                quantity: Number(dealerProduct.product.box_count) * Number(product.boxes),
                received_quantity: Number(dealerProduct.product.box_count) * Number(product.boxes)
            };
            templateProducts.push(baseProduct);

            let offerProduct = null;
            switch (product.name) {
                case "VIP Natural Hair Colour Shampoo - Men Pack - 20ml Black":
                    offerProduct = createOfferProduct("342", "VIP Natural Hair Colour Shampoo - Men Pack - 20ml Black Offer", 20);
                    break;
                case "VIP Natural Hair Colour Shampoo - Men Pack - 20ml Brown":
                    offerProduct = createOfferProduct("363", "VIP Natural Hair Colour Shampoo - Men Pack - 20ml Brown Offer", 20);
                    break;
                case "Express Hair Colour Shampoo - Men Pack - 20ml Black":
                    offerProduct = createOfferProduct("434", "Express Hair Colour Shampoo - Men Pack - 20ml Black Offer", 20);
                    break;
                case "VIP Natural Hair Colour Shampoo - Women Pack - 40ml Black":
                    offerProduct = createOfferProduct("368", "VIP Natural Hair Colour Shampoo - Women Pack - 40ml Black Offer", 12);
                    break;
                case "VIP Natural Hair Colour Shampoo - Family Pack - 180ml Black":
                    offerProduct = createOfferProduct("374", "VIP Natural Hair Colour Shampoo - Family Pack - 180ml Black Offer", 2);
                    break;
                case "VIP Natural Hair Colour Shampoo - Family Pack - 180ml Brown":
                    offerProduct = createOfferProduct("387", "VIP Natural Hair Colour Shampoo - Family Pack - 180ml Brown Offer", 2);
                    break;
                default:
                    offerProduct = null;
            }

            if (offerProduct) {
                const offerProductEntry = {
                    ...baseProduct,
                    product: offerProduct,
                    quantity: Number(offerProduct.box_count) * Number(product.boxes),
                    received_quantity: Number(offerProduct.box_count) * Number(product.boxes)
                };
                templateProducts.push(offerProductEntry);
            }
        });

        return templateProducts;
    }
    function HandleSubmit(values){

        axiosInstance.get("/api/buyhappy_sales_invoices/getVimacInvoice/" + values.invoice_number + "/" + values.dealer.dealer_id)
        .then(response => {
            var pidate = new Date(Number(response.data.pi_created_date));
            var piday = ("0" + pidate.getDate()).slice(-2);
            var pimonth = ("0" + (pidate.getMonth() + 1)).slice(-2);
            var pitoday = pidate.getFullYear()+"-"+(pimonth)+"-"+(piday)


            var sidate = new Date(Number(response.data.supplier_invoice_date));
            var siday = ("0" + sidate.getDate()).slice(-2);
            var simonth = ("0" + (sidate.getMonth() + 1)).slice(-2);
            var sitoday = sidate.getFullYear()+"-"+(simonth)+"-"+(siday)

            const initialValues = {
                supplier: {"id":68,"company_name":"VIMAC"},
                warehouse: {"warehouse_id":1,"warehouse_name":"Chennai"},
                invoice_type:"BUY_HAPPY_SALES_DEALERS_INVOICE",
                pi_created_date:response.data.pi_created_date,
                pi_date_field:pitoday,
                pi_number:values.invoice_number,
                supplier_invoice_number:values.invoice_number,
                supplier_invoice_date_field:sitoday,
                supplier_invoice_date:response.data.supplier_invoice_date,
                dealer:values.dealer,
                invoice_date: '',
                dealerAddresses:undefined,
                mode_of_transport: '',
                products:returnProductSplit(response)
            };


            setData(initialValues);
        });
    }

    return(
        <Card>
            <Card.Body>
                <Formik
                    validationSchema={automaticSchema}
                    onSubmit={async (values) => {

                        HandleSubmit(values);

                    }}
                    initialValues={initialValues}>
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                      }) => (<Form noValidate onSubmit={handleSubmit}>
                        <Card.Header>
                            <Card.Title tag="h5">Load VIMAC Invoice details</Card.Title>
                        </Card.Header>
                        <Row>
                            <Col sm={4}>
                                <Form.Group  className="mb-3">
                                    <Form.Label>Trader Name</Form.Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        name="dealer"
                                        value={values.dealer}
                                        getOptionLabel={(option)=>option.dealer_name}
                                        getOptionValue={(option)=>option.dealer_id}
                                        options={customerOptions}
                                        isLoading={isCustomersLoading}
                                        onChange={selectedOption => {
                                            let event = { target : {
                                                    name:'dealer',
                                                    value: {
                                                        dealer_id:selectedOption.dealer_id,
                                                        dealer_name:selectedOption.dealer_name
                                                    }
                                                }}
                                            handleChange(event);
                                        }}
                                    />
                                    <ErrorMessage name={`dealer`} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Form.Label>
                                    Invoice Number
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="invoice_number"
                                    isValid={touched.invoice_number && !errors.invoice_number}
                                    isInvalid={touched.invoice_number && !!errors.invoice_number}
                                    onChange={handleChange}
                                    placeholder="Invoice number" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.invoice_number}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Button type="submit" variant="primary">Submit</Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>)}
                </Formik>

            </Card.Body>
        </Card>
    );
}

const VIMACAutomaticInvoiceFormPage = () => {

    const [templateData,setTemplateData] = useState(null);
    const initialValues = {
        dealer:undefined,
        invoice_number: '',
    };

    if(templateData==null){
        return ( <React.Fragment>
            <Helmet title="Automatic Dealer Invoicing" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">VIMAC Automatic Invoice</h1>
                <Row>
                    <Col lg="12">
                        <VIMACAutomaticInvoiceForm initialValues={initialValues} setData={setTemplateData}/>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>);
    }else{
        return ( <React.Fragment>
            <Helmet title="Automatic Dealer Invoicing" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Automatic Invoice</h1>
                <Row>
                    <Col lg="12">
                        <AutomaticInvoiceForm initialValues={templateData} disableEdit={true}/>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>);
    }
};

export default VIMACAutomaticInvoiceFormPage;
