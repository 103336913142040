import React, { useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Badge, Button, ButtonGroup, Container,  Modal} from "react-bootstrap";
import EntityTable, { RowSpanCell} from "../../../../components/EntityTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../../utils/axios";
import ReactDOM from "react-dom";

/**
 * Dealer ListView Table used in details page
 * @param dealerId
 * @returns {JSX.Element}
 * @constructor
 */

const ClaimsDetailsDealerPageListView = ({key,setKey,dealer}) => {

    let dealerId = dealer.dealer_id;

    function approveClaim(claimId){
        axiosInstance("/api/claims_tracker/"+claimId+"/approve").then(() => {
            setKey(new Date().getTime());
        });
    }

    function rejectClaim(claimId){
        axiosInstance("/api/claims_tracker/"+claimId+"/reject").then(() => {
            setKey(new Date().getTime());
        });
    }

    function dataCallBackFn(claims){


        let totalMonthBillValue = {};
        let totalMonthClaimValue = {};
        let totalMonthClaimPayableValue = {};

        claims.map((claim) => {
            const dateObj = new Date(Number(claim.invoiceDate));
            const year = dateObj.getFullYear();
            const month = dateObj.getMonth();
            const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const monthAbbr = monthNames[month];
            claim.formattedInvoiceDate = `${year}-${monthAbbr}`;
            let invoiceValue = 0;
            claim.claims.map((claimProduct) => {
                invoiceValue+= claimProduct.productTotal;
            });
            claim.invoiceValue = invoiceValue;

            if(totalMonthBillValue[claim.formattedInvoiceDate]===undefined){
                totalMonthBillValue[claim.formattedInvoiceDate] = 0;
                totalMonthClaimValue[claim.formattedInvoiceDate] = 0;
                totalMonthClaimPayableValue[claim.formattedInvoiceDate] = 0;
            }

            totalMonthBillValue[claim.formattedInvoiceDate]+=invoiceValue;
            totalMonthClaimValue[claim.formattedInvoiceDate]+=claim.totalAmountClaim;
            totalMonthClaimPayableValue[claim.formattedInvoiceDate]+=claim.totalAmountPayable;
        });

        let claimInvoiceTotal = 0;
        let claimDistributorTotal = 0;
        let claimBHTotal = 0;

        for (let i = 0; i < claims.length - 1; i++) {
            claimInvoiceTotal += claims[i].invoiceValue;
            claimDistributorTotal += claims[i].totalAmountClaim;
            claimBHTotal += claims[i].totalAmountPayable;

            if (claims[i].retailer.dealer_name !== claims[i + 1].retailer.dealer_name || claims[i].formattedInvoiceDate !== claims[i + 1].formattedInvoiceDate) {

                let claimTotal = {
                    approvalStatus: "TOTAL",
                    invoiceNumber: "TOTAL",
                    invoiceValue: claimInvoiceTotal,
                    totalAmountPayable: claimBHTotal,
                    totalAmountClaim: claimDistributorTotal,
                    retailer: {...claims[i].retailer,retailer_percentage:"",dealer_name: claims[i].retailer.dealer_name+" Total ",gst:""},
                    formattedInvoiceDate:claims[i].formattedInvoiceDate

                };

                claims.splice(i + 1, 0, claimTotal);

                // Reset the totals
                claimInvoiceTotal = 0;
                claimDistributorTotal = 0;
                claimBHTotal = 0;

                i++;
            }
        }

        claimInvoiceTotal += claims[claims.length - 1].invoiceValue;
        claimDistributorTotal += claims[claims.length - 1].totalAmountClaim;
        claimBHTotal += claims[claims.length - 1].totalAmountPayable;

        let claimTotal = {
            approvalStatus: "TOTAL",
            invoiceNumber: "TOTAL",
            invoiceValue: claimInvoiceTotal,
            totalAmountPayable: claimBHTotal,
            totalAmountClaim: claimDistributorTotal,
            retailer: {...claims[claims.length - 1].retailer,retailer_percentage:"",dealer_name: claims[claims.length - 1].retailer.dealer_name+" Total ",gst:""},
            formattedInvoiceDate:claims[claims.length - 1].formattedInvoiceDate
        };

        claims.push(claimTotal);

        Object.keys(totalMonthBillValue).forEach((key) => {
            claims.push({
                approvalStatus: "MONTH TOTAL",
                invoiceNumber: "MONTH TOTAL",
                invoiceValue: totalMonthBillValue[key],
                totalAmountPayable: totalMonthClaimPayableValue[key],
                totalAmountClaim: totalMonthClaimValue[key],
                retailer: {retailer_percentage:""},
                formattedInvoiceDate:key
            });
        });
        return claims;
    }

    const claimsTableColumns = [
        {
            Header: "Name",
            accessor: "retailer.dealer_name",
            Cell: ({ cell, row,data }) => {
                if(cell.value===undefined || cell.value===""){
                    return <td></td>
                }

                return <RowSpanCell
                    value={cell.value}
                    rowIndex={row.index}
                    accessor={"retailer.dealer_name"}
                    data={data}
                    cell={cell}
                />
            },
            dontRenderTag:true
        },
        {
            Header: "GST Number",
            accessor: "retailer.gst",
            Cell: ({ cell, row,data }) => {
                if(cell.value===undefined)
                    return <td></td>
                return <RowSpanCell
                    value={cell.value}
                    rowIndex={row.index}
                    accessor={cell.column.id}
                    data={data}
                    cell={cell}
                />
            },
            dontRenderTag:true
        },
        {
            Header: "Invoice Date",
            accessor: "formattedInvoiceDate",
            Cell: ({ cell, row,data }) => {
                return <RowSpanCell
                    value={cell.value}
                    rowIndex={row.index}
                    accessor={"retailer.dealer_name"}
                    nestedAccessor={"formattedInvoiceDate"}
                    data={data}
                    cell={cell}
                />
            },
            dontRenderTag:true
        },
        {
            Header: "Margin%",
            accessor: "retailer.retailer_percentage",

        },
        {
            Header: "Type",
            accessor: "claimType",
            Filter: false,
        },
        {
            Header: "Invoice Number",
            accessor: "invoiceNumber",
            Cell: ({ cell: { value },row:{ original } }) => {
                if(original.invoiceNumber === "TOTAL" || original.invoiceNumber === "MONTH TOTAL")
                    return <td><div style={{color:"red"}}>{value}</div></td>
                return <td><a target="_blank" href={"/claims/claim-details/"+dealerId+"/"+original.claim_id}>{value}</a></td>;
            },
            dontRenderTag:true
        },
        {
            Header: "Invoice Value",
            accessor: "invoiceValue",
            dontRenderTag: true,
            Cell: ({ cell: { value },row:{ original } }) => {
                return (
                    <td
                        style={{
                            textAlign: "center",
                            color: (original.invoiceNumber === "TOTAL" || original.invoiceNumber === "MONTH TOTAL") ? "red" : "",
                        }}
                    >
                        {value.toLocaleString("en-IN", {
                            style: "currency",
                            currency: "INR",
                        })}
                    </td>
                );
            },
            Filter:false
        },
        {
            Header: "Distributor Claim",
            accessor: "totalAmountClaim",
            Cell: ({ cell: { value },row:{ original } }) => {
                return (
                    <div
                        style={{
                            textAlign: "center",
                            color: (original.invoiceNumber === "TOTAL" || original.invoiceNumber === "MONTH TOTAL") ? "red" : "",
                        }}
                    >
                        {(value?.toLocaleString("en-IN", {
                            style: "currency",
                            currency: "INR",
                        })) || 'N/A'
                        }
                    </div>
                );
            },
            Filter:false
        },
        {
            Header: "BH Claim - Without Tax",
            accessor: "totalAmountPayable",
            Cell: ({ cell: { value },row:{ original } }) => {
                return (
                    <div
                        style={{
                            textAlign: "center",
                            color: (original.invoiceNumber === "TOTAL" || original.invoiceNumber === "MONTH TOTAL") ? "red" : "",
                        }}
                    >
                        {value.toLocaleString("en-IN", {
                            style: "currency",
                            currency: "INR",
                        })}
                    </div>
                );
            },
            Filter:false
        },
        {
            Header: "Status",
            accessor: "approvalStatus",
            Cell: ({ cell: { value },row:{ original } }) => {
                if(value==="TRUE"){
                    return(<Badge bg="success" >
                        <FontAwesomeIcon icon={faCheck} /> Approved
                    </Badge>);
                }else if (value === "TOTAL") {
                    return (
                        <td style={{ textAlign: "center" }}>
                            <ButtonGroup>
                                <Button
                                    onClick={() => {
                                        renderApproveAllClaims(
                                            dealer.dealer_id,
                                            original.retailer.dealer_id,
                                            original.formattedInvoiceDate,
                                            setKey
                                        );
                                    }}
                                    variant={"warning"}
                                >
                                    Approve All
                                </Button>
                                <Button disabled={true} variant={"danger"}>
                                    Reject All
                                </Button>
                            </ButtonGroup>
                        </td>
                    );
                }
                else if(value==="TOTAL"){
                    return(<td style={{textAlign:"center"}}> <ButtonGroup ><Button onClick={() => {
                        renderApproveAllClaims(original.retailer.dealer_id,0,1);
                    }} variant={"warning"}> Approve All</Button><Button disabled={true} variant={"danger"}>Reject All</Button></ButtonGroup></td>);
                }else if(value==="MONTH TOTAL"){
                    return(<td></td>);
                }else{
                    return(<td style={{textAlign:"center"}}> <ButtonGroup><Button onClick={() => approveClaim(original.claim_id)} variant={"success"}> Approve </Button><Button onClick={() => rejectClaim(original.claim_id)} variant={"outline-danger"}>Reject</Button></ButtonGroup></td>);
                }

            },
            Filter:false,
            dontRenderTag: true
        }
    ];

    let condition = [];
    // condition.push({field:"claimType",value:"CLAIM",type:"enum",enumName:"ClaimEnums"})
    condition.push({
        field:"dealer.dealer_id",
        value:dealerId,
        type:"number"
    });
    condition.push({
        field:"approvalStatus",
        value:"WAITING",
        type:"enum",
        enumName:"BooleanStatus"
    });

    return(<React.Fragment>
        <Helmet title="Claims"/>
        <div id={"modalRender"}></div>
        <Container fluid className="p-0">
            <EntityTable
                dataCallBackFn={(claims) => dataCallBackFn(claims)}
                key={key}
                columns={claimsTableColumns}
                url={"claims_tracker"}
                sortBy={[{field: "retailer.dealer_name", order: "asc"},{field: "invoiceDate", order: "asc"}]}
                buttons={[]}
                pageSize={500}
                searchCondition={condition}
                tableProps={() => ({
                    style:{
                        textAlign:"center"
                    },
                    className:"table-sm"
                })}
                rowProps={row => ({
                    style: {
                        cursor: "pointer",
                    },
                    className:row.original.dealer===undefined?"table-primary":""
                })}
            />
        </Container>
    </React.Fragment>);

};

const renderApproveAllClaims = (dealerId,retailerId,invoiceDate,setKey) => {
    const modalRoot = document.getElementById('modalRender');
    ReactDOM.unmountComponentAtNode(modalRoot); // Unmount any previous components

    ReactDOM.render(
        <ApproveAllClaims dealerId={dealerId} retailerId={retailerId} invoiceDate={invoiceDate} setKey={setKey}/>,
        modalRoot
    );
};

const ApproveAllClaims = ({ dealerId, retailerId, invoiceDate,setKey }) => {
    const [show, setShow] = useState(true);
    const [data, setData] = useState([]);
    const [claimIds, setClaimIds] = useState([]);
    const buttonRef = useRef(null);
    const handleClose = () => setShow(null);

    useEffect(() => {
        try {
            let entityURL = "/api/claims_tracker/dealer/" + dealerId + "/retailer/" + retailerId + "/invoiceDate/" + invoiceDate;
            axiosInstance.get(entityURL)
                .then(function (response) {
                    let responseData = response.data.claims_tracker;

                    let filteredData = responseData.filter(claim => claim.approvalStatus === 'WAITING');
                    let claimIds = filteredData.map(claim => claim.claim_id);
                    setClaimIds(claimIds);
                    setData(responseData);
                });

        } catch (e) {
            console.log(e);
        }
    }, []);

    const claimsTableColumns = [
        {
            Header: "Invoice Number",
            accessor: "invoiceNumber",
            Filter: false,
        },
        {
            Header: "Invoice Date",
            accessor: "invoiceDate",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                let date = new Date(Number(value));
                date = date.toString().slice(3, 16)
                return date;
            }
        },
        {
            Header: "Total Claim",
            accessor: "totalAmountClaim",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                if (value == null)
                    return Number(0).toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR"
                    });
                return value.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR"
                });
            },
        },
        {
            Header: "Total Amount Payable",
            accessor: "totalAmountPayable",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                return value.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR"
                });
            },
        },
        {
            Header: "Total Amount Paid",
            accessor: "totalAmountPaid",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                if (value == null)
                    return Number(0).toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR"
                    });
                return value.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR"
                });
            },
        },
        {
            Header: "Created Date",
            accessor: "createdDate",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                let date = new Date(Number(value));
                date = date.toString().slice(3, 21)
                return date;
            }

        },
        {
            Header: "Created",
            accessor: "createdBy.name",
            Filter: false,
        },
        {
            Header: "Type",
            accessor: "claimType",
            Filter: false,
        },
        {
            Header: "Status",
            accessor: "approvalStatus",
            Filter: false,
        },
        {
            Header: "A/R",
            accessor: "approvalBy.name",
            Filter: false,
            Cell: ({ value }) => {

                if (value != null) {
                    return (value);
                }
                return "N/A"
            },
        },
    ];

    const approveClaims = async (claimIds) => {
        for (const claimId of claimIds) {
            try {
                await axiosInstance("/api/claims_tracker/" + claimId + "/approve");
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} size={"xl"}>
                <Modal.Header closeButton>
                    <Modal.Title>Approve Claims - {data[0]?.retailer.dealer_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EntityTable
                        key={new Date().getTime()}
                        columns={claimsTableColumns}
                        preFetchedData={data}
                        sortBy={[{ field: "claim_id", order: "desc" }]}
                        buttons={[]}
                        searchCondition={[]}
                        tableProps={() => ({
                            style: {
                                textAlign: "center",
                            },
                            className: "table-sm",
                        })}
                        rowProps={(row) => ({
                            onClick: () => {
                              window.open("/claims/claim-details/"+row.original.dealer.dealer_id+"/"+row.original.claim_id, "_blank");
                            },
                            style: {
                                cursor: "pointer",
                            },
                            className: row.original.approvalStatus === "WAITING" ? "table-primary" : row.original.approvalStatus === "TRUE"?"table-success":"table-danger",
                        })}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button ref={buttonRef} variant="primary" onClick={() => {
                        buttonRef.current.disabled = true;
                        approveClaims(claimIds).then(() => {
                            handleClose();
                            setKey(new Date().getTime());
                        });
                    }}>
                        Approve Claims
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ClaimsDetailsDealerPageListView;