import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Badge, Col, Container, Nav, Row, Tab, Table} from "react-bootstrap";
import {useParams} from "react-router-dom";
import axiosInstance, {GetAsInputData} from "../../../../utils/axios";
import Loader from "../../../../components/Loader";
import {RetailerForm} from "./RetailerForm";


const RetailerDetails = () => {
    const params = useParams();
    const [dealerData,setDealerData] = useState();
    const [dealerAddressData,setDealerAddressData] = useState([]);

    useEffect(() => {
        const load =  () => {
            try{
                let entityURL = "/api/dealers/"+params.retailerID;
                axiosInstance.get(entityURL)
                    .then(function(response){

                        setTimeout(() => {
                            setDealerData(response.data.dealer);
                        }, 1500);
                    })
            }catch(e){
                console.log(e);
            }

            try{
                let entityURL = "/api/dealer_address";

                const input_data = {
                    listInfo:{
                        startRecord:0,
                        rowCount:9999,
                        sortBy:[{"field":"dealer.dealer_name","order":"asc"}],
                        searchConditions:[{"field":"dealer.dealer_id","value":""+params.retailerID,"type":"number"}]
                    }
                };


                let encodedData = GetAsInputData(input_data);

                axiosInstance.get(entityURL+"?"+encodedData)
                    .then(function(response){

                        setTimeout(() => {
                            setDealerAddressData(response.data.dealer_address);
                        }, 1500);
                    })
            }catch(e){
                console.log(e);
            }
        }

        load();
    },[]);

    if(dealerData ===undefined){
        return <Loader />;
    }else{
        return(

            <Container fluid className="p-0">
                <h1 className="h3 mb-3">{dealerData.dealer_name}</h1>
                <Row>
                    <Col lg="12">
                        <div className={"tab " }>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Nav variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Details</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">Modify Retailer</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <h4 className="tab-title">Contact Info</h4>
                                            <Col lg="6">
                                                <Table size="sm" className="my-2">
                                                    <tbody>
                                                    <tr>
                                                        <th>Name</th>
                                                        <td>{dealerData.dealer_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Contact Name</th>
                                                        <td>{dealerData.contact_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Phone Number</th>
                                                        <td>{dealerData.phone_number}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Email</th>
                                                        <td>{dealerData.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>GST</th>
                                                        <td>{dealerData.gst}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Status</th>
                                                        <td>
                                                            <Badge bg="success">Active</Badge>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        <br/>
                                        <h4 className="tab-title">Billing Address</h4>
                                        <Col lg="6">
                                            <p>
                                                {dealerData.address} {dealerData.sub_address}
                                            </p>
                                        </Col>
                                        <br/>
                                        <h4 className="tab-title">Shipping Address </h4>
                                        <Col lg="6">
                                            <Table size="sm" className="my-2">
                                                {dealerAddressData.length>0?dealerAddressData.map( (row, i) => {
                                                    return(
                                                        <tbody>
                                                            <tr>
                                                                <th>Name</th>
                                                                <td>{row.dealer_contact_name}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Number</th>
                                                                <td>{row.dealer_contact_number}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Address</th>
                                                                <td>{row.dealer_address}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>GST</th>
                                                                <td>{row.dealer_address_gst}</td>
                                                            </tr>
                                                            <br/>
                                                        </tbody>
                                                    );
                                                }):("Same as billing address")}
                                            </Table>
                                        </Col>
                                        <br/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <RetailerForm initialValues={dealerData} setDealerData={setDealerData} successUrl={"/customers/active/retailers-details/"+dealerData.dealer_id}/>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </Col>
                </Row>
            </Container>
            );
    }


};

const RetailerDetailsPage = () => (

    <React.Fragment>
        <Helmet title="Retailer Details" />
        <RetailerDetails />
    </React.Fragment>
);

export default RetailerDetailsPage;
