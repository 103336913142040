import React from "react";
import {matchPath} from "react-router-dom";

import SidebarNavListItem from "./SidebarNavListItem";
import SidebarNavList from "./SidebarNavList";

const reduceChildRoutes = (props) => {
    const {items, page, depth, currentRoute, user} = props;

    if (page.children) {
        const open = page.href
            ? !!matchPath(
                {
                    path: page.href,
                    end: false,
                },
                currentRoute
            )
            : false;

        let hasLeastOneRole = false;
        let roles = [];

        for (let index = 0; index < page.children.length; ++index) {
            const childItem = page.children[index];
            if (childItem.roles !== undefined && childItem.roles.length != 0) {
                let isApproved = true;
                childItem.roles.forEach(function (role) {
                    if (user.roles.indexOf(role) == -1) {
                        isApproved = false;
                    }
                    roles.push(role);
                });
                if (isApproved) hasLeastOneRole = true;
            }
            if (hasLeastOneRole) break;
        }
        if (hasLeastOneRole || roles.length == 0)
            items.push(
                <SidebarNavListItem
                    depth={depth}
                    icon={page.icon}
                    key={page.title}
                    badge={page.badge}
                    open={!!open}
                    title={page.title}
                    href={page.href}
                >
                    <SidebarNavList depth={depth + 1} pages={page.children}/>
                </SidebarNavListItem>
            );
    } else {
        if (page.roles === undefined) {
            items.push(
                <SidebarNavListItem
                    depth={depth}
                    href={page.href}
                    icon={page.icon}
                    key={page.title}
                    badge={page.badge}
                    title={page.title}
                />
            );
        } else {
            let isApproved = true;
            page.roles.forEach(function (role) {
                if (user.roles.indexOf(role) == -1) {
                    isApproved = false;
                }
            });
            if (isApproved)
                items.push(
                    <SidebarNavListItem
                        depth={depth}
                        href={page.href}
                        icon={page.icon}
                        key={page.title}
                        badge={page.badge}
                        title={page.title}
                    />
                );
        }
    }
    return items;
};

export default reduceChildRoutes;
