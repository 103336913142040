import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import {Col, Container, Row} from "react-bootstrap";
import * as Icon from "react-feather";
import useAuth from "../../../../hooks/useAuth";
import DesignationForm from "./DesignationForm";
import {roles} from "../../../../utils/roles";
import EntityTable from "../../../../components/EntityTable";

const DesignationTable = () => {
  const [key, setKey] = useState(new Date().getTime());
  const [editData, setEditData] = useState();
  const columns = [
    {
      Header: "Designation",
      accessor: "name",
    },
    {
      Header: "Action",
      accessor: "id",
      Filter: false,
      Cell: ({ row }) => {
        const userEdit = useAuth().user.roles.indexOf(
          roles.ROLE_CREATE_MODIFY_ADMIN_CONFIG
        ) != -1 && (
          <Icon.Edit2
            onClick={() => {
              setEditData(row.original);
              setKey(new Date().getTime());
            }}
          />
        );

        return <div style={{ cursor: "pointer" }}> {userEdit} </div>;
      },
    },
  ];

  return (
    <React.Fragment>
      <Helmet title="Designation" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Designation</h1>
        <Row>
          <Col>
            <EntityTable
              key={key}
              columns={columns}
              url={"designations"}
              pageSize={99999}
              sortBy={{ field: "name", order: "asc" }}
              buttons={[]}
            />
          </Col>
          {editData != null && (
            <Col>
              <DesignationForm
                key={key}
                setKey={setKey}
                setEditData={setEditData}
                initialValues={editData}
              />
            </Col>
          )}
          {editData == null && (
            <Col>
              <DesignationForm
                key={key}
                setKey={setKey}
                initialValues={{
                  name: undefined,
                }}
              />
            </Col>
          )}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default DesignationTable;
