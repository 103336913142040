import React, {useContext, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import Select from "react-select";
import axiosInstance, {GetAsInputData} from "../../../utils/axios";
import NotyfContext from "../../../contexts/NotyfContext";


const PaymentDownloadComponent = ({setKey,url,isDealerEnabled}) => {
    const [customerOptions, setCustomerOptions] = useState([]);
    const [isCustomersLoading, setIsCustomersLoading] = useState(true);
    const notify = useContext(NotyfContext)
    const [fromDate,setFromDate] = useState(null);
    const [toDate,setToDate] = useState(null);
    const [dealer,setDealer] = useState(null);

    useEffect(() => {
        const loadSelectData =  () => {
            try{
                axiosInstance.get("/api/customers?"+GetAsInputData({
                    listInfo:{
                        startRecord:0,
                        rowCount:9999,
                        sortBy: [{"field":"dealer_name","order":"asc"}],
                        searchConditions: [{"field":"type","enumName":"DealerType","type":"enum","value":"RETAILER","condition":"not_equals"}]
                    }
                })).then(function(response){
                    setCustomerOptions(response.data.customers);
                    setTimeout(() => {
                        setIsCustomersLoading(false);
                    }, 1500);
                });


            }catch(e){
                console.log(e);
            }
        }
        loadSelectData();
    },[]);

    return(<React.Fragment>
        <Helmet title="Payments"/>
        <Container fluid className="p-0">
            <Row>
                <h1 className="h3 mb-3">Generate ledger Report</h1>
                <Row>
                    {isDealerEnabled!==false && <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Dealer
                            </Form.Label>
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                name="dealer"
                                options={customerOptions.map((key, text) => {
                                    return {
                                        value: key.dealer_id,
                                        label: key.dealer_name,
                                        dealer_id:key.dealer_id,
                                    }}
                                )}
                                isLoading={isCustomersLoading}
                                onChange={selectedOption => {
                                    setDealer(selectedOption.value);
                                }}
                            />
                        </Form.Group>
                    </Col>}
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                From Date
                            </Form.Label>
                            <Form.Control
                                type="date"
                                placeholder="From Date"
                                onChange={e => {
                                    setFromDate(new Date(e.currentTarget.value).getTime());
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                To Date
                            </Form.Label>
                            <Form.Control
                                type="date"
                                placeholder="To Date"
                                onChange={e => {
                                    setToDate(new Date(e.currentTarget.value).getTime());
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <Button variant={"success"} onClick={() => {
                            const input_data = {
                                listInfo:{
                                    startRecord:0,
                                    rowCount:150,
                                    sortBy:[{"field":"created_date","order":"desc"}],
                                    searchConditions:[]
                                }
                            };

                            if(dealer!=null)
                                input_data.listInfo.searchConditions.push({
                                    field:"dealer.dealer_id",
                                    value:dealer+"",
                                    type:"number"
                                });

                            if(fromDate!=null)
                                input_data.listInfo.searchConditions.push({
                                    field:"created_date",
                                    value:fromDate+"",
                                    type:"number",
                                    condition:"greater_than_or_equal"
                                });

                            if(toDate!=null)
                                input_data.listInfo.searchConditions.push({
                                    field:"created_date",
                                    value:toDate+"",
                                    type:"number",
                                    condition:"lesser_than_or_equal"
                                });

                            if(input_data.listInfo.searchConditions.length==0)
                                input_data.listInfo.searchConditions = null

                            let encodedData = GetAsInputData(input_data);

                            axiosInstance.get(url+"?"+encodedData).then(r =>{
                                notify.success("Report will be generated in reports tab")
                                setKey(new Date().getTime());
                            })
                        }}>Generate</Button>
                    </Col>
                </Row>
            </Row>

        </Container>
    </React.Fragment>);

};

export default PaymentDownloadComponent;



