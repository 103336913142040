import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";

import {Alert, Button, Card, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import Select from "react-select";
import axiosInstance, {ErrorMessage, GetAsInputData} from "../../../utils/axios";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {FieldArray, Formik} from "formik";

const saleOrderSchema = Yup.object().shape({
    dealer: Yup.object().required("Trader Name is required"),
    warehouse: Yup.object().required("Warehouse is required"),
    po_number:Yup.string().required("Po number is required"),
    po_date_field:Yup.date().required("Po date is required"),
    products: Yup.array()
        .of(
            Yup.object().shape({
                product: Yup.object().required("Product is required").nullable(),
                boxes: Yup.number().required("Box count is required")
            })
        )

});


const DomesticSaleOrderForm = ({initialValues,setSaleData,successUrl}) => {
    const navigate = useNavigate();
    const [productOptions, setProductOptions] = useState([]);
    const [isProductsLoading, setIsProductLoading] = useState(true);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [isCustomersLoading, setIsCustomersLoading] = useState(true);
    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [isWarehouseLoading, setIsWarehouseLoading] = useState(true);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function HandlePost(url,data,navigateTo){
        axiosInstance.post("/api/"+url,data).then(function(response,success){
            if(response.status===200)
                navigate(navigateTo);
        })
    }
    function HandlePut(url,data,navigateTo){
        axiosInstance.put("/api/"+url,data).then(function(response,success){
            setSaleData(response.data.sales_order);
            handleShow();
            setTimeout(handleClose,2500);
            if(response.status===200)
                navigate(navigateTo);
        })

    }


    function returnSaleValues(values,type){
       let sale = 0;
       let tax = 0;
       for(let i=0;i<values.length;i++){
           if(values[i].product!==undefined && values[i].quantity!==undefined){
               sale += (values[i].product.basic_rate===undefined?values[i].price:values[i].product.basic_rate) * values[i].quantity;
               tax += (values[i].product.basic_rate===undefined?values[i].price:values[i].product.basic_rate) * values[i].quantity * (values[i].product.product_gst);
           }
       }
       if(type==="sale")
            return sale;
       if(type==="tax")
            return tax/100;
        if(type==="sale_tax")
            return (sale+(tax/100));
    }

    function loadDealerProducts(dealerID){
        axiosInstance.get("/api/dealer_products?"+GetAsInputData({
            listInfo:{
                startRecord:0,
                rowCount:9999,
                sortBy: [{"field":"product.name","order":"asc"}],
                searchConditions:[{"field":"dealer.dealer_id","value":""+dealerID,"type":"number"}]
            }
        })).then(function(response){
            let dealerProducts = [];
            response.data.dealer_products.map((dealerProduct,index) => {
                dealerProduct.product.basic_rate = dealerProduct.basic_rate;
                dealerProducts.push(dealerProduct.product);
            });
            setProductOptions(dealerProducts);
            setTimeout(() => {
                setIsProductLoading(false);
            }, 1500);
        });
    }

    useEffect(() => {
        const loadSelectData =  () => {

            axiosInstance.get("/api/customers?"+GetAsInputData({
                listInfo:{
                    startRecord:0,
                    rowCount:9999,
                    sortBy: [{"field":"dealer_name","order":"asc"}],
                    searchConditions: [
                        {"field":"type","enumName":"DealerType","type":"enum","value":"RETAILER","condition":"not_equals"},
                        {"field":"type","enumName":"DealerType","type":"enum","value":"NON_BILLING","condition":"not_equals"}
                    ]
                }
            })).then(function(response){
                setCustomerOptions(response.data.customers);
                setTimeout(() => {
                    setIsCustomersLoading(false);
                }, 1500);
            });

            axiosInstance
                .get("/api/warehouses?"+GetAsInputData({
                    listInfo:{
                        startRecord:0,
                        rowCount:9999,
                        sortBy: [{"field":"warehouse_name","order":"asc"}],
                    }
                })).then(function(response){
                setWarehouseOptions(response.data.warehouses);
                setIsWarehouseLoading(false);
            });

            if(initialValues.so_number!==undefined)
                loadDealerProducts(initialValues.dealer.dealer_id);
        }
        loadSelectData();
    },[]);

    return( <Card>
        <Alert show={show} onHide={show} variant="primary" key="0" dismissible>
            <div className="alert-message">
                <strong>Sale Order</strong> updated successfully!
            </div>
        </Alert>
        <Card.Header>
            <Card.Title tag="h5">{initialValues.so_number===undefined?"Add":"Modify Domestic"} Sale Order</Card.Title>
        </Card.Header>
        <Card.Body>
            <Formik
                validationSchema={saleOrderSchema}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500));
                    if(values.so_number===undefined)
                        HandlePost("sales_order",GetAsInputData({sale_order:values}),successUrl);
                    else
                        HandlePut("sales_order/"+values.so_number,GetAsInputData({sale_order:values}),successUrl);
                }}
                initialValues={initialValues}>
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                  }) => (<Form noValidate onSubmit={handleSubmit}>
                    <Row>
                        <Col sm={5}>
                            <Form.Group  className="mb-3">
                                <Form.Label>Trader Name</Form.Label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="dealer"
                                    value={values.dealer}
                                    getOptionLabel={(option)=>option.dealer_name}
                                    getOptionValue={(option)=>option.dealer_id}
                                    options={customerOptions}
                                    isLoading={isCustomersLoading}
                                    onChange={selectedOption => {
                                        let event = { target : {
                                                                name:'dealer',
                                                                value: {
                                                                        dealer_id:selectedOption.dealer_id,
                                                                        dealer_name:selectedOption.dealer_name
                                                                }
                                                        }}
                                        handleChange(event);
                                        setIsProductLoading(true);
                                        setProductOptions([]);
                                        loadDealerProducts(selectedOption.dealer_id);
                                    }}
                                />
                                <ErrorMessage name={`dealer`} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Label>
                                Is Claim Order
                            </Form.Label>
                            <br/>
                            <Button className={"me-3"} onClick={() => {
                                let event = { target : {
                                        name:'claim',
                                        value: true
                                    }}
                                handleChange(event);
                            }} variant={values.claim?"success":"secondary"} >Yes</Button>
                            <Button className={"me-3"} onClick={() => {
                                let event = { target : {
                                        name:'claim',
                                        value: false
                                    }}
                                handleChange(event);
                            }} variant={!values.claim?"danger":"secondary"} >No</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Po Number
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={values.po_number}
                                    name="po_number"
                                    isValid={touched.po_number && !errors.po_number}
                                    isInvalid={touched.po_number && !!errors.po_number}
                                    onChange={handleChange}
                                    placeholder="Po Number" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.po_number}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Label>
                                Po Date
                            </Form.Label>
                            <Form.Control
                                type="date"
                                value={values.po_date_field}
                                name="po_date_field"
                                isValid={touched.po_date_field && !errors.po_date_field}
                                isInvalid={touched.po_date_field && !!errors.po_date_field}
                                onChange={ event => {
                                            values.po_date = new Date(event.currentTarget.value).getTime();
                                            handleChange(event);}
                                        }
                                placeholder="Po Date" />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.po_date_field}
                            </Form.Control.Feedback>
                        </Col>
                        <Col sm={4}>
                            <Form.Label>
                                Warehouse
                            </Form.Label>
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                value={values.warehouse}
                                getOptionLabel={(option)=>option.warehouse_name}
                                getOptionValue={(option)=>option.warehouse_id}
                                options={warehouseOptions}
                                isLoading={isWarehouseLoading}
                                onChange={selectedOption => {
                                    let event = { target : { name:`warehouse`,value: selectedOption } };
                                    handleChange(event);
                                }}
                            />
                            <ErrorMessage name={`warehouse`} />
                        </Col>
                    </Row>
                    <Card.Header/>
                        <Card.Title tag="h5">Sale Products</Card.Title>
                    <Card.Header/>
                    <FieldArray
                        name="products"
                        render={arrayHelpers => (
                            <div>
                            {
                                values.products && values.products.length > 0 ? (
                                values.products.map((saleProduct, index) => (
                                        <Row key={index}>
                                            <Col md={4}>
                                                <Form.Label htmlFor="product">Product</Form.Label>
                                                <Form.Group className="mb-3">
                                                    <Select
                                                        key={`products.${index}`}
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        name={`products[${index}].product`}
                                                        value={saleProduct.product}
                                                        getOptionLabel={(option)=>option.name}
                                                        getOptionValue={(option)=>option.id}
                                                        options={productOptions}
                                                        isDisabled={isProductsLoading}
                                                        isLoading={isProductsLoading}
                                                        onChange={selectedOption => {

                                                            if(saleProduct.boxes!==undefined){
                                                                let quantity = saleProduct.boxes * selectedOption.box_count;
                                                                saleProduct.quantity = quantity;
                                                            }
                                                            let event = { target : { name:`products[${index}].product`,value: selectedOption } };
                                                            handleChange(event);
                                                        }}
                                                    />
                                                    <ErrorMessage name={`products[${index}].product`} />
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Label htmlFor="boxes">Boxes</Form.Label>
                                                <InputGroup>
                                                    <FormControl
                                                        key={`products.${index}`}
                                                        placeholder="Boxes"
                                                        type="number"
                                                        name={`products[${index}].boxes`}
                                                        value={saleProduct.boxes }
                                                        aria-label="Boxes"
                                                        onChange={event => {
                                                            let quantity = saleProduct.product.box_count * Number(event.target.value);
                                                            saleProduct.quantity = quantity;
                                                            handleChange(event);
                                                        }}
                                                        disabled={!saleProduct.product} />
                                                </InputGroup>
                                                <ErrorMessage name={`products[${index}].boxes`} />
                                            </Col>
                                            <Col md={3}>
                                                <Form.Label htmlFor="quantity">Quantity</Form.Label>
                                                <InputGroup>
                                                    <FormControl
                                                        key={`products.${index}`}
                                                        placeholder="Quantity"
                                                        type="number"
                                                        name={`products[${index}].quantity`}
                                                        value={saleProduct.quantity}
                                                        aria-label="Quantity"
                                                        disabled />
                                                    <Button onClick={() => arrayHelpers.push({
                                                        product: undefined,
                                                        boxes:0,
                                                        quantity:0
                                                    })} variant="secondary">Add More</Button>
                                                    <Button onClick={() => {
                                                        arrayHelpers.remove(index);
                                                    }} variant="secondary">Remove</Button>

                                                </InputGroup>
                                            </Col>
                                    </Row>))):(
                                    arrayHelpers.push({
                                        product: undefined,
                                        boxes:0,
                                        quantity:0
                                    })
                                )}</div>
                        )}
                    />
                    <Row>
                        <Col sm={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Sale
                                </Form.Label>
                                <Form.Control type="text" value={ returnSaleValues(values.products,"sale")  } placeholder="Sale" disabled/>
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Label>
                                Tax
                            </Form.Label>
                            <Form.Control type="text"value={ returnSaleValues(values.products,"tax")  } placeholder="Tax" disabled/>
                        </Col>
                        <Col sm={4}>
                            <Form.Label>
                                Total
                            </Form.Label>
                            <Form.Control type="text" value={ returnSaleValues(values.products,"sale_tax")  } placeholder="Sales + Tax" disabled/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Button type="submit" variant="primary">Submit</Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>)}
            </Formik>

        </Card.Body>
    </Card>);
}

const SaleOrderFormPage = () => {

    const initialValues = {
        dealer: undefined,
        warehouse: {"warehouse_id":1,"warehouse_name":"Chennai"},
        sale_type:"DOMESTIC",
        sale:0,
        po_number:undefined,
        po_date_field:undefined,
        tax:0,
        sale_tax:0,
        products:[{
            product:undefined,
            boxes:undefined,
            quantity:undefined
        }],
        claim:false
    };
    return( <React.Fragment>
        <Helmet title="Domestic Sale Order" />
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Domestic Sale Order</h1>
                <Row>
                    <Col lg="12">
                     <DomesticSaleOrderForm initialValues={initialValues} successUrl={"/sales/orders"} />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}
export {DomesticSaleOrderForm}
export default SaleOrderFormPage;
