import React from "react";
import {useRoutes} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";

import "./i18n";
import routes from "./routes";

import {ThemeProvider} from "./contexts/ThemeContext";
import {SidebarProvider} from "./contexts/SidebarContext";
import {LayoutProvider} from "./contexts/LayoutContext";

import {AuthProvider} from "./contexts/JWTContext";

const App = () => {
  const content = useRoutes(routes);

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | BuyHappy - Be Happy"
        defaultTitle="BuyHappy - Be Happy"
      />
      {/*<Provider>*/}
          <ThemeProvider>
              <SidebarProvider>
                  <LayoutProvider>
                      <AuthProvider>
                          {content}
                      </AuthProvider>
                  </LayoutProvider>
              </SidebarProvider>
          </ThemeProvider>
      {/*</Provider>*/}
    </HelmetProvider>
  );
};

export default App;
