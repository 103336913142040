import React, {useState} from "react";
import {Badge, ButtonGroup, Col, Dropdown, DropdownButton, Form, FormLabel, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faExclamation, faTimes} from "@fortawesome/free-solid-svg-icons";
import EntityTable, {CustomFilter, SelectColumnFilter} from "../../../../components/EntityTable";
import axiosInstance from "../../../../utils/axios";
import {roles} from "../../../../utils/roles";
import useAuth from "../../../../hooks/useAuth";


const ClaimsTableDealerListView = ({dealerId}) => {
    const navigate = useNavigate();
    const {user} = useAuth();
    const [searchCondition1,setSearchCondition1] = useState();
    const [searchConditionSelectedValue1,setSearchConditionSelectedValue1] = useState();
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // +1 because getMonth() returns 0-11
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedToMonth, setSelectedToMonth] = useState(new Date().getMonth() + 1); // +1 because getMonth() returns 0-11
    const [selectedToYear, setSelectedToYear] = useState(new Date().getFullYear());
    const claimsTableColumns = [
        {
            Header: "Name",
            accessor: "retailer.dealer_name",
        },
        {
            Header: "Invoice Number",
            accessor: "invoiceNumber",
        },
        {
            Header: "Invoice Date",
            accessor: "invoiceDate",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                let date = new Date(Number(value));
                date = date.toString().slice(3,16)
                return date;
            }
        },
        {
            Header: "Total Claim",
            accessor: "totalAmountClaim",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                if(value==null)
                    return Number(0).toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR"
                    });
                return value.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR"
                });
            },
        },
        {
            Header: "Total Amount Payable",
            accessor: "totalAmountPayable",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                return value.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR"
                });
            },
        },
        {
            Header: "Total Amount Paid",
            accessor: "totalAmountPaid",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                if(value==null)
                    return Number(0).toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR"
                    });
                return value.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR"
                });
            },
        },
        {
            Header: "Created Date",
            accessor: "createdDate",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                let date = new Date(Number(value));
                date = date.toString().slice(3,21)
                return date;
            }

        },
        {
            Header: "Created By",
            accessor: "createdBy.name",
            Filter: () => {
                return CustomFilter({
                        entityUrl:"users",
                        setCondition:setSearchCondition1,
                        entityId:"user_id",
                        entityValue:"name",
                        searchField:"created_by.user_id",
                        searchType:"number",
                        entity:"users",
                        selectedValue:searchConditionSelectedValue1,
                        setSelectedValue:setSearchConditionSelectedValue1
                    }
                )
            },
        },
        {
            Header: "Type",
            accessor: "claimType",
            Filter: false,
        },
        {
            Header: "Status",
            accessor: "approvalStatus",
            Cell: ({ value }) => {
                if(value==="TRUE"){
                    return(<Badge bg="success" >
                        <FontAwesomeIcon icon={faCheck} /> Approved
                    </Badge>);
                }else if(value==="FALSE"){
                    return(<Badge bg="danger" >
                        <FontAwesomeIcon icon={faTimes} /> Rejected
                    </Badge>);
                }else{
                    return(<Badge bg="warning" >
                        <FontAwesomeIcon icon={faExclamation} /> Waiting for Approval
                    </Badge>);
                }

            },
            Filter: SelectColumnFilter,
        },
        {
            Header: "A/R By",
            accessor: "approvalBy.name",
            Cell: ({ row }) => {

                const [is_approved,setIsApproved] = useState(row.values["approvalBy.name"]);
                if(user.roles.indexOf(roles.ROLE_APPROVE_CLAIM)==-1)
                    return "N/A"
                if(is_approved!=null){
                    return(is_approved);
                }

                return(<DropdownButton
                    as={ButtonGroup}
                    key={"primary"}
                    id={`dropdown-variants-primary`}
                    variant={"primary"}
                    title={"Choose"}
                    className="me-2"
                    onClick={event => {
                        event.stopPropagation()
                    }}
                    onSelect={event => {
                        axiosInstance("/api/claims_tracker/"+row.original.claim_id+"/"+event).then((response,success) => {
                            setIsApproved(response.data.claims_tracker.approvalBy.user_name)
                        });
                    }}
                >
                    <Dropdown.Item eventKey="approve">Approve</Dropdown.Item>
                    <Dropdown.Item eventKey="reject">Reject</Dropdown.Item>
                </DropdownButton>);
            },
        },
    ];


    const claimButtons = [
        {
            key:0,
            name:"New Claim",
            href:"/claims/claim-new"
        },
        {
            key:0,
            name:"Download Approved Claim Excel",
            handleClick:() => {
                window.open("/claims_tracker/"+dealerId+"/downloadFile/excel/approved/"+selectedMonth+"/"+selectedYear+"/to/"+selectedToMonth+"/"+selectedToYear,"_blank");
            }
        }
    ];

    let condition = [];
    searchCondition1!=null && condition.push(searchCondition1)
    // condition.push({field:"claimType",value:"CLAIM",type:"enum",enumName:"ClaimEnums"})
    condition.push({field:"dealer.dealer_id",value:dealerId,type:"longnumber"})

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const years = Array.from({length: 5}, (_, i) => new Date().getFullYear() - i);


    return(<React.Fragment>
        <Row>
            <Row  style={{marginLeft:"10px"}}>
                <Col lg={2}>
                    <FormLabel>From Month</FormLabel>
                    <Form.Select aria-label="Select Month" value={selectedMonth} onChange={e => setSelectedMonth(e.target.value)}>
                        <option>Select Month</option>
                        {months.map((month, index) => <option value={index+1} key={index}>{month}</option>)}
                    </Form.Select>

                </Col>
                <Col lg={2}>
                    <FormLabel>From Year</FormLabel>
                    <Form.Select aria-label="Select Year" value={selectedYear} onChange={e => setSelectedYear(e.target.value)}>
                        <option>Select Year</option>
                        {years.map((year, index) => <option value={year} key={index}>{year}</option>)}
                    </Form.Select>
                </Col>
                <Col lg={2}>
                    <FormLabel>To Month</FormLabel>
                    <Form.Select aria-label="Select Month" value={selectedToMonth} onChange={e => setSelectedToMonth(e.target.value)}>
                        <option>Select Month</option>
                        {months.map((month, index) => <option value={index+1} key={index}>{month}</option>)}
                    </Form.Select>

                </Col>
                <Col lg={2}>
                    <FormLabel>To Year</FormLabel>
                    <Form.Select aria-label="Select Year" value={selectedToYear} onChange={e => setSelectedToYear(e.target.value)}>
                        <option>Select Year</option>
                        {years.map((year, index) => <option value={year} key={index}>{year}</option>)}
                    </Form.Select>
                </Col>
            </Row>
        </Row>
        <br/>
        <Row style={{marginTop:"10px"}}>
            <Col>
                <EntityTable
                    key={new Date().getTime()}
                    columns={claimsTableColumns}
                    url={"claims_tracker"}
                    sortBy={[{field: "claim_id", order: "desc"}]}
                    buttons={claimButtons}
                    searchCondition={condition}
                    tableProps={table => ({
                        style:{
                            textAlign:"center"
                        },
                        className:"table-sm"
                    })}
                    rowProps={row => ({
                        onClick: () => navigate("/claims/claim-details/"+row.original.dealer.dealer_id+"/"+row.original.claim_id) ,
                        style: {
                            cursor: "pointer"
                        }
                    })}
                />
            </Col>
        </Row>
    </React.Fragment>);

};

export default ClaimsTableDealerListView;
