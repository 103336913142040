import React, {createContext, useEffect, useReducer} from "react";

import axiosInstance from "../utils/axios";
import {isValidToken, setSession} from "../utils/jwt";
import Main from "../components/Main";
import {Col, Container, Row} from "react-bootstrap";
import {Outlet} from "react-router-dom";
import SignInPage from "../pages/auth/SignIn";
import Loader from "../components/Loader";

const INITIALIZE = "INITIALIZE";
  const SIGN_IN = "SIGN_IN";
  const SIGN_OUT = "SIGN_OUT";

  const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
  };

  const JWTReducer = (state, action) => {
    switch (action.type) {
      case INITIALIZE:
        return {
          isAuthenticated: action.payload.isAuthenticated,
          isInitialized: true,
          user: action.payload.user,
        };
      case SIGN_IN:
        return {
          ...state,
          isAuthenticated: true,
          user: action.payload.user,
        };
      case SIGN_OUT:
        return {
          ...state,
          isAuthenticated: false,
          user: null,
        };

      default:
        return state;
    }
  };

  const AuthContext = createContext(null);

  function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(JWTReducer, initialState);

    useEffect(() => {
      const initialize = async () => {
        try {
          const accessToken = window.localStorage.getItem("accessToken");

          if (accessToken && isValidToken(accessToken)) {
            setSession(accessToken);

            const response = await axiosInstance.get("/api/users/getCurrentUser");
            const { user } = response.data;
            var roles  = [];
            for(var i=0;i<user.roles.length;i++){
              roles.push(user.roles[i].role.role);
            }
            user.roles = roles;
            dispatch({
              type: INITIALIZE,
              payload: {
                isAuthenticated: true,
                user,
              },
            });
          } else {
            dispatch({
              type: INITIALIZE,
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
          }
        } catch (err) {
          console.error(err);
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      };

      initialize();
    }, []);

    const signIn = async (username, password) => {
      const response = await axiosInstance.post("/authenticate", {
        username,
        password,
      });
      const { accessToken, user } = response.data;
      var roles  = [];
      for(var i=0;i<user.authorities.length;i++){
        roles.push(user.authorities[i].authority);
      }
      user.roles = roles;
      setSession(accessToken);
      dispatch({
        type: SIGN_IN,
        payload: {
          user,
        },
      });
    };

    const signOut = async () => {
      setSession(null);
      dispatch({ type: SIGN_OUT });
    };

    if(state.isInitialized===false){
      return (<Loader/>);
    }else if(state.user){
      return (
          <AuthContext.Provider
              value={{
                ...state,
                method: "jwt",
                signIn,
                signOut,
              }}
          >
            {children}
          </AuthContext.Provider>
      );
    }else{
      return (
          <AuthContext.Provider
              value={{
                ...state,
                method: "jwt",
                signIn,
                signOut,
              }}
          >
            <React.Fragment>
              <Main className="d-flex w-100 justify-content-center">
                <Container className="d-flex flex-column">
                  <Row className="h-100">
                    <Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
                      <div className="d-table-cell align-middle">
                        <SignInPage/>
                        <Outlet />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Main>
              {/*<Settings />*/}
            </React.Fragment>
          </AuthContext.Provider>
      );
    }


  }

  export { AuthContext, AuthProvider };
