import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";

import {Button, Card, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import Select from "react-select";
import axiosInstance, {ErrorMessage, GetAsInputData} from "../../../../utils/axios";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {FieldArray, Formik} from "formik";

const prepaidInvoiceSchema = Yup.object().shape({
    invoice_date: Yup.string().required("Invoice Date is required"),
    mode_of_transport: Yup.string().required("Mode of Transport is required"),
    products: Yup.array()
        .of(
            Yup.object().shape({
                product: Yup.object().required("Product is required").nullable(),
                batch_number_custom:Yup.string().required("Batch number is required")
            })
        )

});


const initialValues = {
    invoice_number: undefined,
    invoice_date:undefined,
    mode_of_transport:undefined,
    transport_charge: undefined,
    billed_to_prepaid:undefined,
    is_igst:true,
    igst:true,
    invoice_type:"BUY_HAPPY_SALES_PREPAID_INVOICE",
    sale:0,
    tax:0,
    sale_tax:0,
    products:[{
        product:undefined,
        product_price:undefined,
        quantity:undefined,
        batch_number_custom:undefined
    }]
};

const PrepaidInvoiceForm = () => {
    const navigate = useNavigate();
    const [productOptions, setProductOptions] = useState([]);
    const [isProductsLoading, setIsProductLoading] = useState(true);

    function returnSaleValues(values,type){
        let sale = 0;
        let tax = 0;
        for(let i=0;i<values.length;i++){
            if(values[i].product!==undefined && values[i].boxes!==undefined){
                let quantity = values[i].boxes * values[i].product.box_count;
                sale += values[i].product.price * quantity;
                tax += values[i].product.price * quantity * values[i].product.gst;
            }
        }
        if(type==="sale")
            return sale;
        if(type==="tax")
            return tax/100;
        if(type==="sale_tax")
            return (sale+(tax/100));
    }

    function HandleSubmit(url,data,navigateTo){
        axiosInstance.post("/api/"+url,data).then(function(response,success){
            if(response.status===200)
                navigate(navigateTo);
        })
    }

    function loadSaleProducts(saleOrderID){

    }

    useEffect(() => {
        const loadSelectData =  () => {

            axiosInstance.get("/api/products?"+GetAsInputData({
                listInfo:{
                    startRecord:0,
                    rowCount:9999,
                    sortBy: [{"field":"name","order":"asc"}],
                    searchConditions:[{"field":"forSale","value":true,"type":"boolean"}]
                }
            })).then(function(response){
                setProductOptions(response.data.products);
                setTimeout(() => {
                    setIsProductLoading(false);
                }, 1500);
            });
        }
        loadSelectData();
    },[]);

    return( <Card>
        <Card.Header>
            <Card.Title tag="h5">Add Invoice</Card.Title>
        </Card.Header>
        <Card.Body>
            <Formik
                validationSchema={prepaidInvoiceSchema}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500));
                    HandleSubmit("buyhappy_prepaid_invoices",GetAsInputData({buyhappy_prepaid_invoices:values}),"/invoices/prepaid")
                }}
                initialValues={initialValues}>
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                  }) => (<Form noValidate onSubmit={handleSubmit}>
                    <Row>
                        <Col sm={3}>
                            <Form.Group  className="mb-3">
                                <Form.Label>
                                    Invoice Number
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="invoice_number"
                                    isValid={touched.invoice_number && !errors.invoice_number}
                                    isInvalid={touched.invoice_number && !!errors.invoice_number}
                                    onChange={handleChange}
                                    placeholder="Invoice Number" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.invoice_number}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Invoice Date
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    name="invoice_date"
                                    isValid={touched.invoice_date && !errors.invoice_date}
                                    isInvalid={touched.invoice_date && !!errors.invoice_date}
                                    onChange={event => {
                                        let anotherEvent = { target : { name:'invoice_date',value: new Date(event.currentTarget.value).getTime()}}
                                        handleChange(anotherEvent);
                                    }}
                                    placeholder="Invoice Date" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.invoice_date}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    IGST
                                </Form.Label>
                                <div className="custom-controls-stacked">
                                <Form.Check
                                    inline
                                    type="radio"
                                    id="is_igst"
                                    name="igst"
                                    value={values.igst}
                                    checked={values.igst}
                                    label="IGST"
                                    onChange={event => {
                                        handleChange(event);
                                        let anotherEvent = { target : { name:'is_igst',value: true}}
                                        let anotherEvent1 = { target : { name:'cgst',value: false}}
                                        handleChange(anotherEvent);
                                        handleChange(anotherEvent1);
                                    }}
                                    className="mb-2"
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    id="is_igst"
                                    name="cgst"
                                    value={values.cgst}
                                    checked={values.cgst}
                                    label="CGST & SGST"
                                    onChange={event => {
                                        handleChange(event);
                                        let anotherEvent = { target : { name:'is_igst',value: false}}
                                        let anotherEvent1 = { target : { name:'igst',value: false}}
                                        handleChange(anotherEvent);
                                        handleChange(anotherEvent1);
                                    }}
                                    className="mb-2"
                                />
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>

                        <Col sm={3}>
                            <Form.Label>
                                Mode Of Transport
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="mode_of_transport"
                                isValid={touched.mode_of_transport && !errors.mode_of_transport}
                                isInvalid={touched.mode_of_transport && !!errors.mode_of_transport}
                                onChange={handleChange}
                                placeholder="Mode Of Transport" />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.mode_of_transport}
                            </Form.Control.Feedback>
                        </Col>
                        <Col sm={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Delivery Address
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="billed_to_prepaid"
                                    isValid={touched.billed_to_prepaid && !errors.billed_to_prepaid}
                                    isInvalid={touched.billed_to_prepaid && !!errors.billed_to_prepaid}
                                    onChange={handleChange}
                                    placeholder="Delivery Address" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.billed_to_prepaid}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Delivery Charge
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    name="transport_charge"
                                    isValid={touched.transport_charge && !errors.transport_charge}
                                    isInvalid={touched.transport_charge && !!errors.transport_charge}
                                    onChange={handleChange}
                                    placeholder="Delivery Charge" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.transport_charge}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Card.Header/>
                    <Card.Title tag="h5">Invoice Products</Card.Title>
                    <Card.Header/>
                    <FieldArray
                        name="products"
                        render={arrayHelpers => (
                            <div>
                                {
                                    values.products && values.products.length > 0 ? (
                                        values.products.map((product, index) => (
                                            <Row key={index}>
                                                <Col md={4}>
                                                    <Form.Label htmlFor="product">Product</Form.Label>
                                                    <Form.Group className="mb-3">
                                                        <Select
                                                            key={`products.${index}`}
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            name={`products[${index}].product`}
                                                            value={product.product}
                                                            getOptionLabel={(option)=>option.name}
                                                            getOptionValue={(option)=>option.id}
                                                            isDisabled={isProductsLoading}
                                                            options={productOptions}
                                                            isLoading={isProductsLoading}
                                                            onChange={selectedOption => {
                                                                let event = { target : { name:`products[${index}].product`,value:selectedOption  } };
                                                                handleChange(event);
                                                            }}
                                                        />
                                                        <ErrorMessage name={`products[${index}].product`} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={2}>
                                                    <Form.Label htmlFor="batch_number_custom">Batch Number</Form.Label>
                                                    <InputGroup>
                                                        <FormControl
                                                            key={`products.${index}`}
                                                            placeholder="Batch Number"
                                                            type="text"
                                                            name={`products[${index}].batch_number_custom`}
                                                            value={product.batch_number_custom}
                                                            aria-label="Batch Number"
                                                            onChange={handleChange}
                                                            disabled={!product.product} />
                                                    </InputGroup>
                                                    <ErrorMessage name={`products[${index}].batch_number_custom`} />
                                                </Col>
                                                <Col md={2}>
                                                    <Form.Label htmlFor="product_price">Final MRP Price</Form.Label>
                                                    <InputGroup>
                                                        <FormControl
                                                            key={`products.${index}`}
                                                            placeholder="Price"
                                                            type="number"
                                                            name={`products[${index}].product_price`}
                                                            value={product.product_price}
                                                            aria-label="Price"
                                                            onChange={handleChange}
                                                            disabled={!product.product} />
                                                    </InputGroup>
                                                    <ErrorMessage name={`products[${index}].product_price`} />
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Label htmlFor="quantity">Quantity</Form.Label>
                                                    <InputGroup>
                                                        <FormControl
                                                            key={`products.${index}`}
                                                            placeholder="Quantity"
                                                            type="number"
                                                            name={`products[${index}].quantity`}
                                                            value={product.quantity}
                                                            aria-label="Quantity"
                                                            onChange={handleChange}
                                                            disabled={!product.product} />
                                                        <Button onClick={() => arrayHelpers.push({
                                                            product:undefined,
                                                            product_price:undefined,
                                                            quantity:undefined,
                                                            batch_number_custom:undefined
                                                        })} variant="secondary">Add More</Button>
                                                        <Button onClick={() => {
                                                            arrayHelpers.remove(index);
                                                        }} variant="secondary">Remove</Button>

                                                    </InputGroup>
                                                </Col>
                                            </Row>))):(
                                        arrayHelpers.push({
                                            product:undefined,
                                            product_price:undefined,
                                            quantity:undefined,
                                            batch_number_custom:undefined
                                        })
                                    )}</div>
                        )}
                    />
                    <Row>
                        <Col sm={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Sale
                                </Form.Label>
                                <Form.Control type="text" value={ returnSaleValues(values.products,"sale")  } placeholder="Sale" disabled/>
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Label>
                                Tax
                            </Form.Label>
                            <Form.Control type="text"value={ returnSaleValues(values.products,"tax")  } placeholder="Tax" disabled/>
                        </Col>
                        <Col sm={4}>
                            <Form.Label>
                                Total
                            </Form.Label>
                            <Form.Control type="text" value={ returnSaleValues(values.products,"sale_tax")  } placeholder="Sales + Tax" disabled/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Button type="submit" variant="primary">Submit</Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>)}
            </Formik>

        </Card.Body>
    </Card>);
}

const PrepaidInvoiceFormPage = () => (
    <React.Fragment>
        <Helmet title="New Prepaid Invoice" />
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Prepaid Invoice</h1>

            <Row>
                <Col lg="12">
                    <PrepaidInvoiceForm />
                </Col>
            </Row>
        </Container>
    </React.Fragment>
);

export default PrepaidInvoiceFormPage;
