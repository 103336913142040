import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import {Container} from "react-bootstrap";
import EntityTable, {CustomFilter} from "../../../components/EntityTable";
import {useNavigate} from "react-router-dom";


const SDDistributorsPage = () => {
    const navigate = useNavigate();

    const [searchCondition,setSearchCondition] = useState();
    const [searchConditionSelectedValue1,setSearchConditionSelectedValue1] = useState();

    const regionSearchCondition = [{field: "country.id", type: "number", value: "96"}]

    const sdDistributorsColumns = [
        {
            Header: "SD ID",
            accessor: "distributor_sd_id",
        },
        {
            Header: "Name",
            accessor: "distributorName",
        },
        {
            Header: "Address",
            accessor: "distributor_address",
        },
        {
            Header: "Phone",
            accessor: "distributor_phone",
        },
        {
            Header: "Mobile",
            accessor: "distributor_mobile",
        },
        {
            Header: "Email",
            accessor: "distributor_email",
        },
        {
            Header: "Pin Code",
            accessor: "distributor_pincode",
        },
        {
            Header: "Region",
            accessor: "distributor_region",
            Filter: () => {
                return CustomFilter({
                        entityUrl:"regions",
                        setCondition:setSearchCondition,
                        entityId:"id",
                        entityValue:"region_name",
                        searchField:"distributor_region.id",
                        searchType:"number",
                        entity:"regions",
                        searchCondition:regionSearchCondition,
                        selectedValue:searchConditionSelectedValue1,
                        setSelectedValue:setSearchConditionSelectedValue1
                    }
                )
            },
        },
        {
            Header: "Latitude",
            accessor: "distributor_loc_lat",
        },
        {
            Header: "Longitude",
            accessor: "distributor_loc_long",
        },
        {
            Header: "GST",
            accessor: "distributor_gst",
        },
        {
            Header: "Firestore",
            accessor: "distributor_firestore_id",
            Filter:false
        },
    ];

    let condition = [];
    searchCondition!=null && condition.push(searchCondition)
    if(condition.length==0)
        condition = null

    return(<React.Fragment>
        <Helmet title="Sales Diary Distributors"/>
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Sales Diary Distributors</h1>
            <EntityTable
                key={new Date().getTime()}
                columns={sdDistributorsColumns}
                url={"sd_distributors"}
                pageSize={200}
                sortBy={[{field: "distributorName", order: "asc"}]}
                buttons={[]}
                searchCondition={condition}
                rowProps={row => ({
                   onClick: () => navigate("/sd/distributors-details/"+row.original.distributor_id) ,
                   style: {
                       cursor: "pointer"
                   }
                })}
            />
        </Container>
    </React.Fragment>);

};

export default SDDistributorsPage;
