import React, {useContext, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Badge, Button, Col, Container, Row} from "react-bootstrap";
import EntityTable, {SelectColumnFilter} from "../../../../components/EntityTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../../../hooks/useAuth";
import axiosInstance from "../../../../utils/axios";
import {UserForm} from "./UserForm";
import {roles} from "../../../../utils/roles";
import NotyfContext from "../../../../contexts/NotyfContext";

const ERPUsersPage = () => {
    const [key,setKey] = useState(new Date().getTime());
    const {user} = useAuth();
    const notify = useContext(NotyfContext);

    const userButtons = [
        {
            key:0,
            name:"New User",
            href:"/users/erp-new"
        },
    ];

    const ERPUsersTableColumns = [
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "Username",
            accessor: "username",
        },
        {
            Header: "Department",
            accessor: "department.department_name",
            Filter:false
        },
        {
            Header: "Status",
            accessor: "enabled",
            Filter: SelectColumnFilter,
            Cell: ({ cell: { value } }) => {
                if(value===true){
                    return(<Badge bg="success" >
                        <FontAwesomeIcon icon={faCheck} /> Enabled
                    </Badge>);
                }else if(value===false){
                    return(<Badge bg="danger" >
                        <FontAwesomeIcon icon={faTimes} /> Disabled
                    </Badge>);
                }
                return(<Badge bg="danger" >
                    <FontAwesomeIcon icon={faTimes} /> Disabled
                </Badge>);
            }
        },
        {
            Header: "Actions",
            Cell: ({ row }) => {
                const userEdit = user.roles.indexOf(roles.ROLE_CREATE_MODIFY_USERS) !== -1 && (
                    <Button
                        style={{ width: "100%" }}
                        variant={"secondary"}
                        onClick={() => {
                            axiosInstance
                                .get("/api/users/" + row.original.user_id)
                                .then(function (response) {
                                    editUser(response.data.user);
                                });
                        }}
                    >
                        Edit User
                    </Button>
                );

                const userPasswordChanged = user.roles.indexOf(
                    roles.ROLE_RESET_USERS_PASSWORD
                ) !== -1 && (
                    <Button
                        style={{ width: "100%" }}
                        variant={"warning"}
                        onClick={() => {
                            const url = "/api/users/changePassword/" + row.original.user_id;

                            const password = "password=" + row.original.username.trim();
                            axiosInstance.put(url, password).then(function (response) {
                                notify.success("Password has been reset");
                            });
                        }}
                    >
                        Reset password
                    </Button>
                );

                const userDelete = user.roles.indexOf(roles.ROLE_DISABLE_USERS) !==
                    -1 && (
                        <Button
                            style={{ width: "100%" }}
                            variant={"danger"}
                            onClick={() => {
                                disableUser(row.original.user_id);
                            }}
                        >
                            Delete User
                        </Button>
                    );

                return (
                    row.original.enabled === true && (
                        <div style={{ cursor: "pointer" }}>
                            <Row>
                                <Col lg={3}>{userEdit}</Col>
                                <Col lg={5}>{userPasswordChanged}</Col>
                                <Col lg={4}>{userDelete}</Col>
                            </Row>
                        </div>
                    )
                );
            },
        }
    ];

    const [editUserData,setEditUserData] = useState('');

    const disableUser = (user_id) => {
        axiosInstance.delete("/api/users"+"?ids="+encodeURIComponent(user_id) )
            .then(r => {
                notify.success("User disabled ");
                setKey(key+1);
            })
    }

    const editUser = ({user_id}) => {
        axiosInstance.get("/api/users/"+user_id )
            .then((r) => {
                r.data.user.roles.map((role) => {
                    r.data.user[role.role.role_id] = true;
                });
                setEditUserData(r.data.user);
            }).catch(e => console.log(e));
    }

    if(editUserData==='')
        return(<React.Fragment>
            <Helmet title="Users"/>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">ERP Users</h1>
                <Row>
                    <Col lg={12}>
                        <EntityTable
                            key={key}
                            columns={ERPUsersTableColumns}
                            url={"users"}
                            sortBy={[{field: "name", order: "asc"}]}
                            buttons={userButtons}
                        />
                    </Col>
                </Row>

            </Container>
        </React.Fragment>);
    else{
        return (<React.Fragment>
            <Helmet title="Edit User" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Edit User</h1>
                <Row>
                    <Col lg="12">
                        <UserForm key={key} initialValues={editUserData} successUrl="/users/erp" setEditData={setEditUserData}/>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>);
    }

};

export default ERPUsersPage;



