import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";

import {Alert, Button, Card, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import Select from "react-select";
import axiosInstance, {ErrorMessage, GetAsInputData, UploadFile} from "../../../utils/axios";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {FieldArray, Formik, useFormikContext} from "formik";


const claimOpeningStockSchema = Yup.object().shape({
    currentDate: Yup.date().required("Enter today's date"),
    dealer: Yup.object().required("Dealer is required"),
    retailer: Yup.object().required("Retailer is required"),
    totalAmountClaim:Yup.number().required("Claim amount is required"),
    invoiceNumber:"",
    invoiceDateField:"",
    claims: Yup.array()
        .of(
            Yup.object().shape({
                product: Yup.object().required("Product is required"),
                productCount:Yup.number().required("Count is required"),
                productPrice:Yup.number().required("Price is required"),
                productTotal:Yup.number().required("Claim amount is required"),
            })
        )
});


const ClaimOtherForm = ({initialValues,setClaimData,successUrl}) => {
    const navigate = useNavigate();

    const [productOptions, setProductOptions] = useState([]);
    const [isProductsLoading, setIsProductLoading] = useState(true);

    const [customerOptions, setCustomerOptions] = useState([]);
    const [isCustomersLoading, setIsCustomersLoading] = useState(true);

    const [retailerOptions, setRetailerOptions] = useState([]);
    const [isRetailersLoading, setIsRetailerLoading] = useState(true);

    const [productPricing, setProductPricing] = useState({});
    const [show, setShow] = useState(false);

    const [contracts, setContracts] = useState({});
    const [isContractsLoading, setIsContractsLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function HandlePost(url,data,navigateTo){
        axiosInstance.post("/api/"+url,data).then(function(response,success){
            if(response.status===200)
                navigate(navigateTo);
        })
    }
    function HandlePut(url,data){
        axiosInstance.put("/api/"+url,data).then(function(response,success){
            setClaimData(response.data.claims_tracker);
            handleShow();
            setTimeout(handleClose,5000);
        })

    }

    function loadProductPayable(pricePercentage) {
        axiosInstance.get("/api/dealer_products/"+pricePercentage)
            .then(function(response){
                let map = {};
                let dealerProducts = response.data.dealer_products;
                for(var i=0;i<dealerProducts.length;i++){
                    map[dealerProducts[i].product.id] = dealerProducts[i].basic_rate
                }
                setProductPricing(map);
            });

    }

    function returnPayableValue(values,type){
        let totalClaim = Number(values.totalAmountClaim);
        if(type==="claim")
            return totalClaim.toFixed(2);
        if(type==="gst")
            return (totalClaim - (totalClaim/1.18)).toFixed(2);
        if(type==="actual")
            return (totalClaim/1.18).toFixed(2);

    }

    function loadIndividualProductValues(claimProduct,type){
        if(!!claimProduct.product){
            let totalPrice = claimProduct.productCount * claimProduct.productPrice;
            claimProduct.sale = totalPrice;
            claimProduct.tax = totalPrice * 0.18;
            claimProduct.productTotal = totalPrice + (totalPrice * 0.18);
            if(type=="sale")
                return claimProduct.sale.toFixed(2)
            if(type=="tax")
                return claimProduct.tax.toFixed(2)
            if(type=="productTotal")
                return claimProduct.productTotal.toFixed(2)
        }
        return 0
    }

    function loadDealerProducts(dealerID){
        axiosInstance.get("/api/dealer_products?"+GetAsInputData({
            listInfo:{
                startRecord:0,
                rowCount:9999,
                sortBy: [{"field":"product.name","order":"asc"}],
                searchConditions:[{"field":"dealer.dealer_id","value":""+dealerID,"type":"number"}]
            }
        })).then(function(response){
            let dealerProducts = [];
            response.data.dealer_products.map((dealerProduct,index) => {
                dealerProduct.product.basic_rate = dealerProduct.basic_rate;
                dealerProduct.product.without_offer_price = dealerProduct.without_offer_price;
                dealerProducts.push(dealerProduct.product);
            });
            setProductOptions(dealerProducts);
            setTimeout(() => {
                setIsProductLoading(false);
            }, 1500);
        });
    }

    function loadRetailers(dealerID){
        axiosInstance.get("/api/dealer_retailer?"+GetAsInputData({
            listInfo:{
                startRecord:0,
                rowCount:9999,
                sortBy: [{"field":"retailer.dealer_name","order":"asc"}],
                searchConditions:[{"field":"dealer.dealer_id","value":""+dealerID,"type":"number"}]
            }
        })).then(function(response){
            let retailers = [];
            response.data.dealer_retailer.map((dealerRetailer,index) => {
                retailers.push(dealerRetailer.retailer);
            });
            setRetailerOptions(retailers);
            setTimeout(() => {
                setIsRetailerLoading(false);
            }, 1500);
        });
    }

    function loadContracts(retailerID){
        axiosInstance.get("/api/contracts?"+GetAsInputData({
            listInfo:{
                startRecord:0,
                rowCount:9999,
                sortBy: [{"field":"createdDate","order":"asc"}],
                searchConditions:[{"field":"dealer.dealer_id","value":""+retailerID,"type":"number"}]
            }
        })).then(function(response){
            setContracts(response.data.contracts);
            setTimeout(() => {
                setIsContractsLoading(false);
            }, 1500);
        });
    }

    useEffect(() => {
        axiosInstance.get("/api/customers?"+GetAsInputData({
            listInfo:{
                startRecord:0,
                rowCount:9999,
                sortBy: [{"field":"dealer_name","order":"asc"}],
                searchConditions: [{"field":"type","enumName":"DealerType","type":"enum","value":"RETAILER","condition":"not_equals"}]
            }
        })).then(function(response){
            setCustomerOptions(response.data.customers);
            setTimeout(() => {
                setIsCustomersLoading(false);
            }, 1500);
        });
        if(initialValues.claim_id!==undefined){
            loadDealerProducts(initialValues.dealer.dealer_id);
            loadRetailers(initialValues.dealer.dealer_id);
            loadProductPayable(initialValues.retailer.retailer_percentage)
        }
    },[]);

    return( <Card>
        <Alert show={show} onHide={show} variant="primary" key="0" dismissible>
            <div className="alert-message">
                <strong>Claim</strong> updated successfully!
            </div>
        </Alert>
        <Card.Header>
            <Card.Title tag="h5">{initialValues.claim_id===undefined?"Add":"Modify "} Claim</Card.Title>
        </Card.Header>
        <Card.Body>
            <Formik
                validationSchema={claimOpeningStockSchema}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500));
                    if(values.claim_id===undefined)
                        HandlePost("claims_tracker",GetAsInputData({claims_tracker:values}),successUrl);
                    else
                        HandlePut("claims_tracker/"+values.claim_id,GetAsInputData({claims_tracker:values}),successUrl);
                }}
                initialValues={initialValues}>
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                  }) => (<Form noValidate onSubmit={handleSubmit}>
                    <Row>
                        <Col sm={4}>
                            <Form.Group  className="mb-3">
                                <Form.Label>Trader Name</Form.Label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="dealer"
                                    value={values.dealer}
                                    getOptionLabel={(option)=>option.dealer_name}
                                    getOptionValue={(option)=>option.dealer_id}
                                    options={customerOptions}
                                    isLoading={isCustomersLoading}
                                    onChange={selectedOption => {
                                        let event = { target : {
                                                name:'dealer',
                                                value: {
                                                    dealer_id:selectedOption.dealer_id,
                                                    dealer_name:selectedOption.dealer_name
                                                }
                                            }}
                                        handleChange(event);
                                        event = { target : {
                                                name:'retailer',
                                                value: null
                                            }}
                                        handleChange(event);
                                        event = { target : {
                                                name:'claims',
                                                value: []
                                            }}
                                        handleChange(event);
                                        setIsProductLoading(true);
                                        setProductOptions([]);
                                        loadDealerProducts(selectedOption.dealer_id);
                                        loadRetailers(selectedOption.dealer_id);
                                    }}
                                />
                                <ErrorMessage name={`dealer`} />
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Group  className="mb-3">
                                <Form.Label>Retailer</Form.Label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="retailer"
                                    value={values.retailer}
                                    getOptionLabel={(option)=>option.dealer_name}
                                    getOptionValue={(option)=>option.dealer_id}
                                    options={retailerOptions}
                                    isLoading={isRetailersLoading}
                                    onChange={selectedOption => {
                                        let event = { target : {
                                                name:'retailer',
                                                value: {
                                                    dealer_id:selectedOption.dealer_id,
                                                    dealer_name:selectedOption.dealer_name,
                                                    retailer_percentage:selectedOption.retailer_percentage,
                                                    without_offer_price:selectedOption.without_offer_price
                                                }
                                            }}
                                        handleChange(event);
                                        loadProductPayable(selectedOption.retailer_percentage)
                                        loadContracts(selectedOption.dealer_id);
                                    }}
                                />
                                <ErrorMessage name={`retailer`} />
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Group  className="mb-3">
                                <Form.Label>Contracts</Form.Label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="contract"
                                    value={values.contract}
                                    getOptionLabel={(option)=>(option.contractType+" - "+option.allowed+"/-")}
                                    getOptionValue={(option)=>option.contractId}
                                    options={contracts}
                                    isLoading={isContractsLoading}
                                    onChange={selectedOption => {
                                        values.used = selectedOption.used;
                                        values.claimType = selectedOption.contractType;
                                        let event = { target : {
                                                name:'contract',
                                                value: {
                                                    contractId:selectedOption.contractId,
                                                    contractType:selectedOption.contractType,
                                                    allowed:selectedOption.allowed,
                                                    used:selectedOption.used,
                                                }
                                            }}
                                        handleChange(event);
                                    }}
                                />
                                <ErrorMessage name={`contract`} />
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row>
                        <Col sm={3}>
                            <Form.Label>
                                Used up Amount
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={values.used}
                                disabled
                                placeholder="Used up Amount" />
                        </Col>
                        <Col sm={3}>
                            <Form.Group  className="mb-3">
                                <Form.Label>Invoice number</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={values.invoiceNumber}
                                    onChange={handleChange}
                                    name="invoiceNumber"
                                    isValid={touched.invoiceNumber && !errors.invoiceNumber}
                                    isInvalid={touched.invoiceNumber && !!errors.invoiceNumber}
                                    placeholder="Today" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.invoiceNumber}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Group  className="mb-3">
                                <Form.Label>Invoice Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={values.invoiceDateField}
                                    onChange={ event => {
                                        values.invoiceDate = new Date(event.currentTarget.value).getTime();
                                        handleChange(event);
                                    }}
                                    name="invoiceDateField"
                                    isValid={touched.invoiceDateField && !errors.invoiceDateField}
                                    isInvalid={touched.invoiceDateField && !!errors.invoiceDateField}
                                    placeholder="Today" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.invoiceDateField}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            <Form.Label>
                                Today
                            </Form.Label>
                            <Form.Control
                                type="date"
                                value={values.currentDate}
                                name="currentDate"
                                isValid={touched.currentDate && !errors.currentDate}
                                isInvalid={touched.currentDate && !!errors.currentDate}
                                disabled
                                placeholder="Today" />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.currentDate}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <Form.Group  className="mb-3">
                                <Form.Label>Total Amount Claimed by Distributor</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={values.totalAmountClaim}
                                    onChange={ handleChange}
                                    name="totalAmountClaim"
                                    isValid={touched.totalAmountClaim && !errors.totalAmountClaim}
                                    isInvalid={touched.totalAmountClaim && !!errors.totalAmountClaim}
                                    placeholder="Total Invoice Value" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.totalAmountClaim}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br/>
                    <Card.Title tag="h5">Claim Products</Card.Title>
                    <br/>
                    <Card.Body>
                        <FieldArray
                            name="claims"
                            render={arrayHelpers => (
                                <>
                                    {
                                        values.claims && values.claims.length > 0 ? (
                                            values.claims.map((claimProduct, index) => (
                                                <Row key={index}>
                                                    <Row>
                                                        <Col md={4}>
                                                            <Form.Label htmlFor="product">Product</Form.Label>
                                                            <Form.Group className="mb-3">
                                                                <Select
                                                                    key={`claims.${index}`}
                                                                    className="react-select-container"
                                                                    classNamePrefix="react-select"
                                                                    name={`claims[${index}].product`}
                                                                    value={claimProduct.product}
                                                                    getOptionLabel={(option)=>option.name}
                                                                    getOptionValue={(option)=>option.id}
                                                                    options={productOptions}
                                                                    isDisabled={isProductsLoading}
                                                                    isLoading={isProductsLoading}
                                                                    onChange={selectedOption => {
                                                                        let event = { target : { name:`claims[${index}].product`,value: selectedOption } };
                                                                        handleChange(event);

                                                                    }}
                                                                />
                                                                <ErrorMessage name={`claims[${index}].product`} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Label htmlFor="productCount">Count</Form.Label>
                                                            <InputGroup>
                                                                <FormControl
                                                                    key={`claims.${index}`}
                                                                    placeholder="Count"
                                                                    type="number"
                                                                    name={`claims[${index}].productCount`}
                                                                    value={claimProduct.productCount }
                                                                    onChange={handleChange}
                                                                    disabled={!claimProduct.product} />
                                                            </InputGroup>
                                                            <ErrorMessage name={`claims[${index}].productCount`} />
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Label htmlFor="productCount">Trader Invoice Price</Form.Label>
                                                            <InputGroup>
                                                                <FormControl
                                                                    key={`claims.${index}`}
                                                                    placeholder="Price"
                                                                    type="number"
                                                                    name={`claims[${index}].productPrice`}
                                                                    value={claimProduct.productPrice }
                                                                    onChange={handleChange}
                                                                    disabled={!claimProduct.product} />
                                                            </InputGroup>
                                                            <ErrorMessage name={`claims[${index}].productPrice`} />
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginBottom: "20px" }}>
                                                        <Col md={2}>
                                                            <Form.Label htmlFor="sale">Sale</Form.Label>
                                                            <InputGroup>
                                                                <FormControl
                                                                    key={`claims.${index}`}
                                                                    placeholder="Sale"
                                                                    type="number"
                                                                    name={`claims[${index}].sale`}
                                                                    value={ loadIndividualProductValues(claimProduct,"sale") }
                                                                    onChange={handleChange}
                                                                    disabled
                                                                />
                                                                <ErrorMessage name={`claims[${index}].sale`} />
                                                            </InputGroup>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Label htmlFor="tax">Tax</Form.Label>
                                                            <InputGroup>
                                                                <FormControl
                                                                    key={`claims.${index}`}
                                                                    placeholder="Tax"
                                                                    type="number"
                                                                    name={`claims[${index}].tax`}
                                                                    value={ loadIndividualProductValues(claimProduct,"tax") }
                                                                    onChange={handleChange}
                                                                    disabled
                                                                />
                                                                <ErrorMessage name={`claims[${index}].tax`} />
                                                            </InputGroup>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Label htmlFor="productTotal">Total</Form.Label>
                                                            <InputGroup>
                                                                <FormControl
                                                                    key={`claims.${index}`}
                                                                    placeholder="Total"
                                                                    type="number"
                                                                    name={`claims[${index}].productTotal`}
                                                                    value={ loadIndividualProductValues(claimProduct,"productTotal") }
                                                                    disabled
                                                                />
                                                                <ErrorMessage name={`claims[${index}].productTotal`} />
                                                            </InputGroup>
                                                        </Col>
                                                        <Col md={4}>
                                                            <Form.Label htmlFor="productTotal">Number of Months claim </Form.Label>
                                                            <InputGroup>
                                                                <FormControl
                                                                    key={`claims.${index}`}
                                                                    placeholder="Amount Payable"
                                                                    type="number"
                                                                    name={`claims[${index}].amountPayable`}
                                                                    value={claimProduct.amountPayable }
                                                                    onChange={handleChange}
                                                                />

                                                                <Button onClick={() => arrayHelpers.push({
                                                                    product: undefined,
                                                                    productCount:0,
                                                                    productPrice:0,
                                                                    productTotal:0,
                                                                    amountPayable:0
                                                                })} variant="secondary">Add More</Button>
                                                                <Button onClick={() => {
                                                                    arrayHelpers.remove(index);
                                                                }} variant="secondary">Remove</Button>
                                                                <ErrorMessage name={`claims[${index}].amountPayable`} />
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                </Row>))):(
                                            arrayHelpers.push({
                                                product: undefined,
                                                productCount:0,
                                                productPrice:0,
                                                productTotal:0,
                                                amountPayable:0,
                                                evaluate:undefined,
                                                reason:""
                                            })
                                        )}
                                </>
                            )}
                        />
                    </Card.Body>
                    <br/>
                    <Row>
                        <Col sm={3}>
                            <Form.Label>
                                Total amount payable
                            </Form.Label>
                            <Form.Control
                                type="number"
                                value={returnPayableValue(values,"claim")}
                                disabled/>
                        </Col>
                        <Col sm={3}>
                            <Form.Label>
                                Total GST Deductible
                            </Form.Label>
                            <Form.Control
                                type="number"
                                value={returnPayableValue(values,"gst")}
                                disabled/>
                        </Col>
                        <Col sm={3}>
                            <Form.Label>
                                Total payable
                            </Form.Label>
                            <Form.Control
                                type="number"
                                value={returnPayableValue(values,"actual")}
                                disabled/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Button type="submit" variant="primary">Submit</Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>)}
            </Formik>

        </Card.Body>
    </Card>);
}

const ClaimOtherFormPage = () => {


    var date = new Date();
    var day = ("0" + date.getDate()).slice(-2);
    var month = ("0" + (date.getMonth() + 1)).slice(-2);

    const initialValues = {
        dealer: undefined,
        retailer: undefined,
        currentDate:date.getFullYear()+"-"+(month)+"-"+(day),
        invoiceNumber:"",
        invoiceDate:"",
        totalAmountClaim:0,
        claims:[{
            product: undefined,
            productCount:0,
            productPrice:0,
            productTotal:0,
            amountPayable:0,
            evaluate:undefined,
            reason:""
        }]
    };
    return( <React.Fragment>
            <Helmet title="Claims" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Claims</h1>
                <Row>
                    <Col lg="12">
                        <ClaimOtherForm initialValues={initialValues} successUrl={"/claims/claim"} />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}
export {ClaimOtherForm}
export default ClaimOtherFormPage;
