import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Alert, Badge, Button, Col, Container, Nav, Row, Tab, Table} from "react-bootstrap";
import {useParams} from "react-router-dom";
import axiosInstance, {DownloadAttachment} from "../../../utils/axios";
import Loader from "../../../components/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faExclamation, faTimes} from "@fortawesome/free-solid-svg-icons";
import {ClaimForm} from "./ClaimForm";
import {ClaimOtherForm} from "./ClaimOtherForm";

const ClaimDetails = () => {
    const params = useParams();
    const [claimData,setClaimData] = useState();
    const [claimApproved,setClaimApproved] = useState(false);
    const [claimRejected,setClaimRejected] = useState(false);

    useEffect(updateData,[]);

    function updateData(){
        try{
            let entityURL = "/api/claims_tracker/"+params.claimID;
            axiosInstance.get(entityURL)
                .then(function(response){
                    setTimeout(() => {
                        var sidate = new Date(Number(response.data.claims_tracker.invoiceDate));
                        var siday = ("0" + sidate.getDate()).slice(-2);
                        var simonth = ("0" + (sidate.getMonth() + 1)).slice(-2);
                        var sitoday = sidate.getFullYear()+"-"+(simonth)+"-"+(siday)
                        response.data.claims_tracker.invoiceDateField = sitoday

                        var now = new Date(Number(response.data.claims_tracker.createdDate));
                        var today = ("0" + now.getDate()).slice(-2);
                        var tomonth = ("0" + (now.getMonth() + 1)).slice(-2);
                        today = now.getFullYear()+"-"+(tomonth)+"-"+(today)
                        response.data.claims_tracker.currentDate = today
                        setClaimData(response.data.claims_tracker);
                        setClaimApproved(false);
                        setClaimRejected(false);
                    }, 500);
                })
        }catch(e){
            console.log(e);
        }
    }

    function approveClaim(){
        axiosInstance("/api/claims_tracker/"+claimData.claim_id+"/approve").then((response,success) => {
            setClaimApproved(true);
            updateData();
        });
    }

    function rejectClaim(){
        axiosInstance("/api/claims_tracker/"+claimData.claim_id+"/reject").then((response,success) => {
            setClaimRejected(true);
            updateData();
        });
    }


    if(claimData ===undefined){
        return <Loader />;
    }else{
        return(

            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Claim for {claimData.dealer.dealer_name} - {claimData.retailer.dealer_name}</h1>
                <Row>
                    <Col lg="12">
                        <div className={"tab " }>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Nav variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Details</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Approval</Nav.Link>
                                    </Nav.Item>
                                    {claimData.approvalStatus==="WAITING" && <Nav.Item><Nav.Link eventKey="third">Modify Claim</Nav.Link></Nav.Item>}
                                </Nav>
                                <Tab.Content>
                                    <Alert show={claimApproved} onHide={claimApproved} variant="success" key="2" dismissible>
                                        <div className="alert-message">
                                            <strong>Claim</strong> approved successfully!
                                        </div>
                                    </Alert>
                                    <Alert show={claimRejected} onHide={claimRejected} variant="danger" key="3" dismissible>
                                        <div className="alert-message">
                                            <strong>Claim</strong> rejected!
                                        </div>
                                    </Alert>
                                    <Tab.Pane eventKey="first">
                                        <Row>
                                            <Col lg="4">
                                                <h4 className="tab-title">Claim Details</h4>
                                                <Table size="sm" className="my-2">
                                                    <tbody>
                                                    <tr>
                                                        <th>Type</th>
                                                        <td>{claimData.claimType}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Created on</th>
                                                        <td>{new Date(Number(claimData.createdDate)).toString().slice(3,21)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Created by</th>
                                                        <td>{claimData.createdBy.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Invoice Number</th>
                                                        <td>{claimData.invoiceNumber}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Invoice Date</th>
                                                        <td>{new Date(Number(claimData.invoiceDate)).toString().slice(3,21)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Total Claim Value</th>
                                                        {claimData.totalAmountClaim!=null && <td>{claimData.totalAmountClaim.toLocaleString("en-IN", {
                                                            style: "currency",
                                                            currency: "INR"
                                                        })}</td>}
                                                        {claimData.totalAmountClaim==null && <td>0</td>}
                                                    </tr>
                                                    <tr>
                                                        <th>Total Amount Payable</th>
                                                        <td>{claimData.totalAmountPayable.toLocaleString("en-IN", {
                                                            style: "currency",
                                                            currency: "INR"
                                                        })}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Total Amount Paid</th>
                                                        {claimData.totalAmountPaid!=null && <td>{claimData.totalAmountPaid.toLocaleString("en-IN", {
                                                            style: "currency",
                                                            currency: "INR"
                                                        })}</td>}
                                                        {claimData.totalAmountPaid==null && <td>0</td>}
                                                    </tr>
                                                    <tr>
                                                        <th>Status</th>
                                                        <td>
                                                            {
                                                                claimData.approvalStatus==="TRUE"?
                                                                    <Badge bg="success"><FontAwesomeIcon icon={faCheck} /> Approved</Badge>:
                                                                    claimData.approvalStatus==="FALSE"?
                                                                        <Badge bg="danger" ><FontAwesomeIcon icon={faTimes} /> Rejected</Badge>:
                                                                        claimData.approvalStatus==="WAITING"?
                                                                            <Badge bg="warning" ><FontAwesomeIcon icon={faExclamation} /> Waiting for Approval</Badge>:<Badge>{claimData.status}</Badge>
                                                            }
                                                        </td>
                                                    </tr>
                                                    {claimData.approvalStatus==="FALSE" && <tr><th>Rejected By</th><td>{claimData.approvalBy.name}</td></tr>}
                                                    {claimData.approvalStatus==="TRUE" && <tr><th>Approved By</th><td>{claimData.approvalBy.name}</td></tr>}

                                                    {claimData.approvalStatus==="WAITING" && <tr><th style={{verticalAlign:'middle'}}>Download Dealers Claims</th><td><Button variant={`info`} className=" me-1 mb-1" onClick={() => {
                                                        window.open("/claims_tracker/"+claimData.dealer.dealer_id+"/downloadFile")
                                                    }} > Download All Unapproved Claim - PDF </Button></td></tr> }

                                                    {claimData.approvalStatus==="WAITING" && <tr><th style={{verticalAlign:'middle'}}>Download Dealers Claims</th><td><Button variant={`info`} className=" me-1 mb-1" onClick={() => {
                                                        window.open("/claims_tracker/"+claimData.dealer.dealer_id+"/downloadFile/excel")
                                                    }} > Download All Unapproved Claim - EXCEL </Button></td></tr> }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row>
                                            <Col lg="4">
                                                <h4 className="tab-title">Retailer Details</h4>
                                                <Table size="sm" className="my-2">
                                                    <tbody>
                                                    <tr>
                                                        <th>Name</th>
                                                        <td>{claimData.retailer.dealer_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Contact Name</th>
                                                        <td>{claimData.retailer.contact_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Number</th>
                                                        <td>{claimData.retailer.phone_number}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>GST</th>
                                                        <td>{claimData.retailer.gst}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Discount percentage</th>
                                                        <td>{claimData.retailer.retailer_percentage}</td>
                                                    </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row>
                                            <Col lg="10">
                                                <h4 className="tab-title">Product Claims </h4>
                                                <div className="table-responsive">
                                                    <Table size="sm" className="my-2">
                                                        <thead>
                                                        <tr>
                                                            <th>Product Name</th>
                                                            <th>Price</th>
                                                            <th>Count</th>
                                                            <th>Total ( Sales + Tax )</th>
                                                            <th>Amount Payable ( GST Excluded )</th>
                                                            <th>Reason ( if applicable )</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        { claimData.claims.map((claim, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{claim.product.name}</td>
                                                                    <td>{claim.productPrice}</td>
                                                                    <td>{claim.productCount}</td>
                                                                    <td>{claim.productTotal}</td>
                                                                    <td>{claim.amountPayable}</td>
                                                                    <td>{claim.reason}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <Row>
                                            <Col lg="6">
                                                <h4 className="tab-title">Product Claims </h4>
                                                <div className="table-responsive">
                                                    <Table size="sm" className="my-2">
                                                        <thead>
                                                        <tr>
                                                            <th>Product Name</th>
                                                            <th>Price</th>
                                                            <th>Count</th>
                                                            <th>Invoice Value</th>
                                                            <th>Amount Payable</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        { claimData.claims.map((claim, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{claim.product.name}</td>
                                                                    <td>{claim.productPrice}</td>
                                                                    <td>{claim.productCount}</td>
                                                                    <td>{claim.productTotal}</td>
                                                                    <td>{claim.amountPayable}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Col>
                                        </Row>
                                        <br/>
                                        { claimData.approvalStatus==="WAITING" && <Button onClick={approveClaim} variant={`success`} className=" me-1 mb-1" > Approve </Button> }
                                        { claimData.approvalStatus==="WAITING" && <Button onClick={rejectClaim} variant={`danger`} className=" me-1 mb-1" > Reject </Button> }

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        {claimData.approvalStatus==="WAITING" && claimData.claimType==="CLAIM" && <ClaimForm initialValues={claimData} setClaimData={setClaimData} />}
                                        {claimData.approvalStatus==="WAITING" && claimData.claimType!=="CLAIM" && <ClaimOtherForm initialValues={claimData} setClaimData={setClaimData} />}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }


};

const ClaimDetailsPage = () => (

    <React.Fragment>
        <Helmet title="Claim Details" />
        <ClaimDetails />
    </React.Fragment>
);

export default ClaimDetailsPage;
