import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Badge, Button, Col, Container, Nav, Row, Tab, Table} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../../utils/axios";
import Loader from "../../../components/Loader";
import {ExternalLink} from "react-feather";
import EntityTable, {CustomFilter} from "../../../components/EntityTable";
import SDDistributorForm from "./SDDistributorForm";


const SDDistributorDetails = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [distributorData,setDistributorData] = useState();
    const [searchCondition,setSearchCondition] = useState();
    const [searchConditionSelectedValue,setSearchConditionSelectedValue] = useState();
    const [searchCondition1,setSearchCondition1] = useState();
    const [searchConditionSelectedValue1,setSearchConditionSelectedValue1] = useState();

    const regionSearchCondition = [{field: "country.id", type: "number", value: "96"}]
    const [defaultSearchCondition,SetDefaultSearchCondition] = useState([])

    const sdBeatsColumns = [
        {
            Header: "SD ID",
            accessor: "beat_sd_id",
        },
        {
            Header: "Name",
            accessor: "beat_name",
            Cell:  ({ row }) => {
                return <>{row.original.beat_name} <Button onClick={() => {
                    navigate("/sd/beats-details/"+row.original.beat_id)
                }}>Goto beat <ExternalLink  size={14} className="align-middle" /></Button></>
            }
        },
        {
            Header: "Region",
            accessor: "beat_region.region_name",
            Filter: () => {
                return CustomFilter({
                        entityUrl:"regions",
                        setCondition:setSearchCondition,
                        entityId:"id",
                        entityValue:"region_name",
                        searchField:"beat_region.id",
                        searchType:"number",
                        entity:"regions",
                        searchCondition:regionSearchCondition,
                        selectedValue:searchConditionSelectedValue1,
                        setSelectedValue:setSearchConditionSelectedValue1
                    }
                )
            },
            Cell: ({ row }) => {
                const [region,setRegion] = useState(row.original.beat_region);
                if(region==null){
                    return(<Button onClick={() => {
                        axiosInstance.get("/api/sd_beats/"+row.original.beat_id+"/sync/region").then(function(response){
                            setRegion(response.data.sd_beats.beat_region);
                        })
                    }}> Sync distributor region </Button>)
                }
                return(
                    <>{region.region_name}</>
                );
            },
        },
        {
            Header: "Sync Count",
            Cell: ({ row }) => {
                return(
                    <Button style={{width:'100%'}} onClick={() => {
                        axiosInstance.get("/api/sd_beats/"+row.original.beat_id+"/sync/count")
                    }}>Sync count</Button>
                );
            },
        },
        {
            Header: "Retailer Count",
            accessor: "retailer_count",
        },
        {
            Header: "Outlet type 1",
            Cell: ({ row }) => {
                return(
                    <>
                        Super Market Count : {row.original.superMarketCount}<br/>
                        MTCount : {row.original.mtcount}<br/>
                        Chemist : {row.original.chemistCount}<br/>
                        FancyCount : {row.original.fancyCount}<br/>

                    </>
                );
            },
            accessor: "superMarketCount",
        },
        {
            Header: "Outlet type 2",
            Cell: ({ row }) => {
                return(
                    <>
                        CosmeticsCount : {row.original.cosmeticsCount}<br/>
                        HypermarketCount : {row.original.hypermarketCount}<br/>
                        KiranaCount : {row.original.kiranaCount}<br/>
                        GMCount : {row.original.gmcount}<br/>
                        OthersCount : {row.original.othersCount}
                    </>
                );
            },
            accessor: "KiranaCount",
        },
        {
            Header: "SD Distributor",
            accessor: "distributor.distributorName",
            Filter: () => {
                return CustomFilter({
                        entityUrl:"sd_distributors",
                        setCondition:setSearchCondition1,
                        entityId:"distributor_id",
                        entityValue:"distributorName",
                        searchField:"distributor.distributor_id",
                        searchType:"number",
                        entity:"sd_distributors",
                        selectedValue:searchConditionSelectedValue,
                        setSelectedValue:setSearchConditionSelectedValue
                    }
                )
            },
        },
        {
            Header: "Firestore",
            accessor: "beat_firestore_id",
            Filter:false,
            Cell: ({ row }) => {
                const [firestoreID,setFirestoreID] = useState(row.original.beat_firestore_id);
                if(firestoreID==null){
                    if(distributorData.distributor_firestore_id==null){
                        return(<>Sync Distributor first</>)
                    }

                    return(<Button onClick={() => {
                        axiosInstance.get("/api/sd_beats/"+row.original.beat_id+"/sync").then(function(response){
                            setFirestoreID(response.data.sd_beats.beat_firestore_id);
                        });
                    }}> Sync to server </Button>)
                }
                return(
                    <>{firestoreID}</>
                );
            },
        },
    ];

    let condition = defaultSearchCondition;
    searchCondition!=null && condition.push(searchCondition)
    searchCondition1!=null && condition.push(searchCondition1)
    if(condition.length==0)
        condition = null

    useEffect(() => {
        const load =  () => {
            try{
                let entityURL = "/api/sd_distributors/"+params.distributorID;
                axiosInstance.get(entityURL)
                    .then(function(response){

                        setTimeout(() => {
                            setDistributorData(response.data.sd_distributors);
                            SetDefaultSearchCondition([{field: "distributor.distributor_id", type: "number", value: ""+response.data.sd_distributors.distributor_id}])
                        }, 1500);
                    })
            }catch(e){
                console.log(e);
            }
        }

        load();
    },[]);

    if(distributorData === undefined){
        return <Loader />;
    }else{
        return(

            <Container fluid className="p-0">
                <h1 className="h3 mb-3">{distributorData.distributorName}</h1>
                <Row>
                    <Col lg="12">
                        <div className={"tab " }>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Nav variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Details</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Modify</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">Beats</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <h4 className="tab-title">Contact Info</h4>
                                            <Col lg="6">
                                                <Table size="sm" className="my-2">
                                                    <tbody>
                                                    <tr>
                                                        <th>Name</th>
                                                        <td>{distributorData.distributorName}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Address</th>
                                                        <td>{distributorData.distributor_address}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Pin Code</th>
                                                        <td>{distributorData.distributor_pincode}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Region</th>
                                                        <td>{distributorData.distributor_region.region_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Phone</th>
                                                        <td>{distributorData.distributor_phone}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Mobile</th>
                                                        <td>{distributorData.distributor_mobile}</td>
                                                    </tr>

                                                    <tr>
                                                        <th>Email</th>
                                                        <td>{distributorData.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>GST</th>
                                                        <td>{distributorData.distributor_gst}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Location</th>
                                                        <td>{distributorData.distributor_loc_lat} , {distributorData.distributor_loc_long}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Status</th>
                                                        <td>
                                                            {distributorData.distributor_firestore_id!=null && <Badge bg="success">Active</Badge>}
                                                            {distributorData.distributor_firestore_id==null && <Badge bg="warning">Not yet synced with server</Badge>}
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>
                                            <br/>
                                            <h4 className="tab-title">Update</h4>
                                            <Col>
                                                {distributorData.distributor_firestore_id==null && distributorData.distributor_gst!=null &&<Button onClick={() => {
                                                    axiosInstance.get("/api/sd_distributors/"+distributorData.distributor_id+"/sync")
                                                        .then(function(response){

                                                            setTimeout(() => {
                                                                setDistributorData(response.data.sd_distributors);
                                                                SetDefaultSearchCondition([{field: "distributor.distributor_id", type: "number", value: ""+response.data.sd_distributors.distributor_id}])
                                                            }, 1500);
                                                        })
                                                }} variant={'info'}>Sync to server</Button>}
                                                {distributorData.distributor_firestore_id==null && distributorData.distributor_gst==null &&<Button  variant={'danger'}>Update with GST details </Button>}
                                                <br/>
                                                <br/>
                                                {distributorData.distributor_firestore_id!=null && <><Button variant={'info'}>Update data to server</Button><br/><br/></>}
                                            </Col>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <SDDistributorForm initialValues={distributorData} setDealerData={setDistributorData} successUrl={"/sd/distributors-details/"+distributorData.distributor_id}/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        {condition!=null && <EntityTable
                                            key={new Date().getTime()}
                                            columns={sdBeatsColumns}
                                            url={"sd_beats"}
                                            pageSize={200}
                                            sortBy={[{field: "beat_name", order: "asc"}]}
                                            buttons={[]}
                                            searchCondition={condition}
                                        />}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </Col>
                </Row>
            </Container>
            );
    }


};

const SDDistributorDetailsPage = () => (

    <React.Fragment>
        <Helmet title="SD Distributor Details" />
        <SDDistributorDetails />
    </React.Fragment>
);

export default SDDistributorDetailsPage;
