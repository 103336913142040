import React from "react";

import {Dropdown} from "react-bootstrap";

import {Settings} from "react-feather";
import useAuth from "../../hooks/useAuth";
import ReactDOM from "react-dom";
import {UserChangePasswordForm} from "../../pages/modules/settings/users/UserChangePasswordForm";

const NavbarUser = () => {
    const { user, signOut } = useAuth();

    function changePassword() {
        return ReactDOM.render(
            <>
                <UserChangePasswordForm
                    userId={user.user_id}
                    key={new Date().getTime()}
                />
            </>,
            document.getElementById("content")
        );
    }

    return (
        <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
            <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          {/*<img*/}
            {/*  src={avatar1}*/}
            {/*  className="avatar img-fluid rounded-circle me-1"*/}
            {/*  alt="Chris Wood"*/}
            {/*/>*/}
            <span className="text-dark">
            {user.name ? user.name : user.username}{" "}
          </span>
        </Dropdown.Toggle>
      </span>
            <Dropdown.Menu drop="end">
                {/*<Dropdown.Item>*/}
                {/*  <User size={18} className="align-middle me-2" />*/}
                {/*  Profile*/}
                {/*</Dropdown.Item>*/}
                {/*<Dropdown.Item>*/}
                {/*  <PieChart size={18} className="align-middle me-2" />*/}
                {/*  Analytics*/}
                {/*</Dropdown.Item>*/}
                {/*<Dropdown.Divider />*/}
                {/*<Dropdown.Item>Settings & Privacy</Dropdown.Item>*/}
                {/*<Dropdown.Item>Help</Dropdown.Item>*/}
                <Dropdown.Item
                    onClick={() => {
                        changePassword();
                    }}
                >
                    Change Password
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => {
                        signOut();
                    }}
                >
                    Sign out
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default NavbarUser;
