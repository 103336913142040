import React, {useContext} from "react";

import {Button, Card, Col, Form, Row} from "react-bootstrap";
import * as Yup from "yup";
import {Formik} from "formik";
import axiosInstance, {GetAsInputData} from "../../../../utils/axios";
import NotyfContext from "../../../../contexts/NotyfContext";

const DesignationForm = ({ setKey, initialValues, setEditData }) => {
  const notify = useContext(NotyfContext);
  const schema = Yup.object().shape({
    name: Yup.string().required("Designation is required"),
  });

  function HandleSubmit(url, data, navigateTo) {
    axiosInstance.post("/api/" + url, data).then(function (response, success) {
      notify.success("Designation created");
      setTimeout(() => {
        setKey(new Date().getTime());
      }, 1500);
    });
  }

  function HandlePut(url, data, navigateTo) {
    axiosInstance.put("/api/" + url, data).then(function (response, success) {
      notify.success("Designation updated");
      setTimeout(() => {
        setEditData(null);
        setKey(new Date().getTime());
      }, 1500);
    });
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5">Add Designation</Card.Title>
      </Card.Header>
      <Card.Body>
        <Formik
          validationSchema={schema}
          onSubmit={async (values) => {
            if (values.id === undefined)
              HandleSubmit(
                "designations",
                GetAsInputData({ designation: values })
              );
            else
              HandlePut(
                "designations/" + values.id,
                GetAsInputData({ designation: values })
              );
          }}
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row>
                <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isValid={touched.name && !errors.name}
                      isInvalid={touched.name && !!errors.name}
                      placeholder="Name"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Button type="submit" variant="primary">
                      Submit
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default DesignationForm;
