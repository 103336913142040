import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import {Badge, Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import * as Icon from 'react-feather';
import {FirebaseUserForm} from "./FirebaseUserForm";
import EntityTable, {SelectColumnFilter} from "../../../../components/EntityTable";

const userButtons = [
    {
        key:0,
        name:"New User",
        href:"/users/sales-new"
    },
];

const SalesUsersPage = () => {

    const [editData,setEditData] = useState('');

    const firebaseUsersTableColumns = [
        {
            Header: "ID",
            accessor: "uid",
        },
        {
            Header: "Phone Number",
            accessor: "phoneNumber",
        },
        {
            Header: "Name",
            accessor: "displayName",
            Filter:false
        },
        {
            Header: "Created on",
            accessor: "userMetadata.creationTimestamp",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                let date = new Date(Number(value));
                date = date.toString().slice(3,21)
                return date;
            }
        },
        {
            Header: "Last signed on",
            accessor: "userMetadata.lastSignInTimestamp",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                let date = new Date(Number(value));
                date = date.toString().slice(3,21)
                return date;
            }
        },
        {
            Header: "Last refresh at",
            accessor: "userMetadata.lastRefreshTimestamp",
            Filter: false,
            Cell: ({ cell: { value } }) => {
                let date = new Date(Number(value));
                date = date.toString().slice(3,21)
                return date;
            }
        },
        {
            Header: "Status",
            accessor: "disabled",
            Filter: SelectColumnFilter,
            Cell: ({ cell: { value } }) => {
                if(value===false){
                    return(<Badge bg="success" >
                        <FontAwesomeIcon icon={faCheck} /> Enabled
                    </Badge>);
                }else if(value===true){
                    return(<Badge bg="danger" >
                        <FontAwesomeIcon icon={faTimes} /> Disabled
                    </Badge>);
                }
            }
        },
        {
            Header: "Actions",
            Cell: ({ row }) => {
                return <Icon.Edit2 onClick={() =>{
                    setEditData(row.original);
                }}/>
            }
        }
    ];

    if(editData===''){
        return(<React.Fragment>
            <Helmet title="Users"/>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Sales APP Users</h1>
                <EntityTable
                    columns={firebaseUsersTableColumns}
                    url={"firebaseUsers"}
                    buttons={userButtons}
                />
            </Container>
        </React.Fragment>);
    }else{
        return (<React.Fragment>
            <Helmet title="New Firebase User" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Edit Firebase User</h1>

                <Row>
                    <Col lg="4">
                        <FirebaseUserForm initialValues={editData} successUrl="/users/sales" setEditData={setEditData} />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>);
    }


};

export default SalesUsersPage;



