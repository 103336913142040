import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";

import {Alert, Button, Card, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import Select from "react-select";
import axiosInstance, {ErrorMessage, GetAsInputData} from "../../../utils/axios";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {FieldArray, Formik} from "formik";

const schema = Yup.object().shape({
    company_name: Yup.string().required("Supplier is required"),
    gst: Yup.string()
        .required("GST is required")
        .min(15, "Must be exactly 15 digits")
        .max(15, "Must be exactly 15 digits"),
    name: Yup.string().required("Contact Name is required"),
    address1: Yup.string().required("Address is required"),
    address2: Yup.string().required("Pin code is required"),
    number: Yup.string().required("Phone number is required"),
    land_line: Yup.string().required("Landline number is required"),
    email: Yup.string().email(),
    zone: Yup.object().required("Zone is required"),
    supplierProducts: Yup.array().of(
        Yup.object().shape({
            product: Yup.object().required("Product is required"),
            price: Yup.number().required("Basic Rate is required"),
        })
    ),
});

const SupplierForm = ({ initialValues, successUrl, setEditData, setKey }) => {
    const navigate = useNavigate();

    const [regionOptions, setRegionOptions] = useState([]);
    const [isRegionLoading, setIsRegionLoading] = useState(true);
    const [productOptions, setProductOptions] = useState([]);
    const [isProductLoading, setIsProductLoading] = useState(true);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function HandlePost(url, data, navigateTo) {
        axiosInstance.post("/api/" + url, data).then(function (response, success) {
            if (response.status === 200) navigate(navigateTo);
        });
    }

    function HandlePut(url, data, navigateTo) {
        axiosInstance.put("/api/" + url, data).then(function (response, success) {
            handleShow();
            setTimeout(() => {
                handleClose();
                setEditData(null);
                setKey(new Date().getTime());
            }, 1500);
        });
    }

    useEffect(() => {
        axiosInstance
            .get(
                "/api/zones?" +
                GetAsInputData({
                    listInfo: {
                        startRecord: 0,
                        rowCount: 9999,
                        sortBy: [{ field: "name", order: "asc" }],
                    },
                })
            )
            .then(function (response) {
                setRegionOptions(response.data.zones);
                setTimeout(() => {
                    setIsRegionLoading(false);
                }, 1000);
            });

        axiosInstance
            .get(
                "/api/products?" +
                GetAsInputData({
                    listInfo: {
                        startRecord: 0,
                        rowCount: 9999,
                        sortBy: [{ field: "name", order: "asc" }],
                    },
                })
            )
            .then(function (response) {
                setProductOptions(response.data.products);
                setTimeout(() => {
                    setIsProductLoading(false);
                }, 1500);
            });
    }, []);

    return (
        <Card>
            <Alert show={show} onHide={show} variant="primary" key="0" dismissible>
                <div className="alert-message">
                    <strong>Supplier</strong> updated successfully!
                </div>
            </Alert>
            <Card.Header>
                <Card.Title tag="h5">
                    {initialValues.dealer_id === undefined ? "Add" : "Modify"} Supplier
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Formik
                    validationSchema={schema}
                    onSubmit={async (values) => {
                        await new Promise((r) => setTimeout(r, 500));
                        if (values.id === undefined)
                            HandlePost(
                                "suppliers",
                                GetAsInputData({ supplier: values }),
                                successUrl
                            );
                        else
                            HandlePut(
                                "suppliers/" + values.id,
                                GetAsInputData({ supplier: values }),
                                successUrl
                            );
                    }}
                    initialValues={initialValues}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Supplier</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="company_name"
                                            value={values.company_name}
                                            placeholder="Supplier"
                                            isValid={touched.company_name && !errors.company_name}
                                            isInvalid={touched.company_name && !!errors.company_name}
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.company_name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>GST</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="gst"
                                            value={values.gst}
                                            placeholder="GST"
                                            isValid={touched.gst && !errors.gst}
                                            isInvalid={touched.gst && !!errors.gst}
                                            onChange={(event) => {
                                                const gstNumber = event.currentTarget.value;
                                                if (gstNumber.length !== 15) {
                                                    handleChange(event);
                                                } else {
                                                    handleChange(event);
                                                    axiosInstance
                                                        .get(
                                                            "https://appyflow.in/api/verifyGST?gstNo=" +
                                                            gstNumber +
                                                            "&key_secret=w1pv9sAv18OwYa73oB8Qatj95EH3"
                                                        )
                                                        .then((response) => {
                                                            response = response.data;
                                                            values.company_name =
                                                                response.taxpayerInfo.tradeNam === ""
                                                                    ? response.taxpayerInfo.lgnm
                                                                    : response.taxpayerInfo.tradeNam;
                                                            values.address2 =
                                                                response.taxpayerInfo.pradr.addr.pncd;
                                                            values.address1 = "";
                                                            if (response.taxpayerInfo.pradr.addr.bnm !== "")
                                                                values.address1 +=
                                                                    response.taxpayerInfo.pradr.addr.bnm + ", ";
                                                            if (response.taxpayerInfo.pradr.addr.bno !== "")
                                                                values.address1 +=
                                                                    response.taxpayerInfo.pradr.addr.bno + ", ";

                                                            if (response.taxpayerInfo.pradr.addr.flno !== "")
                                                                values.address1 +=
                                                                    response.taxpayerInfo.pradr.addr.flno + ", ";

                                                            if (response.taxpayerInfo.pradr.addr.lg !== "")
                                                                values.address1 +=
                                                                    response.taxpayerInfo.pradr.addr.lg + ", ";

                                                            if (response.taxpayerInfo.pradr.addr.lt !== "")
                                                                values.address1 +=
                                                                    response.taxpayerInfo.pradr.addr.lt + ", ";

                                                            if (response.taxpayerInfo.pradr.addr.loc !== "")
                                                                values.address1 +=
                                                                    response.taxpayerInfo.pradr.addr.loc + ", ";

                                                            if (response.taxpayerInfo.pradr.addr.st !== "")
                                                                values.address1 +=
                                                                    response.taxpayerInfo.pradr.addr.st + ", ";

                                                            if (response.taxpayerInfo.pradr.addr.dst !== "")
                                                                values.address1 +=
                                                                    response.taxpayerInfo.pradr.addr.dst + ", ";

                                                            if (
                                                                response.taxpayerInfo.pradr.addr.city !== ""
                                                            ) {
                                                                values.address1 +=
                                                                    response.taxpayerInfo.pradr.addr.city + ", ";
                                                                values.zone =
                                                                    response.taxpayerInfo.pradr.addr.city;
                                                            }

                                                            if (
                                                                response.taxpayerInfo.pradr.addr.stcd !== ""
                                                            ) {
                                                                values.address1 +=
                                                                    response.taxpayerInfo.pradr.addr.stcd;
                                                                values.zone =
                                                                    response.taxpayerInfo.pradr.addr.stcd;
                                                            }
                                                            values.address1 = values.address1.replaceAll(
                                                                ",,",
                                                                ","
                                                            );
                                                            values.gst = response.taxpayerInfo.gstin;
                                                            values.distributorPan =
                                                                response.taxpayerInfo.panNo;

                                                            let anotherEvent = {
                                                                target: {
                                                                    name: `company_name`,
                                                                    value: values.company_name,
                                                                },
                                                            };
                                                            handleChange(anotherEvent);
                                                            anotherEvent = {
                                                                target: { name: `gst`, value: values.gst },
                                                            };
                                                            handleChange(anotherEvent);
                                                            anotherEvent = {
                                                                target: {
                                                                    name: `address1`,
                                                                    value: values.address1,
                                                                },
                                                            };
                                                            handleChange(anotherEvent);
                                                            anotherEvent = {
                                                                target: {
                                                                    name: `address2`,
                                                                    value: values.address2,
                                                                },
                                                            };
                                                            handleChange(anotherEvent);
                                                            let zone = regionOptions.filter((e) => {
                                                                if (e.region_name === values.zone) return e;
                                                            });
                                                            if (zone.length != 0) {
                                                                anotherEvent = {
                                                                    target: { name: `zone`, value: zone[0] },
                                                                };
                                                                handleChange(anotherEvent);
                                                            }
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                        });
                                                }
                                            }}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.gst}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="address1"
                                        value={values.address1}
                                        placeholder="Address"
                                        isValid={touched.address1 && !errors.address1}
                                        isInvalid={touched.address1 && !!errors.address1}
                                        onChange={handleChange}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.address1}
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <Form.Label>Region</Form.Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        name="zone"
                                        value={values.zone}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        options={regionOptions}
                                        isLoading={isRegionLoading}
                                        onChange={(selectedOption) => {
                                            let event = {
                                                target: { name: "zone", value: selectedOption },
                                            };
                                            handleChange(event);
                                        }}
                                    />
                                    <ErrorMessage name={`zone`} />
                                </Col>

                                <Col sm={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Pin Code</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="address2"
                                            value={values.address2}
                                            placeholder="Pin Code"
                                            isValid={touched.address2 && !errors.address2}
                                            isInvalid={touched.address2 && !!errors.address2}
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.address2}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Contact Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            placeholder="Contact Name"
                                            isValid={touched.name && !errors.name}
                                            isInvalid={touched.name && !!errors.name}
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col sm={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="number"
                                            value={values.number}
                                            placeholder="Phone Number"
                                            isValid={touched.number && !errors.number}
                                            isInvalid={touched.number && !!errors.number}
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.number}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col sm={3}>
                                    <Form.Label>LandLine Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="land_line"
                                        value={values.land_line}
                                        placeholder="Landline Number"
                                        isValid={touched.land_line && !errors.land_line}
                                        isInvalid={touched.land_line && !!errors.land_line}
                                        onChange={handleChange}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.land_line}
                                    </Form.Control.Feedback>
                                </Col>
                                <Col sm={3}>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={values.email}
                                        placeholder="Email"
                                        isValid={touched.email && !errors.email}
                                        isInvalid={touched.email && !!errors.email}
                                        onChange={handleChange}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Card.Header>
                                <Card.Title tag="h5">Product Suppliers</Card.Title>
                            </Card.Header>
                            <FieldArray
                                name="supplierProducts"
                                render={(arrayHelpers) => (
                                    <div>
                                        {values.supplierProducts &&
                                        values.supplierProducts.length > 0
                                            ? values.supplierProducts.map(
                                                (productSupplier, index) => (
                                                    <Row key={index}>
                                                        <Col md={3}>
                                                            <Form.Label htmlFor="supplier">
                                                                Products
                                                            </Form.Label>
                                                            <Form.Group className="mb-3">
                                                                <Select
                                                                    className="react-select-container"
                                                                    classNamePrefix="react-select"
                                                                    value={productSupplier.product}
                                                                    name={`supplierProducts[${index}].product`}
                                                                    getOptionLabel={(option) => option.name}
                                                                    getOptionValue={(option) => option.id}
                                                                    options={productOptions}
                                                                    isLoading={isProductLoading}
                                                                    onChange={(selectedOption) => {
                                                                        let event = {
                                                                            target: {
                                                                                name: `supplierProducts[${index}].product`,
                                                                                value: selectedOption,
                                                                            },
                                                                        };
                                                                        handleChange(event);
                                                                    }}
                                                                />
                                                                <ErrorMessage
                                                                    name={`supplierProducts[${index}].product`}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Form.Label htmlFor="description">
                                                                Price
                                                            </Form.Label>
                                                            <InputGroup>
                                                                <FormControl
                                                                    key={`supplierProducts.${index}`}
                                                                    placeholder="Price"
                                                                    type="text"
                                                                    value={productSupplier.price}
                                                                    name={`supplierProducts[${index}].price`}
                                                                    onChange={handleChange}
                                                                />
                                                                <Button
                                                                    onClick={() =>
                                                                        arrayHelpers.push({
                                                                            product: undefined,
                                                                            price: 0,
                                                                        })
                                                                    }
                                                                    variant="secondary"
                                                                >
                                                                    Add More
                                                                </Button>
                                                                <Button
                                                                    onClick={() => {
                                                                        arrayHelpers.remove(index);
                                                                    }}
                                                                    variant="secondary"
                                                                >
                                                                    Remove
                                                                </Button>
                                                            </InputGroup>
                                                            <ErrorMessage
                                                                name={`supplierProducts[${index}].Price`}
                                                            />
                                                        </Col>
                                                    </Row>
                                                )
                                            )
                                            : arrayHelpers.push({
                                                product: undefined,
                                                price: 0,
                                            })}
                                    </div>
                                )}
                            />
                            <Row>
                                <Col md={12}>
                                    <Form.Group className="mb-3">
                                        <Button type="submit" variant="primary">
                                            Submit
                                        </Button>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Card.Body>
        </Card>
    );
};

const SupplierFormPage = () => {
    const initialValues = {
        company_name: "",
        gst: "",
        address1: "",
        address2: "",
        land_line: "",
        number: "",
        email: "",
        name: "",
        region: undefined,
        supplierProducts: [
            {
                product: undefined,
                price: 0,
            },
        ],
    };

    return (
        <React.Fragment>
            <Helmet title="New Supplier" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Supplier</h1>

                <Row>
                    <Col lg="12">
                        <SupplierForm
                            initialValues={initialValues}
                            successUrl="/purchases/vendors"
                        />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export { SupplierForm };

export default SupplierFormPage;
