import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";

import {Button, Card, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import Select from "react-select";
import axiosInstance, {GetAsInputData} from "../../../utils/axios";

const ExportSaleOrderForm = () => {
    const [productColl, setProductColl] = useState([{}]);
    const [productOptions, setProductOptions] = useState([]);
    const [isProductsLoading, setIsProductLoading] = useState(true);
    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [isWarehouseLoading, setIsWarehouseLoading] = useState(true);

    function handleAdd(){
        const values = [...productColl];
        values.push({ });
        setProductColl(values);
    }

    function handleRemove(i) {
        const values = [...productColl];
        values.splice(i, 1);
        setProductColl(values);
    }

    useEffect(() => {
        const loadSelectData =  () => {

            try{
                axiosInstance.get("/api/products?"+GetAsInputData({
                    listInfo:{
                        startRecord:0,
                        rowCount:9999,
                        sortBy: [{"field":"name","order":"asc"}],
                        searchConditions:[{"field":"forSale","value":"true","type":"boolean"}]
                    }
                })).then(function(response){
                    setProductOptions(response.data.products);
                    setTimeout(() => {
                        setIsProductLoading(false);
                    }, 1500);
                });

                axiosInstance
                    .get("/api/warehouses?"+GetAsInputData({
                        listInfo:{
                            startRecord:0,
                            rowCount:9999,
                            sortBy: [{"field":"warehouse_name","order":"asc"}],
                        }
                    })).then(function(response){
                    setWarehouseOptions(response.data.warehouses);
                    setIsWarehouseLoading(false);
                });

            }catch(e){
                console.log(e);
            }
        }
        loadSelectData();
    },[]);

    return( <Card>
        <Card.Header>
            <Card.Title tag="h5">Add SaleOrders</Card.Title>
        </Card.Header>
        <Card.Body>
            <Form>
                <Row>
                    <Col sm={6}>
                        <Form.Group  className="mb-3">
                            <Form.Label>Trader Name</Form.Label>
                            <Form.Control type="text" name="name" placeholder="Trader Name" />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                               Po Number
                            </Form.Label>
                            <Form.Control type="text" name="contact_name" placeholder="Contact Name" />
                        </Form.Group>
                    </Col>
                    <Col sm={4}>
                        <Form.Label>
                            Po Date
                        </Form.Label>
                        <Form.Control type="text" name="address" placeholder="Address" />
                    </Col>
                    <Col sm={4}>
                        <Form.Label>
                            Warehouse
                        </Form.Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            getOptionLabel={(option)=>option.warehouse_name}
                            getOptionValue={(option)=>option.warehouse_id}
                            options={warehouseOptions}
                            isLoading={isWarehouseLoading}
                        />
                    </Col>
                </Row>
                <Card.Header/><Card.Title tag="h5">Sale Products</Card.Title><Card.Header/>
                {productColl.map((field, idx) => {
                    return (
                        <Row key={`${field}-${idx}`}>
                            <Col md={4}>
                                <Form.Label htmlFor="mrp">Product</Form.Label>
                                <Form.Group className="mb-3">
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={productOptions.map((key, text) => {
                                            return {
                                                value: key.id,
                                                label: key.name
                                            }}
                                        )}
                                        isLoading={isProductsLoading}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Label htmlFor="box count">Box Count</Form.Label>
                                <InputGroup>
                                    <FormControl placeholder="Box Count" aria-label="Box Count"/>
                                    <Button onClick={() => handleRemove(idx)} variant="secondary">Remove</Button>
                                    <Button onClick={() => handleAdd()} variant="secondary">Add More</Button>

                                </InputGroup>
                            </Col>
                        </Row>
                    );
                })}
                <Row>
                    <Col sm={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Sale
                            </Form.Label>
                            <Form.Control type="text" name="contact_name" placeholder="Sale" disabled/>
                        </Form.Group>
                    </Col>
                    <Col sm={4}>
                        <Form.Label>
                            Tax
                        </Form.Label>
                        <Form.Control type="text" name="address" placeholder="Tax" disabled/>
                    </Col>
                    <Col sm={4}>
                        <Form.Label>
                            Total
                        </Form.Label>
                        <Form.Control type="text" name="address" placeholder="Sales + Tax" disabled/>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form.Group   className="mb-3">
                            <Button variant="primary">Submit</Button>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        </Card.Body>
    </Card>);
}

const ExportSaleOrderFormPage = () => (
    <React.Fragment>
        <Helmet title="New Export Sale Order" />
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Export Sale Order</h1>

            <Row>
                <Col lg="12">
                    <ExportSaleOrderForm />
                </Col>
            </Row>
        </Container>
    </React.Fragment>
);

export default ExportSaleOrderFormPage;
