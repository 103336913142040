import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Col, Container, Nav, Row, Tab, Table} from "react-bootstrap";
import {useParams} from "react-router-dom";
import axiosInstance, {GetAsInputData} from "../../../../utils/axios";
import Loader from "../../../../components/Loader";
import ShippingAddressForm from "../NewDealerAddressForm";
import RetailerMappingForm from "../retailer/RetailerMappingForm";
import {MTDistributorForm} from "./MTDistributorForm";
import {getBadge} from "../../../../utils/misc";
import HistoryTable from "../../history/HistoryTable";
import useAuth from "../../../../hooks/useAuth";
import {roles} from "../../../../utils/roles";
import {CustomerModificationPage} from "../CustomerModificationPage";


const MtDistributorDetails = () => {
    const params = useParams();
    const {user} = useAuth();
    const [dealerData,setDealerData] = useState();
    const [dealerAddressData,setDealerAddressData] = useState([]);
    const [dealerRetailerData,setDealerRetailerData] = useState([]);

    function fetchUpdateDealerData(){
        let entityURL = "/api/dealers/"+params.dealerID;
        axiosInstance.get(entityURL)
            .then(function(response){

                setTimeout(() => {
                    setDealerData(response.data.dealer);
                }, 1500);
            })
    }

    useEffect(() => {
        const load =  () => {
            fetchUpdateDealerData();

            try{
                let entityURL = "/api/dealer_address";

                const input_data = {
                    listInfo:{
                        startRecord:0,
                        rowCount:9999,
                        sortBy:[{"field":"dealer.dealer_name","order":"asc"}],
                        searchConditions:[{"field":"dealer.dealer_id","value":""+params.dealerID,"type":"number"}]
                    }
                };


                let encodedData = GetAsInputData(input_data);

                axiosInstance.get(entityURL+"?"+encodedData)
                    .then(function(response){

                        setTimeout(() => {
                            setDealerAddressData(response.data.dealer_address);
                        }, 1500);
                    })
            }catch(e){
                console.log(e);
            }

            try{
                let entityURL = "/api/dealer_retailer";

                const input_data = {
                    listInfo:{
                        startRecord:0,
                        rowCount:9999,
                        sortBy:[{"field":"retailer.dealer_name","order":"asc"}],
                        searchConditions:[{"field":"dealer.dealer_id","value":""+params.dealerID,"type":"number"}]
                    }
                };


                let encodedData = GetAsInputData(input_data);

                axiosInstance.get(entityURL+"?"+encodedData)
                    .then(function(response){

                        setTimeout(() => {
                            setDealerRetailerData(response.data.dealer_retailer);
                        }, 1500);
                    })
            }catch(e){
                console.log(e);
            }
        }

        load();
    },[]);

    if(dealerData ===undefined){
        return <Loader />;
    }else{
        return(

            <Container fluid className="p-0">
                <h1 className="h3 mb-3">{dealerData.dealer_name}</h1>
                <Row>
                    <Col lg="12">
                        <div className={"tab " }>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Nav variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Details</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="fourth">Add Retailers</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Add Shipping Address</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">Modify MT Distributor</Nav.Link>
                                    </Nav.Item>
                                    {user.roles.indexOf(roles.ROLE_ENABLE_DISABLE_DEALERS_GENERAL_TRADER)!=-1 && <Nav.Item>
                                        <Nav.Link eventKey="fifth">Custom Modification</Nav.Link>
                                    </Nav.Item>}
                                    <Nav.Item>
                                        <Nav.Link eventKey="history">History</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey={"fifth"}>
                                        <CustomerModificationPage dealerData={dealerData} fetchUpdateDealerData={fetchUpdateDealerData}/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={"history"}>
                                        <Row>
                                            <Col lg={6}>
                                                <HistoryTable
                                                    url={
                                                        "/api/audit/dealer/" + dealerData.dealer_id
                                                    }
                                                />
                                            </Col>
                                            {dealerData.products.map((product, i) => {
                                                return (
                                                    <Col lg={6}>
                                                        <HistoryTable
                                                            url={"/api/audit/dealer/product/" + product.dpid}
                                                            name={product.product.name}
                                                        />
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="first">
                                        <h4 className="tab-title">Contact Info</h4>
                                            <Col lg="6">
                                                <Table size="sm" className="my-2">
                                                    <tbody>
                                                    <tr>
                                                        <th>Name</th>
                                                        <td>{dealerData.dealer_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Contact Name</th>
                                                        <td>{dealerData.contact_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Phone Number</th>
                                                        <td>{dealerData.phone_number}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Email</th>
                                                        <td>{dealerData.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>GST</th>
                                                        <td>{dealerData.gst}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Status</th>
                                                        <td>

                                                            {getBadge("Active")}
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        <br/>
                                        <h4 className="tab-title">Billing Address</h4>
                                        <Col lg="6">
                                            <p>
                                                {dealerData.address} {dealerData.sub_address}
                                            </p>
                                        </Col>
                                        <br/>
                                        <h4 className="tab-title">Shipping Address </h4>
                                        <Col lg="6">
                                            <Table size="sm" className="my-2">
                                                {dealerAddressData.length>0?dealerAddressData.map( (row, i) => {
                                                    return(
                                                        <tbody>
                                                            <tr>
                                                                <th>Name</th>
                                                                <td>{row.dealer_contact_name}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Number</th>
                                                                <td>{row.dealer_contact_number}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Address</th>
                                                                <td>{row.dealer_address}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>GST</th>
                                                                <td>{row.dealer_address_gst}</td>
                                                            </tr>
                                                            <br/>
                                                        </tbody>
                                                    );
                                                }):("Same as billing address")}
                                            </Table>
                                        </Col>
                                        <br/>
                                        <h4 className="tab-title">Mapped Retailers </h4>
                                        <Col lg="6">
                                            <Table size="sm" className="my-2">
                                                {dealerRetailerData.length>0?dealerRetailerData.map( (row, i) => {
                                                    return(
                                                        <tbody>
                                                        <tr>
                                                            <th>Retailer Name</th>
                                                            <td>{row.retailer.dealer_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Contact Name</th>
                                                            <td>{row.retailer.contact_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Phone Number</th>
                                                            <td>{row.retailer.phone_number}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Email</th>
                                                            <td>{row.retailer.email}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>GST</th>
                                                            <td>{row.retailer.gst}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Status</th>
                                                            <td>
                                                                {getBadge("Active")}
                                                            </td>
                                                        </tr>
                                                        <br/>
                                                        </tbody>
                                                    );
                                                }):("No retailers mapped")}
                                            </Table>
                                        </Col>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <ShippingAddressForm initialValues={{
                                            dealer:{
                                                dealer_id:dealerData.dealer_id,
                                            },
                                            dealer_contact_name:undefined,
                                            dealer_contact_number:undefined,
                                            dealer_address:undefined,
                                            dealer_address_gst:undefined
                                        }} key={new Date().getTime()} setDealerAddressData={setDealerAddressData} successUrl={"/customers/active/mts-details/"+dealerData.dealer_id} /></Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <MTDistributorForm initialValues={dealerData} setDealerData={setDealerData} successUrl={"/customers/active/mts-details/"+dealerData.dealer_id}/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fourth">
                                        <RetailerMappingForm initialValues={{
                                            dealer:{
                                                dealer_id:dealerData.dealer_id,
                                            },
                                            retailer:undefined,
                                        }} key={new Date().getTime()} setDealerRetailerData={setDealerRetailerData} successUrl={"/customers/active/dealers-details/"+dealerData.dealer_id} /></Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </Col>
                </Row>
            </Container>
            );
    }


};

const MtDistributorDetailsPage = () => (

    <React.Fragment>
        <Helmet title="Mt Distributor Details" />
        <MtDistributorDetails />
    </React.Fragment>
);

export default MtDistributorDetailsPage;
