import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import {Container} from "react-bootstrap";
import EntityTable, {CustomFilter, SelectColumnFilter} from "../../../components/EntityTable";
import {useNavigate} from "react-router-dom";
import {getBadge} from "../../../utils/misc";


const SalesPage = () => {

    const navigate = useNavigate();

    const [searchCondition,setSearchCondition] = useState();
    const [searchConditionSelectedValue,setSearchConditionSelectedValue] = useState();
    const [searchCondition1,setSearchCondition1] = useState();
    const [searchConditionSelectedValue1,setSearchConditionSelectedValue1] = useState();


    const salesTableColumns = [
        {
            Header: "Sales Order ID",
            accessor: "sale_number",
        },
        {
            Header: "Type",
            accessor: "sale_type",
            Filter: SelectColumnFilter,
            filter: "includes",
        },
        {
            Header: "Created Date",
            accessor: "created_date",Filter: false,
            Cell: ({ cell: { value } }) => {
                let date = new Date(Number(value));
                date = date.toString().slice(0,24)
                return date;
            }

        },
        {
            Header: "Created By",
            accessor: "created_by.name",
            Filter: () => {
                return CustomFilter({
                        entityUrl:"users",
                        setCondition:setSearchCondition1,
                        entityId:"user_id",
                        entityValue:"name",
                        searchField:"created_by.user_id",
                        searchType:"number",
                        entity:"users",
                        selectedValue:searchConditionSelectedValue1,
                        setSelectedValue:setSearchConditionSelectedValue1
                    }
                )
            },
        },
        {
            Header: "Trader Name",
            accessor: "dealer.dealer_name",
            Filter: () => {
                return CustomFilter({
                        entityUrl:"customers",
                        setCondition:setSearchCondition,
                        entityId:"dealer_id",
                        entityValue:"dealer_name",
                        searchField:"dealer.dealer_id",
                        searchType:"number",
                        entity:"customers",
                        selectedValue:searchConditionSelectedValue,
                        setSelectedValue:setSearchConditionSelectedValue
                    }
                )
            },
        },
        {
            Header: "PO Number",
            accessor: "po_number",
            Filter: false,
        },
        {
            Header: "Status",
            accessor: "status",
            Filter: SelectColumnFilter,
            Cell: ({ cell: { value } }) => {
                return getBadge(value)
            }
        }
    ];

    const salesButtons = [
        {
            key:0,
            name:"Domestic Sales Order",
            href:"/sales/domestic-orders"
        },
        // {
        //     key:1,
        //     name:"Export Sales Order",
        //     href:"/sales/export-orders"
        // },
        {
            key:2,
            name:"LUT Sales Order",
            href:"/sales/lut-orders"
        }
    ];
    let condition = [];
    searchCondition!=null && condition.push(searchCondition)
    searchCondition1!=null && condition.push(searchCondition1)
    if(condition.length==0)
        condition = null

    return(<React.Fragment>
        <Helmet title="Sales Order"/>
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Sales Order</h1>

            <EntityTable
                columns={salesTableColumns}
                url={"sales_order"}
                sortBy={[{field: "so_number", order: "desc"}]}
                buttons={salesButtons}
                searchCondition={condition}
                rowProps={row => ({
                    onClick: () => navigate("/sales/orders-details/"+row.original.so_number) ,
                    style: {
                        cursor: "pointer"
                    }
                })}
            />
        </Container>
    </React.Fragment>);

};

export default SalesPage;



