import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Container} from "react-bootstrap";
import EntityTable, {CustomFilter, SelectColumnFilter} from "../../../../components/EntityTable";
import {useNavigate, useParams} from "react-router-dom";
import {getBadge} from "../../../../utils/misc";
import axiosInstance from "../../../../utils/axios";


const InvoicePage = () => {

    const navigate = useNavigate();
    const params = useParams();
    const [searchCondition,setSearchCondition] = useState();
    const [searchConditionSelectedValue,setSearchConditionSelectedValue] = useState();
    const [searchCondition1,setSearchCondition1] = useState();
    const [searchConditionSelectedValue1,setSearchConditionSelectedValue1] = useState();
    const [heading,setHeading] = useState("Invoices");

    useEffect(() => {
        axiosInstance.get("/api/warehouses/"+params.warehouseID).then(r => {
            setHeading(r.data.warehouses.warehouse_name+" Invoice")
        })
    },[params])

    const tableColumns = [
        {
            Header: "Invoice Number",
            accessor: "invoice_number",
        },
        {
            Header: "SaleOrder Number",
            accessor: "salesOrderShortList.sale_number",
        },
        {
            Header: "Dealer Name",
            accessor: "dealer.dealer_name",
            Filter: () => {
                return CustomFilter({
                        entityUrl:"customers",
                        setCondition:setSearchCondition,
                        entityId:"dealer_id",
                        entityValue:"dealer_name",
                        searchField:"dealer.dealer_id",
                        searchType:"number",
                        entity:"customers",
                        selectedValue:searchConditionSelectedValue,
                        setSelectedValue:setSearchConditionSelectedValue
                    }
                )
            },
        },
        {
            Header: "Created Date",
            accessor: "created_date",Filter: false,
            Cell: ({ cell: { value } }) => {
                let date = new Date(Number(value));
                date = date.toString().slice(0,24)
                return date;
            }

        },
        {
            Header: "Created By",
            accessor: "created_by.name",
            Filter: () => {
                return CustomFilter({
                        entityUrl:"users",
                        setCondition:setSearchCondition1,
                        entityId:"user_id",
                        entityValue:"name",
                        searchField:"created_by.user_id",
                        searchType:"number",
                        entity:"users",
                        selectedValue:searchConditionSelectedValue1,
                        setSelectedValue:setSearchConditionSelectedValue1
                    }
                )
            },
        },
        {
            Header: "Grand Total",
            accessor: "total_sale_after_tax",
            filter:false
        },
        {
            Header: "Status",
            accessor: "status",
            Filter: SelectColumnFilter,
            Cell: ({ cell: { value } }) => {

                return getBadge(value);
            }
        }
    ];

    const invoiceButtons = [
        {
            key:0,
            name:"New Domestic Invoice",
            href:"/invoices/new"
        },
        // {
        //     key:1,
        //     name:"New Export Invoice",
        //     href:"sales/domestic-orders"
        // },
        {
            key:2,
            name:"New LUT Invoice",
            href:"/invoices/chennai-new-lut"
        }
    ];

    let condition = [{
        field:"salesOrderShortList.warehouse.warehouse_id",
        type:"number",
        value:params.warehouseID,
    }];
    searchCondition!=null && condition.push(searchCondition)
    searchCondition1!=null && condition.push(searchCondition1)
    if(condition.length==0)
        condition = null

    return(<React.Fragment>
        <Helmet title="Invoices"/>
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">{heading}</h1>
            <EntityTable
                columns={tableColumns}
                url={"buyhappy_sales_invoices"}
                sortBy={[{field: "id", order: "desc"}]}
                buttons={invoiceButtons}
                searchCondition={condition}
                rowProps={row => ({
                    onClick: () => {
                        window.open("/invoices/details/"+row.original.id,"_blank")
                    },
                    style: {
                        cursor: "pointer"
                    }
                })}
            />
        </Container>
    </React.Fragment>);

};

export default InvoicePage;



