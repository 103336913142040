import React from "react";
import {Helmet} from "react-helmet-async";
import {Badge, Button, Container} from "react-bootstrap";
import EntityTable from "../../../components/EntityTable";
import {ModelConstants} from "../../../utils/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faExclamation, faTimes} from "@fortawesome/free-solid-svg-icons";


const ContractsTablePage = () => {

    const contractsTableColumns = [
        {
            Header: "Id",
            accessor: ModelConstants.Contract.CONTRACT_ID,
        },
        {
            Header: "Name",
            accessor: ModelConstants.Contract.DEALER+"."+ModelConstants.Dealer.DEALER_NAME,
        },
        {
            Header: "Contract Type",
            accessor: ModelConstants.Contract.CONTRACT_TYPE,
        },
        {
            Header: "Status",
            accessor: ModelConstants.Contract.APPROVAL_STATUS,
            Cell: ({ value }) => {
                if(value==="TRUE"){
                    return(<Badge bg="success" >
                        <FontAwesomeIcon icon={faCheck} /> Approved
                    </Badge>);
                }else if(value==="FALSE"){
                    return(<Badge bg="danger" >
                        <FontAwesomeIcon icon={faTimes} /> Rejected
                    </Badge>);
                }else{
                    return(<Badge bg="warning" >
                        <FontAwesomeIcon icon={faExclamation} /> Draft
                    </Badge>);
                }

            },
        },
        {
            Header: "Approved Value",
            accessor: ModelConstants.Contract.ALLOWED,
        },
        {
            Header: "Reimbursed Value",
            accessor: ModelConstants.Contract.USED,
        }
    ];

    const contractButtons = [
        {
            key:0,
            name:"New Contract",
            href:"/claims/contracts-new"
        },
    ];

    return(<React.Fragment>
        <Helmet title="Contracts"/>
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Contracts</h1>
            <EntityTable
                key={new Date().getTime()}
                columns={contractsTableColumns}
                url={"contracts"}
                sortBy={[{field: ModelConstants.Contract.CONTRACT_ID, order: "desc"}]}
                buttons={contractButtons}
            />
        </Container>
    </React.Fragment>);

};

export default ContractsTablePage;
