import React from "react";
import {Helmet} from "react-helmet-async";
import {Container} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import EntityTable from "../../../components/EntityTable";


const ClaimsTablePage = () => {
    const navigate = useNavigate();
    const claimsTableColumns = [
        {
            Header: "Name",
            accessor: "dealer.dealer_name",
        },
        {
            Header: "GST Number",
            accessor: "dealer.gst",
        },
        {
            Header: "Waiting Count",
            accessor: "WAITING",
        },
        {
            Header: "Approved Count",
            accessor: "TRUE",
        },
        {
            Header: "Rejected Count",
            accessor: "FALSE",
        }
    ];


    const claimButtons = [
        {
            key:0,
            name:"New Margin Claim",
            href:"/claims/claim-new"
        },
        {
            key:1,
            name:"New Other Claims",
            href:"/claims/claim-new-other"
        },
    ];
    return(<React.Fragment>
        <Helmet title="Claims"/>
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Claims</h1>
            <EntityTable
                key={new Date().getTime()}
                dataCallBackFn={function(response){
                    const groupedData = response.reduce((accumulator, item) => {
                        const dealerName = item.dealer.dealer_id;

                        if (!accumulator[dealerName]) {
                            accumulator[dealerName] = {
                                dealer:item.dealer,
                                "TRUE":0,
                                "FALSE":0,
                                "WAITING":0,
                            };
                        }

                        accumulator[dealerName][item.status] = item.claimCount;

                        return accumulator;
                    }, {});

                    let data = Object.values(groupedData)
                        data = data.sort((a, b) => {
                            if (a.WAITING > b.WAITING) return -1;
                            if (a.WAITING < b.WAITING) return 1;
                            if (a.TRUE > b.TRUE) return -1;
                            if (a.TRUE < b.TRUE) return 1;
                            if (a.FALSE > b.FALSE) return -1;
                            if (a.FALSE < b.FALSE) return 1;
                            return 0;
                        });
                  return data;
                }}
                columns={claimsTableColumns}
                url={"claims_tracker_dealer"}
                sortBy={[{field: "claim_id", order: "desc"}]}
                buttons={claimButtons}
                rowProps={row => ({
                    onClick: () => navigate("/claims/claim-details/"+row.original.dealer.dealer_id) ,
                    style: {
                        cursor: "pointer"
                    }
                })}
            />
        </Container>
    </React.Fragment>);

};

export default ClaimsTablePage;
