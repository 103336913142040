import React, {useState} from "react";
import {Badge, Button, Card, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";

const ClaimDetailsDealerPageCardView = ({key,retailer}) => {
    const ClaimCounter = ({ claimCount }) => {
        const [numRows, setNumRows] = useState(5);

        const handleShowMore = () => {
            setNumRows(numRows + 5);
        };

        return (
            <>
                <Row
                    className={"pb-2 pt-2"}
                    style={{
                        borderBottom: "1px solid black",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    <Col style={{ width: "20%" }}>Month</Col>
                    <Col lg={1} style={{ width: "15%" }}>
                        No
                    </Col>
                    <Col lg={2} style={{ width: "20%" }}>
                        Status
                    </Col>
                    <Col lg={3} style={{ width: "20%",textAlign:"right",marginRight:"10px" }}>Payable (Total)</Col>
                    <Col style={{ textAlign:"right",marginRight:"10px" }}>%</Col>
                    <Col style={{ textAlign:"right",marginRight:"10px" }}>Difference</Col>
                </Row>
                {claimCount.slice(0, numRows).map((claim) => {
                    return (
                        <Row
                            className={"pb-2 pt-2"}
                            style={{
                                borderBottom: "1px solid black",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            <Col style={{ width: "20%" }}>{claim.month}</Col>
                            <Col lg={1} style={{ width: "15%" }}>
                                {claim.count}
                            </Col>
                            <Col lg={2} style={{ width: "20%", }}>
                                {claim.status === "TRUE" ? (
                                    <Badge bg="success">
                                        <FontAwesomeIcon icon={faCheck} /> Done
                                    </Badge>
                                ) : claim.status === "WAITING" ?(
                                    <Badge bg="warning">
                                        <FontAwesomeIcon icon={faTimes} /> Pend.
                                    </Badge>
                                ) : (<Badge bg="danger">
                                    <FontAwesomeIcon icon={faTimes} /> Rej
                                </Badge>) }
                            </Col>
                            <Col lg={3} style={{ width: "20%",textAlign:"right",marginRight:"10px" }}>{claim.totalAmount.toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                            })}</Col>
                            <Col style={{ width: "15%",textAlign:"right",marginRight:"10px" }}>
                                {claim.difference > -1 ? (
                                    <>
                                        <span style={{ color: "red" }}>{claim.percentage}%</span>
                                    </>
                                ) : (
                                    <span>{claim.percentage}%</span>
                                )}
                            </Col>
                            <Col style={{ width: "10%",textAlign:"right",marginRight:"10px" }}>
                                {claim.difference > -1 ? (
                                    <span style={{ color: "red" }}>{claim.difference.toLocaleString("en-IN", {
                                        style: "currency",
                                        currency: "INR",
                                    })}</span>
                                ) : (
                                    <span>{claim.difference.toLocaleString("en-IN", {
                                        style: "currency",
                                        currency: "INR",
                                    })}</span>
                                )}
                            </Col>
                        </Row>
                    );
                })}
                {numRows < claimCount.length && (
                    <Button style={{width:"100%"}} onClick={handleShowMore} variant="outline-primary" className="mt-2">
                        Show More
                    </Button>
                )}
            </>
        );
    };


    const Retailer = ({ retailer }) => (
        <Col lg={"6"}><Card >
            <Card.Header className="px-4 pt-4" style={{textAlign:"center"}}>
                <Card.Title className="mb-0">{retailer.retailer.dealer_name}</Card.Title>
                <Badge className="my-2" >
                    {retailer.retailer.gst}
                </Badge>
            </Card.Header>
            <Card.Body className="px-4 pt-2">
               <ClaimCounter claimCount={retailer.claimCount}/>
            </Card.Body>
        </Card></Col>

    );

   return <Retailer key={key} retailer={retailer}/>
};

export default ClaimDetailsDealerPageCardView;
