import React from "react";
import {Helmet} from "react-helmet-async";
import {Container} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import EntityTable from "../../../../components/EntityTable";


const activeMTsColumns = [
    {
        Header: "Name",
        accessor: "dealer_name",
    },
    {
        Header: "Account Number",
        accessor: "code",
    },
    {
        Header: "GST",
        accessor: "gst"
    }
];


const activeMtsButton = [
    {
        key:0,
        name:"New MT Distributor",
        href:"/customers/active/mts-new"
    }
];

const activeMtsSearchCondition = [
    {field: "type", type: "number", value: "1"},
];

const ActiveMT = () => {

    const navigate = useNavigate();
    return(<React.Fragment>
        <Helmet title="MT Distributors"/>
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Active MT Distributors</h1>

            <EntityTable
                key={new Date().getTime()}
                columns={activeMTsColumns}
                url={"customers"}
                pageSize={99999}
                sortBy={[{field: "dealer_name", order: "asc"}]}
                buttons={activeMtsButton}
                searchCondition={activeMtsSearchCondition}
                rowProps={row => ({
                onClick: () => navigate("/customers/active/mts-details/"+row.original.dealer_id) ,
                    style: {
                    cursor: "pointer"
                    }
                })}
            />
        </Container>
    </React.Fragment>);

};

export default ActiveMT;
