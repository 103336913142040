import React from "react";
import axios from "axios";
import {Field, getIn} from "formik";
import {Notyf} from "notyf";

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        console.log(error)
        try{
            let notify = new Notyf({
                duration: 5000,
                ripple: true,
                dismissible: false,
                position: {
                    x: "right",
                    y: "top",
                },
            });
            if(!!error.response.status && error.response.status === 400){

                if(!!error.response.data.errors){
                    if(error.response.data.errors.length>0){
                        error.response.data.errors.forEach(function(err){
                            if(!!err.errorMessage)
                                notify.error({message:err.errorMessage});
                        });
                    }
                }else{
                    notify.error({message:'Bad Request'});
                }
            }
            else if(!!error.response.status && error.response.status === 500){
                if (!!error.response.data.message) {
                    notify.error({ message: error.response.data.message });
                } else {
                    notify.error({ message: "Internal server error" });
                }
            }
            else if(!!error.response.status && error.response.status === 401){
                notify.error({message:'Permission Denied'});
            }else if(!!error.response.status && error.response.status === 405){
                notify.error({message:'Method Not allowed'});
            }

        }catch (e) {
            console.log(e)
        }finally {
            return Promise.reject(
                (error.response && error.response.data) || "Something went wrong"

            )
        }
    }

);


export function PostApproval(url, entityNumber, approvalField, approvalValue, callBackfn, errorfn) {
    var f = {};
    f.approval = {};
    f.approval.approval_field = approvalField;
    f.approval.value = approvalValue;
    axiosInstance.put("/api/"+url + "/approval/" + entityNumber, GetAsInputData(f))
    .then((response,success)=>{
        callBackfn(response.data);
    }).catch((response,error) => {
        if(errorfn!==undefined)
            errorfn(response,error)
    })
}

export function GetAsInputData(d) {
    if (d instanceof Object) {
        var c = "input_data=" + encodeURIComponent(Object.toJSON ? Object.toJSON(d) : JSON.stringify(d));
        return c
    }
    return d
}


export const ErrorMessage = ({ name }) => {

    return (<Field
        name={name}>
        {({field,form: { touched, errors },meta}) => {
            let error = getIn(errors, name);
            let touchedField = getIn(touched, name);
            if ( error && touchedField )
                return <div style={{display:"block"}} className="invalid-feedback">{error}</div>;
            else
                return null;
        }}
    </Field>);
};

export function DownloadAttachment(fileUrl){
    axiosInstance({
        url: fileUrl,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers['content-disposition']); //or any other extension
        document.body.appendChild(link);
        link.click();
    });
}
//
// export function DownloadFile(fileUrl){
//
//     axiosInstance({
//         url: fileUrl+"/downloadFile",
//         method: 'GET',
//         responseType: 'blob', // important
//     }).then((response) => {
//         const url = window.URL.createObjectURL(new Blob([response.data]));
//         const link = document.createElement('a');
//         link.href = url;
//         let fileName = fileUrl.slice(5).replaceAll("/"," ")+".pdf"
//         fileName = fileName.replaceAll("_"," ");
//         link.setAttribute('download', fileName); //or any other extension
//         document.body.appendChild(link);
//         link.click();
//     });
// }


export function DownloadFileExcel(fileUrl){

    axiosInstance({
        url: fileUrl+"/downloadFile/excel",
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let fileName = fileUrl.slice(5).replaceAll("/"," ")+".xlsx"
        fileName = fileName.replaceAll("_"," ");
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
    });
}

export async function UploadFile(file, module, handleChange, field) {

    var formData = new FormData();
    formData.append("file", file);

    let response = await axiosInstance({
        url: "/uploadFile/" + module,
        method: 'POST',
        data: formData,
    })

    let event = {
        target: {
            name: field,
            value: response.data
        }
    }
    handleChange(event);

}

export default axiosInstance
