import React from "react";
import {Helmet} from "react-helmet-async";
import {Container} from "react-bootstrap";
import EntityTable from "../../../../components/EntityTable";
import {useNavigate} from "react-router-dom";


const activeDealersTableColumns = [
    {
        Header: "Name",
        accessor: "dealer_name",
    },
    {
        Header: "Account Number",
        accessor: "code",
    },
    {
        Header: "GST",
        accessor: "gst"
    }
];


const activeDealerButtons = [
    {
        key:0,
        name:"New Super Stockist",
        href:"/customers/active/ss-new"
    }
];

const activeDealerSearchCondition = [
    {field: "type", type: "number", value: "2"}
];

const ActiveSS = () => {

    const navigate = useNavigate();

    return(<React.Fragment>
        <Helmet title="Super Stockists"/>
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Active Super Stockists</h1>

            <EntityTable
                key={new Date().getTime()}
                columns={activeDealersTableColumns}
                url={"customers"}
                pageSize={99999}
                sortBy={[{field: "dealer_name", order: "asc"}]}
                buttons={activeDealerButtons}
                searchCondition={activeDealerSearchCondition}
                rowProps={row => ({
                onClick: () => navigate("/customers/active/ss-details/"+row.original.dealer_id) ,
                    style: {
                    cursor: "pointer"
                    }
                })}
            />
        </Container>
    </React.Fragment>);

};

export default ActiveSS;
