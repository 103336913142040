import React, {useContext, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Container} from "react-bootstrap";
import {getBadge, getTimeStringFromLongNumber,} from "../../../utils/misc";
import axiosInstance from "../../../utils/axios";
import Loader from "../../../components/Loader";
import EntityTable from "../../../components/EntityTable";
import NotyfContext from "../../../contexts/NotyfContext";

const HistoryTable = ({ url, name }) => {
  const [historyData, setHistoryData] = useState();
  const notify = useContext(NotyfContext)
  function compare(a, b) {
    if (a.propertyNameWithPath < b.propertyNameWithPath) {
      return -1;
    }
    if (a.propertyNameWithPath > b.propertyNameWithPath) {
      return 1;
    }
    return 0;
  }

  function getLeftToRightPropertyChange(change, subChange) {
    if (change.changes != null) {
      return (
        <div>
          &emsp;{change.propertyNameWithPath} collection changes{" "}
          {change.changes.map((change, index) => {
            if (change.addedValue != null)
              return (
                <div>
                  &emsp;&emsp;{index} . {change.addedValue.typeName} /{" "}
                  {change.addedValue.cdoId} added{" "}
                </div>
              );
            if (change.removedValue != null)
              return (
                <div>
                  &emsp;&emsp;{index} . {change.removedValue.typeName} /{" "}
                  {change.removedValue.cdoId} removed{" "}
                </div>
              );
            return (
              <div>
                &emsp;&emsp;{index} . {change.leftValue.typeName} /{" "}
                {change.leftValue.cdoId} {"changed to  "}{" "}
                {change.rightValue.typeName} / {change.rightValue.cdoId}
              </div>
            );
          })}
        </div>
      );
    }

    if (
      change.propertyNameWithPath.includes("Status") ||
      change.propertyNameWithPath.includes("Approval") ||
        change.propertyNameWithPath.startsWith("Is")
    ) {
      return (
        <div className={"my-2"}>
          &emsp;{change.propertyNameWithPath} changed from{" "}
          {getBadge(change.left)}
          {"  to  "}
          {getBadge(change.right)}
        </div>
      );
    }
    if (
      change.propertyNameWithPath.includes("Date") ||
      change.propertyNameWithPath.includes("Time")
    ) {
      return (
        <div>
          &emsp;{change.propertyNameWithPath} changed from{" "}
          {getTimeStringFromLongNumber(change.left)} {" to "}
          {getTimeStringFromLongNumber(change.right)}
        </div>
      );
    }
    if (change.right.typeName != null) {
      subChange =
        change.propertyNameWithPath +
        " = " +
        change.left.typeName +
        "/" +
        change.right.cdoId +
        " -> " +
        change.right.typeName +
        "/" +
        change.right.cdoId;
    } else {
      if (change.propertyNameWithPath.includes("Total") || change.propertyNameWithPath.includes("Price")) {
        subChange =
          change.propertyNameWithPath +
          " changed from " +
          change.left.toLocaleString("en-IN", {
            style: "currency",
            currency: "INR",
          }) +
          " to " +
          change.right.toLocaleString("en-IN", {
            style: "currency",
            currency: "INR",
          });
      } else {
        subChange =
          change.propertyNameWithPath +
          " changed from " +
          change.left +
          " to " +
          change.right;
      }
    }

    return <div>&emsp;{subChange}</div>;
  }

  function getLeftPropertyChange(change, subChange) {
    if (
      change.propertyNameWithPath.includes("Status") ||
      change.propertyNameWithPath.includes("Approval")||
        change.propertyNameWithPath.startsWith("Is")
    ) {
      return (
        <div className={"my-2"}>
          &emsp;{change.propertyNameWithPath} = {getBadge(change.right)}
        </div>
      );
    }
    if (
      change.propertyNameWithPath.includes("Date") ||
      change.propertyNameWithPath.includes("Time")
    ) {
      return (
        <div>
          &emsp;{change.propertyNameWithPath} ={" "}
          {getTimeStringFromLongNumber(change.right)}
        </div>
      );
    }
    if (change.right.typeName != null) {
      subChange =
        change.propertyNameWithPath +
        " = " +
        change.right.typeName +
        "/" +
        change.right.cdoId;
    } else {
      if (change.propertyNameWithPath.includes("Total") || change.propertyNameWithPath.includes("Price")) {
        subChange =
          change.propertyNameWithPath +
          " = " +
          change.right.toLocaleString("en-IN", {
            style: "currency",
            currency: "INR",
          });
      } else {
        subChange = change.propertyNameWithPath + " = " + change.right;
      }
    }
    return <div>&emsp;{subChange}</div>;
  }

  const columns = [
    {
      Header: "User",
      accessor: " ",
      Filter: false,
      Cell: ({ row }) => {
        return (
          <>
            {row.original.createdBy} on
            <br />
            {getTimeStringFromLongNumber(row.original.createdTime)}
          </>
        );
      },
    },
    // {
    //   Header: "Message",
    //   accessor: "change",
    //   Filter: false,
    //   Cell: ({ cell: { value } }) => {
    //     let messages = value.split("\n");
    //     let change = <></>;
    //     change = messages.map((message, i) => {
    //       if (message.indexOf("\t") == -1)
    //         return (
    //           <>
    //             {message}
    //             <br />
    //           </>
    //         );
    //       else {
    //         return (
    //           <>
    //             &emsp;{message}
    //             <br />
    //           </>
    //         );
    //       }
    //     });
    //     return change;
    //   },
    // },
    {
      Header: "Message",
      accessor: "changeRaw",
      Filter: false,
      Cell: ({ cell: { value } }) => {
        value.sort(compare);
        let message =
          "Commit " +
          value[0].commitMetadata.id.majorId +
          " done by " +
          value[0].commitMetadata.author +
          " at " +
          "" +
          getTimeStringFromLongNumber(
            new Date(value[0].commitMetadata.commitDateInstant)
          );
        return (
          <>
            {message} :
            <br />
            Creation or Updation on{" "}
            {value[0].affectedGlobalId.ownerId == null
              ? value[0].affectedGlobalId.typeName
              : value[0].affectedGlobalId.ownerId.typeName}{" "}
            /{" "}
            {value[0].affectedGlobalId.ownerId == null
              ? value[0].affectedGlobalId.cdoId
              : value[0].affectedGlobalId.ownerId.cdoId}
            {value.map((change) => {
              let subChange = null;
              if (change.changeType === "PROPERTY_VALUE_CHANGED") {
                if (change.propertyValueChanged) {
                  if (change.left == null) {
                    return getLeftPropertyChange(change, subChange);
                  } else {
                    return getLeftToRightPropertyChange(change, subChange);
                  }
                }
              } else {
                if (process.env.NODE_ENV === "development" && change.changeType!=null) {
                  notify.error(change.propertyName);
                }
              }

              if (subChange == null) return "";
              return <div>&emsp;{subChange}</div>;
            })}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    axiosInstance.get(url).then((r) => {
      setHistoryData(r.data);
    });
  }, [url]);

  if (process.env.NODE_ENV === "development") {
    columns.push({
      Header: "Message",
      accessor: "change",
      Filter: false,
      Cell: ({ cell: { value } }) => {
        let messages = value.split("\n");
        let change = <></>;
        change = messages.map((message, i) => {
          if (message.indexOf("\t") == -1)
            return (
              <>
                {message}
                <br />
              </>
            );
          else {
            return (
              <>
                &emsp;{message}
                <br />
              </>
            );
          }
        });
        return change;
      },
    });
  }

  if (historyData == null) return <Loader />;

  return (
    <React.Fragment>
      <Helmet title="History" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{name || "History"}</h1>
        <EntityTable
          columns={columns}
          preFetchedData={historyData}
          buttons={[]}
        />
      </Container>
    </React.Fragment>
  );
};

export default HistoryTable;
