import React, {useState} from "react";

import {Alert, Button, Card, Col, Form, Row} from "react-bootstrap";

import axiosInstance, {GetAsInputData} from "../../../utils/axios";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {Formik} from "formik";

const dealerSchema = Yup.object().shape({
    dealer: Yup.object().required("Trader Name is required"),
    dealer_address_gst: Yup.string().required("GST is required").min(15, 'Must be exactly 15 digits').max(15, 'Must be exactly 15 digits'),
    dealer_contact_name: Yup.string().required("Contact Name is required"),
    dealer_contact_number: Yup.string().required("Phone number is required"),
    dealer_address: Yup.string().required("Phone number is required"),
});

const ShippingAddressForm = ({initialValues,successUrl,setDealerAddressData}) => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function HandlePost(url,data,navigateTo){
        axiosInstance.post("/api/"+url,data).then(function(response,success){
            if(response.status===200){
                handleShow();
                setTimeout(handleClose,2500)


                setTimeout(() => {
                    let entityURL = "/api/dealer_address";

                    const input_data = {
                        listInfo:{
                            startRecord:0,
                            rowCount:9999,
                            sortBy:[{"field":"dealer.dealer_name","order":"asc"}],
                            searchConditions:[{"field":"dealer.dealer_id","value":""+initialValues.dealer.dealer_id,"type":"number"}]
                        }
                    };
                    let encodedData = GetAsInputData(input_data);
                    axiosInstance.get(entityURL+"?"+encodedData)
                        .then(function(response){
                            navigate(navigateTo);
                            setTimeout(() => {
                                setDealerAddressData(response.data.dealer_address);
                            }, 500);
                        })
                },1000);

            }
        })
    }

    return( <Card>
        <Alert show={show} onHide={show} variant="primary" key="0" dismissible>
            <div className="alert-message">
                <strong>Shipping Address</strong> added successfully!
            </div>
        </Alert>
        <Card.Header>
            <Card.Title tag="h5">Add shipping address</Card.Title>
        </Card.Header>
        <Card.Body>
            <Formik
                validationSchema={dealerSchema}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500));
                    HandlePost("dealer_address",GetAsInputData({dealer_address:values}),successUrl);
                }}
                initialValues={initialValues}>
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row>
                            <Col sm={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Contact Name
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="dealer_contact_name"
                                        value={values.dealer_contact_name}
                                        placeholder="Contact Name"
                                        isValid={touched.dealer_contact_name && !errors.dealer_contact_name}
                                        isInvalid={touched.dealer_contact_name && !!errors.dealer_contact_name}
                                        onChange={handleChange} />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.dealer_contact_name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Phone Number
                                    </Form.Label>
                                    <Form.Control type="text" name="dealer_contact_number"
                                               value={values.dealer_contact_number}
                                               placeholder="Phone Number"
                                               isValid={touched.dealer_contact_number && !errors.dealer_contact_number}
                                               isInvalid={touched.dealer_contact_number && !!errors.dealer_contact_number}
                                               onChange={handleChange}/>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.dealer_contact_number}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            </Row>
                        <Row>
                            <Col sm={4}>
                                <Form.Group  className="mb-3">
                                    <Form.Label>GST</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="dealer_address_gst"
                                        value={values.dealer_address_gst}
                                        placeholder="GST"
                                        isValid={touched.dealer_address_gst && !errors.dealer_address_gst}
                                        isInvalid={touched.dealer_address_gst && !!errors.dealer_address_gst}
                                        onChange={handleChange} />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.dealer_address_gst}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col sm={4}>
                                <Form.Label>
                                    Address
                                </Form.Label>
                                <Form.Control type="text" name="dealer_address"
                                              value={values.dealer_address}
                                              placeholder="Address"
                                              isValid={touched.dealer_address && !errors.dealer_address}
                                              isInvalid={touched.dealer_address &&!!errors.dealer_address}
                                              onChange={handleChange} />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.dealer_address}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Button type="submit" variant="primary">Submit</Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>)}
            </Formik>
        </Card.Body>
    </Card>);
}

export default ShippingAddressForm;
