import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import {Col, Container, Row} from "react-bootstrap";
import EntityTable from "../../../../components/EntityTable";
import TransportForm from "./TransportForm";

const TransportTable = () => {
  const [key, setKey] = useState(new Date().getTime());
  const [editData, setEditData] = useState();
  const columns = [
    {
      Header: "Name",
      accessor: "transport_name",
    },
    {
      Header: "ID",
      accessor: "transport_id",
    },
    {
      Header: "Vehicle Number",
      accessor: "transport_vehicle_number",
    },
  ];

  return (
    <React.Fragment>
      <Helmet title="Transport" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Transport</h1>
        <Row>
          <Col>
            <EntityTable
              key={key}
              columns={columns}
              url={"eway_transports"}
              pageSize={99999}
              buttons={[]}
              rowProps={row => ({
                onClick: () => {
                  setEditData(row.original);
                  setKey(new Date().getTime());
                },
                style: {
                  cursor: "pointer"
                }
              })}
            />
          </Col>
          {editData != null && (
            <Col>
              <TransportForm
                key={key}
                setKey={setKey}
                setEditData={setEditData}
                initialValues={editData}
              />
            </Col>
          )}
          {editData == null && (
            <Col>
              <TransportForm
                key={key}
                setKey={setKey}
                initialValues={{
                  transport_name: undefined,
                  transport_id: undefined,
                  transport_vehicle_number: undefined,
                }}
              />
            </Col>
          )}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default TransportTable;
