import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";

import {Button, Card, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import Select from "react-select";
import axiosInstance, {ErrorMessage, GetAsInputData} from "../../../../utils/axios";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {FieldArray, Formik} from "formik";

const formSchema = Yup.object().shape({
    salesOrder: Yup.object().required("Sale Order is required"),
    invoice_date: Yup.string().required("Invoice Date is required"),
    dealerAddresses:Yup.object().nullable(),
    mode_of_transport: Yup.string().required("Mode of Transport is required"),
    products: Yup.array()
        .of(
            Yup.object().shape({
                product: Yup.object().required("Product is required").nullable(),
                boxes: Yup.number().required("Box count is required"),
                batch_number_custom:Yup.string().required("Batch number is required")
            })
        )

});


const initialValues = {
    salesOrder: undefined,
    mode_of_transport:undefined,
    invoice_date:undefined,
    dealerAddresses:undefined,
    invoice_type:"BUY_HAPPY_LUT_INVOICE",
    sale:0,
    tax:0,
    sale_tax:0,
    products:[{
        product:undefined,
        boxes:undefined,
        quantity:undefined,
        batch_number_custom:undefined
    }]
};

const ChennaiInvoiceForm = () => {
    const navigate = useNavigate();
    const [productOptions, setProductOptions] = useState([]);
    const [isProductsLoading, setIsProductLoading] = useState(true);
    const [saleOptions, setSaleOptions] = useState([]);
    const [isSalesLoading, setIsSalesLoading] = useState(true);
    const [dealerAddressOptions, setDealerAddressOptions] = useState([]);
    const [isDealerAddressLoading, setIsDealerAddressLoading] = useState(true);

    function returnSaleValues(values,type){
        let sale = 0;
        let tax = 0;
        for(let i=0;i<values.length;i++){
            if(values[i].product!==undefined && values[i].boxes!==undefined){
                let quantity = values[i].boxes * values[i].product.box_count;
                sale += values[i].product.price * quantity;
                tax += values[i].product.price * quantity * values[i].product.gst;
            }
        }
        if(type==="sale")
            return sale;
        if(type==="tax")
            return tax * 0.001;
        if(type==="sale_tax")
            return (sale+(tax * 0.001));
    }

    function HandleSubmit(url,data,navigateTo){
        axiosInstance.post("/api/"+url,data).then(function(response,success){
            if(response.status===200)
                navigate(navigateTo);
        })
    }

    function loadSaleProducts(saleOrderID){
        axiosInstance.get("/api/sales_products?"+GetAsInputData({
            listInfo:{
                startRecord:0,
                rowCount:9999,
                sortBy: [{"field":"product.name","order":"asc"}],
                searchConditions:[{"field":"order.so_number","value":""+saleOrderID,"type":"number"}]
            }
        })).then(function(response){
            setProductOptions(response.data.sales_products);
            setTimeout(() => {
                setIsProductLoading(false);
            }, 1500);
        });
    }

    function loadDealerAddress(dealerID){
        axiosInstance.get("/api/dealer_address?"+GetAsInputData({
            listInfo:{
                startRecord:0,
                rowCount:9999,
                sortBy: [{"field":"dealer_address","order":"asc"}],
                searchConditions:[{"field":"dealer.dealer_id","value":""+dealerID,"type":"longnumber"}]
            }
        })).then(function(response){
            setDealerAddressOptions(response.data.dealer_address);
            setTimeout(() => {
                setIsDealerAddressLoading(false);
            }, 1500);
        });
    }

    useEffect(() => {
        const loadSelectData =  () => {

            try{
                axiosInstance.get("/api/sales_order?"+GetAsInputData({
                    listInfo:{
                        startRecord:0,
                        rowCount:9999,
                        sortBy: [{"field":"sale_number","order":"asc"}],
                        searchConditions:[{"field":"warehouse.warehouse_id","value":"1","type":"longnumber"},{"field":"status","value":"ACKNOWLEDGED","type":"enum","enumName":"SaleStatus"},{"field":"sale_type","value":"LUT","type":"enum","enumName":"SaleType"}]
                    }
                })).then(function(response){
                    setSaleOptions(response.data.sales_order);
                    setTimeout(() => {
                        setIsSalesLoading(false);
                    }, 1500);
                });


            }catch(e){
                console.log(e);
            }
        }
        loadSelectData();
    },[]);

    return( <Card>
        <Card.Header>
            <Card.Title tag="h5">Add Invoice</Card.Title>
        </Card.Header>
        <Card.Body>
            <Formik
                validationSchema={formSchema}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500));
                    HandleSubmit("buyhappy_sales_invoices",GetAsInputData({buyhappy_sales_invoices:values}),"/invoices/chennai")
                }}
                initialValues={initialValues}>
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                  }) => (<Form noValidate onSubmit={handleSubmit}>
                    <Row>
                        <Col sm={5}>
                            <Form.Group  className="mb-3">
                                <Form.Label>Sale Order</Form.Label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="dealer"
                                    options={saleOptions.map((key, text) => {
                                        return {
                                            value: key.so_number,
                                            label: key.sale_number +" - "+ key.dealer.dealer_name,
                                            so_number:key.so_number,
                                            dealer_id: key.dealer.dealer_id
                                        }}
                                    )}
                                    isLoading={isSalesLoading}
                                    onChange={selectedOption => {
                                        let event = { target : { name:'salesOrder',value: selectedOption}}
                                        handleChange(event);
                                        setIsProductLoading(true);
                                        setProductOptions([]);
                                        setIsDealerAddressLoading(true);
                                        setDealerAddressOptions([]);
                                        loadSaleProducts(selectedOption.value);
                                        loadDealerAddress(selectedOption.dealer_id);
                                    }}
                                />
                                <ErrorMessage name={`salesOrder`} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Invoice Date
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    name="invoice_date"
                                    isValid={touched.invoice_date && !errors.invoice_date}
                                    isInvalid={touched.invoice_date && !!errors.invoice_date}
                                    onChange={event => {
                                        let anotherEvent = { target : { name:'invoice_date',value: new Date(event.currentTarget.value).getTime()}}
                                        handleChange(anotherEvent);
                                    }}
                                    placeholder="Invoice Date" />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.invoice_date}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Label>
                                Mode Of Transport
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="mode_of_transport"
                                isValid={touched.mode_of_transport && !errors.mode_of_transport}
                                isInvalid={touched.mode_of_transport && !!errors.mode_of_transport}
                                onChange={handleChange}
                                placeholder="Mode Of Transport" />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.mode_of_transport}
                            </Form.Control.Feedback>
                        </Col>
                        <Col sm={4}>
                            <Form.Label>
                                Shipped to
                            </Form.Label>
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                value={values.dealerAddresses}
                                name="dealerAddresses"
                                options={dealerAddressOptions.map((key, text) => {
                                    return {
                                        value: key.dealer_shipping_id,
                                        label: key.dealer_address,
                                        dealer_shipping_id:key.dealer_shipping_id
                                    }}
                                )}
                                isLoading={isDealerAddressLoading}
                                isDisabled={isDealerAddressLoading}
                                onChange={selectedOption => {
                                    let event = { target : { name:'dealerAddresses',value: selectedOption}}
                                    handleChange(event);
                                }}
                            />
                            <ErrorMessage name={`dealerAddresses`} />
                        </Col>
                    </Row>
                    <Card.Header/>
                    <Card.Title tag="h5">Invoice Products</Card.Title>
                    <Card.Header/>
                    <FieldArray
                        name="products"
                        render={arrayHelpers => (
                            <div>
                                {
                                    values.products && values.products.length > 0 ? (
                                        values.products.map((product, index) => (
                                            <Row key={index}>
                                                <Col md={4}>
                                                    <Form.Label htmlFor="product">Product</Form.Label>
                                                    <Form.Group className="mb-3">
                                                        <Select
                                                            key={`products.${index}`}
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            name={`products[${index}].product`}
                                                            value={product.product}
                                                            isDisabled={isProductsLoading}
                                                            options={productOptions.map((key, text) => {
                                                                return {
                                                                    value: key.product.id,
                                                                    label: key.product.name,
                                                                    optionData:key
                                                                }}
                                                            )}
                                                            isLoading={isProductsLoading}
                                                            onChange={selectedOption => {
                                                                let event = { target : { name:`products[${index}].product`,value: {
                                                                            value: selectedOption.value,
                                                                            label: selectedOption.label,
                                                                            id: selectedOption.value,
                                                                            name: selectedOption.label,
                                                                            price:selectedOption.optionData.price,
                                                                            gst:selectedOption.optionData.product.product_gst,
                                                                            box_count:selectedOption.optionData.product.box_count
                                                                        } } };
                                                                handleChange(event);
                                                                if(product.boxes!==undefined){
                                                                    let quantity = product.boxes * selectedOption.optionData.product.box_count;
                                                                    let anotherEvent = { target : { name:`products[${index}].quantity`,value: quantity}};
                                                                    handleChange(anotherEvent);
                                                                }
                                                            }}
                                                        />
                                                        <ErrorMessage name={`products[${index}].product`} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={2}>
                                                    <Form.Label htmlFor="batch_number_custom">Batch Number</Form.Label>
                                                    <InputGroup>
                                                        <FormControl
                                                            key={`products.${index}`}
                                                            placeholder="Batch Number"
                                                            type="text"
                                                            name={`products[${index}].batch_number_custom`}
                                                            value={product.batch_number_custom}
                                                            aria-label="Batch Number"
                                                            onChange={handleChange}
                                                            disabled={!product.product} />
                                                    </InputGroup>
                                                    <ErrorMessage name={`products[${index}].batch_number_custom`} />
                                                </Col>
                                                <Col md={2}>
                                                    <Form.Label htmlFor="boxes">Boxes</Form.Label>
                                                    <InputGroup>
                                                        <FormControl
                                                            key={`products.${index}`}
                                                            placeholder="Boxes"
                                                            type="number"
                                                            name={`products[${index}].boxes`}
                                                            value={product.boxes}
                                                            aria-label="Boxes"
                                                            onChange={event => {
                                                                let quantity = product.product.box_count * event.target.value;
                                                                let anotherEvent = { target : { name:`products[${index}].quantity`,value: quantity}};
                                                                handleChange(event);
                                                                handleChange(anotherEvent);
                                                            }}
                                                            disabled={!product.product} />
                                                    </InputGroup>
                                                    <ErrorMessage name={`products[${index}].boxes`} />
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Label htmlFor="quantity">Quantity</Form.Label>
                                                    <InputGroup>
                                                        <FormControl
                                                            key={`products.${index}`}
                                                            placeholder="Quantity"
                                                            type="number"
                                                            name={`products[${index}].quantity`}
                                                            value={product.quantity}
                                                            aria-label="Quantity"
                                                            disabled />
                                                        <Button onClick={() => arrayHelpers.push({
                                                            product: undefined,
                                                            boxes:0,
                                                            quantity:0
                                                        })} variant="secondary">Add More</Button>
                                                        <Button onClick={() => {
                                                            arrayHelpers.remove(index);
                                                        }} variant="secondary">Remove</Button>

                                                    </InputGroup>
                                                </Col>
                                            </Row>))):(
                                        arrayHelpers.push({
                                            product: undefined,
                                            boxes:0,
                                            quantity:0
                                        })
                                    )}</div>
                        )}
                    />
                    <Row>
                        <Col sm={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Sale
                                </Form.Label>
                                <Form.Control type="text" value={ returnSaleValues(values.products,"sale")  } placeholder="Sale" disabled/>
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Label>
                                Tax
                            </Form.Label>
                            <Form.Control type="text"value={ returnSaleValues(values.products,"tax")  } placeholder="Tax" disabled/>
                        </Col>
                        <Col sm={4}>
                            <Form.Label>
                                Total
                            </Form.Label>
                            <Form.Control type="text" value={ returnSaleValues(values.products,"sale_tax")  } placeholder="Sales + Tax" disabled/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Button type="submit" variant="primary">Submit</Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>)}
            </Formik>

        </Card.Body>
    </Card>);
}

const ChennaiLUTInvoiceFormPage = () => (
    <React.Fragment>
        <Helmet title="New Chennai LUT Invoice" />
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Chennai LUT Invoice</h1>

            <Row>
                <Col lg="12">
                    <ChennaiInvoiceForm />
                </Col>
            </Row>
        </Container>
    </React.Fragment>
);

export default ChennaiLUTInvoiceFormPage;
