import React, {useEffect, useState} from "react";

import {Alert, Button, Card, Col, Form, InputGroup, Row} from "react-bootstrap";
import axiosInstance, {GetAsInputData} from "../../../utils/axios";
import * as Yup from "yup";
import {Formik} from "formik";
import {useNavigate} from "react-router-dom";


const dealerSchema = Yup.object().shape({
    distributorName: Yup.string().required("Trader Name is required"),
    distributor_gst: Yup.string().required("GST is required").min(15, 'Must be exactly 15 digits').max(15, 'Must be exactly 15 digits'),
    distributor_address: Yup.string().required("Address is required"),
    distributor_pincode: Yup.string().required("Pin code is required"),
    distributor_phone: Yup.string().required("Phone number is required").min(8, 'Must be at least 8 digits').max(10, 'Must be less than 11 digits'),
    distributor_region: Yup.string().required("Region is required")
});


const SDDistributorForm = ({initialValues,successUrl,setDealerData}) => {


    const navigate = useNavigate();

    const [regionOptions, setRegionOptions] = useState([]);
    const [isRegionLoading, setIsRegionLoading] = useState(true);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function HandlePut(url,data,navigateTo){
        axiosInstance.put("/api/"+url,data).then(function(response,success){
            setDealerData(response.data.sd_distributors);
            handleShow();
            setTimeout(handleClose,2500);
        })

    }


    useEffect(() => {
        const loadSelectData =  () => {

            try{
                axiosInstance.get("/api/regions?"+GetAsInputData({
                    listInfo:{
                        startRecord:0,
                        rowCount:9999,
                        sortBy: [{"field":"region_name","order":"asc"}],
                        searchConditions:[{"field":"country.id","value":"96","type":"number"}]
                    }
                })).then(function(response){
                    setRegionOptions(response.data.regions);
                    setTimeout(() => {
                        setIsRegionLoading(false);
                    }, 1500);
                });

            }catch(e){
                console.log(e);
            }
        }
        loadSelectData();
    },[]);


    return( <Card>
        <Alert show={show} onHide={show} variant="primary" key="0" dismissible>
            <div className="alert-message">
                <strong>SD Distributor</strong> updated successfully!
            </div>
        </Alert>
        <Card.Header>
            <Card.Title tag="h5">{initialValues.distributor_id===undefined?"Add":"Modify"} SD Distributor</Card.Title>
        </Card.Header>
        <Card.Body>
            <Formik
                validationSchema={dealerSchema}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500));
                    HandlePut("sd_distributors/"+values.distributor_id,GetAsInputData({sd_distributor:values}),successUrl);

                }}
                initialValues={initialValues}>
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row>
                            <Col sm={6}>
                                <Form.Group   className="mb-3">
                                    <Form.Label>Trader Name</Form.Label>

                                    <Form.Control
                                        type="text"
                                        name="distributorName"
                                        value={values.distributorName}
                                        placeholder="Trader Name"
                                        isValid={touched.distributorName && !errors.distributorName}
                                        isInvalid={touched.distributorName && !!errors.distributorName}
                                        onChange={handleChange} />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.distributorName}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Label>GST</Form.Label>
                                    <InputGroup   className="mb-3">
                                        <Button onClick={() => {
                                            axiosInstance.get("https://appyflow.in/api/verifyGST?gstNo="+values.distributor_gst+"&key_secret=w1pv9sAv18OwYa73oB8Qatj95EH3")
                                                .then((response) => {
                                                    response = response.data;
                                                    values.distributor_pincode = response.taxpayerInfo.pradr.addr.pncd;
                                                    values.distributor_address = "";
                                                    if(response.taxpayerInfo.pradr.addr.bnm!=="")
                                                        values.distributor_address+=response.taxpayerInfo.pradr.addr.bnm+", ";
                                                    if(response.taxpayerInfo.pradr.addr.bno!=="")
                                                        values.distributor_address+=response.taxpayerInfo.pradr.addr.bno+", ";

                                                    if(response.taxpayerInfo.pradr.addr.flno!=="")
                                                        values.distributor_address+=response.taxpayerInfo.pradr.addr.flno+", ";

                                                    if(response.taxpayerInfo.pradr.addr.lg!=="")
                                                        values.distributor_address+=response.taxpayerInfo.pradr.addr.lg+", ";

                                                    if(response.taxpayerInfo.pradr.addr.lt!=="")
                                                        values.distributor_address+=response.taxpayerInfo.pradr.addr.lt+", ";

                                                    if(response.taxpayerInfo.pradr.addr.loc!=="")
                                                        values.distributor_address+=response.taxpayerInfo.pradr.addr.loc+", ";

                                                    if(response.taxpayerInfo.pradr.addr.st!=="")
                                                        values.distributor_address+=response.taxpayerInfo.pradr.addr.st+", ";

                                                    if(response.taxpayerInfo.pradr.addr.dst!=="")
                                                        values.distributor_address+=response.taxpayerInfo.pradr.addr.dst+", ";

                                                    if(response.taxpayerInfo.pradr.addr.city!==""){
                                                        values.distributor_address+=response.taxpayerInfo.pradr.addr.city+", ";
                                                        values.distributor_region = response.taxpayerInfo.pradr.addr.city;
                                                    }

                                                    if(response.taxpayerInfo.pradr.addr.stcd!==""){
                                                        values.distributor_address+=response.taxpayerInfo.pradr.addr.stcd;
                                                        values.distributor_region = response.taxpayerInfo.pradr.addr.stcd;
                                                    }
                                                    values.distributor_address = values.distributor_address.replaceAll(",,",",");
                                                    values.distributor_gst = response.taxpayerInfo.gstin;
                                                    values.distributorPan = response.taxpayerInfo.panNo;

                                                    let anotherEvent = { target : { name:`distributorName`,value:values.distributorName  } };
                                                    handleChange(anotherEvent);
                                                    anotherEvent = { target : { name:`distributor_gst`,value:values.distributor_gst  } };
                                                    handleChange(anotherEvent);
                                                    anotherEvent = { target : { name:`distributor_address`,value:values.distributor_address  } };
                                                    handleChange(anotherEvent);
                                                    anotherEvent = { target : { name:`distributor_pincode`,value:values.distributor_pincode  } };
                                                    handleChange(anotherEvent);
                                                    anotherEvent = { target : { name:`distributor_region`,value:values.distributor_region } };
                                                    handleChange(anotherEvent);


                                                }).catch((error) => {
                                                console.log(error);
                                            })
                                        }} variant="secondary" id="button-addon1">
                                            Fetch GST details from server
                                        </Button>
                                    <Form.Control
                                        type="text"
                                        name="distributor_gst"
                                        value={values.distributor_gst}
                                        placeholder="GST"
                                        isValid={touched.distributor_gst && !errors.distributor_gst}
                                        isInvalid={!!errors.distributor_gst}
                                        onChange={handleChange} />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.distributor_gst}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Label>
                                    Address
                                </Form.Label>
                                <Form.Control type="text" name="distributor_address"
                                              value={values.distributor_address}
                                              placeholder="Address"
                                              isValid={touched.distributor_address && !errors.distributor_address}
                                              isInvalid={touched.distributor_address &&!!errors.distributor_address}
                                              onChange={handleChange} />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.distributor_address}
                                </Form.Control.Feedback>
                            </Col>
                            <Col sm={2}>
                                <Form.Label>
                                    Pincode
                                </Form.Label>
                                <Form.Control mask="999-999" name="distributor_pincode"
                                           value={values.distributor_pincode}
                                           placeholder="Pincode"
                                           isValid={touched.distributor_pincode && !errors.distributor_pincode}
                                           isInvalid={touched.distributor_pincode && !!errors.distributor_pincode}
                                           onChange={handleChange}/>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.distributor_pincode}
                                </Form.Control.Feedback>
                            </Col>
                            <Col sm={3}>
                                <Form.Label>
                                    Region
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="distributor_region"
                                    value={values.distributor_region}
                                    placeholder="Region"
                                    isValid={touched.distributor_region && !errors.distributor_region}
                                    isInvalid={!!errors.distributor_region}
                                    onChange={handleChange} />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.distributor_region}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Phone Number
                                    </Form.Label>
                                    <Form.Control  mask="999-99-999-99" name="distributor_phone"
                                               value={values.distributor_phone}
                                               placeholder="Phone Number"
                                               isValid={touched.distributor_phone && !errors.distributor_phone}
                                               isInvalid={touched.distributor_phone && !!errors.distributor_phone}
                                               onChange={handleChange}/>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.distributor_phone}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Label>
                                    LandLine Number
                                </Form.Label>
                                <Form.Control type="text" name="distributor_mobile"
                                           value={values.distributor_mobile}
                                           placeholder="Landline Number"
                                           isValid={touched.distributor_mobile && !errors.distributor_mobile}
                                           isInvalid={touched.distributor_mobile && !!errors.distributor_mobile}
                                           onChange={handleChange}/>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.distributor_mobile}
                                </Form.Control.Feedback>
                            </Col>
                            <Col sm={3}>
                                <Form.Label>
                                    Email
                                </Form.Label>
                                <Form.Control type="distributor_email" name="distributor_email"
                                              value={values.distributor_email}
                                              placeholder="Email"
                                              isValid={touched.distributor_email && !errors.distributor_email}
                                              isInvalid={touched.distributor_email && !!errors.distributor_email}
                                              onChange={handleChange}/>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.distributor_email}
                                </Form.Control.Feedback>
                            </Col>

                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Button type="submit" variant="primary">Submit</Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>)}
            </Formik>
        </Card.Body>
    </Card>);
}

export default SDDistributorForm
