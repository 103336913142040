import React, {useContext, useEffect, useState} from "react";

import {Button, Card, Col, Form, Row} from "react-bootstrap";
import * as Yup from "yup";
import {Formik} from "formik";
import axiosInstance, {ErrorMessage, GetAsInputData,} from "../../../../utils/axios";
import NotyfContext from "../../../../contexts/NotyfContext";
import Select from "react-select";

const WarehouseForm = ({ setKey, initialValues, setEditData }) => {
  const notify = useContext(NotyfContext);

  const [regionOptions, setRegionOptions] = useState([]);
  const [isRegionLoading, setIsRegionLoading] = useState(true);

  const schema = Yup.object().shape({
    warehouse_name: Yup.string().required("Name is required"),
    region: Yup.object().required("Region is required"),
  });

  useEffect(() => {
    axiosInstance
      .get(
        "/api/regions?" +
          GetAsInputData({
            listInfo: {
              startRecord: 0,
              rowCount: 9999,
              sortBy: [{ field: "region_name", order: "asc" }],
              searchConditions: [
                { field: "country.id", value: "96", type: "number" },
              ],
            },
          })
      )
      .then(function (response) {
        setRegionOptions(response.data.regions);
        setTimeout(() => {
          setIsRegionLoading(false);
        }, 1000);
      });
  }, []);

  function HandleSubmit(url, data, navigateTo) {
    axiosInstance.post("/api/" + url, data).then(function (response, success) {
      notify.success("Warehouse created");
      setTimeout(() => {
        setKey(new Date().getTime());
      }, 1500);
    });
  }

  function HandlePut(url, data, navigateTo) {
    axiosInstance.put("/api/" + url, data).then(function (response, success) {
      notify.success("Warehouse updated");
      setTimeout(() => {
        setEditData(null);
        setKey(new Date().getTime());
      }, 1500);
    });
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5">
          {initialValues.id == undefined ? "Add" : "Modify"} Warehouse
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Formik
          validationSchema={schema}
          onSubmit={async (values) => {
            if (values.id === undefined)
              HandleSubmit("warehouses", GetAsInputData({ warehouse: values }));
            else
              HandlePut(
                "warehouses/" + values.id,
                GetAsInputData({ warehouse: values })
              );
          }}
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row>
                <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="warehouse_name"
                      value={values.warehouse_name}
                      onChange={handleChange}
                      isValid={touched.warehouse_name && !errors.warehouse_name}
                      isInvalid={touched.warehouse_name && !!errors.warehouse_name}
                      placeholder="Name"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.warehouse_name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Group className={"my-3"}>
                    <Form.Label>Region</Form.Label>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="region"
                      value={values.region}
                      getOptionLabel={(option) => option.region_name}
                      getOptionValue={(option) => option.id}
                      options={regionOptions}
                      isLoading={isRegionLoading}
                      onChange={(selectedOption) => {
                        let event = {
                          target: { name: "region", value: selectedOption },
                        };
                        handleChange(event);
                      }}
                    />
                    <ErrorMessage name={`region`} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Button type="submit" variant="primary">
                      Submit
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default WarehouseForm;
