import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {Badge, Button, Col, Container, Nav, Row, Tab, Table} from "react-bootstrap";
import {useParams} from "react-router-dom";
import axiosInstance, {GetAsInputData, PostApproval} from "../../../../utils/axios";
import Loader from "../../../../components/Loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faExclamation, faTimes} from "@fortawesome/free-solid-svg-icons";
import {PurchaseOrderForm} from "./PurchaseOrderForm";

const PurchaseOrderDetails = () => {
    const params = useParams();
    const [purchaseOrderData,setPurchaseOrderData] = useState();
    const [purchaseOrderInvoices,setPurchaseOrderInvoices] = useState([]);

    useEffect(() => {
        const load =  () => {
            try{
                let entityURL = "/api/purchases/"+params.purchaseOrderID;
                axiosInstance.get(entityURL)
                    .then(function(response){
                        response.data.purchase.products.map((purchaseProduct,index) =>{
                            purchaseProduct.product.seller_price = purchaseProduct.price ;
                            return purchaseProduct;
                        });
                        var date = new Date(Number(response.data.purchase.pi_created_date));
                        var day = ("0" + date.getDate()).slice(-2);
                        var month = ("0" + (date.getMonth() + 1)).slice(-2);
                        response.data.purchase.pi_date_field = date.getFullYear()+"-"+(month)+"-"+(day);
                        setTimeout(() => {
                            setPurchaseOrderData(response.data.purchase);
                        }, 500);
                    })
            }catch(e){
                console.log(e);
            }

            try{
                let entityURL = "/api/purchase_stocks";

                const input_data = {
                    listInfo:{
                        startRecord:0,
                        rowCount:9999,
                        sortBy:[{"field":"receipt.grn_id","order":"desc"}],
                        searchConditions:[{"field":"purchase_order.po_number","value":""+params.purchaseOrderID,"type":"longnumber"}]
                    }
                };


                let encodedData = GetAsInputData(input_data);

                axiosInstance.get(entityURL+"?"+encodedData)
                    .then(function(response){
                        setTimeout(() => {
                            setPurchaseOrderInvoices(response.data.purchase_stocks);
                        }, 500);
                    })
            }catch(e){
                console.log(e);
            }
        }

        load();
    },[]);

    function updateData(){
        try{
            let entityURL = "/api/purchases/"+params.purchaseOrderID;
            axiosInstance.get(entityURL)
                .then(function(response){
                    setTimeout(() => {
                        setPurchaseOrderData(response.data.purchase);
                    }, 500);
                })
        }catch(e){
            console.log(e);
        }
    }

    if(purchaseOrderData ===undefined){
        return <Loader />;
    }else{
        return(

            <Container fluid className="p-0">
                <h1 className="h3 mb-3">{purchaseOrderData.supplier.company_name} - {purchaseOrderData.purchase_number}</h1>
                <Row>
                    <Col lg="12">
                        <div className={"tab " }>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Nav variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Details</Nav.Link>
                                    </Nav.Item>
                                    {
                                        purchaseOrderData.status==="CREATED"?
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">Approval</Nav.Link>
                                            </Nav.Item>:""
                                    }
                                    {
                                        purchaseOrderData.status!=="CREATED" && purchaseOrderData.status!=="REJECTED"?
                                            <Nav.Item>
                                                <Nav.Link eventKey="third">Receipts</Nav.Link>
                                            </Nav.Item>:""
                                    }

                                    {
                                        purchaseOrderData.status==="CREATED" || purchaseOrderData.status==="REJECTED" ?<Nav.Item>
                                            <Nav.Link eventKey="fourth">Modify Purchase Order</Nav.Link>
                                        </Nav.Item>:""
                                    }
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <h4 className="tab-title">Purchase Details</h4>
                                        <Col lg="6">
                                            <Table size="sm" className="my-2">
                                                <tbody>
                                                <tr>
                                                    <th>ID</th>
                                                    <td>{purchaseOrderData.po_number}</td>
                                                </tr>
                                                <tr>
                                                    <th>Created on</th>
                                                    <td>{new Date(Number(purchaseOrderData.created_date)).toString().slice(3,21)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Created by</th>
                                                    <td>{purchaseOrderData.created_by.name}</td>
                                                </tr>
                                                <tr>
                                                    <th>Proforma Number</th>
                                                    <td>{purchaseOrderData.pi_number}</td>
                                                </tr>
                                                <tr>
                                                    <th>Proforma Date</th>
                                                    <td>{new Date(Number(purchaseOrderData.pi_created_date)).toString().slice(3,21)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Mode of Transport</th>
                                                    <td>{purchaseOrderData.transport}</td>
                                                </tr>

                                                <tr>
                                                    <th>Total</th>
                                                    <td>{purchaseOrderData.total.toLocaleString("en-IN", {
                                                        style: "currency",
                                                        currency: "INR"
                                                    })}</td>
                                                </tr>
                                                <tr>
                                                    <th>Status</th>
                                                    <td>
                                                        {
                                                            purchaseOrderData.status==="CLOSED"?
                                                                <Badge bg="success"><FontAwesomeIcon icon={faCheck} /> Closed</Badge>:
                                                                purchaseOrderData.status==="REJECTED"?
                                                                    <Badge bg="danger" ><FontAwesomeIcon icon={faTimes} /> Rejected</Badge>:
                                                                    purchaseOrderData.status==="ACKNOWLEDGED"?
                                                                        <Badge bg="success" ><FontAwesomeIcon icon={faExclamation} /> Acknowledged</Badge>:<Badge>{purchaseOrderData.status}</Badge>
                                                        }
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Button variant={`info`} className=" me-1 mb-1" onClick={() => {

                                            window.open("/purchases/" + purchaseOrderData.po_number+"/downloadFile","_blank")
                                        }} ><FontAwesomeIcon icon={faExclamation} /> Download Purchase order </Button>
                                        <br/>
                                        <h4 className="tab-title">Purchase Products </h4>
                                        <Col lg="6">
                                            <div className="table-responsive">
                                                {
                                                    <Table size="sm" className="my-2">
                                                        <thead>
                                                        <tr>
                                                            <th>Product Name</th>
                                                            <th>Quantity</th>
                                                            <th>Pending</th>
                                                            <th>Price</th>
                                                            <th>Amount</th>
                                                            <th>GST</th>
                                                            <th>Total</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        { purchaseOrderData.products.map((product, i) => {
                                                            return (
                                                                <tr>
                                                                    <td>{product.product.name}</td>
                                                                    <td>{product.quantity}</td>
                                                                    <td>{product.pending_quantity}</td>
                                                                    <td>{product.price}</td>
                                                                    <td>{product.total_before_gst}</td>
                                                                    <td>{product.total_amount - product.total_before_gst}</td>
                                                                    <td>{product.total_amount}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>
                                                    </Table>
                                                }
                                            </div>
                                        </Col>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">

                                        <div className="table-responsive">
                                            <Table>
                                                <thead>
                                                <tr>
                                                    <th>GRN ID</th>
                                                    <th>Created by</th>
                                                    <th>Created date</th>
                                                    <th>Supplier Invoice Number</th>
                                                    <th>Supplier Invoice Date</th>
                                                    <th>Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {purchaseOrderInvoices.length>0?purchaseOrderInvoices.map( (row, i) => {
                                                    return(
                                                        <tr>
                                                            <td>{row.receipt.grn_id}</td>
                                                            <td>{row.receipt.created_by.name}</td>
                                                            <td>{new Date(Number(row.receipt.created_date)).toString().slice(3,21)}</td>
                                                            <td>{row.receipt.invoice_number}</td>
                                                            <td>{new Date(Number(row.receipt.invoice_date)).toString().slice(3,21)}</td>
                                                            <td>{
                                                                row.receipt.status==="STOCK_APPROVED"?
                                                                    <Badge bg="success"><FontAwesomeIcon icon={faCheck} /> Approved</Badge>:
                                                                    row.receipt.status==="REJECTED"?
                                                                        <Badge bg="danger" ><FontAwesomeIcon icon={faTimes} /> Rejected</Badge>:
                                                                        row.receipt.status==="CREATED"?
                                                                            <Badge bg="warning" ><FontAwesomeIcon icon={faExclamation} /> Waiting for approval</Badge>:<Badge>{row.invoice.status}</Badge>
                                                            }</td>
                                                        </tr>
                                                    );
                                                }):("No receipts available")}

                                                </tbody>
                                            </Table>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fourth">{
                                        (purchaseOrderData.status==="REJECTED" || purchaseOrderData.status==="CREATED") && <PurchaseOrderForm initialValues={purchaseOrderData} setPurchaseData={setPurchaseOrderData} successUrl={"/purchases/orders-details/"+purchaseOrderData.po_number} />

                                    }</Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <Col lg={2}>
                                            <Table size="sm" className="my-2">
                                                <tbody>
                                                <tr>
                                                    <th>Order Total</th>
                                                    <td>{purchaseOrderData.total}</td>
                                                </tr>
                                                <tr>
                                                    <th>Status</th>
                                                    <td>{
                                                        purchaseOrderData.status==="CLOSED"?
                                                            <Badge bg="success"><FontAwesomeIcon icon={faCheck} /> Closed</Badge>:
                                                            purchaseOrderData.status==="REJECTED"?
                                                                <Badge bg="danger" ><FontAwesomeIcon icon={faTimes} /> Rejected</Badge>:
                                                                purchaseOrderData.status==="ACKNOWLEDGED"?
                                                                    <Badge bg="success" ><FontAwesomeIcon icon={faExclamation} /> Acknowledged</Badge>:<Badge>{purchaseOrderData.status}</Badge>
                                                    }</td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <br/>
                                        { purchaseOrderData.status==="CREATED" && <Button variant={`primary`} className=" me-1 mb-1" onClick={ () =>{PostApproval("purchases",purchaseOrderData.po_number,"approval","APPROVED",updateData);}}>Approve</Button> }

                                        { purchaseOrderData.status==="CREATED" && <Button variant={`danger`} className=" me-1 mb-1" onClick={ () =>{PostApproval("purchases",purchaseOrderData.po_number,"approval","REJECTED",updateData);}}> Reject </Button> }
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }


};

const PurchaseOrderDetailsPage = () => (

    <React.Fragment>
        <Helmet title="Sales Order Details" />
        <PurchaseOrderDetails />
    </React.Fragment>
);

export default PurchaseOrderDetailsPage;
